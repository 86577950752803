<template>
    <form-item-wrapper-ly :field="field" :rules="rules" :design-state="designState"
             :parent-widget="parentWidget" :parent-list="parentList" :index-of-parent-list="indexOfParentList">
        <div :class="[field.options.autoFullWidth ? 'lyfullwidth' : '']">
            <el-time-picker ref="lyfieldEditor" v-model="fieldModel"
                :disabled="field.options.disabled" :readonly="field.options.readonly"
                :size="widgetSize"
                :clearable="field.options.clearable" :editable="field.options.editable"
                :format="field.options.format" value-format="HH:mm:ss"
                :placeholder="field.options.placeholder || '选择时间'"
                @focus="handleFocusCustomEvent" @blur="handleBlurCustomEvent"
                @change="handleChangeEvent">
            </el-time-picker>
        </div>
    </form-item-wrapper-ly>
</template>

<script setup>
    import {ref, onMounted, reactive, computed, watch, inject,getCurrentInstance} from 'vue'
    import { useBuilderStore } from "@/store/lyFormBuilder";
    import useCommon from "@/components/lyform-builder/lyform-design/useCommon";
    
    const {proxy}  = getCurrentInstance(); 

    const props = defineProps({
        field: Object,
        parentWidget: Object,
        parentList: Array,
        indexOfParentList: Number,
        designState: {
            type: Boolean,
            default: false
        },
    })
    const builderStore = useBuilderStore()
    const fieldModel = ref(null)
    let rules = ref([])
    const widgetSize = computed(() => {
        return props.field.options.size || 'default'
    })
    function handleFocusCustomEvent(event) {
        if (!!props.field.options.onFocus) {
            let customFn = new Function('event', props.field.options.onFocus)
            customFn(proxy,event)
        }
    }
    function handleBlurCustomEvent(event) {
        if (!!props.field.options.onBlur) {
            let customFn = new Function('event', props.field.options.onBlur)
            customFn.call(proxy,event)
        }
    }
    let {createFieldRules,getPropName,handleFieldValidation} = useCommon()
    const globalFormModel = inject("globalFormModel",null)
    let refList = inject("refList",null)

    function updateFormModel(value) {
        if (!!props.designState) {
            return
        }
        let keyName = getPropName(props)
        globalFormModel.value[keyName] = value
    }
    function handleChangeEvent(value) {
        updateFormModel(value)
        handleFieldValidation(getPropName(props))
        if (!!props.field.options.onChange) {
            let customFn = new Function('value', props.field.options.onChange)
            customFn.call(proxy,value)
        }
    }

    function getWidgetRef(widgetName) {
        if((refList != null) && (refList.value != null)){
            return refList.value[widgetName]
        }
        return null
    }

    function getValue(){
        return fieldModel.value
    }

    function setValue(newValue){
        fieldModel.value = newValue
        updateFormModel(newValue)
        handleFieldValidation(getPropName(props))
    }

    function clearFieldRules() {
        if (!props.field.formItemFlag) {
            return
        }
        rules.value.splice(0, rules.value.length)
    }

    function setHidden(flag) {
        props.field.options.hidden = flag
        if (!!flag) { 
            clearFieldRules() //既然隐藏了，就没必要做form表单验证
        } else {
            createFieldRules(props.field,rules.value) //不隐藏就把表单校验加回来
        }
    }

    function initRefList() {
        if ((refList != null) && (refList.value != null) && !!props.field.options.name) {
            refList.value[props.field.options.name] = proxy
        }
    }

    function initFieldModel() {
        if (!props.field.formItemFlag) {
            return
        }
        if(!(!!globalFormModel)){
            fieldModel.value = props.field.options.defaultValue
        }else if(globalFormModel.value[props.field.options.name]=== undefined && props.field.options.defaultValue !== undefined){
            fieldModel.value = props.field.options.defaultValue
        }else if(globalFormModel.value[props.field.options.name] === undefined){
            globalFormModel.value[props.field.options.name] = null
        }else {
            fieldModel.value = globalFormModel.value[props.field.options.name]
        }
    }
    watch(()=>props.field.options.defaultValue,(n,o)=>{
        fieldModel.value = n
    })
    onMounted(()=>{
        initFieldModel()
        initRefList()
        createFieldRules(props.field,rules.value)
    })

    defineExpose({
        getWidgetRef,
        getValue,
        setValue,
        setHidden
    })

</script>

<style lang="scss" scoped>
    .lyfullwidth {
        width: 100% !important;
        :deep(.el-date-editor){
            width: 100% !important;
        }
    }
</style>