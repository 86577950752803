<template>
    <static-content-wrapper-ly :field="field" :rules="rules" :design-state="designState"
                     :parent-widget="parentWidget" :parent-list="parentList" :index-of-parent-list="indexOfParentList">
        <el-text ref="lyfieldEditor" :tag="field.options.tag"
                 :style="[`text-align:${field.options.labelPosition}`,!!field.options.fontSize ? `font-size: ${field.options.fontSize};`: '']"
                 :type="field.options.type" :truncated="field.options.truncated">
            {{field.options.textContent}}
        </el-text>
    </static-content-wrapper-ly>
</template>

<script setup>
    import {ref, onMounted, reactive, computed, watch,inject, getCurrentInstance} from 'vue'
    import { useBuilderStore } from "@/store/lyFormBuilder";
    import useCommon from "@/components/lyform-builder/lyform-design/useCommon";

    const {proxy}  = getCurrentInstance(); 

    const props = defineProps({
        field: Object,
        parentWidget: Object,
        parentList: Array,
        indexOfParentList: Number,
        designState: {
            type: Boolean,
            default: false
        },
    })
    const builderStore = useBuilderStore()
    const fieldModel = ref(null)
    let rules = ref([])
    let refList = inject("refList",null)

    let {createFieldRules} = useCommon()

    const widgetSize = computed(() => {
        return props.field.options.size || 'default'
    })

    function getWidgetRef(widgetName) {
        if((refList != null) && (refList.value != null)){
            return refList.value[widgetName]
        }
        return null
    }

    function getValue(){
        return fieldModel.value
    }

    function setValue(newValue){
        fieldModel.value = newValue
        // updateFormModel(newValue)
        // handleFieldValidation(getPropName(props))
    }

    function clearFieldRules() {
        if (!props.field.formItemFlag) {
            return
        }
        rules.value.splice(0, rules.value.length)
    }

    function setHidden(flag) {
        props.field.options.hidden = flag
        if (!!flag) { 
            clearFieldRules() //既然隐藏了，就没必要做form表单验证
        } else {
            createFieldRules(props.field,rules.value) //不隐藏就把表单校验加回来
        }
    }

    function initRefList() {
        if ((refList != null) && (refList.value != null) && !!props.field.options.name) {
            refList.value[props.field.options.name] = proxy
        }
    }

    function initFieldModel() {
        if (!props.field.formItemFlag) {
            return
        }
        fieldModel.value = props.field.options.defaultValue
    }
    watch(()=>props.field.options.defaultValue,(n,o)=>{
        fieldModel.value = n
    })

    onMounted(()=>{
        initFieldModel()
        initRefList()
    })

    defineExpose({
        getWidgetRef,
        getValue,
        setValue,
        setHidden
    })

</script>

<style lang="scss" scoped>
</style>