<!--
 * Descripttion: 流程设计器
 * version: 1.0
 * Author: lybbn
 * Email: 1042594286@qq.com
 * Program: django-vue-lyadmin 专业版
 * Website: http://doc.lybbn.cn
 * Date: 2023-11-29
 * LastEditDate: 2023-11-29
 * Remark: 如果要分发django-vue-lyadmin源码或其中组件等，需在本文件顶部保留此文件头信息！！！
-->
<template>
    <LyWorkflow ref="lyworkflow"></LyWorkflow>
</template>

<script setup>
    import {ref} from 'vue'
    import LyWorkflow from '@/components/lyworkflow/index'
    let lyworkflow = ref(null)
    function setFull(){
        lyworkflow.value.setFull()
    }
    defineExpose({
        setFull
    })
</script>

<style scoped>

</style>