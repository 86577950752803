<template>
    <form-item-wrapper-ly :field="field" :rules="rules" :design-state="designState"
             :parent-widget="parentWidget" :parent-list="parentList" :index-of-parent-list="indexOfParentList">
        <el-select ref="lyfieldEditor" v-model="fieldModel" class="lyfullwidthinput"
               :disabled="field.options.disabled"
               :size="widgetSize"
               :clearable="field.options.clearable"
               :filterable="field.options.filterable"
               :allow-create="field.options.allowCreate"
               :default-first-option="allowDefaultFirstOption"
               :automatic-dropdown="field.options.automaticDropdown"
               :multiple="field.options.multiple" :multiple-limit="field.options.multipleLimit"
               :placeholder="field.options.placeholder || '请选择'"
               :remote="field.options.remote" :remote-method="handleOnRemoteMethod" @remove-tag="handelRemoveTagEvent"
               @focus="handleFocusCustomEvent" @blur="handleBlurCustomEvent"
               @change="handleChangeEvent">
            <el-option v-for="item in field.options.optionItems" :key="item[field.options.crudField.foreign_key.value]" :label="item[field.options.crudField.foreign_key.label]" :value="lyconvertStrNum(fieldModel,item[field.options.crudField.foreign_key.value])"  v-if="field.options.useForeignData"></el-option>
            <el-option v-for="item in field.options.optionItems" :key="item.value" :label="item.label" :value="lyconvertStrNum(fieldModel,item.value)"  v-else></el-option>
        </el-select>
    </form-item-wrapper-ly>
</template>

<script setup>
    import {ref, onMounted ,reactive,computed,watch,inject, getCurrentInstance} from 'vue'
    import { useBuilderStore } from "@/store/lyFormBuilder";
    import useCommon from "@/components/lyform-builder/lyform-design/useCommon";

    const {proxy}  = getCurrentInstance();

    const props = defineProps({
        field: Object,
        parentWidget: Object,
        parentList: Array,
        indexOfParentList: Number,
        designState: {
            type: Boolean,
            default: false
        },
    })
    const builderStore = useBuilderStore()
    const fieldModel = ref(null)
    let rules = ref([])
    const emit = defineEmits(['handelFieldValidate'])

    const widgetSize = computed(() => {
        return props.field.options.size || 'default'
    })
    let {createFieldRules,getPropName,handleFieldValidation,lyconvertStrNum} = useCommon()
    const globalFormModel = inject("globalFormModel",null)
    let refList = inject("refList",null)

    function updateFormModel(value) {
        if (!!props.designState) {
            return
        }
        let keyName = getPropName(props)
        globalFormModel.value[keyName] = value
    }
    function handleChangeEvent(value) {
        updateFormModel(value)
        handleFieldValidation(getPropName(props))
        if (!!props.field.options.onChange) {
            let customFn = new Function('value', props.field.options.onChange)
            customFn.call(proxy,value)
        }
    }
    function handelRemoveTagEvent(value){
        if (!!props.field.options.onRemoveTag) {
            let customFn = new Function('value', props.field.options.onRemoveTag)
            customFn.call(proxy,value)
        }
    }
    function handleFocusCustomEvent(event) {
        if (!!props.field.options.onFocus) {
            let customFn = new Function('event', props.field.options.onFocus)
            customFn.call(proxy,event)
        }
    }
    function handleBlurCustomEvent(event) {
        if (!!props.field.options.onBlur) {
            let customFn = new Function('event', props.field.options.onBlur)
            customFn.call(proxy,event)
        }
    }

    function getWidgetRef(widgetName) {
        if((refList != null) && (refList.value != null)){
            return refList.value[widgetName]
        }
        return null
    }

    function getValue(){
        return fieldModel.value
    }

    function setValue(newValue){
        fieldModel.value = newValue
        updateFormModel(newValue)
        handleFieldValidation(getPropName(props))
    }

    function clearFieldRules() {
        if (!props.field.formItemFlag) {
            return
        }
        rules.value.splice(0, rules.value.length)
    }

    function setHidden(flag) {
        props.field.options.hidden = flag
        if (!!flag) { 
            clearFieldRules() //既然隐藏了，就没必要做form表单验证
        } else {
            createFieldRules(props.field,rules.value) //不隐藏就把表单校验加回来
        }
    }

    function initRefList() {
        if ((refList != null) && (refList.value != null) && !!props.field.options.name) {
            refList.value[props.field.options.name] = proxy
        }
    }

    function initFieldModel() {
        if (!props.field.formItemFlag) {
            return
        }
        if(!(!!globalFormModel)){
            fieldModel.value = props.field.options.defaultValue
        }else if(globalFormModel.value[props.field.options.name]=== undefined && props.field.options.defaultValue !== undefined){
            fieldModel.value = props.field.options.defaultValue
        }else if(globalFormModel.value[props.field.options.name] === undefined){
            globalFormModel.value[props.field.options.name] = null
        }else {
            fieldModel.value = globalFormModel.value[props.field.options.name]
        }
    }

    function handleOnRemoteMethod(query) {
        if (!!props.field.options.onRemoteMethod) {
            let remoteFn = new Function('query', props.field.options.onRemoteMethod)
            remoteFn(query)
        }
    }
    const allowDefaultFirstOption = computed(() => {
        return (!!props.field.options.filterable && !!props.field.options.allowCreate)
    })

    watch(()=>props.field.options.defaultValue,(n,o)=>{
        fieldModel.value = n
    })
    onMounted(()=>{
        initFieldModel()
        initRefList()
        createFieldRules(props.field,rules.value)
    })

    defineExpose({
        getWidgetRef,
        getValue,
        setValue,
        setHidden
    })

</script>

<style lang="scss" scoped>
    .lyfullwidthinput {
        width: 100% !important;
    }
</style>