<template>
  <div>
    <ly-dialog v-model="dialogVisible" :title="loadingTitle" :width="dialogWidth" :height="6000" :top="dialogTop"
               :fullscreen="fullscreen"
               :before-close="handleClose" class="form-dialog">
      <el-form :inline="false" :model="formData" :rules="rules" ref="rulesForm" label-position="right"
               label-width="auto" class="form-data">
        <div class="form-data-item">
          <div class="form-title">基础信息</div>
          <!--          <div style="display: flex;align-items: center;">-->
          <!--            <el-form-item label="产品分类：" prop="category_product">-->
          <!--              <el-select v-model="formData.category_product" placeholder="请选择分类" clearable filterable multiple-->
          <!--                         style="width: 300px">-->
          <!--                <el-option-->
          <!--                    v-for="item in category_product_list"-->
          <!--                    :key="item.id"-->
          <!--                    :label="item.name"-->
          <!--                    :value="item.id">-->
          <!--                </el-option>-->
          <!--              </el-select>-->
          <!--            </el-form-item>-->
          <!--          </div>-->
          <el-form-item label="产品分类：" prop="category_product_many" v-if="true">
            <el-select v-model="formData.category_product_many" multiple clearable filterable placeholder="请选择分类"
                       style="width: 100%;">
              <el-option
                  v-for="item in category_product_many_list"
                  :key="item.id"
                  :label="item.name"
                  :value="item.id"/>
            </el-select>
          </el-form-item>
          <el-form-item label="商品名称：" prop="name">
            <el-input v-model.trim="formData.name" placeholder="请输入商品名称" style="width: 300px"></el-input>
          </el-form-item>
          <el-form-item label="商品标题：" prop="sub_name">
            <el-input v-model.trim="formData.sub_name" placeholder="请输入商品标题" style="width: 300px"></el-input>
          </el-form-item>
          <el-form-item label="商品排序：" prop="sort">
            <el-input-number v-model="formData.sort" :min="0" :max="9999"></el-input-number>
          </el-form-item>
          <el-form-item label="是否推荐：" prop="is_tuijian">
            <el-radio-group v-model="formData.is_tuijian" style="width: 380px">
              <el-radio :label="true">是</el-radio>
              <el-radio :label="false">否</el-radio>
            </el-radio-group>
          </el-form-item>
          <el-form-item label="是否显示会员价：" prop="is_vip_price">
            <el-radio-group v-model="formData.is_vip_price" style="width: 380px">
              <el-radio :label="true">是</el-radio>
              <el-radio :label="false">否</el-radio>
            </el-radio-group>
          </el-form-item>
          <el-form-item label="商品状态：" prop="is_launched">
            <el-radio-group v-model="formData.is_launched" style="width: 380px">
              <el-radio :label="true">上架</el-radio>
              <el-radio :label="false">下架</el-radio>
            </el-radio-group>
          </el-form-item>
          <el-form-item label="关联型号表" prop="model_list" v-if="true">
            <el-select v-model="formData.model_list" clearable filterable
                       placeholder="请选择关联型号表" style="width: 100%;">
              <el-option
                  v-for="item in model_listLyAudoCodeAPIList"
                  :key="item.id"
                  :label="item.name"
                  :value="item.id"/>
            </el-select>
          </el-form-item>
          <el-form-item label="是否显示关联型号表：" prop="is_model_list">
            <el-radio-group v-model="formData.is_model_list" style="width: 380px">
              <el-radio :label="true">是</el-radio>
              <el-radio :label="false">否</el-radio>
            </el-radio-group>
          </el-form-item>
          <el-form-item label="关联通用表" prop="general_list" v-if="true">
            <el-select v-model="formData.general_list" clearable filterable
                       placeholder="请选择关联通用表" style="width: 100%;">
              <el-option
                  v-for="item in general_listLyAudoCodeAPIList"
                  :key="item.id"
                  :label="item.name"
                  :value="item.id"/>
            </el-select>
          </el-form-item>
          <el-form-item label="是否显示关联通用表：" prop="is_general_list">
            <el-radio-group v-model="formData.is_general_list" style="width: 380px">
              <el-radio :label="true">是</el-radio>
              <el-radio :label="false">否</el-radio>
            </el-radio-group>
          </el-form-item>
          <el-form-item label="是否包邮：" prop="is_free_shipping">
            <el-radio-group v-model="formData.is_free_shipping" style="width: 380px">
              <el-radio :label="true">是</el-radio>
              <el-radio :label="false">否</el-radio>
            </el-radio-group>
          </el-form-item>
          <el-form-item label="运费" prop="freight_config" v-if="true">
            <el-select v-model="formData.freight_config" clearable filterable
                       placeholder="请选择运费" style="width: 100%;">
              <el-option
                  v-for="item in freight_configLyAudoCodeAPIList"
                  :key="item.id"
                  :label="item.comment"
                  :value="item.id"/>
            </el-select>
          </el-form-item>
          <el-form-item label="服务" prop="serve_product" v-if="true">
            <el-select v-model="formData.serve_product" multiple clearable
                       filterable placeholder="请选择服务" style="width: 100%;">
              <el-option
                  v-for="item in serve_productLyAudoCodeAPIList"
                  :key="item.id"
                  :label="item.name"
                  :value="item.id"/>
            </el-select>
          </el-form-item>
          <el-form-item label="特点" prop="characteristic_product" v-if="true">
            <el-select v-model="formData.characteristic_product" multiple
                       clearable filterable placeholder="请选择特点" style="width: 100%;">
              <el-option
                  v-for="item in characteristic_productLyAudoCodeAPIList"
                  :key="item.id"
                  :label="item.name"
                  :value="item.id"/>
            </el-select>
          </el-form-item>
        </div>
        <div class="form-data-item">
          <div class="form-title">商品图片</div>
          <el-form-item label="商品缩略图：" prop="default_image" style="padding: 10px">
            <el-upload
                ref="lyimagupload"
                class="avatar-uploader"
                :limit="1"
                action=""
                :show-file-list="false"
                :http-request="imgUploadRequest"
                :on-success="imgUploadSuccess"
                :before-upload="imgBeforeUpload">
              <img v-if="formData.default_image" :src="formData.default_image" class="avatar">
              <el-icon v-else class="avatar-uploader-icon">
                <Plus/>
              </el-icon>
            </el-upload>
          </el-form-item>
          <el-form-item label="商品轮播图：" style="padding: 10px">
            <ly-upload-goods v-model="pics"></ly-upload-goods>
          </el-form-item>
          <el-form-item label="首页视频：" prop="home_video" v-if="true">
            <ly-public-file-multiple-upload
                v-model="formData.home_video"
                :multiple="true"
                :drag="true"
                :limit="1"
                tip=""
                :show-file-list="true"
                :max-size="200"
                :http-request="homeVideoUploadRequest"
                :on-success="homeVideoUploadSuccess"
                :before-upload="homeVideoBeforeUpload"
                icon="plus"
                button-type="primary">
              <el-icon class="el-icon--upload" v-if="true">
                <upload-filled/>
              </el-icon>
              <div class="el-upload__text" v-if="true">
                拖拽文件到这 或 <em>点击上传</em>
              </div>
            </ly-public-file-multiple-upload>
          </el-form-item>
        </div>
        <div class="form-data-item">
          <div class="form-title">规格配置</div>
          <el-form-item label="商品规格：" prop="spec_type">
            <el-radio-group v-model="formData.spec_type" style="width: 380px">
              <el-radio :label="0">单规格</el-radio>
              <el-radio :label="1">多规格</el-radio>
            </el-radio-group>
          </el-form-item>
          <!-- 单规格-->
          <div v-if="formData.spec_type==0">
            <el-form-item label="商品价格：" prop="price" class="is-required">
              <el-input-number v-model="formData.price" :precision="2" style="width: 300px"
                               placeholder="请输入商品普通价格"></el-input-number>
            </el-form-item>
            <el-form-item label="普通会员价格：" prop="price_vip" class="is-required">
              <el-input-number v-model="formData.price_vip" :precision="2" style="width: 300px"
                               placeholder="请输入普通会员价格"></el-input-number>
            </el-form-item>
            <el-form-item label="超级会员价格：" prop="price_svip" class="is-required">
              <el-input-number v-model="formData.price_svip" :precision="2" style="width: 300px"
                               placeholder="请输入超级会员价格"></el-input-number>
            </el-form-item>
            <el-form-item label="库存：" prop="stock" class="is-required">
              <el-input-number v-model="formData.stock" style="width: 300px" placeholder="请输入库存"></el-input-number>
            </el-form-item>
            <el-form-item prop="shop_number" label="商品编号" min-width="150">
              <el-input v-model="formData.shop_number" placeholder="请输入商品编号" style="width: 100%">
              </el-input>
            </el-form-item>
            <el-form-item prop="supplier" label="供应商" min-width="150">
              <el-input v-model="formData.supplier" placeholder="请输入供应商" style="width: 100%">
              </el-input>
            </el-form-item>
            <el-form-item prop="remarks" label="备注" min-width="150">
              <el-input v-model="formData.remarks" placeholder="请输入备注" style="width: 100%">
              </el-input>
            </el-form-item>
          </div>
          <!-- 多规格-->
          <div v-if="formData.spec_type==1">
            <!--                                    <el-form-item  label="选择规格："  >-->
            <!--                                        <el-select  v-model="formData.category1" placeholder="请选择规格模板" clearable filterable  style="width: 300px">-->
            <!--                                            <el-option-->
            <!--                                                    v-for="item in category_list"-->
            <!--                                                    :key="item.id"-->
            <!--                                                    :label="item.name"-->
            <!--                                                    :value="item.id">-->
            <!--                                            </el-option>-->
            <!--                                        </el-select>-->
            <!--                                    </el-form-item>-->
            <el-form-item label="" class="is-required">
              <!--              <el-button type="primary" @click="addSpec()" v-if="createBnt">添加新规格</el-button>-->
              <!--              <el-button type="success" @click="generateSpec()" v-if="generatenowBnt" style="margin-left:10px">-->
              <!--                立即生成-->
              <!--              </el-button>-->
              <el-button type="primary" @click="addSpec()">添加新规格</el-button>
              <el-button type="success" @click="generateSpec()" style="margin-left:10px">
                立即生成
              </el-button>
            </el-form-item>
            <div style="display: flex;align-items: center;" v-if="isSpecBtn">
              <el-form-item label="规格名：" prop="specName">
                <el-input v-model.trim="specName" style="width: 200px" placeholder="请输入规格名"></el-input>
              </el-form-item>
              <el-form-item label="规格值：" prop="specValue">
                <el-input v-model.trim="specValue" style="width: 200px" placeholder="请输入规格值"></el-input>
              </el-form-item>
              <el-button type="primary" @click="createSpecName()" style="margin-bottom: 18px;margin-left: 10px;">确定
              </el-button>
              <el-button @click="cancelAddSpec()" style="margin-bottom: 18px;margin-left: 10px;">取消</el-button>
            </div>
            <el-form-item label="" style="padding-right: 10px;padding-left: 10px;">
              <div style="width: 100%">
                <draggable group="guige" :list="specList" :move="checkMove" @end="end" handle=".move-icon"
                           animation="500" item-key="value">
                  <template #item="{ element,index}">
                    <div class="tag-item">
                      <div class="move-icon">
                        <span class="el-icon-s-grid"><el-icon><Grid/></el-icon></span>
                      </div>
                      <div class="input-item" :class="moveIndex === index ? 'borderStyle' : ''">
                        <div>
                          <span>{{ element.value }}</span>
                          <i class="el-icon-error" @click="deleteSpec(index, element)">
                            <el-icon>
                              <CircleCloseFilled/>
                            </el-icon>
                          </i>
                        </div>
                        <div class="spec-item">
                          <el-tag
                              type="warning"
                              size="large"
                              closable
                              color="primary"
                              v-for="(j, indexn) in element.detail"
                              :key="indexn"
                              :name="j"
                              @close="handleRemove2(element.detail, indexn, element)"
                          >{{ j }}
                          </el-tag>
                          <el-input placeholder="请输入属性名称" v-model="element.detail.attrsVal" style="width: 200px">
                            <template #append>
                              <!--                              <el-button style="color: #FFF;background-color: #409EFF;border-color: #409EFF;"-->
                              <!--                                         @click="addSpecDetail(element.detail.attrsVal,index)"-->
                              <!--                                         :disabled="!generatenowBnt">-->
                              <!--                                添加-->
                              <!--                              </el-button>-->
                              <el-button style="color: #FFF;background-color: #409EFF;border-color: #409EFF;"
                                         @click="addSpecDetail(element.detail.attrsVal,index)">
                                添加
                              </el-button>
                            </template>
                          </el-input>
                        </div>
                      </div>
                    </div>
                  </template>
                </draggable>
              </div>
              <!-- 多规格表格展示设置-->
              <!-- 批量设置-->
              <div style="width: 100%">
                <div class="table" v-if="tableColumnList.tableHeaderList.length>0">
                  <span>批量设置：</span>
                  <el-table :data="manySpecBatchData" border style="width: 100%">
                    <el-table-column prop="price" label="售价" min-width="140">
                      <template #default="scope">
                        <el-input-number v-model="scope.row.price" v-limit-positive-number-fixed2 :precision="2"
                                         controls-position="right" :min="0" :max="9999999" label="普通售价"
                                         style="width: 100%"></el-input-number>
                      </template>
                    </el-table-column>
                    <el-table-column prop="price_vip" label="普通会员售价" min-width="140">
                      <template #default="scope">
                        <el-input-number v-model="scope.row.price_vip" v-limit-positive-number-fixed2 :precision="2"
                                         controls-position="right" :min="0" :max="9999999" label="普通会员售价"
                                         style="width: 100%"></el-input-number>
                      </template>
                    </el-table-column>
                    <el-table-column prop="price_svip" label="超级会员售价" min-width="140">
                      <template #default="scope">
                        <el-input-number v-model="scope.row.price_svip" v-limit-positive-number-fixed2 :precision="2"
                                         controls-position="right" :min="0" :max="9999999" label="超级会员售价"
                                         style="width: 100%"></el-input-number>
                      </template>
                    </el-table-column>
                    <el-table-column prop="stock" label="库存" min-width="140">
                      <template #default="scope">
                        <el-input-number v-model="scope.row.stock" v-limit-positive-number :precision="0"
                                         controls-position="right" :min="0" :max="9999999" label="库存"
                                         style="width: 100%">

                        </el-input-number>
                      </template>
                    </el-table-column>
                    <el-table-column prop="shop_number" label="商品编号" min-width="150">
                      <template #default="scope">
                        <el-input v-model="scope.row.shop_number" placeholder="请输入商品编号" style="width: 100%">
                        </el-input>
                      </template>
                    </el-table-column>
                    <el-table-column prop="supplier" label="供应商" min-width="150">
                      <template #default="scope">
                        <el-input v-model="scope.row.supplier" placeholder="请输入供应商" style="width: 100%">
                        </el-input>
                      </template>
                    </el-table-column>
                    <el-table-column prop="remarks" label="备注" min-width="150">
                      <template #default="scope">
                        <el-input v-model="scope.row.remarks" placeholder="请输入备注" style="width: 100%">
                        </el-input>
                      </template>
                    </el-table-column>
                    <el-table-column label="操作" min-width="160">
                      <template #default="scope">
                        <span class="table-operate-btn" @click="batchAdd">批量添加</span>
                        <span class="table-operate-btn" @click="batchDel">清空</span>
                      </template>
                    </el-table-column>
                  </el-table>
                </div>
                <!-- 多规格表格-->
                <div class="table" v-if="tableColumnList.tableBodyList.length>0">
                  <span>商品规格：</span>
                  <el-table :data="tableColumnList.tableBodyList" border style="width: 100%">
                    <el-table-column show-overflow-tooltip :label="item.propName" :property="item.prop"
                                     v-for="item in tableColumnList.tableHeaderList" :key="item.prop" min-width="150">
                      <template #default="scope">
                        <span>{{ scope.row[scope.column.property] }}</span>
                      </template>
                    </el-table-column>
                    <el-table-column prop="default_image" label="图片" min-width="80">
                      <template #default="scope">
                        <el-upload
                            class="avatar-uploader"
                            action=""
                            :show-file-list="false"
                            :http-request="imgUploadRequest_sku"
                            :on-success="(response, file, fileList)=>imgUploadSuccess_sku(response, file, fileList,scope.$index)"
                            :before-upload="imgBeforeUpload_sku">
                          <img v-if="scope.row.default_image" :src="scope.row.default_image" class="avatar"
                               style="width: 60px;height: 60px;line-height: 60px;">
                          <i v-else class="el-icon-plus avatar-uploader-icon"
                             style="width: 60px;height: 60px;line-height: 60px;">
                            <el-icon>
                              <Plus/>
                            </el-icon>
                          </i>
                        </el-upload>
                      </template>
                    </el-table-column>
                    <el-table-column prop="is_launched" label="状态" min-width="80">
                      <template #default="scope">
                        <el-tag v-if="scope.row.is_launched">上架</el-tag>
                        <el-tag v-else type="danger">下架</el-tag>
                      </template>
                    </el-table-column>
                    <el-table-column prop="price" label="售价" min-width="150">
                      <template #default="scope">
                        <el-input-number v-model="scope.row.price" :precision="2" controls-position="right" :min="0"
                                         :max="9999999" label="普通售价" style="width: 100%"></el-input-number>
                      </template>
                    </el-table-column>
                    <el-table-column prop="price_vip" label="普通会员售价" min-width="150">
                      <template #default="scope">
                        <el-input-number v-model="scope.row.price_vip" :precision="2" controls-position="right" :min="0"
                                         :max="9999999" label="普通会员售价" style="width: 100%"></el-input-number>
                      </template>
                    </el-table-column>
                    <el-table-column prop="price_svip" label="超级会员售价" min-width="150">
                      <template #default="scope">
                        <el-input-number v-model="scope.row.price_svip" :precision="2" controls-position="right"
                                         :min="0"
                                         :max="9999999" label="超级会员售价" style="width: 100%"></el-input-number>
                      </template>
                    </el-table-column>
                    <el-table-column prop="stock" label="库存" min-width="150">
                      <template #default="scope">
                        <el-input-number v-model="scope.row.stock" :precision="0" controls-position="right" :min="0"
                                         :max="9999999" label="库存" style="width: 100%"></el-input-number>
                      </template>
                    </el-table-column>
                    <el-table-column prop="shop_number" label="商品编号" min-width="150">
                      <template #default="scope">
                        <el-input v-model="scope.row.shop_number" placeholder="请输入商品编号" style="width: 100%">
                        </el-input>
                      </template>
                    </el-table-column>
                    <el-table-column prop="supplier" label="供应商" min-width="150">
                      <template #default="scope">
                        <el-input v-model="scope.row.supplier" placeholder="请输入供应商" style="width: 100%">
                        </el-input>
                      </template>
                    </el-table-column>
                    <el-table-column prop="remarks" label="备注" min-width="150">
                      <template #default="scope">
                        <el-input v-model="scope.row.remarks" placeholder="请输入备注" style="width: 100%">
                        </el-input>
                      </template>
                    </el-table-column>
                    <el-table-column label="操作" min-width="150">
                      <template #default="scope">
                        <!--                        <span class="table-operate-btn" @click="singleSpecDel(scope.$index)"-->
                        <!--                              v-if="generatenowBnt">删除</span>-->
                        <!--                        <span class="table-operate-btn" @click="handleEdit(scope.row)" v-if="!generatenowBnt">-->
                        <!--                          保存修改-->
                        <!--                        </span>-->
                        <span class="table-operate-btn" @click="singleSpecDel(scope.$index, scope.row)">
                          删除
                        </span>
                        <span class="table-operate-btn" @click="soldOut(scope.$index, scope.row)" >
                            <span v-if="scope.row.is_launched">下架</span>
                            <span v-else>上架</span>
                        </span>
                        <span class="table-operate-btn" @click="handleEdit(scope.$index, scope.row)"
                              v-if="!generatenowBnt">
                          保存修改
                        </span>
                        <!--                        <span class="table-operate-btn" @click="handleAddSkups(scope.row)">-->
                        <!--                          保存新增-->
                        <!--                        </span>-->
                      </template>
                    </el-table-column>
                  </el-table>
                </div>
              </div>
            </el-form-item>
          </div>
        </div>
        <div class="form-data-item">
          <div class="form-title">商品详情</div>
          <el-form-item label="商品详情：" class="is-required" style="padding-right: 10px">
            <TEditor v-model="formData.desc_detail" v-if="dialogVisible" :lyheight=550></TEditor>
          </el-form-item>
        </div>

      </el-form>
      <template #footer>
        <el-button @click="handleClose" :loading="loadingSave">取消</el-button>
        <el-button type="primary" @click="submitData" :loading="loadingSave">确定</el-button>
      </template>
    </ly-dialog>
  </div>
</template>

<script>
import TEditor from '@/components/TEditor'
import {
  classificationLyAudoCodeAPI,
  mallGoodsspuAdd,
  mallGoodsspueAddskups,
  mallGoodsspueDelskups,
  mallGoodsspueDelSkupsName,
  mallGoodsspueDelSkupsValue,
  mallGoodsspuEdit,
  mallGoodsspueEditskups, mallGoodsspueIslaunched,
  platformsettingsUploadPlatformImg
} from "@/api/api";
import LyDialog from "../../../components/dialog/dialog";
import LyUploadGoods from "../../../components/upload/goods";
import {CircleCloseFilled, Grid, Plus} from "@element-plus/icons-vue";
import {ajaxGet} from "@/api/request";

import lyVideo from "@/components/lyvideo/lyVideo"
// 导入关联型号表，关联产品表、服务、特点表的数据
const model_listLyAudoCodeAPI = params => ajaxGet({url: `autocode/ModelListType/`, params})
const general_listLyAudoCodeAPI = params => ajaxGet({url: `autocode/GeneralList/`, params})
const freight_configLyAudoCodeAPI = params => ajaxGet({url: `mall/freightcfg/`, params})
const serve_productLyAudoCodeAPI = params => ajaxGet({url: `autocode/ProductService/`, params})
const characteristic_productLyAudoCodeAPI = params => ajaxGet({url: `autocode/ProductFeature/`, params})

// 定义条件函数
function condition(item) {
  return item.id > 1; // 假设你想要筛选 id 大于 1 的对象
}

export default {
  emits: ['refreshData', 'closed'],
  name: "addModuleGoodsManage",
  components: {
    CircleCloseFilled,
    Grid,
    Plus,
    LyUploadGoods,
    LyDialog,
    TEditor,
    lyVideo
  },
  data() {
    return {
      fullscreen: false,
      dialogWidth: "50%",
      dialogTop: "15vh",
      pics: [],
      //自定义规格开始
      specList: [],//规格
      specName: '',//要添加的规格名
      specValue: '',//要添加的规格值
      isSpecBtn: false,//是否点击了添加规格
      createBnt: true,//添加规格按钮是否显示
      generatenowBnt: true,//立即生成按钮是否显示
      upload_sku_default_image_res: "",//sku图片上传结果
      moveIndex: "",
      manySpecBatchData: [{
        price: 0,
        price_vip: 0,
        price_svip: 0,
        stock: 0,
        is_launched:1,
        shop_number: '',
        supplier: '',
        remarks: '',
      }],
      tableColumnList: {
        tableHeaderList: [],
        tableBodyList: []
      },//表格展示
      //自定义规格结束
      dialogVisible: false,
      loadingSave: false,
      loadingTitle: '',
      formData: {
        model_list: null, // 关联型号表
        is_model_list: true, // 是否显示关联型号表
        general_list: null, // 关联通用表
        is_general_list: true, // 是否显示关联通用表
        freight_config: null, // 关联运费表
        is_free_shipping: true, // 是否免邮
        serve_product: null, // 服务
        characteristic_product: null, // 特点
        name: '',
        sub_name: '',
        category_product_many_name: '',
        category_product_many: '',
        price: 0,
        price_vip: 0,
        price_svip: 0,
        stock: 0,
        sort: 0,
        shop_number: '',
        supplier: '',
        remarks: '',
        default_image: '',
        home_video: '',
        is_launched: true,
        image_list: [],//轮播图
        skus: [],//sku/规格
        spu_specs: [],//spu规格信息
        spec_type: 0,
        is_tuijian: false,
        is_vip_price: false,
        desc_detail: '',
      },
      activeName: '1',
      rules: {
        // category1: [
        //   {required: true, message: '请选择商品分类', trigger: 'blur'}
        // ],
        category_product_many: [
          {required: true, message: '请选择产品分类', trigger: 'blur'}
        ],
        name: [
          {required: true, message: '请输入商品名称', trigger: 'blur'}
        ],
        sub_name: [
          {required: true, message: '请输入商品标题', trigger: 'blur'}
        ],
        default_image: [
          {required: true, message: '请上传商品默认图片', trigger: 'blur'}
        ],

      },

      model_listLyAudoCodeAPIList: [],  // 关联型号表
      general_listLyAudoCodeAPIList: [],  // 关联通用表
      freight_configLyAudoCodeAPIList: [],  // 关联运费表
      serve_productLyAudoCodeAPIList: [],  // 服务
      characteristic_productLyAudoCodeAPIList: [], // 特点
      // category_list: [], // 商品分类列表
      category_product_many_list: [], // 产品分类列表
    }
  },
  mounted() {
    window.addEventListener("focusin", this.onFocusIn, true);
    this.model_listLyAudoCodeAPIFunction()
    this.general_listLyAudoCodeAPIFunction()
    this.freight_configLyAudoCodeAPIFunction()
    this.serve_productLyAudoCodeAPIFunction()
    this.characteristic_productLyAudoCodeAPIFunction()
  },
  unmounted() {
    window.removeEventListener("focusin", this.onFocusIn);
  },
  methods: {
    onFocusIn(e) {
      e.stopImmediatePropagation()//阻止当前和后面的一系列事件
    },
    addModuleFn(item, flag, categoryProductList) {
      this.loadingTitle = flag
      this.dialogVisible = true
      this.getCategoryProductList()
      // this.category_product_many_list = categoryProductList  // 产品分类列表
      if (item) {
        this.formData = item
        //轮播图开始
        this.pics = []
        if (item && item.image_list && item.image_list.length > 0) {
          item.image_list.forEach(items => {
            this.pics.push({
              pic: items
            })
          })
        }
        //轮播图结束
        //多规格编辑打开时需要前端数据转换
        if (item.spec_type) {
          //禁用立即生成和添加规格的按钮
          // this.createBnt = false
          this.generatenowBnt = false

          let temp_spu_spec = item.spu_specs
          let temp_skus = item.skus
          temp_spu_spec.map((item) => {
            let temp_spu_spec_options = []
            item.options.map((item2) => {
              temp_spu_spec_options.push(item2.value)
            })
            this.specList.push({
              value: item.name,
              detail: temp_spu_spec_options
            })
            this.tableColumnList.tableHeaderList.push({
              prop: item.name,
              propName: item.name,
            })
          })
          temp_skus.map((item3) => {
            let temp_table_body_list = {
              id: item3.id,
              price: parseFloat(item3.price),
              price_vip: parseFloat(item3.price_vip),
              price_svip: parseFloat(item3.price_svip),
              stock: item3.stock,
              is_launched:item3.is_launched,
              shop_number: item3.shop_number,
              supplier: item3.supplier,
              remarks: item3.remarks,
              default_image: item3.default_image,
            }
            item3.specs.map((item4) => {
              let temp_sepc_name = item4.spec
              temp_table_body_list[temp_sepc_name] = item4.option
            })
            this.tableColumnList.tableBodyList.push(temp_table_body_list)
          })
          // console.log(this.tableColumnList)
        }
      }
    },
    soldOut(index, row) {
      let vm = this
      vm.$confirm('确定要下架该商品吗？', "警告", {
        confirmButtonText: "确定",
        cancelButtonText: "取消",
        type: "warning"
      }).then(function () {
        if (row.id) {
          const params = {
            id: row.id,
          }
          return mallGoodsspueIslaunched(params).then(res => {
            if (res.code === 2000) {
              // 这里应该是将数据库返回的数据替换原来表格内的数据 并 更新页面
              row.is_launched = !row.is_launched;
              vm.$message.success(res.msg)
            } else {
              vm.$message.warning(res.msg)
            }
          })
        } else {
            vm.$message.warning("数据库中该商品不存在，请删除或先保存信息")
        }
      })
    },
    handleEdit(index, row) {
      if (row.price <= 0) {
        this.$message.warning("商品规格普通价格要大于0")
        this.loadingSave = false
        return
      }
      if (row.price_vip <= 0) {
        this.$message.warning("商品规格普通会员价格要大于0")
        this.loadingSave = false
        return
      }
      if (row.price_svip <= 0) {
        this.$message.warning("商品规格超级会员价格要大于0")
        this.loadingSave = false
        return
      }
      if (row.stock <= 0) {
        this.$message.warning("商品规格库存要大于0")
        this.loadingSave = false
        return
      }
      let params = {
        ...row
      }
      params['id'] = row.id
      params['price'] = row.price
      params['price_vip'] = row.price_vip
      params['price_svip'] = row.price_svip
      params['stock'] = row.stock
      params['default_image'] = row.default_image
      params['shop_number'] = row.shop_number
      params['supplier'] = row.supplier
      params['remarks'] = row.remarks
      params['is_launched'] = row.is_launched

      let vm = this
      vm.$confirm('确定要修改保存吗？', "警告", {
        confirmButtonText: "确定",
        cancelButtonText: "取消",
        type: "warning"
      }).then(function () {
        if (params.id) {
          return mallGoodsspueEditskups(params).then(res => {
            if (res.code === 2000) {
              // 这里应该是将数据库返回的数据替换原来表格内的数据
              // vm.formData.skus[index] = params;
              vm.$message.success(res.msg)
            } else {
              vm.$message.warning(res.msg)
            }
          })
        } else if (!params.id) {
          // 将id添加成商品的id
          params['spu_id'] = vm.formData.id
          params['id'] = vm.formData.id
          return mallGoodsspueAddskups(params).then(res => {
            if (res.code === 2000) {
              vm.$message.success(res.msg)
            } else {
              vm.$message.warning(res.msg)
            }
          })
        }
      })
    },

    /*
    * 规格方法自定义开始
    * */
    //清空添加的规格和值得缓存
    clearAttr() {
      this.specName = "";
      this.specValue = "";
    },
    //添加规格
    addSpec() {
      this.clearAttr()
      this.isSpecBtn = true
      this.createBnt = false
    },
    // 取消添加规格
    cancelAddSpec() {
      this.isSpecBtn = false
      this.createBnt = true
    },
    //添加规格属性值
    addSpecDetail(num, index) {
      if (num) {
        if (this.specList[index].detail.includes(this.specList[index].detail.attrsVal)) {
          this.$message.warning('此属性值已存在~')
          return
        }
        // this.specList[index].detail.push(this.specList[index].detail.attrsVal)
        this.specList[index].detail.push(num);
        this.specList[index].detail.attrsVal = ''
      } else {
        this.$message.warning("请添加属性");
      }

    },
    // 删除规格
    deleteSpec(index, row) {
      // if (!this.generatenowBnt) {
      //   return
      // }
      let vm = this
      this.$confirm('确定删除此规格？').then(_ => {
        let name = row.value
        const targetItem = vm.formData.spu_specs.find(item =>
            item.name === name
        );
        if (targetItem) {
          // 如果ID存在
          const params = {
            id: targetItem.id,
          }
          // 此处需要向后端发送消息，删除此规格
          return mallGoodsspueDelSkupsName(params).then(res => {
            if (res.code === 2000) {
              // 删除表格的数据
              this.specList.splice(index, 1)
              vm.$message.success(res.msg)
            } else {
              vm.$message.warning(res.msg)
            }
          })
        } else {
          // 删除表格的数据
          this.specList.splice(index, 1)
        }
      }).catch(_ => {

      })
    },
    // 添加规格名称
    createSpecName() {
      let isSame = false
      if (this.specName && this.specValue) {
        let data = {
          value: this.specName,
          detail: [this.specValue],
        };
        for (var i = 0; i < this.specList.length; i++) {
          if (this.specList[i].value === this.specName) {
            isSame = true
            break
          }
        }
        if (isSame) {
          this.$message.warning("已存在该规格请添加其他规格！");
          return
        }
        this.specList.push(data);
        var hash = {};
        // this.specList = this.specList.reduce(function (item, next) {
        //   /* eslint-disable */
        //   hash[next.value] ? "" : (hash[next.value] = true && item.push(next));
        //   return item;
        // }, []);
        this.clearAttr()
        this.isSpecBtn = false;
        this.createBnt = true;
      } else {
        this.$message.warning("请添加完整的规格！");
      }
    },
    checkMove(evt) {
      this.moveIndex = evt.draggedContext.index;
    },
    end() {
      this.moveIndex = "";
    },
    // 删除属性
    handleRemove2(item, index, row) {
      // if (!this.generatenowBnt) {
      //   return
      // }
      let vm = this
      this.$confirm('确定删除此属性吗？').then(_ => {
        let value = row.detail[index]
        let name = row.value
        const targetItem = vm.formData.spu_specs.find(item =>
            item.name === name
        );
        if (targetItem) {
          // 如果规格存在
          // 找到规格对象在原始数据的index位置
          const outerIndex = vm.formData.spu_specs.findIndex(item =>
              item.name === name && item.options.some(option => option.value === value)
          );
          // 找到规格属性对象元素
          const targetOption = targetItem.options.find(option => option.value === value);
          if (targetOption) {
            // 如果属性存在
            // 找到内层要删除的option对象
            const innerIndex = vm.formData.spu_specs[outerIndex].options.findIndex(option => option.value === "金色");
            const params = {
              id: targetOption.id,
            }
            //  此处需要向后端发送消息，删除此规格的属性
            return mallGoodsspueDelSkupsValue(params).then(res => {
              if (res.code === 2000) {
                // 删除表格的数据
                item.splice(index, 1);
                // 删除 formData.spu_specs 数组中的元素
                vm.formData.spu_specs[outerIndex].options.splice(innerIndex, 1);
                vm.$message.success(res.msg)
              } else {
                vm.$message.warning(res.msg)
              }
            })
          } else {
            // 删除表格的数据
            item.splice(index, 1);
          }
        } else {
          // 删除表格的数据
          item.splice(index, 1);
        }
      }).catch(_ => {

      })
    },
    // 笛卡尔积算法
    cartesianProductOf(array) {
      if (array.length < 2) return array[0] || []
      return [].reduce.call(array, function (col, set) {
        var res = []
        col.forEach(function (c) {
          set.forEach(function (s) {
            var t = [].concat(Array.isArray(c) ? c : [c])
            t.push(s)
            res.push(t)
          })
        })
        return res
      })
    },
    //立即生成规格表
    generateSpec() {
      let clonespecList = this.specList
      if (clonespecList.length <= 0) {
        return
      }
      let attrName = [] //规格名数组
      let attrValue = [] //规格值数组[['x','s'],['黑色','白色']]
      for (let key in clonespecList) {
        attrName.push(clonespecList[key].value)
        delete (clonespecList[key].detail, 'attrsVal')
        attrValue.push(clonespecList[key].detail)
      }
      // 表格内容数据（笛卡尔积算法）
      let finalArr = this.cartesianProductOf(attrValue)
      let tableObj = {
        tableBodyList: [],
        tableHeaderList: []
      }
      let source_obj = this.formData.skus
      // 表格内容(两种及以上规格名的规格)
      if (attrValue.length > 1) {
        // 这里需要提交所有规格及其值给后端存储数据，然后将后端返回的数据更新缓存中的spu_specs
        tableObj.tableBodyList = finalArr.map((item) => {
          let obj = {
            price: 0,
            price_vip: 0,
            price_svip: 0,
            stock: 0,
            is_launched:1,
            shop_number: "",
            supplier: "",
            remarks: "",
            default_image: "",
          }
          let conditions = {}
          for (let i = 0; i < item.length; i++) {
            obj[attrName[i]] = item[i]
            conditions[attrName[i]] = item[i]
          }

          // 获取原始数据，如果存在完全相同的规格和值，就将原始数据赋值给新生成的数据
          function filterProducts(products, conditions) {
            return products.filter(product => {
              // 创建一个映射对象，将规格映射到选项
              const specMap = Object.fromEntries(product.specs.map(spec => [spec.spec, spec.option]));

              // 遍历条件，检查是否所有条件都在映射中找到匹配
              for (const [key, value] of Object.entries(conditions)) {
                if (specMap[key] !== value) {
                  return false; // 如果有任何一个条件不匹配，排除此产品
                }
              }

              return true; // 所有条件都匹配，保留此产品
            });
          }

          if (source_obj.length) {
            // 调用函数并打印结果（与上面的示例相同）
            const filteredProducts = filterProducts(source_obj, conditions);
            if (filteredProducts.length) {
              // 直接将原来的值赋值给新对象
              obj['id'] = filteredProducts[0]['id']
              obj['price'] = parseFloat(filteredProducts[0]['price'])
              obj['price_vip'] = parseFloat(filteredProducts[0]['price_vip'])
              obj['price_svip'] = parseFloat(filteredProducts[0]['price_svip'])
              obj['stock'] = filteredProducts[0]['stock']
              obj['shop_number'] = filteredProducts[0]['shop_number']
              obj['supplier'] = filteredProducts[0]['supplier']
              obj['remarks'] = filteredProducts[0]['remarks']
              obj['is_launched'] = filteredProducts[0]['is_launched']
              obj['default_image'] = filteredProducts[0]['default_image']
            }
          }
          return obj
        })
      } else {
        //一种规格名的规格
        tableObj.tableBodyList = finalArr.map((item) => {
          let obj = {
            price: 0,
            price_vip: 0,
            price_svip: 0,
            stock: 0,
            is_launched:1,
            shop_number: '',
            supplier: '',
            remarks: '',
          }
          obj[attrName[0]] = item
          return obj
        })
      }
      this.tableColumnList.tableBodyList = tableObj.tableBodyList //表格内容数据
      // 表头
      let skuTableArr = attrName
      tableObj.tableHeaderList = skuTableArr.map((item) => {
        return {
          prop: item,
          propName: item
        }
      })
      this.tableColumnList.tableHeaderList = tableObj.tableHeaderList // 表头
    },
    //批量设置-清空
    batchDel() {
      this.manySpecBatchData = [{
        price: 0,
        price_vip: 0,
        price_svip: 0,
        stock: 0,
        is_launched:1,
        shop_number: '',
        supplier: '',
        remarks: '',
      }]
    },
    //批量设置-添加
    batchAdd() {
      for (let val of this.tableColumnList.tableBodyList) {
        if (this.manySpecBatchData[0].stock) {
          val.stock = this.manySpecBatchData[0].stock
        }
        if (this.manySpecBatchData[0].price > 0) {
          val.price = this.manySpecBatchData[0].price
        }
        if (this.manySpecBatchData[0].price_vip > 0) {
          val.price_vip = this.manySpecBatchData[0].price_vip
        }
        if (this.manySpecBatchData[0].price_svip > 0) {
          val.price_svip = this.manySpecBatchData[0].price_svip
        }
        if (this.manySpecBatchData[0].shop_number) {
          val.shop_number = this.manySpecBatchData[0].shop_number
        }
        if (this.manySpecBatchData[0].supplier) {
          val.supplier = this.manySpecBatchData[0].supplier
        }
        if (this.manySpecBatchData[0].remarks) {
          val.remarks = this.manySpecBatchData[0].remarks
        }
        if (this.manySpecBatchData[0].is_launched) {
          val.is_launched = this.manySpecBatchData[0].is_launched
        }

      }
    },
    //规格表格展示-删除
    singleSpecDel(index, row) {
      let vm = this
      //提示框，判断用户是否操作失误
      this.$confirm('确定要删除吗', '提示', {
        confirmButtonText: '确定',
        cancelButtonText: '取消',
        type: 'warning'
      }).then(() => {
        if (vm.tableColumnList.tableBodyList.length <= 1) {
          vm.$message.warning("只有一个不能删除")
          return
        }
        // 删除表格上的数据
        vm.tableColumnList.tableBodyList.splice(index, 1)
        // 删除 formData中的skus 的index下标的数据
        this.formData.skus.splice(index, 1);
        // 删除sku中第index下标的值
        if (row.id) {
          // 如果ID存在
          const params = {
            id: row.id,
          }
          let vm = this
          return mallGoodsspueDelskups(params).then(res => {
            if (res.code === 2000) {
              vm.$message.success(res.msg)
            } else {
              vm.$message.warning(res.msg)
            }
          })
        } else {
          this.$message({
            type: 'success',
            message: '删除成功!'
          })
        }
      }).catch(() => {
      });
    },
    //sku图片上传
    imgBeforeUpload_sku(file) {
      const allowedTypes = ['image/jpeg', 'image/png', "image/webp"];
      const isJPG = allowedTypes.includes(file.type);
      if (!isJPG) {
        this.$message.error('图片只能是 JPG/PNG 格式!');
        return false
      }
      return isJPG;
    },
    async imgUploadRequest_sku(param) {
      var vm = this
      let res = await platformsettingsUploadPlatformImg(param)
      if (res.code == 2000) {
        vm.upload_sku_default_image_res = res.data.data[0]
      } else {
        vm.$message.warning(res.msg)
      }
    },
    imgUploadSuccess_sku(response, file, fileList, index) {
      // 设置（更新）对应行文件图片
      this.tableColumnList.tableBodyList[index].default_image = this.upload_sku_default_image_res
    },
    /*
    * 规格方法自定义结束
    * */
    handleClose() {
      this.dialogVisible = false
      this.loadingSave = false
      // 设置是否全屏
      this.fullscreen = false
      this.dialogWidth = "50%"
      this.dialogTop = "15vh"
      this.pics = []
      this.clearAttr()
      this.formData = {
        name: '',
        sub_name: '',
        category_product_many_name: '',
        category_product_many: '',
        id: '',
        price: 0,
        price_vip: 0,
        price_svip: 0,
        stock: 0,
        shop_number: '',
        supplier: '',
        remarks: '',
        default_image: '',
        is_launched: true,
        image_list: [],
        spec_type: 0,
        sort: 0,
        is_tuijian: false,
        is_vip_price: false,
        desc_detail: '',
      },
          this.activeName = '1'
      this.specList = []
      this.manySpecBatchData = [{
        id: '',
        price: 0,
        price_vip: 0,
        price_svip: 0,
        stock: 0,
        is_launched:1,
        shop_number: '',
        supplier: '',
        remarks: '',
      }]
      this.tableColumnList = {
        tableHeaderList: [],
        tableBodyList: []
      }//表格展示
      this.isSpecBtn = false//是否点击了添加规格
      // this.createBnt = true//添加规格按钮是否显示
      this.generatenowBnt = true//立即生成按钮是否显示
      this.$emit('refreshData')
    },
    submitData() {
      this.$refs['rulesForm'].validate(obj => {
        if (obj) {
          let param = {
            ...this.formData
          }
          // param.is_launched === param.is_launched ? 1 : 0
          //轮播图开始
          if (this.pics.length < 1) {
            this.$message.warning('请先上传商品轮播图~')
            return
          }
          let picList = []
          this.pics.forEach(item => {
            picList.push(item.pic)
          })
          param.image_list = picList
          //轮播图结束

          // param.sub_name = param.sub_name
          delete (param.category_product_many_name)
          // param.image_list= JSON.stringify(param.image_list)
          this.loadingSave = true
          if (this.formData.id) {
            mallGoodsspuEdit(param).then(res => {
              this.loadingSave = false
              if (res.code === 2000) {
                this.$message.success(res.msg)
                this.handleClose()
                this.$emit('refreshData')
              } else {
                this.$message.warning(res.msg)
              }
            })
          } else {
            if (this.formData.spec_type === 0) {
              if (!this.formData.serve_product) {
                this.$message.warning('商品服务不能为空')
                this.loadingSave = false
                return;
              }
              if (!this.formData.characteristic_product) {
                this.$message.warning('商品特点不能为空')
                this.loadingSave = false
                return;
              }
              if (this.formData.price <= 0) {
                this.$message.warning("商品规格普通价格要大于0")
                this.loadingSave = false
                return
              }
              if (this.formData.price_vip <= 0) {
                this.$message.warning("商品规格普通会员价格要大于0")
                this.loadingSave = false
                return
              }
              if (this.formData.price_svip <= 0) {
                this.$message.warning("商品规格超级会员价格要大于0")
                this.loadingSave = false
                return
              }
              if (this.formData.stock <= 0) {
                this.$message.warning("商品规格库存要大于0")
                this.loadingSave = false
                return
              }
              //单规格
              let skus = []
              skus.push({
                id: this.formData.id,
                price: this.formData.price,
                price_vip: this.formData.price_vip,
                price_svip: this.formData.price_svip,
                stock: this.formData.stock,
                shop_number: this.formData.shop_number,
                supplier: this.formData.supplier,
                remarks: this.formData.remarks,
                is_launched: this.formData.is_launched,
                default_image: this.formData.default_image,
              })
              param.skus = skus
              param.price = this.formData.price
              param.price_vip = this.formData.price_vip
              param.price_svip = this.formData.price_svip
            } else {
              //多规格
              //规格sku数据处理
              let skus = []
              let spu_specs = []
              let minprice = 0
              let minprice_vip = 0
              let minprice_svip = 0
              let errormsg = "";
              let that = this;
              let thetemptabledata = that.tableColumnList.tableBodyList
              for (var s = 0; s < thetemptabledata.length; s++) {
                let item = thetemptabledata[s]
                let sepcs = []
                if (item.price <= 0) {
                  errormsg = "商品规格价格要大于0"
                  that.loadingSave = false
                  break
                }
                if (item.price_vip <= 0) {
                  errormsg = "商品规格普通会员价格要大于0"
                  that.loadingSave = false
                  break
                }
                if (item.price_svip <= 0) {
                  errormsg = "商品规格超级会员价格要大于0"
                  that.loadingSave = false
                  break
                }
                if (item.stock <= 0) {
                  errormsg = "商品规格库存要大于0"
                  this.loadingSave = false
                  return
                }
                if (item.default_image == "") {
                  errormsg = "SKU商品图片未上传"
                  that.loadingSave = false
                  break
                }
                let tempsku = {
                  id: item.id,
                  price: item.price,
                  price_vip: item.price_vip,
                  price_svip: item.price_svip,
                  stock: item.stock,
                  shop_number: item.shop_number,
                  supplier: item.supplier,
                  remarks: item.remarks,
                  is_launched:item.is_launched,
                  default_image: item.default_image,
                }
                // 获取固定的规格前几列
                for (var i in item) {
                  if (i in ['default_image']) {
                    console.log(i)
                    sepcs.push({
                      option: item[i],
                      spec: i,
                    })
                  }
                }
                tempsku.specs = sepcs
                skus.push(tempsku)
              }
              if (errormsg != "") {
                this.loadingSave = false
                this.$message.warning(errormsg)
                return;
              }
              ;
              (async function () {
                that.specList.map((item) => {
                  delete (item.detail, 'attrsVal')
                  let options = []
                  item.detail.map((item2) => {
                        options.push({
                          value: item2
                        })
                      }
                  )
                  spu_specs.push({
                    name: item.value,
                    options: options,
                  })
                })
              })();

              if (skus.length < 1) {
                this.loadingSave = false
                this.$message.warning("请点击立即生成")
                return;
              }
              param.skus = skus
              param.spu_specs = spu_specs
              param.price = minprice
              param.price_vip = minprice_vip
              param.price_svip = minprice_svip
            }
            mallGoodsspuAdd(param).then(res => {
              this.loadingSave = false
              if (res.code == 2000) {
                this.$message.success(res.msg)
                this.handleClose()
                this.$emit('refreshData')
              } else {
                this.$message.warning(res.msg)
              }
            })
          }
          this.loadingSave = false
        }
      })
    },

    imgBeforeUpload(file) {
      const allowedTypes = ['image/jpeg', 'image/png', "image/webp"];
      const isJPG = allowedTypes.includes(file.type);
      if (!isJPG) {
        this.$message.error('图片只能是 JPG/PNG 格式!');
        return false
      }
      return isJPG;
    },
    // 视频上传
    homeVideoBeforeUpload(file) {
      const isVideo = file.type === 'video/mp4' || file.type === 'video/webm' || file.type === 'video/ogg' || file.type === 'video/avi'; // 添加更多格式
      if (!isVideo) {
        this.$message.error('图片只能是 MP4/WEBM/OGG/AVI 格式!');
        return false
      }
      return isVideo;
    },
    async imgUploadRequest(param) {
      var vm = this
      let res = await platformsettingsUploadPlatformImg(param)
      if (res.code == 2000) {
        vm.formData.default_image = res.data.data[0]
      } else {
        vm.$message.warning(res.msg)
      }
    },
    async homeVideoUploadRequest(param) {
      var vm = this
      let res = await platformsettingsUploadPlatformImg(param)
      if (res.code == 2000) {
        vm.formData.home_video = res.data.data[0]
      } else {
        vm.$message.warning(res.msg)
      }
    },
    imgUploadSuccess(response, file, fileList) {
      this.$refs.lyimagupload.clearFiles()
    },
    homeVideoUploadSuccess(response, file, fileList) {
      this.$refs.lyimagupload.clearFiles()
    },
    //获取 产品分类列表
    getCategoryProductList() {
      let param = {
        page: 1,
        limit: 9999999,
      }
      classificationLyAudoCodeAPI(param).then(res => {
        if (res.code == 2000) {
          this.category_product_many_list = res.data.data
        }
      })
    },

    // 获取关联型号表、通用包、服务、特点数据
    model_listLyAudoCodeAPIFunction() {
      model_listLyAudoCodeAPI({page: 1, limit: 999}).then(res => {
        if (res.code == 2000) {
          this.model_listLyAudoCodeAPIList = res.data.data
        }
      })
    },
    general_listLyAudoCodeAPIFunction() {
      general_listLyAudoCodeAPI({page: 1, limit: 999}).then(res => {
        if (res.code == 2000) {
          this.general_listLyAudoCodeAPIList = res.data.data
        }
      })
    },
    freight_configLyAudoCodeAPIFunction() {
      freight_configLyAudoCodeAPI({page: 1, limit: 999}).then(res => {
        if (res.code == 2000) {
          this.freight_configLyAudoCodeAPIList = res.data.data
        }
      })
    },
    serve_productLyAudoCodeAPIFunction() {
      serve_productLyAudoCodeAPI({page: 1, limit: 999}).then(res => {
        if (res.code == 2000) {
          this.serve_productLyAudoCodeAPIList = res.data.data
        }
      })
    },
    characteristic_productLyAudoCodeAPIFunction() {
      characteristic_productLyAudoCodeAPI({page: 1, limit: 999}).then(res => {
        if (res.code == 2000) {
          this.characteristic_productLyAudoCodeAPIList = res.data.data
        }
      })
    },

  },

}
</script>
<style lang="scss" scoped>
::v-deep(.el-dialog__body) {
  padding: 0 30px !important;
}

.add-specs-inner {
  padding: 10px;
  background: #fffcfc;
  border: 1px solid #e0e0e0;

  .tips {
    font-size: 12px;
    color: #999999;
  }

  .add-specs-item {
    display: flex;
    align-items: center;

    .name {
      width: 60px;
      font-size: 12px;
    }
  }
}

.uploadImg {
  display: flex;
  line-height: 36px;
  font-size: 14px;

  .photoNum {
    font-weight: bold;

    em {
      font-style: normal;
      color: #ff0000;
    }
  }

  .input-fileup {
    width: 120px;
    height: 36px;
    line-height: 36px;
    text-align: center;
    overflow: hidden;
    position: relative;
    background: #409EFF;
    color: #ffffff;
    border: 1px solid #dcdfe6;
    text-decoration: none;
    display: block;
    margin: 0 15px 0 5px;
    border-radius: 3px;
    /*color: #666666;*/
    input {
      position: absolute;
      left: 0;
      top: 0;
      width: 100%;
      height: 100%;
      opacity: 0;
      cursor: pointer;
    }
  }
}

.pickAlbumPreListO {
  width: 100%;
  background: #f1f1f1;
  /*height: 224px;*/
  .tipsCon {
    text-align: center;
    color: #999999;
  }
}

.pickAlbumPreList {
  display: table;
  margin: 0;
  padding: 0;
  /*height: 174px;*/
  li {
    width: 110px;
    padding: 10px;
    box-sizing: border-box;
    /*height: 140px;*/
    display: block;
    float: left;
    margin: 5px;
    position: relative;
    font-size: 12px;
    text-align: center;

    img, .el-image {
      display: block;
      width: 90px;
      height: 90px;
      border: 1px solid #d6d6d6;
    }

    i.el-icon-close {
      display: none;
      position: absolute;
      right: 0;
      top: 0;
      background: #409eff;
      color: #ffffff;
      font-size: 20px;
      cursor: pointer;
    }

    .btnchang {
      display: flex;
      margin-top: 10px;
      align-items: center;
      align-content: center;
    }

    .sizeImg {
      color: #ff0000;
    }

    button {
      width: 40px;
      margin: 0 auto;
      padding: 0;
      height: 28px;
    }

    &:hover i.el-icon-close {
      display: block;
    }

    i.el-icon-picture {
      width: 90px;
      height: 90px;
      display: block;
      text-align: center;
      line-height: 90px;
      border: 1px solid #e1e1e1;
      font-size: 20px;
      border-radius: 4px;
      background: #fff;
    }
  }
}

// 规格样式
.tag-item {
  display: flex;
  flex-direction: row;
  column-gap: 10px;
  background: #f1f1f1;
  padding: 8px;
  margin-bottom: 10px;

  .el-icon-s-grid {
    color: #e6ddd8;
    font-size: 32px;
    cursor: move;
  }

  .input-item {
    flex: 1;
    display: flex;
    flex-direction: column;
    column-gap: 10px;

    .el-icon-error {
      font-size: 14px;
      color: #000000;
      cursor: pointer;
    }

    .spec-item {
      flex: 1;
      display: flex;
      flex-direction: row;
      align-items: center;
      column-gap: 10px;

    }
  }
}

.avatar-uploader .el-upload {
  border: 1px dashed #d9d9d9;
  border-radius: 6px;
  cursor: pointer;
  position: relative;
  overflow: hidden;
}

.avatar-uploader .el-upload:hover {
  border-color: #409EFF;
}

.avatar-uploader-icon {
  font-size: 28px;
  color: #8c939d;
  width: 128px;
  height: 128px;
  line-height: 128px;
  text-align: center;
}

.avatar {
  width: 128px;
  height: 128px;
  display: block;
}

.form-data {
  height: calc(100vh - 170px);
  overflow-y: auto;
  border: 1px solid #b3d8ff;

  .form-title {
    color: #409eff;
    background: #ecf5ff;
    padding: 10px;
    margin-bottom: 20px;
  }
}
</style>
