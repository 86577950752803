<template>
  <div id="shoppingMallOrderDetail">
    <ly-dialog v-model="dialogVisible" :title="loadingTitle" width="50%" :before-close="handleClose">
      <el-form :inline="false" label-position="right" label-width="110px" class="form-order" v-model="formData">
        <el-form-item label="收货信息：">
          <div style="display:flex;flex-direction:column;">
            <span>收货人：{{ formData.address.receiver }}</span>
            <span>联系电话：{{ formData.address.mobile }}</span>
            <span>收货地址：{{ formData.address.areas }}</span>
          </div>
        <el-button type="text"  @click="copyInfoToClipboard">复制</el-button>
        </el-form-item>
        <el-form-item label="备注：">
          <span>{{ formData.remark }}</span>
        </el-form-item>
        <!-- 双击修改商家备注-->
        <el-form-item label="商家备注：">
          <span v-if="!editingMerchantRemark">{{ formData.merchant_remark }}</span>
          <el-input v-else v-model="editingMerchantRemarkValue"></el-input>
          <el-button type="text" @click="startEditingMerchantRemark" v-if="!editingMerchantRemark">编辑</el-button>
          <el-button type="primary" @click="saveMerchantRemark" v-if="editingMerchantRemark">保存</el-button>
        </el-form-item>
        <!-- 结束-->
        <el-form-item label="物流单号：" style="width:100%">
          <span>{{ formData.logistics_id }}</span>
          <span v-if="formData.logistics_company">（{{formData.logistics_company}}）</span>
        </el-form-item>
        <el-form-item label="订单信息：" style="width:100%">
          <div>
            <span style="display: inline;margin-right: 20px">订单编号：{{ formData.order_id }}</span>
            <span style="display: inline">付款时间：{{ formData.pay_time }}</span>
          </div>
          <el-table border size="small" :data="formData.goodsinfo" :span-method="objectSpanMethod">
            <el-table-column label="标题">
              <template #default="scope">
                <span>{{ scope.row.sku_name }}</span>
              </template>
            </el-table-column>
            <el-table-column label="商品信息" show-overflow-tooltip width="200">
              <template #default="scope">
                <div style="display: flex;align-items: center">
                  <img :src="scope.row.sku_default_image" style="width: 40px;height:40px;margin-right: 5px">
                  <span style="width: 150px" class="ellipsis">{{ scope.row.sku_spec }}</span>
                </div>
              </template>
            </el-table-column>
            <el-table-column label="留言">
              <template #default="scope">
                <span>{{ scope.row.comment }}</span>
              </template>
            </el-table-column>
            <el-table-column label="单价">
              <template #default="scope">
                <span>￥{{ scope.row.price }}</span>
              </template>
            </el-table-column>
            <el-table-column label="数量">
              <template #default="scope">
                <span>{{ scope.row.count }}</span>
              </template>
            </el-table-column>
            <el-table-column label="备注">
              <template #default="scope">
                <span>{{ scope.row.remarks }}</span>
              </template>
            </el-table-column>
            <el-table-column label="运费">
              ￥{{ formData.freight }}
            </el-table-column>
            <el-table-column label="订单状态">
              <template #default="scope">
                <span>{{ orderStatusList.filter(item => item.id == formData.status)[0].name }}</span>
              </template>
            </el-table-column>
            <el-table-column label="支付状态">
              <template #default="scope">
                <span>{{ payStatusList.filter(item => item.id == formData.pay_status)[0].name }}</span>
              </template>
            </el-table-column>
            <el-table-column label="总价">￥{{ Number(formData.total_amount) }}</el-table-column>
            <!--                        <el-table-column label="评价" :show="false">-->
            <!--                            <template #default="scope">-->
            <!--                                <span>{{scope.row.comment}}</span>-->
            <!--                            </template>-->
            <!--                        </el-table-column>-->
            <!--                        <el-table-column label="满意度评分" v-show="false">-->
            <!--                            <template #default="scope">-->
            <!--                                <span>{{scope.row.score}}</span>-->
            <!--                            </template>-->
            <!--                        </el-table-column>-->
          </el-table>
        </el-form-item>
      </el-form>
      <template #footer>
        <el-button @click="printDivContent">打印</el-button>
        <el-button @click="deliverGoods">发货</el-button>
        <el-button @click="handleClose" type="primary">取消</el-button>
      </template>
      <deliver-goods-module ref="deliverGoodsModuleFlag"></deliver-goods-module>
    </ly-dialog>
  </div>
</template>

<script>
import deliverGoodsModule from "./deliverGoodsModule";
import LyDialog from "../../../components/dialog/dialog";
import {mallGoodsOrderMerchantRemark} from "@/api/api";

export default {
  name: "shoppingMallOrderDetail",
  components: {LyDialog, deliverGoodsModule},
  data() {
    return {
      editingMerchantRemark: false, // 跟踪是否正在编辑商家备注
      editingMerchantRemarkValue: '', // 临时存储编辑中的商家备注值
      dialogVisible: false,
      loadingPage: false,
      loadingTitle: "详情",
      formData: {
        logistics_id: '',
        address: {
          receiver: '',
          mobile: '',
          areas: ''
        },
        goodsinfo: []
      },
      orderStatusList: [
        {id: 1, name: '待支付'},
        {id: 2, name: '待发货'},
        {id: 3, name: '待收货'},
        {id: 4, name: '待评价'},
        {id: 5, name: '已完成'},
        {id: 6, name: '已取消'}
      ],
      payStatusList: [
        {id: 0, name: '待支付'},
        {id: 1, name: '已支付'},
        {id: 2, name: '退款中'},
        {id: 3, name: '退款失败'}
      ]
    }
  },
  methods: {
    // 复制订单信息到剪贴板
    async copyInfoToClipboard() {
      try {
        const copyText = `收货人：${this.formData.address.receiver}
联系电话：${this.formData.address.mobile}
收货地址：${this.formData.address.areas}
备注：${this.formData.remark}`;
        await navigator.clipboard.writeText(copyText);
        this.$message.success('复制成功！'); // 假设你使用了Element UI的$message提示
      } catch (err) {
        console.error('Failed to copy text: ', err);
        this.$message.error('复制失败，请手动复制'); // 假设你使用了Element UI的$message提示
      }
    },
    // 打印订单详情
    printDivContent() {
      //   // 获取需要打印的div元素
      //   const printContents = document.getElementById('shoppingMallOrderDetail').outerHTML;
      //
      //   // 创建一个新的窗口来承载打印内容
      //   const printWindow = window.open('', '_blank');
      //   printWindow.document.write(`
      //   <html>
      //     <head>
      //       <title>打印订单详情</title>
      //     </head>
      //     <body>
      //       ${printContents}
      //     </body>
      //   </html>
      // `); // 注意这里使用了反引号来定义模板字符串
      // printWindow.print();
      window.print();
      // 注意：这里不立即关闭窗口，而是让用户决定是否关闭或等打印完成后手动关闭。
    },
    handleClose() {
      this.dialogVisible = false
      this.$emit('refreshData')
    },
    orderDetailFn(item) {
      this.dialogVisible = true
      this.formData = item
    },
    deliverGoods() {
      // this.loadingPage=true
      let param = {
        id: this.formData.id
      }
      this.$refs.deliverGoodsModuleFlag.deliverGoodsModuleFn(param)
    },
    //table表格合并行
    objectSpanMethod({row, column, rowIndex, columnIndex}) {
      if (columnIndex === 6 || columnIndex === 7 || columnIndex === 8 || columnIndex === 9)  {
        // if (rowIndex % 2 === 0) {
        //   return {
        //     rowspan: 2,//实际上就是给td加上rowspan属性
        //     colspan: 1//实际上就是给td加上colspan属性
        //   };
        // } else {
        //   return {
        //     rowspan: 0,
        //     colspan: 0
        //   };
        // }
        var _row = 0;
        if (rowIndex === 0) {
          _row = this.formData.goodsinfo.length
        }
        if (rowIndex >= 1) {
          _row = 0
        }

        return {
          rowspan: _row,
          colspan: 1
        };
      }
    },
    // 双击修改商家备注
    startEditingMerchantRemark() {
      this.editingMerchantRemark = true;
      this.editingMerchantRemarkValue = this.formData.merchant_remark; // 初始化输入框的值
    },
    saveMerchantRemark() {
      const params = {
        id: this.formData.id,
        merchant_remark: this.editingMerchantRemarkValue
      }
      console.log(params)
      mallGoodsOrderMerchantRemark(params).then(res => {
        if (res.code == 2000) {
          // 上传成功后更新formData中的值
          this.formData.merchant_remark = this.editingMerchantRemarkValue;
          // 关闭编辑模式
          this.editingMerchantRemark = false;
          // 提示用户保存成功（如果需要）
          this.$message.success(res.msg)
        } else {
          this.$message.warning(res.msg)
        }
      }).catch(error => {
        // 处理请求失败的情况
        console.error('保存商家备注失败:', error);
        this.$message.error('保存商家备注失败');
      });
    },
  }
}
</script>
<style lang="scss">
@media print {
  /* 在这里添加打印时的样式 */
  body {
    background-color: white; /* 打印时通常设置为白色背景 */
    color: black; /* 设置打印文本颜色 */
  }
  /* 你可以隐藏不想打印的元素，例如导航栏、按钮等 */
  .navbar, .button {
    display: none;
  }
  /* 其他打印样式 */
}

.form-order {
  background: #f1f1f1;

  .el-form-item {
    margin-bottom: 10px;

    span {
      display: block;
    }

    .el-form-item__label {
      font-weight: bold;
    }
  }

  .el-table {
    width: 98%;
    margin-bottom: 20px;

    .price-show {
      display: flex;

      span {
        margin-right: 5px;
      }
    }
  }
}
</style>

