<template>
    <div :class="{'ly-is-full':isFull}">
        <div class="tableSelect" ref="tableSelect">
            <el-form :model="formInline">
                <el-form-item label="品牌名称：">
                    <el-input v-model="formInline.name" maxlength="100" clearable placeholder="请输入品牌名称" @change="search" style="width:180px"></el-input>
                </el-form-item>
                <el-form-item label="中文名称：">
                    <el-input v-model="formInline.c_name" maxlength="100" clearable placeholder="请输入中文名称" @change="search" style="width:180px"></el-input>
                </el-form-item>
                <el-form-item label="最后采集时间：">
                    <el-date-picker style="width:350px" v-model="end_crawler_timeTimers" type="daterange" @change="end_crawler_timeTimeChange" range-separator="至" start-placeholder="开始日期" end-placeholder="结束日期"></el-date-picker>
                </el-form-item>
                <el-form-item label="创建时间：" v-if="false">
                    <el-date-picker
                        style="width:350px"
                        v-model="timers"
                        type="datetimerange"
                        @change="timeChange"
                        range-separator="至"
                        start-placeholder="开始日期"
                        end-placeholder="结束日期">
                    </el-date-picker>
                </el-form-item>
                <el-form-item label="" v-show="hasPermission(this.$route.name,'Search')"><el-button  @click="search" type="primary" icon="Search">查询</el-button></el-form-item>
                <el-form-item label=""><el-button  @click="handleEdit('','reset')" icon="Refresh">重置</el-button></el-form-item>
                <el-form-item label="" v-show="hasPermission(this.$route.name,'Create')"><el-button type="primary" icon="Plus" @click="handelAdd" >新增</el-button></el-form-item>
                <el-form-item label="" v-if="hasPermission(this.$route.name,'Delete') && true"><el-button @click="handleDelete" type="danger" :disabled="multiple" icon="Delete" >删除</el-button></el-form-item>
                <el-form-item label="" v-if="hasPermission(this.$route.name,'Export') && true"><el-button type="primary" @click="exportDataBackend" :disabled="loadingPage" icon="Download">导出</el-button></el-form-item>
            </el-form>
        </div>
        <div class="table">
            <el-table  :height="tableHeight" border :data="tableData" ref="tableref" v-loading="loadingPage" style="width: 100%" @selection-change="handleSelectionChange">
                <el-table-column type="selection" width="60" align="center"></el-table-column>
                <el-table-column type="index" width="60" align="center" label="序号">
                    <template #default="scope">
                        <span v-text="getIndex(scope.$index)"></span>
                    </template>
                </el-table-column>
                <el-table-column min-width="100" prop="name" label="品牌名称" v-if="true"></el-table-column>
                <el-table-column min-width="100" prop="c_name" label="中文名称" v-if="true"></el-table-column>
                <el-table-column min-width="100" prop="img" label="主图" v-if="true">
                    <template #default="scope">
                        <el-image preview-teleported :src="scope.row.img" style="width: 30px;height:30px" :preview-src-list="[scope.row.img]" v-if="scope.row.img"></el-image>
                    </template>
                </el-table-column>
                <el-table-column min-width="100" prop="url" label="网站地址" v-if="true"></el-table-column>
                <el-table-column min-width="100" prop="end_crawler_time" label="最后采集时间" v-if="true"></el-table-column>
                <el-table-column min-width="150" prop="create_datetime" label="创建时间"></el-table-column>
                <el-table-column label="操作" fixed="right" width="180">
                    <template #header>
                        <div style="display: flex;justify-content: space-between;align-items: center;">
                            <div>操作</div>
                            <div @click="setFull">
                                <el-tooltip content="全屏" placement="bottom">
                                    <el-icon ><full-screen /></el-icon>
                                </el-tooltip>
                            </div>
                        </div>
                    </template>
                    <template #default="scope">
                        <span class="table-operate-btn" @click="handleEdit(scope.row,'detail')" v-show="hasPermission(this.$route.name,'Retrieve')">详情</span>
                        <span class="table-operate-btn" @click="handleEdit(scope.row,'edit')" v-show="hasPermission(this.$route.name,'Update')">编辑</span>
                        <span class="table-operate-btn" @click="handleEdit(scope.row,'delete')" v-show="hasPermission(this.$route.name,'Delete')">删除</span>
                    </template>
                </el-table-column>
            </el-table>
        </div>
        <ly-dialog v-model="dialogVisible" :title="dialogTitle" :width="dialogWidth" :top="dialogTop" :fullscreen="fullscreen" :before-close="handleDialogClose">
            <el-form :model="formData" :rules="rules" ref="rulesForm" :disabled="formDisabled" :label-position="formLabelPosition" :label-width="formLabelWidth">
                <el-row :gutter="20">
                    <el-col :span="24?24:24">
                        <el-form-item label="品牌名称" prop="name" v-if="true">
                            <el-input v-model="formData.name" :disabled="isFormItemEditDisabled(false)" style="width: 100%;" placeholder="请输入品牌名称"/>
                        </el-form-item>
                    </el-col>
                    <el-col :span="24?24:24">
                        <el-form-item label="中文名称" prop="c_name" v-if="true">
                            <el-input v-model="formData.c_name" :disabled="isFormItemEditDisabled(false)" style="width: 100%;" placeholder="请输入中文名称"/>
                        </el-form-item>
                    </el-col>
                    <el-col :span="24?24:24">
                        <el-form-item label="主图" prop="img" v-if="true">
                            <ly-public-pictrue-single-upload v-model="formData.img" :disabled="isFormItemEditDisabled(false)" :round="false" :cropper="false"
                                title="" :show-file-list="true" :width="148" :height="148"
                                icon="plus" :compress="1" :aspect-ratio="NaN" :max-size="10">
                            </ly-public-pictrue-single-upload>
                        </el-form-item>
                    </el-col>
                    <el-col :span="24?24:24">
                        <el-form-item label="网站地址" prop="url" v-if="true">
                            <el-input v-model="formData.url" :disabled="isFormItemEditDisabled(false)" style="width: 100%;" placeholder="请输入网站地址"/>
                        </el-form-item>
                    </el-col>
                    <el-col :span="24?24:24">
                        <el-form-item label="最后采集时间" prop="end_crawler_time" v-if="true">
                            <el-date-picker type="date" v-model="formData.end_crawler_time" :disabled="isFormItemEditDisabled(false)" clearable format="YYYY-MM-DD" value-format="YYYY-MM-DD" placeholder="请选择最后采集时间" style="width: 100%;">
                            </el-date-picker>
                        </el-form-item>
                    </el-col>
                </el-row>
            </el-form>
            <template v-slot:footer>
                <el-button @click="handleDialogClose">取消</el-button>
                <el-button @click="handleDialogSubmit" type="primary" :loading="dialogLoadingSave" v-if="dialogTitle!='详情'">确定</el-button>
            </template>
        </ly-dialog>
        <Pagination v-bind:child-msg="pageparm" @callFather="callFather"></Pagination>
    </div>
</template>

<script>

    import {ajaxGet,ajaxPost,ajaxDelete,ajaxPut,ajaxPatch,ajaxDownloadExcel} from '@/api/request'
    // 列表
    const lyAutoCodeList = params => ajaxGet({url: `autocode/BrandPhone/`,params})
    // 新增
    const lyAutoCodeAdd = params => ajaxPost({url: `autocode/BrandPhone/`,params})
    // 编辑
    const lyAutoCodeEdit = params => ajaxPut({url: `autocode/BrandPhone/`,params})
    // 删除
    const lyAutoCodeDelete = params => ajaxDelete({url: `autocode/BrandPhone/`,params})
    // 导出
    const lyAutoCodeExport = params => ajaxDownloadExcel({url: `autocode/BrandPhone/export/`,params})
    
    import {useDictStore} from "@/store/dict";
    
    import Pagination from "@/components/Pagination";
    import {dateFormats,getTableHeight,deepClone} from "@/utils/util";
    import LyDialog from "@/components/dialog/dialog";
    export default {
        name: "lyAutoCodeBrandPhone",
        components: {LyDialog, Pagination},
        setup(){
            const dictStore = useDictStore()
            return { dictStore }
        },
        data(){
            return{
                isFull:false,
                tableHeight:500,
                loadingPage:false,
                // 选项框选中数组
                ids: [],
                // 选项框非单个禁用
                single: true,
                // 非多个禁用
                multiple: true,
                formInline:{
                    page: 1,
                    limit: 10,
                },
                pageparm: {
                    page: 1,
                    limit: 10,
                    total: 0
                },
                timers:[],
                tableData:[],
                //弹窗
                dialogVisible:false,
                dialogTitle:"",
                dialogLoadingSave:false,
                fullscreen:true,
                dialogWidth:"50%",
                dialogTop:"15vh",
                formDisabled:false,
                formLabelPosition:"right",
                formLabelWidth:"auto",
                formData:{"name": null, "c_name": null, "img": null, "url": null, "end_crawler_time": null},
                rules:{
                    name: [{ required: true, message: "品牌名称不能为空",trigger: 'blur'}],
                    c_name: [{ required: true, message: "中文名称不能为空",trigger: 'blur'}],
                },
                end_crawler_timeTimers:[],
            }
        },
        methods:{
            // 表格序列号
            getIndex($index) {
                // (当前页 - 1) * 当前显示数据条数 + 当前行数据的索引 + 1
                return (this.pageparm.page-1)*this.pageparm.limit + $index +1
            },
            setFull(){
                this.isFull=!this.isFull
                window.dispatchEvent(new Event('resize'))
            },
            //多选项框被选中数据
            handleSelectionChange(selection) {
                this.ids = selection.map(item => item.id);
                this.single = selection.length !== 1;
                this.multiple = !selection.length;
            },
            /** 批量删除按钮操作 */
            handleDelete(row) {
                const ids = this.ids;
                let vm = this
                vm.$confirm('是否确认删除选中的数据项?', "警告", {
                    confirmButtonText: "确定",
                    cancelButtonText: "取消",
                    type: "warning"
                }).then(function() {
                    return lyAutoCodeDelete({id:ids}).then(res=>{
                        if(res.code == 2000) {
                            vm.$message.success(res.msg)
                            vm.getData()
                        } else {
                            vm.$message.warning(res.msg)
                        }
                    })
                })
            },
            convertStrNum(model,value){
                if(model === "" || model === null || model === undefined){
                    return value
                }
                else if(Object.prototype.toString.call(model)=== "[object String]"){
                    return value.toString()
                }
                else if(Object.prototype.toString.call(model) === "[object Number]"){
                    return value*1
                }else{
                    return value
                }
            },
            //判断单个组件编辑时是否禁用disabled参数为bool值
            isFormItemEditDisabled(disabled = false){
                if(disabled == "" || disabled == null || disabled == undefined){
                    return false
                }else if((disabled == true || disabled == 'True') && this.dialogTitle==='编辑'){
                    return true
                }
                return false
            },
            handelAdd() {
                this.dialogTitle = "添加"
                this.dialogVisible = true
            },
            handleDialogClose(){
                this.dialogTitle =""
                this.dialogVisible = false
                this.dialogLoadingSave = false
                this.formDisabled = false
                this.formData = {"name": null, "c_name": null, "img": null, "url": null, "end_crawler_time": null}
            },
            handleDialogSubmit(){
                this.$refs['rulesForm'].validate((obj)=>{
                    if(obj) {
                        this.dialogLoadingSave=true
                        let param = {
                            ...this.formData
                        }
                        if(this.dialogTitle=="编辑"){
                            lyAutoCodeEdit(param).then(res=>{
                                this.dialogLoadingSave=false
                                if(res.code ==2000) {
                                    this.$message.success(res.msg)
                                    this.handleDialogClose()
                                    this.getData()
                                } else {
                                    this.$message.warning(res.msg)
                                }
                            })
                        }else{
                            lyAutoCodeAdd(param).then(res=>{
                                this.dialogLoadingSave=false
                                if(res.code ==2000) {
                                    this.$message.success(res.msg)
                                    this.handleDialogClose()
                                    this.getData()
                                } else {
                                    this.$message.warning(res.msg)
                                }
                            })
                        }

                    }
                })
                this.dialogLoadingSave=false
            },
            handleEdit(row,flag) {
                if(flag=='edit') {
                    this.dialogTitle = "编辑"
                    this.dialogVisible = true
                    this.formData = deepClone(row)
                }
                else if(flag=='delete') {
                    let vm = this
                    vm.$confirm('您确定要删除选中的数据吗？',{
                        closeOnClickModal:false
                    }).then(res=>{
                        lyAutoCodeDelete({id:row.id}).then(res=>{
                            if(res.code == 2000) {
                                vm.$message.success(res.msg)
                                vm.search()
                            } else {
                                vm.$message.warning(res.msg)
                            }
                        })
                    }).catch(()=>{

                    })
                }else if(flag=='detail'){
                    this.dialogTitle = "详情"
                    this.dialogVisible = true
                    this.formDisabled = true
                    this.formData = deepClone(row)
                }else if(flag=="reset"){
                    this.formInline = {
                        page:1,
                        limit: 10
                    }
                    this.pageparm={
                        page: 1,
                        limit: 10,
                        total: 0
                    }
                    this.timers = []
                    this.end_crawler_timeTimers=[]
                    this.getData()
                }
            },
            /**
             * 从URL里下载文件
            */
            // 下载文件
            downloadFileURL(url) {
                var a =document.createElement("a")
                var event = new MouseEvent('click')
                a.href = url
                a.target = "_blank"
                a.dispatchEvent(event)
            },
            //excel文件流下载
            downloadExcelBlob(res) {
                let fileName = new Date().getTime() +".xlsx"
                let dispositionStr = res.headers["content-disposition"];
                if (dispositionStr == null || dispositionStr === "") {

                }else{
                    // 获取文件名
                    let dispositionArr = dispositionStr.split(";");
                    fileName = decodeURIComponent(dispositionArr[1]);
                    fileName = fileName.split("=")[1];
                }
                const blob = new Blob([res.data], {
                    type: "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet",
                });
                let href = window.URL.createObjectURL(blob); //下载链接
                let link = document.createElement("a")
                link.href = href
                link.download = fileName //下载后文件名
                document.body.appendChild(link);
                link.click(); //点击下载
                document.body.removeChild(link); //下载完成移除元素
                window.URL.revokeObjectURL(href);  //释放blob对象
                this.$message.success('导出成功')
            },
            //导出
            exportDataBackend() {
                let params = {}
                if(this.ids.length>0){
                    params = {
                        ids:this.ids,
                    }
                }
                this.loadingPage = true
                lyAutoCodeExport(params).then(res => {
                    this.loadingPage = false
                    this.downloadExcelBlob(res)
                })
            },
            callFather(parm) {
                this.formInline.page = parm.page
                this.formInline.limit = parm.limit
                this.getData()
            },
            search() {
                this.formInline.page = 1
                this.formInline.limit = 10
                this.getData()
            },
            //获取列表
            async getData() {
                this.loadingPage = true
                lyAutoCodeList(this.formInline).then(res => {
                     this.loadingPage = false
                     if(res.code ==2000) {
                         this.tableData = res.data.data
                         this.pageparm.page = res.data.page;
                         this.pageparm.limit = res.data.limit;
                         this.pageparm.total = res.data.total;
                     }
                })
            },
            timeChange(val){
                if (val) {
                    this.formInline.beginAt=dateFormats(val[0],'yyyy-MM-dd hh:mm:ss');
                    this.formInline.endAt=dateFormats(val[1],'yyyy-MM-dd hh:mm:ss');
                } else {
                    this.formInline.beginAt = null
                    this.formInline.endAt = null
                }
                this.search()
            },
            end_crawler_timeTimeChange(val) {
                if (val) {
                    this.formInline.end_crawler_time_beginAt=dateFormats(val[0],'yyyy-MM-dd');
                    this.formInline.end_crawler_time_endAt=dateFormats(val[1],'yyyy-MM-dd');
                } else {
                    this.formInline.end_crawler_time_beginAt = null
                    this.formInline.end_crawler_time_endAt = null
                }
                this.search()
            },
            // 计算搜索栏的高度
            listenResize() {
                this.$nextTick(() => {
                    this.getTheTableHeight()
                })
            },
            getTheTableHeight(){
                let tabSelectHeight = this.$refs.tableSelect?this.$refs.tableSelect.offsetHeight:0
                tabSelectHeight = this.isFull?tabSelectHeight - 110:tabSelectHeight
                this.tableHeight =  getTableHeight(tabSelectHeight)
            },

        },
        created() {
            this.getData()
        },
        mounted() {
            // 监听页面宽度变化搜索框的高度
            window.addEventListener('resize', this.listenResize);
            this.$nextTick(() => {
                this.getTheTableHeight()
            })
        },

        unmounted() {
            // 页面销毁，去掉监听事件
            window.removeEventListener("resize", this.listenResize);
        },

    }
</script>

<style lang="scss" scoped>

</style>
