<template>
    <lyPdfPreview :url="fileUrl" v-if="mode === 'pdf'"></lyPdfPreview>
    <lyDocxPreview :url="fileUrl" v-if="mode === 'docx'"></lyDocxPreview>
</template>

<script setup>
    import lyPdfPreview from "@/components/office/lyPdfPreview"
    import lyDocxPreview from "@/components/office/lyDocxPreview"
    let Base64 = require('js-base64').Base64
    let href = window.location.href
    let getqyinfo = href.split('?')[1]
    let getqys = new URLSearchParams('?'+getqyinfo)
    let fileUrl = !!getqys.get('fileUrl')?Base64.decode(getqys.get('fileUrl')):''
    let mode = getqys.get('mode')?getqys.get('mode'):'pdf'
</script>

<style scoped>

</style>