<template>
  <div :class="{'ly-is-full':isFull}">
    <div class="tableSelect" ref="tableSelect">
      <el-form :model="formInline">
        <el-form-item label="ID：">
          <el-input v-model="formInline.id" maxlength="100" clearable placeholder="请输入ID" @change="search"
                    style="width:180px"></el-input>
        </el-form-item>
        <el-form-item label="型号表名称：">
          <el-input v-model="formInline.name" maxlength="100" clearable placeholder="请输入型号表名称" @change="search"
                    style="width:180px"></el-input>
        </el-form-item>
        <el-form-item label="创建时间：" v-if="false">
          <el-date-picker
              style="width:350px"
              v-model="timers"
              type="datetimerange"
              @change="timeChange"
              range-separator="至"
              start-placeholder="开始日期"
              end-placeholder="结束日期">
          </el-date-picker>
        </el-form-item>
        <el-form-item label="" v-show="hasPermission(this.$route.name,'Search')">
          <el-button @click="search" type="primary" icon="Search">查询</el-button>
        </el-form-item>
        <el-form-item label="">
          <el-button @click="handleEdit('','reset')" icon="Refresh">重置</el-button>
        </el-form-item>
        <el-form-item label="" v-show="hasPermission(this.$route.name,'Create')">
          <el-button type="primary" icon="Plus" @click="handelAdd">新增</el-button>
        </el-form-item>
        <el-form-item label="" v-show="hasPermission(this.$route.name,'Create')">
          <el-button type="primary" icon="Plus" @click="handelAddBatch">批量添加</el-button>
        </el-form-item>
        <el-form-item label="" v-if="hasPermission(this.$route.name,'Delete') && true">
          <el-button @click="handleDelete" type="danger" :disabled="multiple" icon="Delete">删除</el-button>
        </el-form-item>
        <el-form-item label="" v-if="hasPermission(this.$route.name,'Export') && false">
          <el-button type="primary" @click="exportDataBackend" :disabled="loadingPage" icon="Download">导出</el-button>
        </el-form-item>
        <el-form-item>
          <el-upload
              ref="upload"
              :auto-upload="true"
              :before-upload="ImportExcelbeforeUpload"
              :file-list="fileList"
              :http-request="ImportExcelUploadRequest"
              :limit="1"
              :multiple="false"
              :on-success="ImportExcelhandleSuccess"
              :show-file-list="false"
              accept=".xlsx"
              action="">
            <el-button v-show="isShowBtn(this.$route.name,'型号表管理','ImportExcel')" icon="Plus"
                       type="primary">导入
            </el-button>
          </el-upload>
        </el-form-item>
      </el-form>
    </div>
    <div class="table">
      <ly-table tableName="lyAutoCodeModelListTypeTable" :height="tableHeight" :pageSize="10" :apiObj="apiObjList"
                :params="formInline" ref="tableref" :column="column" showSelectable showSequence
                @selection-change="handleSelectionChange">
        <el-table-column label="操作" fixed="right" width="180">
          <template #header>
            <div style="display: flex;justify-content: space-between;align-items: center;">
              <div>操作</div>
              <div @click="setFull">
                <el-tooltip content="全屏" placement="bottom">
                  <el-icon>
                    <full-screen/>
                  </el-icon>
                </el-tooltip>
              </div>
            </div>
          </template>
          <template #default="scope">
            <span class="table-operate-btn" @click="handleEdit(scope.row,'detail')"
                  v-show="hasPermission(this.$route.name,'Retrieve')">详情</span>
            <span class="table-operate-btn" @click="handleEdit(scope.row,'edit')"
                  v-show="hasPermission(this.$route.name,'Update')">编辑</span>
            <span class="table-operate-btn" @click="handleEdit(scope.row,'delete')"
                  v-show="hasPermission(this.$route.name,'Delete')">删除</span>
            <span class="table-operate-btn" @click="exportDataBackend(scope.row)">下载</span>
          </template>
        </el-table-column>
      </ly-table>
    </div>
    <ly-dialog v-model="dialogVisible" :title="dialogTitle" :width="dialogWidth" :top="dialogTop"
               :fullscreen="fullscreen" :before-close="handleDialogClose">
      <el-form :model="formData" :rules="rules" ref="rulesForm" :disabled="formDisabled"
               :label-position="formLabelPosition" :label-width="formLabelWidth">
        <el-row :gutter="20">
          <el-col :span="24?24:24">
            <el-form-item label="ID" prop="id" v-if="false">
              <el-input v-model="formData.id" :disabled="isFormItemEditDisabled(true)" style="width: 100%;"
                        placeholder="请输入ID"/>
            </el-form-item>
          </el-col>
          <el-col :span="24?24:24">
            <el-form-item label="型号表名称" prop="name" v-if="true">
              <el-input v-model="formData.name" :disabled="isFormItemEditDisabled(false)" style="width: 100%;"
                        placeholder="请输入型号表名称"/>
            </el-form-item>
          </el-col>
          <el-col :span="24?24:24">
            <el-form-item label="手机品牌数量" prop="brand_phone_number" v-if="false">
              <el-input v-model="formData.brand_phone_number" :disabled="isFormItemEditDisabled(true)"
                        style="width: 100%;" placeholder="请输入手机品牌数量"/>
            </el-form-item>
          </el-col>
          <el-col :span="24?24:24">
            <el-form-item label="手机型号数量" prop="model_phone_number" v-if="false">
              <el-input v-model="formData.model_phone_number" :disabled="isFormItemEditDisabled(true)"
                        style="width: 100%;" placeholder="请输入手机型号数量"/>
            </el-form-item>
          </el-col>
        </el-row>
      </el-form>


      <!--        子表编辑页面 start -->
      <div :class="{'ly-is-full':isFull2}">
        <div class="tableSelect" ref="tableSelect2">
          <el-form :model="formInline2">
            <el-form-item label="手机品牌：">
              <el-input v-model="formInline2.brand_phone" maxlength="100" clearable placeholder="请输入手机品牌"
                        @change="search2" style="width:180px"></el-input>
            </el-form-item>
            <el-form-item label="手机型号：">
              <el-input v-model="formInline2.model_phone" maxlength="100" clearable placeholder="请输入手机型号"
                        @change="search2" style="width:180px"></el-input>
            </el-form-item>
            <el-form-item label="创建时间：" v-if="false">
              <el-date-picker
                  style="width:350px"
                  v-model="timers2"
                  type="datetimerange"
                  @change="timeChange2"
                  range-separator="至"
                  start-placeholder="开始日期"
                  end-placeholder="结束日期">
              </el-date-picker>
            </el-form-item>
            <el-form-item label="" v-show="hasPermission(this.$route.name,'Search')">
              <el-button @click="search2" type="primary" icon="Search">查询</el-button>
            </el-form-item>
            <el-form-item label="">
              <el-button @click="handleEdit2('','reset')" icon="Refresh">重置</el-button>
            </el-form-item>
            <el-form-item label="" v-show="hasPermission(this.$route.name,'Create')">
              <el-button type="primary" icon="Plus" @click="handelAdd2">新增</el-button>
            </el-form-item>
            <el-form-item label="" v-if="hasPermission(this.$route.name,'Delete')">
              <el-button @click="handleDelete2" type="danger" :disabled="multiple2" icon="Delete">删除</el-button>
            </el-form-item>
            <el-form-item label="" v-if="hasPermission(this.$route.name,'Export')">
              <el-button type="primary" @click="exportDataBackend2" :disabled="loadingPage2" icon="Download">导出
              </el-button>
            </el-form-item>
          </el-form>
        </div>
        <div class="table">
          <ly-table tableName="lyAutoCodeModelListBrandTable" :height="tableHeight2" :pageSize="10"
                    :apiObj="apiObjList2" :params="formInline2" ref="tableref2" :column="column2"
                    showSelectable showSequence @selection-change="handleSelectionChange2">
            <template #model_name="scope">
              <span>{{ scope.row.model_name_lyautocode_name }}</span>
            </template>
            <el-table-column label="操作" fixed="right" width="180">
              <template #header>
                <div style="display: flex;justify-content: space-between;align-items: center;">
                  <div>操作</div>
                  <div @click="setFull2">
                    <el-tooltip content="全屏" placement="bottom">
                      <el-icon>
                        <full-screen/>
                      </el-icon>
                    </el-tooltip>
                  </div>
                </div>
              </template>
              <template #default="scope">
                      <span class="table-operate-btn" @click="handleEdit2(scope.row,'detail')"
                            v-show="hasPermission(this.$route.name,'Retrieve')">详情</span>
                <span class="table-operate-btn" @click="handleEdit2(scope.row,'edit')"
                      v-show="hasPermission(this.$route.name,'Update')">编辑</span>
                <span class="table-operate-btn" @click="handleEdit2(scope.row,'delete')"
                      v-show="hasPermission(this.$route.name,'Delete')">删除</span>
              </template>
            </el-table-column>
          </ly-table>
        </div>
        <ly-dialog v-model="dialogVisible2" :title="dialogTitle2" :width="dialogWidth2" :top="dialogTop2"
                   :fullscreen="fullscreen2" :before-close="handleDialogClose2">
          <el-form :model="formData2" :rules="rules2" ref="rulesForm2" :disabled="formDisabled2"
                   :label-position="formLabelPosition2" :label-width="formLabelWidth2">
            <el-row :gutter="20">
              <el-col :span="24?24:24">
                <el-form-item label="手机品牌" prop="brand_phone" v-if="true">
                  <el-input v-model="formData2.brand_phone" :disabled="isFormItemEditDisabled2(false)"
                            style="width: 100%;" placeholder="请输入手机品牌"/>
                </el-form-item>
              </el-col>
              <el-col :span="24?24:24">
                <el-form-item label="手机型号" prop="model_phone" v-if="true">
                  <div style="width:100%">
                    <ly-public-teditor v-model="formData2.model_phone" :disabled="isFormItemEditDisabled2(false)"
                                       placeholder="请输入内容" :height="300"></ly-public-teditor>
                  </div>
                </el-form-item>
              </el-col>
            </el-row>
          </el-form>
          <template v-slot:footer>
            <el-button @click="handleDialogClose2">取消</el-button>
            <el-button @click="handleDialogSubmit2" type="primary" :loading="dialogLoadingSave2"
                       v-if="dialogTitle2!='详情'">确定
            </el-button>
          </template>
        </ly-dialog>
      </div>
      <!--        子表编辑页面 end -->

      <template v-slot:footer>
        <el-button @click="handleDialogClose2">取消</el-button>
        <el-button @click="handleDialogSubmit2" type="primary" :loading="dialogLoadingSave2" v-if="dialogTitle!='详情'">
          确定
        </el-button>
      </template>
    </ly-dialog>
    <ly-dialog v-model="dialogVisibleBatch" :title="dialogTitle" :width="dialogWidth" :top="dialogTop"
               :fullscreen="fullscreen" :before-close="handleDialogClose2">

      <div>
        <el-form :inline="false" :model="formData3" :rules="rules3" ref="rulesForm3" label-position="right"
                 label-width="auto" class="form-data">
          <el-form-item label="" class="is-required">
            <el-form-item label="选择型号表：">
              <el-select v-model="model_name" placeholder="请选择型号表" clearable filterable style="width: 300px">
                <el-option
                    v-for="item in allModelNameList"
                    :key="item.id"
                    :label="item.name"
                    :value="item.name">
                </el-option>
              </el-select>
            </el-form-item>
            <el-button type="success" @click="generateSpec()" style="margin-left:10px">
              新增
            </el-button>
          </el-form-item>
          <el-form-item label="" style="padding-right: 10px;padding-left: 10px;">
            <div style="width: 100%">
              <draggable group="guige" :list="specList" :move="checkMove" @end="end" handle=".move-icon"
                         animation="500" item-key="value">
                <template #item="{ element,index}">
                  <div class="tag-item">
                    <div class="move-icon">
                      <span class="el-icon-s-grid"><el-icon><Grid/></el-icon></span>
                    </div>
                    <div class="input-item" :class="moveIndex === index ? 'borderStyle' : ''">
                      <div>
                        <span>{{ element.value }}</span>
                        <i class="el-icon-error" @click="deleteSpec(index, element)">
                          <el-icon>
                            <CircleCloseFilled/>
                          </el-icon>
                        </i>
                      </div>
                      <div class="spec-item">
                        <el-tag
                            type="warning"
                            size="large"
                            closable
                            color="primary"
                            v-for="(j, indexn) in element.detail"
                            :key="indexn"
                            :name="j"
                            @close="handleRemove2(element.detail, indexn, element)"
                        >{{ j }}
                        </el-tag>
                        <el-input placeholder="请输入属性名称" v-model="element.detail.attrsVal" style="width: 200px">
                          <template #append>
                            <!--                              <el-button style="color: #FFF;background-color: #409EFF;border-color: #409EFF;"-->
                            <!--                                         @click="addSpecDetail(element.detail.attrsVal,index)"-->
                            <!--                                         :disabled="!generatenowBnt">-->
                            <!--                                添加-->
                            <!--                              </el-button>-->
                            <el-button style="color: #FFF;background-color: #409EFF;border-color: #409EFF;"
                                       @click="addSpecDetail(element.detail.attrsVal,index)">
                              添加
                            </el-button>
                          </template>
                        </el-input>
                      </div>
                    </div>
                  </div>
                </template>
              </draggable>
            </div>
            <!-- 批量设置-->
            <div style="width: 100%">
              <div class="table" v-if="tableColumnList.tableBodyList.length>0">
                <el-table :data="tableColumnList.tableBodyList" border style="width: 100%">
                  <el-table-column prop="model_name" label="型号表" min-width="150">
                    <template #default="scope">
                      <span>{{ scope.row.model_name }}</span>
                    </template>
                  </el-table-column>
                  <el-table-column prop="phone_brand" label="手机品牌" min-width="150">
                    <template #default="scope">
                      <el-input v-model="scope.row.phone_brand" placeholder="请输入手机品牌" style="width: 100%">
                      </el-input>
                    </template>
                  </el-table-column>
                  <el-table-column prop="phone_model" label="手机型号" min-width="150">
                    <template #default="scope">
                      <el-input v-model="scope.row.phone_model" placeholder="请输入手机型号" style="width: 100%">
                      </el-input>
                    </template>
                  </el-table-column>
                  <el-table-column label="操作" min-width="150">
                    <template #default="scope">
                      <span class="table-operate-btn" @click="singleSpecDel(scope.$index, scope.row)">
                        删除
                      </span>
                    </template>
                  </el-table-column>
                </el-table>
              </div>
            </div>
          </el-form-item>
        </el-form>
      </div>
      <template v-slot:footer>
        <el-button @click="handleDialogClose">取消</el-button>
        <el-button @click="batchsubmitData" type="primary" :loading="dialogLoadingSave3" >
          确定
        </el-button>
      </template>
    </ly-dialog>
  </div>
</template>

<script>

import {ajaxDelete, ajaxDownloadExcel, ajaxGet, ajaxPost, ajaxPut, uploadImg} from '@/api/request'
import {useDictStore} from "@/store/dict";

import {dateFormats, deepClone, getTableHeight} from "@/utils/util";
import LyDialog from "@/components/dialog/dialog";
import Pagination from "@/components/Pagination.vue";
// 列表
const lyAutoCodeList = params => ajaxGet({url: `autocode/ModelListType/`, params})
// 新增
const lyAutoCodeAdd = params => ajaxPost({url: `autocode/ModelListType/`, params})
// 批量新增
const lyAutoCodeAddBatch = params => ajaxPost({url: `autocode/ModelListType/batch/`, params})
// 编辑
const lyAutoCodeEdit = params => ajaxPut({url: `autocode/ModelListType/`, params})
// 删除
const lyAutoCodeDelete = params => ajaxDelete({url: `autocode/ModelListType/`, params})
// 导出
const lyAutoCodeExport = params => ajaxDownloadExcel({url: `autocode/ModelListType/export/`, params})

// 列表
const lyAutoCodeList2 = params => ajaxGet({url: `autocode/ModelListBrand/`, params})
// 新增
const lyAutoCodeAdd2 = params => ajaxPost({url: `autocode/ModelListBrand/`, params})
// 编辑
const lyAutoCodeEdit2 = params => ajaxPut({url: `autocode/ModelListBrand/`, params})
// 删除
const lyAutoCodeDelete2 = params => ajaxDelete({url: `autocode/ModelListBrand/`, params})
// 导出
const lyAutoCodeExport2 = params => ajaxDownloadExcel({url: `autocode/ModelListBrand/export/`, params})
const model_nameLyAudoCodeAPI2 = params => ajaxGet({url: `autocode/ModelListBrand/`, params})

// 批量导入实现
export const batchImportModelTable = params => uploadImg({
  url: `autocode/ModelListType/importexcel/`,
  params
})


export default {
  name: "lyAutoCodeModelListType",
  components: {Pagination, LyDialog},
  setup() {
    const dictStore = useDictStore()
    return {dictStore}
  },
  data() {
    return {
      // 批量添加的参数 start
      tableColumnList: {
        tableHeaderList: [],
        tableBodyList: []
      },//表格展示
      model_name: '',
      // 所有型号表名称
      allModelNameList: [],
      formData3: {},
      rules3: {},
      dialogLoadingSave3: false,
      // 批量添加的参数 end

      //上传文件
      fileList: [],
      isFull: false,
      tableHeight: 500,
      loadingPage: false,
      apiObjList: lyAutoCodeList,
      // 选项框选中数组
      ids: [],
      // 选项框非单个禁用
      single: true,
      // 非多个禁用
      multiple: true,
      formInline: {},
      timers: [],
      tableData: [],
      column: [
        {
          label: "ID",
          prop: "id",
          minWidth: "100",
          hide: true
        },
        {
          label: "型号表名称",
          prop: "name",
          minWidth: "100",
          hide: !true
        },
        {
          label: "手机品牌数量",
          prop: "brand_phone_number",
          minWidth: "100",
          hide: !true
        },
        {
          label: "手机型号数量",
          prop: "model_phone_number",
          minWidth: "100",
          hide: !true
        },
        {
          label: "创建时间",
          prop: "create_datetime",
          minWidth: "180"
        }
      ],
      //弹窗
      dialogVisible: false,
      dialogVisibleBatch: false,
      dialogTitle: "",
      dialogLoadingSave: false,
      fullscreen: false,
      dialogWidth: "50%",
      dialogTop: "15vh",
      formDisabled: false,
      formLabelPosition: "right",
      formLabelWidth: "auto",
      formData: {"id": null, "name": null, "brand_phone_number": null, "model_phone_number": null},
      rules: {
        id: [{required: true, message: "ID不能为空", trigger: 'blur'}],
        name: [{required: true, message: "型号表名称不能为空", trigger: 'blur'}],
      },

      // 子表数据
      isFull2: false,
      tableHeight2: 500,
      loadingPage2: false,
      apiObjList2: lyAutoCodeList2,
      // 选项框选中数组
      ids2: [],
      // 选项框非单个禁用
      single2: true,
      // 非多个禁用
      multiple2: true,
      formInline2: {},
      timers2: [],
      tableData2: [],
      model_name_id: '',
      column2: [
        {
          label: "ID",
          prop: "id",
          minWidth: "100",
          hide: true
        },
        {
          label: "型号名称",
          prop: "model_name",
          minWidth: "100",
          hide: true
        },
        {
          label: "手机品牌",
          prop: "brand_phone",
          minWidth: "10",
          hide: !true
        },
        {
          label: "手机型号",
          prop: "model_phone",
          minWidth: "100",
          hide: !true
        },
        {
          label: "创建时间",
          prop: "create_datetime",
          minWidth: "180",
          hide: true
        }
      ],
      //弹窗
      dialogVisible2: false,
      dialogTitle2: "",
      dialogLoadingSave2: false,
      fullscreen2: false,
      dialogWidth2: "50%",
      dialogTop2: "15vh",
      formDisabled2: false,
      formLabelPosition2: "right",
      formLabelWidth2: "auto",
      formData2: {"id": null, "model_name": null, "brand_phone": null, "model_phone": null},
      rules2: {
        id: [{required: true, message: "ID不能为空", trigger: 'blur'}],
        brand_phone: [{required: true, message: "手机品牌不能为空", trigger: 'blur'}],
      },
      model_nameLyAudoCodeAPIList2: [],
    }
  },
  methods: {
    // 批量设置操作
    // 新增一行数据
    generateSpec() {
      let vm = this
      if (!this.model_name) {
        vm.$message.warning("型号表不能为空")
        return
      }
      let tableObj = {
        tableBodyList: [],
        tableHeaderList: []
      }
      // 假设这是原始的 tableObj.tableBodyList 数组
      let tableBodyListOriginal = this.tableColumnList.tableBodyList;
      // 要追加的对象
      let objToAdd = {
        model_name: this.model_name, // 型号表名称
        phone_brand: null,      // 品牌
        phone_model: null,      // 手机型号
      };

      // 使用展开运算符创建一个新数组，并在末尾追加 objToAdd
      tableObj.tableBodyList = [...tableBodyListOriginal, objToAdd];

      this.tableColumnList.tableBodyList = tableObj.tableBodyList //表格内容数据
      // 表头
      this.tableColumnList.tableHeaderList = {
        prop: 'model_name',
        propName: 'model_name'
      } // 表头
    },
    //获取 所有型号表名称
    getAllModelNameList() {
      let param = {
        page: 1,
        limit: 9999999,
      }
      lyAutoCodeList(param).then(res => {
        if (res.code == 2000) {
          this.allModelNameList = res.data.data
        }
      })
    },
    //批量添加展示-删除
    singleSpecDel(index, row) {
      let vm = this
      //提示框，判断用户是否操作失误
      this.$confirm('确定要删除吗', '提示', {
        confirmButtonText: '确定',
        cancelButtonText: '取消',
        type: 'warning'
      }).then(() => {
        if (vm.tableColumnList.tableBodyList.length <= 1) {
          vm.$message.warning("只有一个不能删除")
          return
        }
        // 删除表格上的数据
        vm.tableColumnList.tableBodyList.splice(index, 1)
        // 删除 formData中的skus 的index下标的数据
        // 删除sku中第index下标的值
        this.$message({
          type: 'success',
          message: '删除成功!'
        })
      }).catch(() => {
      });
    },
    // 批量添加 提交
    batchsubmitData() {
      this.$refs['rulesForm3'].validate(obj => {
        if (obj) {
          //规格sku数据处理
          let param = []
          let errormsg = "";
          let that = this;
          let thetemptabledata = that.tableColumnList.tableBodyList
          for (var s = 0; s < thetemptabledata.length; s++) {
            let item = thetemptabledata[s]
            if (!item.model_name) {
              errormsg = "型号表不能为空"
              that.loadingSave = false
              break
            }
            if (!item.phone_brand) {
              errormsg = "手机品牌不能为空"
              that.loadingSave = false
              break
            }
            if (!item.phone_model) {
              errormsg = "手机型号不能为空"
              that.loadingSave = false
              break
            }
            let tempsku = {
              model_name: item.model_name,
              phone_brand: item.phone_brand,
              phone_model: item.phone_model,
            }
            param.push(tempsku)
          }
          if (errormsg != "") {
            this.loadingSave = false
            this.$message.warning(errormsg)
            return;
          }
          ;
          if (param.length < 1) {
            this.loadingSave = false
            this.$message.warning("请点击新增")
            return;
          }
          lyAutoCodeAddBatch(param).then(res => {
            this.loadingSave = false
            if (res.code == 2000) {
              this.$message.success(res.msg)
              this.handleDialogClose2()
              this.$emit('refreshData')
            } else {
              this.$message.warning(res.msg)
            }
          })
        }
      })
    },
    // 批量设置操作 end
    // hasPermission,
    // isShowBtn,
    //批量导入
    async ImportExcelUploadRequest(param) {
      var vm = this
      // 获取 value_id 的值
      // const value_Id = param.value_id;
      let obj = await batchImportModelTable(param)
      if (obj.code == 2000) {
        vm.$message.success(obj.msg)
        vm.search()
      } else {
        vm.$message.warning(obj.msg)
      }
    },
    ImportExcelbeforeUpload(file) {
      let isLt50M = true
      isLt50M = file.size / 1024 / 1024 < 50
      if (!isLt50M) {
        this.$message.error("上传文件不能超过50MB")
      }
      return isLt50M
    },
    ImportExcelhandleSuccess(response, file, fileList) {
      this.$refs.upload.clearFiles()
    },
    // 批量导入结束
    setLoadingPage(bools) {
      this.loadingPage = bools
      this.$refs.tableref.loadingPage(bools)
    },
    setFull() {
      this.isFull = !this.isFull
      window.dispatchEvent(new Event('resize'))
    },
    //多选项框被选中数据
    handleSelectionChange(selection) {
      this.ids = selection.map(item => item.id);
      this.single = selection.length !== 1;
      this.multiple = !selection.length;
    },
    /** 批量删除按钮操作 */
    handleDelete(row) {
      const ids = this.ids;
      let vm = this
      vm.$confirm('是否确认删除选中的数据项?', "警告", {
        confirmButtonText: "确定",
        cancelButtonText: "取消",
        type: "warning"
      }).then(function () {
        return lyAutoCodeDelete({id: ids}).then(res => {
          if (res.code == 2000) {
            vm.$message.success(res.msg)
            vm.getData()
          } else {
            vm.$message.warning(res.msg)
          }
        })
      })
    },
    convertStrNum(model, value) {
      if (model === "" || model === null || model === undefined) {
        return value
      } else if (Object.prototype.toString.call(model) === "[object String]") {
        return value.toString()
      } else if (Object.prototype.toString.call(model) === "[object Number]") {
        return value * 1
      } else {
        return value
      }
    },
    //判断单个组件编辑时是否禁用disabled参数为bool值
    isFormItemEditDisabled(disabled = false) {
      if (disabled == "" || disabled == null || disabled == undefined) {
        return false
      } else if ((disabled == true || disabled == 'True') && this.dialogTitle === '编辑') {
        return true
      }
      return false
    },
    handelAdd() {
      this.dialogTitle = "添加"
      this.dialogVisible = true
      this.formInline2.model_name_id = 0
      // this.search()
    },
    handelAddBatch() {
      this.dialogTitle = "批量添加"
      this.dialogVisibleBatch = true
      // 获取所有型号表
      this.getAllModelNameList()
    },
    handleDialogClose() {
      this.dialogTitle = ""
      this.dialogVisible = false
      this.dialogVisibleBatch = false
      this.dialogLoadingSave = false
      this.formDisabled = false
      this.formData = {"id": null, "name": null, "brand_phone_number": null, "model_phone_number": null}
      this.tableColumnList = {
        tableHeaderList: [],
        tableBodyList: []
      }
    },
    handleDialogSubmit() {
      this.$refs['rulesForm'].validate((obj) => {
        if (obj) {
          this.dialogLoadingSave = true
          let param = {
            ...this.formData
          }
          if (this.dialogTitle == "编辑") {
            lyAutoCodeEdit(param).then(res => {
              this.dialogLoadingSave = false
              if (res.code == 2000) {
                this.$message.success(res.msg)
                this.handleDialogClose()
                this.getData()
              } else {
                this.$message.warning(res.msg)
              }
            })
          } else {
            lyAutoCodeAdd(param).then(res => {
              this.dialogLoadingSave = false
              if (res.code == 2000) {
                this.$message.success(res.msg)
                this.handleDialogClose()
                this.getData()
              } else {
                this.$message.warning(res.msg)
              }
            })
          }
        }
      })
      this.dialogLoadingSave = false
    },
    handleEdit(row, flag) {
      if (flag == 'edit') {
        this.dialogTitle = "编辑"
        this.dialogVisible = true
        this.formData = deepClone(row)
        // 将主表的ID设置为全局变量
        this.model_name_id = row.id
        // 获取子表数据
        this.formInline2.model_name_id = this.model_name_id
        this.search2()
      } else if (flag == 'delete') {
        let vm = this
        vm.$confirm('您确定要删除选中的数据吗？', '警告', {
          closeOnClickModal: false,
          type: "warning"
        }).then(res => {
          lyAutoCodeDelete({id: row.id}).then(res => {
            if (res.code == 2000) {
              vm.$message.success(res.msg)
              vm.getData()
            } else {
              vm.$message.warning(res.msg)
            }
          })
        }).catch(() => {

        })
      } else if (flag == 'detail') {
        this.dialogTitle = "详情"
        this.dialogVisible = true
        this.formDisabled = true
        this.formData = deepClone(row)
        // 将主表的ID设置为全局变量
        this.model_name_id = row.id
        // 获取子表数据
        this.formInline2.model_name_id = this.model_name_id
        this.search2()
      } else if (flag == "reset") {
        this.formInline = {}
        this.timers = []
        this.search()
      }
    },
    /**
     * 从URL里下载文件
     */
    // 下载文件
    downloadFileURL(url) {
      var a = document.createElement("a")
      var event = new MouseEvent('click')
      a.href = url
      a.target = "_blank"
      a.dispatchEvent(event)
    },
    //excel文件流下载
    downloadExcelBlob(res) {
      let fileName = new Date().getTime() + ".xlsx"
      let dispositionStr = res.headers["content-disposition"];
      if (dispositionStr == null || dispositionStr === "") {

      } else {
        // 获取文件名
        let dispositionArr = dispositionStr.split(";");
        fileName = decodeURIComponent(dispositionArr[1]);
        fileName = fileName.split("=")[1];
      }
      const blob = new Blob([res.data], {
        type: "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet",
      });
      let href = window.URL.createObjectURL(blob); //下载链接
      let link = document.createElement("a")
      link.href = href
      link.download = fileName //下载后文件名
      document.body.appendChild(link);
      link.click(); //点击下载
      document.body.removeChild(link); //下载完成移除元素
      window.URL.revokeObjectURL(href);  //释放blob对象
      this.$message.success('导出成功')
    },
    //导出
    exportDataBackend(my_params) {
      let params = {}
      if (my_params) {
        params = {
          ...my_params
        }
      } else if (this.ids.length > 0) {
        params = {
          ids: this.ids,
        }
      }
      this.setLoadingPage(true)
      lyAutoCodeExport(params).then(res => {
        this.setLoadingPage(false)
        this.downloadExcelBlob(res)
      })
    },
    search() {
      this.$refs.tableref.reload(this.formInline)
    },
    //获取列表
    async getData() {
      this.$refs.tableref.getData()
    },
    timeChange(val) {
      if (val) {
        this.formInline.beginAt = dateFormats(val[0], 'yyyy-MM-dd hh:mm:ss');
        this.formInline.endAt = dateFormats(val[1], 'yyyy-MM-dd hh:mm:ss');
      } else {
        this.formInline.beginAt = null
        this.formInline.endAt = null
      }
      this.search()
    },
    // 计算搜索栏的高度
    listenResize() {
      this.$nextTick(() => {
        this.getTheTableHeight()
      })
    },
    getTheTableHeight() {
      let tabSelectHeight = this.$refs.tableSelect ? this.$refs.tableSelect.offsetHeight : 0
      tabSelectHeight = this.isFull ? tabSelectHeight - 110 : tabSelectHeight
      this.tableHeight = getTableHeight(tabSelectHeight, false) - 125
    },

    // 子表的方法
    setLoadingPage2(bools) {
      this.loadingPage2 = bools
      this.$refs.tableref2.loadingPage(bools)
    },
    setFull2() {
      this.isFull2 = !this.isFull2
      window.dispatchEvent(new Event('resize'))
    },
    //多选项框被选中数据
    handleSelectionChange2(selection) {
      this.ids2 = selection.map(item => item.id);
      this.single2 = selection.length !== 1;
      this.multiple2 = !selection.length;
    },
    /** 批量删除按钮操作 */
    handleDelete2(row) {
      const ids = this.ids2;
      let vm = this
      vm.$confirm('是否确认删除选中的数据项?', "警告", {
        confirmButtonText: "确定",
        cancelButtonText: "取消",
        type: "warning"
      }).then(function () {
        return lyAutoCodeDelete2({id: ids}).then(res => {
          if (res.code == 2000) {
            vm.$message.success(res.msg)
            vm.getData2()
          } else {
            vm.$message.warning(res.msg)
          }
        })
      })
    },
    convertStrNum2(model, value) {
      if (model === "" || model === null || model === undefined) {
        return value
      } else if (Object.prototype.toString.call(model) === "[object String]") {
        return value.toString()
      } else if (Object.prototype.toString.call(model) === "[object Number]") {
        return value * 1
      } else {
        return value
      }
    },
    //判断单个组件编辑时是否禁用disabled参数为bool值
    isFormItemEditDisabled2(disabled = false) {
      if (disabled == "" || disabled == null || disabled == undefined) {
        return false
      } else if ((disabled == true || disabled == 'True') && this.dialogTitle2 === '编辑') {
        return true
      }
      return false
    },
    handelAdd2() {
      this.dialogTitle2 = "添加"
      this.dialogVisible2 = true
      // this.model_nameLyAudoCodeAPIFunction2()
    },
    handleDialogClose2() {
      this.dialogTitle2 = ""
      this.dialogVisible2 = false
      this.dialogLoadingSave2 = false
      this.formDisabled2 = false
      this.formData2 = {"id": null, "model_name": null, "brand_phone": null, "model_phone": null}
      this.loadingSave3 = false
      this.dialogLoadingSave3 = false
      this.dialogVisibleBatch = false
      this.tableColumnList = {
        tableHeaderList: [],
        tableBodyList: []
      }
    },
    handleDialogSubmit2() {
      this.$refs['rulesForm2'].validate((obj) => {
        if (obj) {
          this.dialogLoadingSave2 = true
          let param = {
            ...this.formData2
          }
          param.model_name_id = this.model_name_id
          if (this.dialogTitle2 == "编辑") {
            lyAutoCodeEdit2(param).then(res => {
              this.dialogLoadingSave2 = false
              if (res.code == 2000) {
                this.$message.success(res.msg)
                this.handleDialogClose2()
                this.formInline2.model_name_id = this.model_name_id
                this.getData2()
              } else {
                this.$message.warning(res.msg)
              }
            })
          } else {
            lyAutoCodeAdd2(param).then(res => {
              this.dialogLoadingSave2 = false
              if (res.code == 2000) {
                this.$message.success(res.msg)
                this.handleDialogClose2()
                this.formInline2.model_name_id = this.model_name_id
                this.getData2()
              } else {
                this.$message.warning(res.msg)
              }
            })
          }

        }
      })
      this.dialogLoadingSave2 = false
    },
    handleEdit2(row, flag) {
      if (flag == 'edit') {
        this.dialogTitle2 = "编辑"
        this.dialogVisible2 = true
        this.formData2 = deepClone(row)
        // this.model_nameLyAudoCodeAPIFunction2()
      } else if (flag == 'delete') {
        let vm = this
        vm.$confirm('您确定要删除选中的数据吗？', '警告', {
          closeOnClickModal: false,
          type: "warning"
        }).then(res => {
          lyAutoCodeDelete2({id: row.id}).then(res => {
            if (res.code == 2000) {
              vm.$message.success(res.msg)
              vm.getData2()
            } else {
              vm.$message.warning(res.msg)
            }
          })
        }).catch(() => {

        })
      } else if (flag == 'detail') {
        this.dialogTitle2 = "详情"
        this.dialogVisible2 = true
        this.formDisabled2 = true
        this.formData2 = deepClone(row)
        // this.model_nameLyAudoCodeAPIFunction2()
      } else if (flag == "reset") {
        this.formInline2 = {}
        this.timers2 = []
        this.search2()
      }
    },
    /**
     * 从URL里下载文件
     */
    // 下载文件
    downloadFileURL2(url) {
      var a = document.createElement2("a")
      var event = new MouseEvent2('click')
      a.href = url
      a.target = "_blank"
      a.dispatchEvent(event)
    },
    //excel文件流下载
    downloadExcelBlob2(res) {
      let fileName = new Date().getTime() + ".xlsx"
      let dispositionStr = res.headers["content-disposition"];
      if (dispositionStr == null || dispositionStr === "") {

      } else {
        // 获取文件名
        let dispositionArr = dispositionStr.split(";");
        fileName = decodeURIComponent2(dispositionArr[1]);
        fileName = fileName.split("=")[1];
      }
      const blob = new Blob([res.data], {
        type: "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet",
      });
      let href = window.URL.createObjectURL2(blob); //下载链接
      let link = document.createElement2("a")
      link.href = href
      link.download = fileName //下载后文件名
      document.body.appendChild2(link);
      link.click(); //点击下载
      document.body.removeChild2(link); //下载完成移除元素
      window.URL.revokeObjectURL2(href);  //释放blob对象
      this.$message.success('导出成功')
    },
    //导出
    exportDataBackend2() {
      let params = {}
      if (this.ids2.length > 0) {
        params = {
          ids: this.ids2,
        }
      }
      this.setLoadingPage2(true)
      lyAutoCodeExport2(params).then(res => {
        this.setLoadingPage2(false)
        this.downloadExcelBlob2(res)
      })
    },
    search2() {
      // 获取子表数据
      this.formInline2.model_name_id = this.model_name_id
      console.log(this.model_name_id)
      this.$refs.tableref2.reload(this.formInline2)
      // this.formInline2.page = 1
      // this.formInline2.limit = 10
      // this.getData(this.model_name_id)
    },
    //获取列表
    async getData2(model_name_) {
      this.loadingPage2 = true
      // 先将 this.formInline 的值复制出来
      const formInline2 = {...this.formInline2};
      // 添加一个新的 model_name_id 键和值
      if (model_name_) {
        formInline2.model_name_id = model_name_; // model_name_ 是你要添加的新值
      } else {
        formInline2.model_name_id = this.model_name_id;
      }
      this.model_name_id = model_name_
      // formInline2.page = 1; // 目前永远取第一页数据
      this.$refs.tableref2.getData(formInline2)
    },
    timeChange2(val) {
      if (val) {
        this.formInline2.beginAt = dateFormats(val[0], 'yyyy-MM-dd hh:mm:ss');
        this.formInline2.endAt = dateFormats(val[1], 'yyyy-MM-dd hh:mm:ss');
      } else {
        this.formInline2.beginAt = null
        this.formInline2.endAt = null
      }
      this.search2()
    },
    // 计算搜索栏的高度
    listenResize2() {
      this.$nextTick(() => {
        this.getTheTableHeight2()
      })
    },
    getTheTableHeight2() {
      let tabSelectHeight = this.$refs.tableSelect2 ? this.$refs.tableSelect2.offsetHeight : 0
      tabSelectHeight = this.isFull2 ? tabSelectHeight - 110 : tabSelectHeight
      this.tableHeight2 = getTableHeight(tabSelectHeight, false) - 125
    },
    // model_nameLyAudoCodeAPIFunction2() {
    //   model_nameLyAudoCodeAPI2({page: 1, limit: 999}).then(res => {
    //     if (res.code == 2000) {
    //       this.model_nameLyAudoCodeAPIList2 = res.data.data
    //     }
    //   })
    // },

  },
  mounted() {
    // 监听页面宽度变化搜索框的高度
    window.addEventListener('resize', this.listenResize);
    this.$nextTick(() => {
      this.getTheTableHeight()
    })
  },

  unmounted() {
    // 页面销毁，去掉监听事件
    window.removeEventListener("resize", this.listenResize);
  },

}
</script>

<style lang="scss" scoped>

</style>
