<template>
    <div class="lyworkflow-addnode-btn-box">
	 	<div class="lyworkflow-addnode-btn">
			<el-popover placement="right-start" :width="160" trigger="click" :hide-after="0" :show-after="0" v-if="isDesign">
				<template #reference>
					<el-button type="primary" icon="Plus" circle></el-button>
				</template>
                <div class="lyworkflow-addnode-popover-body">
					<el-menu>
                        <el-menu-item @click="(obj)=>{addNodeClick(obj,1)}" index="1">
                            <el-icon style="color: #e6a23c"><User-filled /></el-icon>
                            <span>审批节点</span>
                        </el-menu-item>
                        <el-menu-item @click="(obj)=>{addNodeClick(obj,2)}" index="2">
                            <el-icon style="color: #419efa;"><Promotion /></el-icon>
                            <span>抄送节点</span>
                        </el-menu-item>
                        <el-menu-item @click="(obj)=>{addNodeClick(obj,4)}" index="4">
                            <el-icon style="color: #67c23a;"><Share /></el-icon>
                            <span>条件分支</span>
                        </el-menu-item>
                        <el-menu-item @click="(obj)=>{addNodeClick(obj,5)}" index="5">
                            <el-icon style="color: #f06705;"><BellFilled /></el-icon>
                            <span>通知节点</span>
                        </el-menu-item>
                    </el-menu>
				</div>
			</el-popover>
	 	</div>
	 </div>
</template>

<script setup>
    import { ref, onMounted} from 'vue'

    const props = defineProps({
        modelValue: { type: Object, default: () => {}  },
        isDesign: { //是否设计模式
            type: Boolean,
            default: true
        }
    })

    const emits = defineEmits(['update:modelValue'])

    function addNodeClick(obj,type){
        var node = {}
        if (type == 1) {
            node = {
                nodeName: "审核人",
                type: 1,			//节点类型
                setType: 1,			//审核人类型
                nodeUserList: [],	//审核人成员
                nodeRoleList: [],	//审核角色
                examineLevel: 1,	//指定主管层级
                directorLevel: 1,	//自定义连续主管审批层级
                selectMode: 1,		//发起人自选类型
                termAuto: false,	//审批期限超时自动审批
                term: 0,			//审批期限
                termMode: 1,		//审批期限超时后执行类型
                examineMode: 1,		//多人审批时审批方式
                directorMode: 0,	//连续主管审批方式
                childNode: props.modelValue
            }
        }else if(type == 2){
            node = {
                nodeName: "抄送人",
                type: 2,
                userSelectFlag: true,
                nodeUserList: [],
                childNode: props.modelValue
            }

        }else if(type == 4){
            node = {
                nodeName: "条件路由",
                type: 4,
                conditionNodes: [
                    {
                        nodeName: "条件1",
                        type: 3,
                        priorityLevel: 1,
                        conditionMode: 1,
                        conditionList: []
                    },
                    {
                        nodeName: "条件2",
                        type: 3,
                        priorityLevel: 2,
                        conditionMode: 1,
                        conditionList: []
                    }
                ],
                childNode: props.modelValue
            }

        }else if(type == 5){
            node = {
                nodeName: "通知",
                type: 5,
                setType: 1,			//通知人类型
                nodeRoleList: [],	//通知角色
                examineLevel: 1,	//指定主管层级
                directorLevel: 1,	//自定义连续主管审批层级
                selectMode: 1,		//发起人自选类型
                noticeTypeList: [], //通知类型（支持多选）1、站内 2、邮件 3、短信 4、钉钉
                nodeUserList: [],   //通知人员
                noticeSubject:"",   //通知主题
                noticeContent:"",   //通知内容
                childNode: props.modelValue
            }

        }
        emits("update:modelValue", node)
    }

</script>

<style scoped>
    
</style>