<template>
  <div class="lyadmin-body" :class="{'ly-is-full':isFull}">
    <el-container class="lycontainer">
      <el-aside width="300px" v-loading="showDicloading">
        <el-container class="leftct">
          <div class="common-head" ref="tableSelect">
            <el-input placeholder="输入关键字进行过滤" v-model="dicFilterText" clearable></el-input>
          </div>
          <el-main class="nopadding">
            <el-scrollbar :height="tableHeight">
              <el-tree ref="dic" class="menu" node-key="id" :data="dicList" :props="dicProps" :highlight-current="true"
                       :expand-on-click-node="false" :filter-node-method="dicFilterNode" @node-click="dicClick">
                <template #default="{node, data}">
									<span class="custom-tree-node">
										<span class="label">{{ node.label }}</span>
										<span class="code">{{ data.value }}</span>
										<span class="do">
											<el-button-group>
												<el-button icon="edit" size="small" @click.stop="dicEdit(data)"></el-button>
												<el-button icon="delete" size="small" @click.stop="dicDel(node, data)"></el-button>
											</el-button-group>
										</span>
									</span>
                </template>
              </el-tree>
            </el-scrollbar>
          </el-main>
        </el-container>
      </el-aside>
      <el-container style="flex-direction: column;">
        <div class="common-head">
          <div>
            <el-button type="primary" icon="plus" @click="addDic" v-show="hasPermission(this.$route.name,'Create')">
              新增字典分类
            </el-button>
            <el-button type="warning" icon="plus" @click="addInfo" v-show="hasPermission(this.$route.name,'Create')">
              新增字典项
            </el-button>
            <el-button type="danger" plain icon="delete" :disabled="multiple" @click="handleDelete"
                       v-show="hasPermission(this.$route.name,'Delete')">删除
            </el-button>
          </div>
        </div>
        <el-main class="nopadding">
          <el-table ref="tableref" :data="tableData" row-key="id" @selection-change="handleSelectionChange">
            <el-table-column type="selection" width="50"></el-table-column>
            <!--						<el-table-column label="" width="60">-->
            <!--							<template #default>-->
            <!--								<el-tag class="move nocursor" style="cursor: move;"><el-icon><DCaret /></el-icon></el-tag>-->
            <!--							</template>-->
            <!--						</el-table-column>-->
            <el-table-column type="index" width="60" align="center" label="序号"></el-table-column>
            <el-table-column label="名称" prop="label" min-width="150"></el-table-column>
            <el-table-column label="键值" prop="value" min-width="150"></el-table-column>
            <el-table-column label="是否有效" prop="status" min-width="100">
              <template #default="scope">
                <el-switch v-model="scope.row.status" @change="changeSwitch($event, scope.row)"
                           :loading="scope.row.$switch_yx"></el-switch>
              </template>
            </el-table-column>
            <el-table-column label="排序" prop="sort" min-width="100"></el-table-column>
            <el-table-column label="备注" prop="remark" min-width="100"></el-table-column>
            <el-table-column label="操作" fixed="right" width="120">
              <template #default="scope">
                <span class="table-operate-btn" @click="table_edit(scope.row, scope.$index)"
                      v-show="hasPermission(this.$route.name,'Update')">编辑</span>
                <el-popconfirm title="确定删除吗？" @confirm="table_del(scope.row, scope.$index)"
                               v-if="hasPermission(this.$route.name,'Delete')">
                  <template #reference>
                    <span class="table-operate-btn">删除</span>
                  </template>
                </el-popconfirm>
              </template>
            </el-table-column>
          </el-table>
        </el-main>
      </el-container>
    </el-container>
    <sys-dic-module ref="sysdicFlag" @refreshData="getData"></sys-dic-module>
    <sys-dic-list-module ref="sysdicDetailFlag" @refreshData="getDicDetailList"></sys-dic-list-module>
  </div>

</template>

<script>
import {systemDictionary, systemDictionaryDelete, systemDictionaryEdit,} from '@/api/api'
import Sortable from 'sortablejs'
import SysDicModule from "@/views/systemManage/sysDictionary/components/sysDicModule";
import SysDicListModule from "@/views/systemManage/sysDictionary/components/sysDicListModule";
import {getTableHeight} from "@/utils/util";

export default {
  name: 'sysDictionary',
  components: {
    SysDicListModule,
    SysDicModule,
  },
  data() {
    return {
      isFull: false,
      // 选项框选中数组
      ids: [],
      // 选项框非单个禁用
      single: true,
      // 非多个禁用
      multiple: true,
      tableHeight: 500,
      dialog: {
        dic: false,
        info: false
      },
      showDicloading: false,
      dicList: [],
      dicFilterText: '',
      dicProps: {
        label: 'label'
      },
      tableData: [],
      listApiParams: {},
    }
  },
  watch: {
    dicFilterText(val) {
      this.$refs.dic.filter(val);
    }
  },
  mounted() {
    this.getData()
    this.rowDrop()
    // 监听页面宽度变化搜索框的高度
    // window.addEventListener('resize', this.listenResize);
    // this.$nextTick(() => {
    //   	this.getTheTableHeight()
    // })
  },
  unmounted() {
    // 页面销毁，去掉监听事件
    // window.removeEventListener("resize", this.listenResize);
  },
  methods: {
    setFull() {
      this.isFull = !this.isFull
      window.dispatchEvent(new Event('resize'))
    },
    //多选项框被选中数据
    handleSelectionChange(selection) {
      this.ids = selection.map(item => item.id);
      this.single = selection.length !== 1;
      this.multiple = !selection.length;
    },
    /** 批量删除按钮操作 */
    handleDelete(row) {
      const ids = this.ids;
      let vm = this
      vm.$confirm('是否确认删除选中的数据项?', "警告", {
        confirmButtonText: "确定",
        cancelButtonText: "取消",
        type: "warning"
      }).then(function () {
        return systemDictionaryDelete({id: ids}).then(res => {
          if (res.code == 2000) {
            const loading = this.$loading();
            this.ids.forEach(item => {
              this.tableData.forEach((itemI, indexI) => {
                if (item.id === itemI.id) {
                  this.tableData.splice(indexI, 1)
                }
              })
            })
            loading.close();
            vm.$message.success(res.msg)
          } else {
            vm.$alert(res.msg, "提示", {type: 'error'})
          }
        })
      })
    },
    //加载树数据
    getData() {
      this.showDicloading = true
      systemDictionary({parent_isnull: true, page: 1, limit: 999}).then(res => {
        this.showDicloading = false
        if (res.code == 2000) {
          this.dicList = res.data.data
        }
        //获取第一个节点,设置选中并且加载明细列表
        var firstNode = this.dicList[0];
        if (firstNode) {
          this.$nextTick(() => {
            this.$refs.dic.setCurrentKey(firstNode.id)
          })
          this.getDicDetailList(firstNode.id)
        }
      })
    },
    getDicDetailList(parent_id) {
      if (parent_id) {
        this.listApiParams = {
          parent: parent_id,
          page: 1,
          limit: 999
        }
      }
      this.$refs.dic.setCurrentKey(this.listApiParams.parent)
      systemDictionary(this.listApiParams).then(res => {
        if (res.code == 2000) {
          this.tableData = res.data.data
        }
      })
    },
    editDicAndDetail(params) {
      systemDictionaryEdit(params).then(res => {
        if (res.code == 2000) {
          this.$message.success(res.msg)
        } else {
          this.$alert(res.msg, "提示", {type: 'error'})
        }
      })
    },
    //树过滤
    dicFilterNode(value, data) {
      if (!value) return true;
      var targetText = data.label + data.value;
      return targetText.indexOf(value) !== -1;
    },
    //树增加
    addDic() {
      this.$refs.sysdicFlag.addModuleFn(null, '新增')
    },
    //编辑树
    dicEdit(data) {
      this.$refs.sysdicFlag.addModuleFn(data, '编辑')
    },
    //树点击事件
    dicClick(data) {
      this.getDicDetailList(data.id)
    },
    //删除树
    dicDel(node, data) {
      this.$confirm(`确定删除 ${data.label} 项吗？`, '提示', {
        type: 'warning'
      }).then(() => {
        let vm = this
        this.showDicloading = true;
        systemDictionaryDelete({id: data.id}).then(res => {
          vm.showDicloading = false;
          if (res.code == 2000) {
            //删除节点是否为高亮当前 是的话 设置第一个节点高亮
            var dicCurrentKey = vm.$refs.dic.getCurrentKey();
            vm.$refs.dic.remove(data.id)
            if (dicCurrentKey == data.id) {
              var firstNode = vm.dicList[0];
              if (firstNode) {
                vm.$refs.dic.setCurrentKey(firstNode.id);
                vm.getDicDetailList(firstNode.id)
              } else {
                vm.tableData = []
              }
            }
          } else {
            this.$alert(res.msg, "提示", {type: 'error'})
          }
        })
      }).catch(() => {

      })
    },
    //行拖拽
    rowDrop() {
      const _this = this
      const tbody = this.$refs.tableref.$el.querySelector('.el-table__body-wrapper tbody')
      Sortable.create(tbody, {
        handle: ".move",
        animation: 300,
        ghostClass: "ghost",
        onEnd({newIndex, oldIndex}) {
          const currRow = _this.tableData.splice(oldIndex, 1)[0]
          _this.tableData.splice(newIndex, 0, currRow)
          _this.$message.success("排序成功")
        }
      })
    },
    //添加明细
    addInfo() {
      var dicCurrentKey = this.$refs.dic.getCurrentKey()
      this.$refs.sysdicDetailFlag.addModuleFn(null, '新增', dicCurrentKey)
    },
    //编辑明细
    table_edit(row) {
      this.$refs.sysdicDetailFlag.addModuleFn(row, '编辑', null)
    },
    //删除明细
    async table_del(row, index) {
      systemDictionaryDelete({id: row.id}).then(res => {
        if (res.code == 2000) {
          this.tableData.splice(index, 1);
          this.$message.success(res.msg)
        } else {
          this.$alert(res.msg, "提示", {type: 'error'})
        }
      })
    },
    //表格内开关事件
    changeSwitch(val, row) {
      //1.还原数据
      row.yx = row.yx == '1' ? '0' : '1'
      //2.执行加载
      row.$switch_yx = true;
      //3.等待接口返回后改变值
      setTimeout(() => {
        delete row.$switch_yx;
        row.yx = val;//新值
        this.editDicAndDetail(row)
      }, 500)
    },
    // 计算搜索栏的高度
    listenResize() {
      this.$nextTick(() => {
        this.getTheTableHeight()
      })
    },
    getTheTableHeight() {
      let tabSelectHeight = this.$refs.tableSelect ? this.$refs.tableSelect.$el.offsetHeight : 0
      tabSelectHeight = this.isFull ? tabSelectHeight - 110 : tabSelectHeight
      this.tableHeight = getTableHeight(tabSelectHeight)
    },
  }
}
</script>

<style scoped>
.lyadmin-body {
  height: 100%;
}

.lycontainer {
  height: 100%;
  border: 1px solid var(--el-border-color-light);
}

.leftct {
  display: flex;
  flex-direction: column;
  height: 100%;
  border-right: 1px solid var(--el-border-color-light);
}

.common-head {
  background: var(--el-color-white);
  border-bottom: 1px solid var(--el-border-color-light);
  padding: 13px 15px;
  justify-content: space-between;
}

.menu:deep(.el-tree-node__content) {
  height: 36px !important;
}

.menu:deep(.el-tree-node__label) {
  display: flex;
  flex: 1;
  height: 100%;
}

.custom-tree-node {
  display: flex;
  flex: 1;
  align-items: center;
  justify-content: space-between;
  font-size: 14px;
  padding-right: 24px;
  height: 100%;
}

.custom-tree-node .code {
  font-size: 12px;
  color: #999;
}

.custom-tree-node .do {
  display: none;
}

.custom-tree-node:hover .code {
  display: none;
}

.custom-tree-node:hover .do {
  display: inline-block;
}

.nopadding:deep(.el-table__inner-wrapper::before) {
  height: 0;
}

.nocursor:deep(.el-icon) {
  cursor: move
}
</style>
