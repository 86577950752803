<template>
  <div class="vue3-cron-div">
<!--    <el-button-->
<!--      class="language"-->
<!--      text-->
<!--      type="primary"-->
<!--      @click="state.language = state.language === 'en' ? 'cn' : 'en'"-->
<!--      >{{ state.language === 'en' ? 'cn' : 'en' }}</el-button>-->
    <el-tabs type="border-card">
      <el-tab-pane>
        <template #label>
          <span><el-icon><Calendar /></el-icon> {{ state.text.Minutes.name }}</span>
        </template>
        <div class="tabBody myScroller" :style="{'max-height': maxHeight}">
          <el-row>
            <el-radio v-model="state.minute.cronEvery" label="1">{{state.text.Minutes.every }}</el-radio>
          </el-row>
          <el-row>
            <el-radio v-model="state.minute.cronEvery" label="2"
              >{{ state.text.Minutes.interval[0] }}
              <el-input-number
                v-model="state.minute.incrementIncrement"
                :min="1"
                :max="60"
              ></el-input-number>
              {{ state.text.Minutes.interval[1] }}
            </el-radio>
          </el-row>
          <el-row>
            <el-radio class="long" v-model="state.minute.cronEvery" label="3"
              >{{ state.text.Minutes.specific }}
              <el-select
                multiple
                v-model="state.minute.specificSpecific"
              >
                <el-option
                  v-for="(val, index) in 60"
                  :key="index"
                  :value="val - 1"
                  >{{ val - 1 }}</el-option
                >
              </el-select>
            </el-radio>
          </el-row>
          <el-row>
            <el-radio v-model="state.minute.cronEvery" label="4"
              >{{ state.text.Minutes.cycle[0] }}
              <el-input-number
                v-model="state.minute.rangeStart"
                :min="1"
                :max="60"
              ></el-input-number>
              {{ state.text.Minutes.cycle[1] }}
              <el-input-number
                v-model="state.minute.rangeEnd"
                :min="0"
                :max="59"
              ></el-input-number>
              {{ state.text.Minutes.cycle[2] }}
            </el-radio>
          </el-row>
        </div>
      </el-tab-pane>
      <el-tab-pane>
        <template #label>
          <span><el-icon><Calendar /></el-icon> {{ state.text.Hours.name }}</span>
        </template>
        <div class="tabBody myScroller" :style="{'max-height': maxHeight}">
          <el-row>
            <el-radio v-model="state.hour.cronEvery" label="1">{{
              state.text.Hours.every
            }}</el-radio>
          </el-row>
          <el-row>
            <el-radio v-model="state.hour.cronEvery" label="2"
              >{{ state.text.Hours.interval[0] }}
              <el-input-number
                v-model="state.hour.incrementIncrement"
                :min="0"
                :max="23"
              ></el-input-number>
              {{ state.text.Hours.interval[1] }}
            </el-radio>
          </el-row>
          <el-row>
            <el-radio class="long" v-model="state.hour.cronEvery" label="3"
              >{{ state.text.Hours.specific }}
              <el-select  multiple v-model="state.hour.specificSpecific">
                <el-option
                  v-for="(val, index) in 24"
                  :key="index"
                  :value="val - 1"
                  >{{ val - 1 }}</el-option
                >
              </el-select>
            </el-radio>
          </el-row>
          <el-row>
            <el-radio v-model="state.hour.cronEvery" label="4"
              >{{ state.text.Hours.cycle[0] }}
              <el-input-number
                v-model="state.hour.rangeStart"
                :min="0"
                :max="23"
              ></el-input-number>
              {{ state.text.Hours.cycle[1] }}
              <el-input-number
                v-model="state.hour.rangeEnd"
                :min="0"
                :max="23"
              ></el-input-number>
              {{ state.text.Hours.cycle[2] }}
            </el-radio>
          </el-row>
        </div>
      </el-tab-pane>
            <el-tab-pane>
        <template #label>
          <span><el-icon><Calendar /></el-icon> {{ state.text.Day.name }}</span>
        </template>
        <div class="tabBody myScroller" :style="{'max-height': maxHeight}">
          <el-row>
            <el-radio v-model="state.day.cronEvery" label="1">{{
              state.text.Day.every
            }}</el-radio>
          </el-row>
          <el-row>
            <el-radio v-model="state.day.cronEvery" label="3"
              >{{ state.text.Day.intervalDay[0] }}
              <el-input-number
                v-model="state.day.incrementIncrement"
                :min="1"
                :max="31"
              ></el-input-number>
              {{ state.text.Day.intervalDay[1] }}
            </el-radio>
          </el-row>
          <el-row>
            <el-radio class="long" v-model="state.day.cronEvery" label="5"
              >{{ state.text.Day.specificDay }}
              <el-select multiple v-model="state.day.specificSpecific">
                <el-option
                  v-for="(val, index) in 31"
                  :key="index"
                  :value="val"
                  >{{ val }}</el-option
                >
              </el-select>
            </el-radio>
          </el-row>
        </div>
      </el-tab-pane>
      <el-tab-pane>
        <template #label>
          <span><el-icon><Calendar /></el-icon> {{ state.text.Month.name }}</span>
        </template>
        <div class="tabBody myScroller" :style="{'max-height': maxHeight}">
          <el-row>
            <el-radio v-model="state.month.cronEvery" label="1">{{
              state.text.Month.every
            }}</el-radio>
          </el-row>
          <el-row>
            <el-radio v-model="state.month.cronEvery" label="2"
              >{{ state.text.Month.interval[0] }}
              <el-input-number
                v-model="state.month.incrementIncrement"
                :min="0"
                :max="12"
              ></el-input-number>
              {{ state.text.Month.interval[1] }}
            </el-radio>
          </el-row>
          <el-row>
            <el-radio class="long" v-model="state.month.cronEvery" label="3"
              >{{ state.text.Month.specific }}
              <el-select multiple v-model="state.month.specificSpecific">
                <el-option
                  v-for="(val, index) in 12"
                  :key="index"
                  :label="val"
                  :value="val"
                ></el-option>
              </el-select>
            </el-radio>
          </el-row>
          <el-row>
            <el-radio v-model="state.month.cronEvery" label="4"
              >{{ state.text.Month.cycle[0] }}
              <el-input-number
                v-model="state.month.rangeStart"
                :min="1"
                :max="12"
              ></el-input-number>
              {{ state.text.Month.cycle[1] }}
              <el-input-number


                v-model="state.month.rangeEnd"
                :min="1"
                :max="12"
              ></el-input-number>
            </el-radio>
          </el-row>
        </div>
      </el-tab-pane>
      <el-tab-pane>
        <template #label>
          <span><el-icon><Calendar /></el-icon> {{ state.text.Week.name }}</span>
        </template>
        <div class="tabBody myScroller" :style="{'max-height': maxHeight}">
          <el-row>
            <el-radio v-model="state.day.cronEvery" label="1">{{
              state.text.Week.every
            }}</el-radio>
          </el-row>
          <el-row>
            <el-radio v-model="state.day.cronEvery" label="2"
              >{{ state.text.Day.intervalWeek[0] }}
              <el-input-number
                v-model="state.week.incrementIncrement"
                :min="1"
                :max="7"
              ></el-input-number>
              {{ state.text.Day.intervalWeek[1] }}
            </el-radio>
          </el-row>
          <el-row>
            <el-radio class="long" v-model="state.day.cronEvery" label="4"
              >{{ state.text.Day.specificWeek }}
              <el-select  multiple v-model="state.week.specificSpecific">
                <el-option
                  v-for="(val, index) in 7"
                  :key="index"
                  :label="state.text.Week.week[val - 1]"
                  :value="
                    ['0', '1', '2', '3', '4', '5', '6'][val - 1]
                  "
                ></el-option>
              </el-select>
            </el-radio>
          </el-row>
        </div>
      </el-tab-pane>
    </el-tabs>
    <div class="bottom">
      <div class="value">
        <span>
          cron预览:
        </span>
        <el-tag type="" size="large">
          {{ state.cron }}
        </el-tag>
        </div>
        <div class="buttonDiv">
      <el-button type="primary"  @click.stop="handleChange">{{ state.text.Save }}</el-button>
      <el-button type="primary"  @click="close">{{ state.text.Close }}</el-button>
      </div>
    </div>
    <div>
      最近五次执行时间：
      <div>
        <ul>
          <li v-for='item in state.previewTimes' :key="item">{{item}}</li>
		</ul>
      </div>
    </div>
  </div>
</template>
<script>
import Language from "./language";
import { reactive, computed,toRefs,defineComponent } from "vue";
export default defineComponent({
  name: "cronExpression",
  props: {
    cronValue: {},
    i18n: {},
    maxHeight: {}
  },
  setup(props, { emit }) {
    const { i18n } = toRefs(props)
    const state = reactive({
      language: i18n.value,
      second: {
        cronEvery: "1",
        incrementStart: 3,
        incrementIncrement: 5,
        rangeStart: 0,
        rangeEnd: 0,
        specificSpecific: [],
      },
      minute: {
        cronEvery: "1",
        incrementStart: 3,
        incrementIncrement: 5,
        rangeStart: 0,
        rangeEnd: 2,
        specificSpecific: [],
      },
      hour: {
        cronEvery: "1",
        incrementStart: 3,
        incrementIncrement: 5,
        rangeStart: 0,
        rangeEnd: 1,
        specificSpecific: [],
      },
      day: {
        cronEvery: "1",
        incrementStart: 1,
        incrementIncrement: 1,
        rangeStart: 0,
        rangeEnd: 0,
        specificSpecific: [],
        cronLastSpecificDomDay: 1,
        cronDaysBeforeEomMinus: 0,
        cronDaysNearestWeekday: 0,
      },
      week: {
        cronEvery: "1",
        incrementStart: 1,
        incrementIncrement: 1,
        specificSpecific: [],
        cronNthDayDay: 1,
        cronNthDayNth: 1,
      },
      month: {
        cronEvery: "1",
        incrementStart: 3,
        incrementIncrement: 5,
        rangeStart: 1,
        rangeEnd: 2,
        specificSpecific: [],
      },
      year: {
        cronEvery: "1",
        incrementStart: 2017,
        incrementIncrement: 1,
        rangeStart: 0,
        rangeEnd: 0,
        specificSpecific: [],
      },
      output: {
        // second: "",
        minute: "",
        hour: "",
        day: "",
        month: "",
        Week: "",
        // year: "",
      },
      text: computed(() => Language[state.language || "cn"]),
      secondsText: computed(() => {
        let seconds = "";
        let cronEvery = state.second.cronEvery;
        switch (cronEvery.toString()) {
          case "1":
            seconds = "*";
            break;
          case "2":
            seconds =
              state.second.incrementStart +
              "/" +
              state.second.incrementIncrement;
            break;
          case "3":
            state.second.specificSpecific.map((val) => {
              seconds += val + ",";
            });
            seconds = seconds.slice(0, -1);
            break;
          case "4":
            seconds = state.second.rangeStart + "-" + state.second.rangeEnd;
            break;
        }
        return seconds;
      }),
      minutesText: computed(() => {
        let minutes = "";
        let cronEvery = state.minute.cronEvery;
        switch (cronEvery.toString()) {
          case "1":
            minutes = "*";
            break;
          case "2":
            minutes =
              "*/" +
              state.minute.incrementIncrement;
            break;
          case "3":
            state.minute.specificSpecific.map((val) => {
              minutes += val + ",";
            });
            minutes = minutes.slice(0, -1);
            break;
          case "4":
            minutes = state.minute.rangeStart + "-" + state.minute.rangeEnd;
            break;
        }
        return minutes;
      }),
      hoursText: computed(() => {
        let hours = "";
        let cronEvery = state.hour.cronEvery;
        switch (cronEvery.toString()) {
          case "1":
            hours = "*";
            break;
          case "2":
            hours =
             "*/" + state.hour.incrementIncrement;
            break;
          case "3":
            state.hour.specificSpecific.map((val) => {
              hours += val + ",";
            });
            hours = hours.slice(0, -1);
            break;
          case "4":
            hours = state.hour.rangeStart + "-" + state.hour.rangeEnd;
            break;
        }
        return hours;
      }),
      daysText: computed(() => {
        let days = "";
        let cronEvery = state.day.cronEvery;
        switch (cronEvery.toString()) {
          case "1":
            break;
          case "2":
          case "4":
          case "11":
            days = "*";
            break;
          case "3":
            days =
              "*/" + state.day.incrementIncrement;
            break;
          case "5":
            state.day.specificSpecific.map((val) => {
              days += val + ",";
            });
            days = days.slice(0, -1);
            break;
          case "6":
            days = "L";
            break;
          case "7":
            days = "LW";
            break;
          case "8":
            days = state.day.cronLastSpecificDomDay + "L";
            break;
          case "9":
            days = "L-" + state.day.cronDaysBeforeEomMinus;
            break;
          case "10":
            days = state.day.cronDaysNearestWeekday + "W";
            break;
        }
        return days;
      }),
      weeksText: computed(() => {
        let weeks = "";
        let cronEvery = state.day.cronEvery;
        switch (cronEvery.toString()) {
          case "1":
          case "3":
          case "5":
            weeks = "*";
            break;
          case "2":
            weeks =
              "*/" + state.week.incrementIncrement;
            break;
          case "4":
            state.week.specificSpecific.map((val) => {
              weeks += val + ",";
            });
            weeks = weeks.slice(0, -1);
            break;
          case "6":
          case "7":
          case "8":
          case "9":
          case "10":
            weeks = "*";
            break;
          case "11":
            weeks = state.week.cronNthDayDay + "#" + state.week.cronNthDayNth;
            break;
        }
        return weeks;
      }),
      monthsText: computed(() => {
        let months = "";
        let cronEvery = state.month.cronEvery;
        switch (cronEvery.toString()) {
          case "1":
            months = "*";
            break;
          case "2":
            months =
              "*/" + state.month.incrementIncrement;
            break;
          case "3":
            state.month.specificSpecific.map((val) => {
              months += val + ",";
            });
            months = months.slice(0, -1);
            break;
          case "4":
            months = state.month.rangeStart + "-" + state.month.rangeEnd;
            break;
        }
        return months;
      }),
      yearsText: computed(() => {
        let years = "";
        let cronEvery = state.year.cronEvery;
        switch (cronEvery.toString()) {
          case "1":
            years = "*";
            break;
          case "2":
            years = state.year.incrementStart + "/" + state.year.incrementIncrement;
            break;
          case "3":
            state.year.specificSpecific.map((val) => {
              years += val + ",";
            });
            years = years.slice(0, -1);
            break;
          case "4":
            years = state.year.rangeStart + "-" + state.year.rangeEnd;
            break;
        }
        return years;
      }),
      cron: computed(() => {
        return `${state.minutesText ||
          "*"} ${state.hoursText || "*"} ${state.daysText ||
          "*"} ${state.monthsText || "*"} ${state.weeksText ||
          "*"}`;
        // return `${state.secondsText || "*"} ${state.minutesText ||
        //   "*"} ${state.hoursText || "*"} ${state.daysText ||
        //   "*"} ${state.monthsText || "*"} ${state.weeksText ||
        //   "?"} ${state.yearsText || "*"}`;
      }),
      dayRule: "",
      dayRuleSup: "",
      dateArr:[],
      isShowPreviewTimes: false,
      previewTimes:computed(()=>{
        // 获取规则数组[1分、2时、3日、4月、5星期]
        let ruleArr = state.cron.split(' ');
        ruleArr.unshift('*')
        ruleArr.push('*')
        let resultArr = [];
        let resultList = [];
        let nums = 0;
        let nTime = new Date();
        let nYear = nTime.getFullYear();
        let nMonth = nTime.getMonth() + 1;
        let nDay = nTime.getDate();
        let nHour = nTime.getHours();
        let nMin = nTime.getMinutes();
        let nSecond = nTime.getSeconds();
        getSecondArr(ruleArr[0]);
        getMinArr(ruleArr[1]);
        getHourArr(ruleArr[2]);
        getDayArr(ruleArr[3]);
        getMonthArr(ruleArr[4]);
        getWeekArr(ruleArr[5]);
        getYearArr(ruleArr[6], nYear);
        let sDate = state.dateArr[0];
        let mDate = state.dateArr[1];
        let hDate = state.dateArr[2];
        let DDate = state.dateArr[3];
        let MDate = state.dateArr[4];
        let YDate = state.dateArr[5];
        let sIdx = getIndex(sDate, nSecond);
        let mIdx = getIndex(mDate, nMin);
        let hIdx = getIndex(hDate, nHour);
        let DIdx = getIndex(DDate, nDay);
        let MIdx = getIndex(MDate, nMonth);
        let YIdx = getIndex(YDate, nYear);
        const resetSecond = function () {
          sIdx = 0;
          nSecond = sDate[sIdx];
        };
        const resetMin = function () {
          mIdx = 0;
          nMin = mDate[mIdx];
          resetSecond();
        };
        const resetHour = function () {
          hIdx = 0;
          nHour = hDate[hIdx];
          resetMin();
        };
        const resetDay = function () {
          DIdx = 0;
          nDay = DDate[DIdx];
          resetHour();
        };
        const resetMonth = function () {
          MIdx = 0;
          nMonth = MDate[MIdx];
          resetDay();
        };
        // 如果当前年份不为数组中当前值
        if (nYear !== YDate[YIdx]) {
          resetMonth();
        }
        // 如果当前月份不为数组中当前值
        if (nMonth !== MDate[MIdx]) {
          resetDay();
        }
        // 如果当前“日”不为数组中当前值
        if (nDay !== DDate[DIdx]) {
          resetHour();
        }
        // 如果当前“时”不为数组中当前值
        if (nHour !== hDate[hIdx]) {
          resetMin();
        }
        // 如果当前“分”不为数组中当前值
        if (nMin !== mDate[mIdx]) {
          resetSecond();
        }
        // 循环年份数组
        goYear: for (let Yi = YIdx; Yi < YDate.length; Yi++) {
          let YY = YDate[Yi];
          // 如果到达最大值时
          if (nMonth > MDate[MDate.length - 1]) {
            resetMonth();
            continue;
          }
          // 循环月份数组
          goMonth: for (let Mi = MIdx; Mi < MDate.length; Mi++) {
            // 赋值、方便后面运算
            let MM = MDate[Mi];
            MM = MM < 10 ? "0" + MM : MM;
            // 如果到达最大值时
            if (nDay > DDate[DDate.length - 1]) {
              resetDay();
              if (Mi == MDate.length - 1) {
                resetMonth();
                continue goYear;
              }
              continue;
            }
            // 循环日期数组
            goDay: for (let Di = DIdx; Di < DDate.length; Di++) {
              // 赋值、方便后面运算
              let DD = DDate[Di];
              let thisDD = DD < 10 ? "0" + DD : DD;

              // 如果到达最大值时
              if (nHour > hDate[hDate.length - 1]) {
                resetHour();
                if (Di == DDate.length - 1) {
                  resetDay();
                  if (Mi == MDate.length - 1) {
                    resetMonth();
                    continue goYear;
                  }
                  continue goMonth;
                }
                continue;
              }

              // 判断日期的合法性，不合法的话也是跳出当前循环
              if (
                checkDate(YY + "-" + MM + "-" + thisDD + " 00:00:00") !== true &&
                state.dayRule !== "workDay" &&
                state.dayRule !== "lastWeek" &&
                state.dayRule !== "lastDay"
              ) {
                resetDay();
                continue goMonth;
              }
              // 如果日期规则中有值时
              if (state.dayRule == "lastDay") {
                // 如果不是合法日期则需要将前将日期调到合法日期即月末最后一天
                if (checkDate(YY + "-" + MM + "-" + thisDD + " 00:00:00") !== true) {
                  while (DD > 0 && checkDate(YY + "-" + MM + "-" + thisDD + " 00:00:00") !== true) {
                    DD--;
                    thisDD = DD < 10 ? "0" + DD : DD;
                  }
                }
              } else if (state.dayRule == "workDay") {
                // 校验并调整如果是2月30号这种日期传进来时需调整至正常月底
                if (checkDate(YY + "-" + MM + "-" + thisDD + " 00:00:00") !== true) {
                  while (DD > 0 && state.checkDate(YY + "-" + MM + "-" + thisDD + " 00:00:00") !== true) {
                    DD--;
                    thisDD = DD < 10 ? "0" + DD : DD;
                  }
                }
                // 获取达到条件的日期是星期X
                let thisWeek = formatDate(
                  new Date(YY + "-" + MM + "-" + thisDD + " 00:00:00"),
                  "week"
                );
                // 当星期日时
                if (thisWeek == 1) {
                  // 先找下一个日，并判断是否为月底
                  DD++;
                  thisDD = DD < 10 ? "0" + DD : DD;
                  // 判断下一日已经不是合法日期
                  if (checkDate(YY + "-" + MM + "-" + thisDD + " 00:00:00") !== true) {
                    DD -= 3;
                  }
                } else if (thisWeek == 7) {
                  // 当星期6时只需判断不是1号就可进行操作
                  if (state.dayRuleSup !== 1) {
                    DD--;
                  } else {
                    DD += 2;
                  }
                }
              } else if (state.dayRule == "weekDay") {
                // 如果指定了是星期几
                // 获取当前日期是属于星期几
                let thisWeek = formatDate(new Date(YY + "-" + MM + "-" + DD + " 00:00:00"), "week");
                // 校验当前星期是否在星期池（dayRuleSup）中
                if (state.dayRuleSup.indexOf(thisWeek) < 0) {
                  // 如果到达最大值时
                  if (Di == DDate.length - 1) {
                    resetDay();
                    if (Mi == MDate.length - 1) {
                      resetMonth();
                      continue goYear;
                    }
                    continue goMonth;
                  }
                  continue;
                }
              } else if (state.dayRule == "assWeek") {
                // 如果指定了是第几周的星期几
                // 获取每月1号是属于星期几
                let thisWeek = formatDate(new Date(YY + "-" + MM + "-" + DD + " 00:00:00"), "week");
                if (state.dayRuleSup[1] >= thisWeek) {
                  DD = (state.dayRuleSup[0] - 1) * 7 + state.dayRuleSup[1] - thisWeek + 1;
                } else {
                  DD = state.dayRuleSup[0] * 7 + state.dayRuleSup[1] - thisWeek + 1;
                }
              } else if (state.dayRule == "lastWeek") {
                // 如果指定了每月最后一个星期几
                // 校验并调整如果是2月30号这种日期传进来时需调整至正常月底
                if (checkDate(YY + "-" + MM + "-" + thisDD + " 00:00:00") !== true) {
                  while (DD > 0 && checkDate(YY + "-" + MM + "-" + thisDD + " 00:00:00") !== true) {
                    DD--;
                    thisDD = DD < 10 ? "0" + DD : DD;
                  }
                }
                // 获取月末最后一天是星期几
                let thisWeek = formatDate(new Date(YY + "-" + MM + "-" + thisDD + " 00:00:00"), "week");
                // 找到要求中最近的那个星期几
                if (state.dayRuleSup < thisWeek) {
                  DD -= thisWeek - state.dayRuleSup;
                } else if (state.dayRuleSup > thisWeek) {
                  DD -= 7 - (state.dayRuleSup - thisWeek);
                }
              }
              // 判断时间值是否小于10置换成“05”这种格式
              DD = DD < 10 ? "0" + DD : DD;

              // 循环“时”数组
              goHour: for (let hi = hIdx; hi < hDate.length; hi++) {
                let hh = hDate[hi] < 10 ? "0" + hDate[hi] : hDate[hi];

                // 如果到达最大值时
                if (nMin > mDate[mDate.length - 1]) {
                  resetMin();
                  if (hi == hDate.length - 1) {
                    resetHour();
                    if (Di == DDate.length - 1) {
                      resetDay();
                      if (Mi == MDate.length - 1) {
                        resetMonth();
                        continue goYear;
                      }
                      continue goMonth;
                    }
                    continue goDay;
                  }
                  continue;
                }
                // 循环"分"数组
                goMin: for (let mi = mIdx; mi < mDate.length; mi++) {
                  let mm = mDate[mi] < 10 ? "0" + mDate[mi] : mDate[mi];

                  // 如果到达最大值时
                  if (nSecond > sDate[sDate.length - 1]) {
                    resetSecond();
                    if (mi == mDate.length - 1) {
                      resetMin();
                      if (hi == hDate.length - 1) {
                        resetHour();
                        if (Di == DDate.length - 1) {
                          resetDay();
                          if (Mi == MDate.length - 1) {
                            resetMonth();
                            continue goYear;
                          }
                          continue goMonth;
                        }
                        continue goDay;
                      }
                      continue goHour;
                    }
                    continue;
                  }
                  // 循环"秒"数组
                  for (let si = sIdx; si <= sDate.length - 1; si++) {
                    let ss = sDate[si] < 10 ? "0" + sDate[si] : sDate[si];
                    // 添加当前时间（时间合法性在日期循环时已经判断）
                    if (MM !== "00" && DD !== "00") {
                      resultArr.push(YY + "-" + MM + "-" + DD + " " + hh + ":" + mm + ":" + ss);
                      nums++;
                    }
                    // 如果条数满了就退出循环
                    if (nums == 5) break goYear;
                    // 如果到达最大值时
                    if (si == sDate.length - 1) {
                      resetSecond();
                      if (mi == mDate.length - 1) {
                        resetMin();
                        if (hi == hDate.length - 1) {
                          resetHour();
                          if (Di == DDate.length - 1) {
                            resetDay();
                            if (Mi == MDate.length - 1) {
                              resetMonth();
                              continue goYear;
                            }
                            continue goMonth;
                          }
                          continue goDay;
                        }
                        continue goHour;
                      }
                      continue goMin;
                    }
                  } //goSecond
                } //goMin
              } //goHour
            } //goDay
          } //goMonth
        }
        if (resultArr.length == 0) {
          resultList = ["没有达到条件的结果！"];
        } else {
          resultList = resultArr;
          if (resultArr.length !== 5) {
            resultList.push(
              "最近10年内只有上面" + resultArr.length + "条结果！"
            );
          }
        }
        return resultList
      })
    });
     // 用于计算某位数字在数组中的索引
    function getIndex(arr, value) {
      if (value <= arr[0] || value > arr[arr.length - 1]) {
        return 0;
      } else {
        for (let i = 0; i < arr.length - 1; i++) {
          if (value > arr[i] && value <= arr[i + 1]) {
            return i + 1;
          }
        }
      }
    }
    // 获取"秒"数组
    function getSecondArr(rule) {
      state.dateArr[0] = getOrderArr(0, 59);
      if (rule.indexOf("-") >= 0) {
        state.dateArr[0] = getCycleArr(rule, 60, true);
      } else if (rule.indexOf("/") >= 0) {
        if(rule.indexOf("*")>=0){
          state.dateArr[0] = getAverageArr(rule.replace('*',0),59);
        }else{
          state.dateArr[0] = getAverageArr(rule, 59);
        }
      } else if (rule !== "*") {
        state.dateArr[0] = getAssignArr(rule);
      }
    }
    // 获取"分"数组
    function getMinArr(rule) {
      state.dateArr[1] = getOrderArr(0, 59);
      if (rule.indexOf("-") >= 0) {
        state.dateArr[1] = getCycleArr(rule, 60, true);
      } else if (rule.indexOf("/") >= 0) {
        if(rule.indexOf("*")>=0){
          state.dateArr[1] = getAverageArr(rule.replace('*',0),59);
        }else{
          state.dateArr[1] = getAverageArr(rule, 59);
        }
      } else if (rule !== "*") {
        state.dateArr[1] = getAssignArr(rule);
      }
    }
    // 获取"时"数组
    function getHourArr(rule) {
      state.dateArr[2] = getOrderArr(0, 23);
      if (rule.indexOf("-") >= 0) {
        state.dateArr[2] = getCycleArr(rule, 24, true);
      } else if (rule.indexOf("/") >= 0) {
        if(rule.indexOf("*")>=0){
          state.dateArr[2] = getAverageArr(rule.replace('*',0),23);
        }else{
          state.dateArr[2] = getAverageArr(rule, 23);
        }
      } else if (rule !== "*") {
        state.dateArr[2] = getAssignArr(rule);
      }
    }
    // 获取"日"数组-少量为日期规则
    function getDayArr(rule) {
      state.dateArr[3] = getOrderArr(1, 31);
      state.dayRule = "";
      state.dayRuleSup = "";
      if (rule.indexOf("-") >= 0) {
        state.dateArr[3] = getCycleArr(rule, 31, false);
        state.dayRuleSup = "null";
      } else if (rule.indexOf("/") >= 0) {
        if(rule.indexOf("*")>=0){
          state.dateArr[3] = getAverageArr(rule.replace('*',0),31);
        }else{
          state.dateArr[3] = getAverageArr(rule, 31);
        }
        state.dayRuleSup = "null";
      } else if (rule.indexOf("W") >= 0) {
        state.dayRule = "workDay";
        state.dayRuleSup = Number(rule.match(/[0-9]{1,2}/g)[0]);
        state.dateArr[3] = [this.dayRuleSup];
      } else if (rule.indexOf("L") >= 0) {
        state.dayRule = "lastDay";
        state.dayRuleSup = "null";
        state.dateArr[3] = [31];
      } else if (rule !== "*" && rule !== "?") {
        state.dateArr[3] = getAssignArr(rule);
        state.dayRuleSup = "null";
      } else if (rule == "*") {
        state.dayRuleSup = "null";
      }
    }
    // 获取"月"数组
    function getMonthArr(rule) {
      state.dateArr[4] = getOrderArr(1, 12);
      if (rule.indexOf("-") >= 0) {
        state.dateArr[4] = getCycleArr(rule, 12, false);
      } else if (rule.indexOf("/") >= 0) {
        if(rule.indexOf("*")>=0){
          state.dateArr[4] = getAverageArr(rule.replace('*',0),12);
        }else{
          state.dateArr[4] = getAverageArr(rule, 12);
        }
      } else if (rule !== "*") {
        state.dateArr[4] = getAssignArr(rule);
      }
    }
     // 获取"日"数组-主要为日期规则
    function getWeekArr(rule) {
      if (rule.indexOf("-") >= 0) {
        state.dayRule = "weekDay";
        state.dayRuleSup = getCycleArr(rule, 7, false);
      } else if (rule.indexOf("#") >= 0) {
        state.dayRule = "assWeek";
        let matchRule = rule.match(/[0-9]{1}/g);
        state.dayRuleSup = [Number(matchRule[1]), Number(matchRule[0])];
        state.dateArr[3] = [1];
        if (state.dayRuleSup[1] == 7) {
          state.dayRuleSup[1] = 0;
        }
      } else if (rule.indexOf("L") >= 0) {
        state.dayRule = "lastWeek";
        state.dayRuleSup = Number(rule.match(/[0-9]{1,2}/g)[0]);
        state.dateArr[3] = [31];
        if (state.dayRuleSup == 7) {
          state.dayRuleSup = 0;
        }
      } else if (rule !== "*" && rule !== "?") {
        state.dayRule = "weekDay";
        state.dayRuleSup = getAssignArr(rule);
      }
    }
    // 获取"年"数组
    function getYearArr(rule, year) {
      state.dateArr[5] = getOrderArr(year, year + 10);
      if (rule !== undefined) {
        if (rule.indexOf("-") >= 0) {
          state.dateArr[5] = getCycleArr(rule, year + 10, false);
        } else if (rule.indexOf("/") >= 0) {
          state.dateArr[5] = getAverageArr(rule, year + 10);
        } else if (rule !== "*") {
          state.dateArr[5] = getAssignArr(rule);
        }
      }
    }

    // 根据传进来的min-max返回一个顺序的数组
    function getOrderArr(min, max) {
      let arr = [];
      for (let i = min; i <= max; i++) {
        arr.push(i);
      }
      return arr;
    }
    // 根据规则返回一个具有周期性的数组
    function getCycleArr(rule, limit, status) {
      // status--表示是否从0开始（则从1开始）
      let arr = [];
      let cycleArr = rule.split("-");
      let min = Number(cycleArr[0]);
      let max = Number(cycleArr[1]);
      if (min > max) {
        max += limit;
      }
      for (let i = min; i <= max; i++) {
        let add = 0;
        if (status == false && i % limit == 0) {
          add = limit;
        }
        arr.push(Math.round((i % limit) + add));
      }
      arr.sort(compare);
      return arr;
    }
    // 根据一定算术规则计算返回一个数组
    function getAverageArr(rule, limit) {
      let arr = [];
      let agArr = rule.split("/");
      let min = Number(agArr[0]);
      let step = Number(agArr[1]);
      while (min <= limit) {
        arr.push(min);
        min += step;
      }
      return arr;
    }
     // 根据规则中指定的零散值返回一个数组
    function getAssignArr(rule) {
      let arr = [];
      let assiginArr = rule.split(",");
      for (let i = 0; i < assiginArr.length; i++) {
        arr[i] = Number(assiginArr[i]);
      }
      arr.sort(compare);
      return arr;
    }
    // 比较数字大小（用于Array.sort）
    function compare(value1, value2) {
      if (value2 - value1 > 0) {
        return -1;
      } else {
        return 1;
      }
    }
    // 格式化日期格式如：2023-05-11 23:08:30
    function formatDate(value, type) {
      // 计算日期相关值
      let time = typeof value == "number" ? new Date(value) : value;
      let Y = time.getFullYear();
      let M = time.getMonth() + 1;
      let D = time.getDate();
      let h = time.getHours();
      let m = time.getMinutes();
      let s = time.getSeconds();
      let week = time.getDay();
      // 如果传递了type的话
      if (type == undefined) {
        return (
          Y +
          "-" +
          (M < 10 ? "0" + M : M) +
          "-" +
          (D < 10 ? "0" + D : D) +
          " " +
          (h < 10 ? "0" + h : h) +
          ":" +
          (m < 10 ? "0" + m : m) +
          ":" +
          (s < 10 ? "0" + s : s)
        );
      } else if (type == "week") {
        // 在celery中 0为星期日
        return week;
      }
    }
    // 检查日期是否存在
    function checkDate(value) {
      let time = new Date(value);
      let format = formatDate(time);
      return value === format;
    }
    const getValue = () => {
      return state.cron
    }
    const close = () => {
      emit('close')
    }
    const handleChange = () => {
      emit('change', state.cron)
      close()
    }
    const rest = (data) => {
      for (let i in data) {
        if (data[i] instanceof Object) {
          this.rest(data[i]);
        } else {
          switch (typeof data[i]) {
            case "object":
              data[i] = [];
              break;
            case "string":
              data[i] = "";
              break;
          }
        }
      }
    }
    return {
      state,
      getValue,
      close,
      handleChange,
      rest
    }
  },
});
</script>
<style lang="scss" scoped>
.vue3-cron-div {
  .el-input-number__decrease, .el-input-number__increase {
    top: 2px !important
  }
  .language {
    position: absolute;
    right: 25px;
    z-index: 1;
    margin-top: 2px;
  }
  .el-tabs {
    box-shadow: none;
  }
  .tabBody {
    overflow: auto;
    .el-row {
      margin: 20px 0;
      .long {
        .el-select {
          width: 350px;
        }
      }
      .el-input-number {
        width: 110px;
      }
    }
  }
  .myScroller {
    &::-webkit-scrollbar {
      /*滚动条整体样式*/
      width : 5px;  /*高宽分别对应横竖滚动条的尺寸*/
      height: 1px;
    }
    &::-webkit-scrollbar-thumb {
      /*滚动条里面小方块*/
      border-radius   : 10px;
      background-color: skyblue;
      background-image: -webkit-linear-gradient(
          45deg,
          rgba(255, 255, 255, 0.2) 25%,
          transparent 25%,
          transparent 50%,
          rgba(255, 255, 255, 0.2) 50%,
          rgba(255, 255, 255, 0.2) 75%,
          transparent 75%,
          transparent
      );
    }
    &::-webkit-scrollbar-track {
      /*滚动条里面轨道*/
      box-shadow   : inset 0 0 5px rgba(0, 0, 0, 0.2);
      background   : #ededed;
      border-radius: 10px;
    }
  }
  .bottom {
    width: 100%;
    margin-top: 5px;
    display: flex;
    align-items: center;
    justify-content: space-between;
    .value {
      float: left;
      font-size: 15px;
      vertical-align: middle;
      span:nth-child(1) {
        color: red
      }
    }
  }
}
</style>