<template>
    <div>
        <el-form size="small" label-position="left" label-width="100px" class="lywidgetsetting" @submit.prevent>
            <el-collapse v-model="lyCollapseActiveList" class="lyformsetting-collapse">
                <el-collapse-item name="lyws01" title="组件属性">
                    <el-form-item label="字段标识">
                        <el-input  v-model.trim="optionModel.name" @change="handleChange"></el-input>
                    </el-form-item>
                    <el-form-item label="隐藏">
                        <el-switch v-model="optionModel.hidden" @change="handleChange"></el-switch>
                    </el-form-item>
                    <el-form-item label="标签">
                        <el-input  v-model="optionModel.label" @change="handleChange"></el-input>
                    </el-form-item>
                    <el-form-item label="显示头部">
                        <el-switch v-model="optionModel.showHeader" @change="handleChange"></el-switch>
                    </el-form-item>
                    <el-form-item label="经典模式">
                        <el-switch v-model="optionModel.classicsMode" @change="handleChange"></el-switch>
                    </el-form-item>
                    <el-form-item label="是否收起">
                        <el-switch v-model="optionModel.folded" @change="handleChange"></el-switch>
                    </el-form-item>
                    <el-form-item label="显示折叠按钮">
                        <el-switch v-model="optionModel.showFold" @change="handleChange"></el-switch>
                    </el-form-item>
                    <el-form-item label="卡片宽度">
                        <el-input  v-model="optionModel.cardWidth" @change="handleChange"></el-input>
                    </el-form-item>
                    <el-form-item label="显示阴影">
                        <el-select v-model="optionModel.shadow" @change="handleChange">
                            <el-option label="never" value="never"></el-option>
                            <el-option label="always" value="always"></el-option>
                            <el-option label="hover" value="hover"></el-option>
                        </el-select>
                    </el-form-item>
                    <customClassLy :selected-widget="selectedWidget" :option-model="optionModel"></customClassLy>
                </el-collapse-item>
            </el-collapse>
        </el-form>
    </div>
</template>

<script setup>
    import {ref, onMounted, reactive, computed, watch} from 'vue'
    import customClassLy from "@/components/lyform-builder/lysetting-pannel/lywidget-common/custom-class-ly"
    import { useBuilderStore } from "@/store/lyFormBuilder";
    const props = defineProps({
        selectedWidget: Object,
        optionModel: Object,
    })
    const builderStore = useBuilderStore()
    function handleChange(newValue) {
         builderStore.saveCurrentHistoryStep()
    }
    let lyCollapseActiveList =  ref(['lyws01','lyws02','lyws03'])

</script>

<style lang="scss" scoped>
    .lywidgetsetting{
        ::v-deep(.el-form-item__label) {
          font-size: 13px;
          overflow: hidden;
          white-space: nowrap;
        }
        .lyformsetting-collapse{
            ::v-deep(.el-collapse-item__header) {
                font-weight: bold;
            }
        }
    }

</style>