<template>
  <div :class="{'ly-is-full':isFull}">
    <div ref="tableSelect" class="tableSelect">
      <el-form :model="formInline">
        <el-form-item label="名称查询：">
          <el-input v-model="formInline.title_search_query" clearable maxlength="100"
                    placeholder="请输入名称，多个关键字使用空格区分" style="width:180px" @change="search"></el-input>
        </el-form-item>
        <el-form-item label="长：">
          <ly-input-range v-model="langDatas" @onchange="langDatasChange" name="lang"></ly-input-range>
        </el-form-item>
        <el-form-item label="宽：">
          <ly-input-range v-model="wideDatas" @onchange="wideDatasChange" name="wide"></ly-input-range>
        </el-form-item>
        <el-form-item label="高：">
          <ly-input-range v-model="highDatas" @onchange="highDatasChange" name="high"></ly-input-range>
        </el-form-item>
        <el-form-item label="分类：">
          <el-select v-model="formInline.classify" clearable filterable placeholder="请选择分类" style="width:180px;"
                     @change="search">
            <el-option
                v-for="item in dictStore.data['phone_type_dict']"
                :key="item.id"
                :label="item.label"
                :value="item.value"/>
          </el-select>
        </el-form-item>
        <el-form-item label="屏幕：">
          <el-select v-model="formInline.screen_type" clearable filterable placeholder="请选择屏幕" style="width:180px;"
                     @change="search">
            <el-option
                v-for="item in dictStore.data['screen_type_dict']"
                :key="item.id"
                :label="item.label"
                :value="item.value"/>
          </el-select>
        </el-form-item>
        <el-form-item label="前摄：">
          <el-select v-model="formInline.proactive_type" clearable filterable placeholder="请选择前摄"
                     style="width:180px;" @change="search">
            <el-option
                v-for="item in dictStore.data['proactive_type_dict']"
                :key="item.id"
                :label="item.label"
                :value="item.value"/>
          </el-select>
        </el-form-item>
        <el-form-item label="地区：">
          <el-select v-model="formInline.district" clearable filterable placeholder="请选择地区" style="width:180px;"
                     @change="search">
            <el-option
                v-for="item in dictStore.data['district_type_dict']"
                :key="item.id"
                :label="item.label"
                :value="item.value"/>
          </el-select>
        </el-form-item>
        <el-form-item label="屏幕尺寸：">
          <ly-input-range v-model="screen_sizeDatas" @onchange="screen_sizeDatasChange"
                          name="screen_size"></ly-input-range>
        </el-form-item>
        <el-form-item label="主屏幕尺寸：">
          <ly-input-range v-model="screen_size_mainDatas" @onchange="screen_size_mainDatasChange"
                          name="screen_size_main"></ly-input-range>
        </el-form-item>
        <el-form-item label="副屏幕尺寸：">
          <ly-input-range v-model="screen_size_secondDatas" @onchange="screen_size_secondDatasChange"
                          name="screen_size_second"></ly-input-range>
        </el-form-item>
        <el-form-item label="产品：">
          <el-select v-model="formInline.product" clearable filterable placeholder="请选择产品" style="width:180px;"
                     @change="search">
            <el-option
                v-for="item in dictStore.data['button_whether_bool']"
                :key="item.id"
                :label="item.label"
                :value="item.value"/>
          </el-select>
        </el-form-item>
        <el-form-item label="隐藏：">
          <el-select v-model="formInline.conceal" clearable filterable placeholder="请选择隐藏" style="width:180px;"
                     @change="search">
            <el-option
                v-for="item in dictStore.data['button_whether_bool']"
                :key="item.id"
                :label="item.label"
                :value="item.value"/>
          </el-select>
        </el-form-item>
        <el-form-item label="发布：">
          <el-select v-model="formInline.issue" clearable filterable placeholder="请选择发布" style="width:180px;"
                     @change="search">
            <el-option
                v-for="item in dictStore.data['button_whether_bool']"
                :key="item.id"
                :label="item.label"
                :value="item.value"/>
          </el-select>
        </el-form-item>
        <el-form-item label="发布时间：">
          <el-date-picker v-model="publish_dateTimers" end-placeholder="结束日期" range-separator="至"
                          start-placeholder="开始日期" style="width:350px" type="daterange"
                          @change="publish_dateTimeChange"></el-date-picker>
        </el-form-item>
        <el-form-item label="采集时间：">
          <el-date-picker v-model="gather_dateTimers" end-placeholder="结束日期" range-separator="至"
                          start-placeholder="开始日期" style="width:350px" type="daterange"
                          @change="gather_dateTimeChange"></el-date-picker>
        </el-form-item>
        <el-form-item label="屏幕尺寸：">
          <ly-input-range v-model="screen_sizeDatas" @onchange="screen_sizeDatasChange"
                          name="screen_size"></ly-input-range>
        </el-form-item>
        <el-form-item label="重采：">
          <el-select v-model="formInline.gather" clearable filterable placeholder="请选择重采" style="width:180px;"
                     @change="search">
            <el-option
                v-for="item in dictStore.data['button_whether_bool']"
                :key="item.id"
                :label="item.label"
                :value="item.value"/>
          </el-select>
        </el-form-item>
        <el-form-item label="重采缩略图：">
          <el-select v-model="formInline.gather_thumbnail_img" clearable filterable placeholder="请选择重采" style="width:180px;"
                     @change="search">
            <el-option
                v-for="item in dictStore.data['button_whether_bool']"
                :key="item.id"
                :label="item.label"
                :value="item.value"/>
          </el-select>
        </el-form-item>
        <el-form-item label="重采详情图：">
          <el-select v-model="formInline.gather_detail_img" clearable filterable placeholder="请选择重采" style="width:180px;"
                     @change="search">
            <el-option
                v-for="item in dictStore.data['button_whether_bool']"
                :key="item.id"
                :label="item.label"
                :value="item.value"/>
          </el-select>
        </el-form-item>
        <el-form-item label="创建时间：" v-if="true">
          <el-date-picker
              v-model="timers"
              end-placeholder="结束日期"
              range-separator="至"
              start-placeholder="开始日期"
              style="width:350px"
              type="datetimerange"
              @change="timeChange">
          </el-date-picker>
        </el-form-item>
        <el-form-item v-show="hasPermission(this.$route.name,'Search')" label="">
          <el-button icon="Search" type="primary" @click="search">查询</el-button>
        </el-form-item>
        <el-form-item label="">
          <el-button icon="Refresh" @click="handleEdit('','reset')">重置</el-button>
        </el-form-item>
        <el-form-item v-show="hasPermission(this.$route.name,'Create')" label="">
          <el-button icon="Plus" type="primary" @click="handelAdd">新增</el-button>
        </el-form-item>
        <el-form-item v-if="hasPermission(this.$route.name,'Delete') && true" label="">
          <el-button :disabled="multiple" icon="Delete" type="danger" @click="handleDelete">删除</el-button>
        </el-form-item>
        <el-form-item v-if="hasPermission(this.$route.name,'Export') && true" label="">
          <el-button :disabled="loadingPage" icon="Download" type="primary" @click="exportDataBackend">导出</el-button>
        </el-form-item>
      </el-form>
    </div>
    <div class="table">
      <ly-table ref="tableref"
                :apiObj="apiObjList"
                :column="column"
                :height="tableHeight"
                :pageSize="10"
                :params="formInline"
                showSelectable showSequence
                tableName="lyAutoCodePhoneManagementTable"
                @selection-change="handleSelectionChange">
        <template #img_path="scope">
          <el-image v-if="scope.row.img_path"
                    :preview-src-list="[scope.row.img_path]"
                    :src="scope.row.img_path"
                    preview-teleported style="width: 30px;height:30px">

          </el-image>
        </template>
        <template #classify="scope">
                    <span v-for="(item,index) in dictStore.data['phone_type_dict']">
                        <span v-if="scope.row.classify == item.value">{{ item.label }}</span>
                    </span>
        </template>
        <template #screen_type="scope">
                    <span v-for="(item,index) in dictStore.data['screen_type_dict']">
                        <span v-if="scope.row.screen_type == item.value">{{ item.label }}</span>
                    </span>
        </template>
        <template #proactive_type="scope">
                    <span v-for="(item,index) in dictStore.data['proactive_type_dict']">
                        <span v-if="scope.row.proactive_type == item.value">{{ item.label }}</span>
                    </span>
        </template>
        <template #district="scope">
                    <span v-for="(item,index) in dictStore.data['district_type_dict']">
                        <span v-if="scope.row.district == item.value">{{ item.label }}</span>
                    </span>
        </template>
        <template #product="scope">
                    <span v-for="(item,index) in dictStore.data['button_whether_bool']">
                        <el-tag v-if="scope.row.product && !!JSON.parse(item.value)" type="success">{{
                            item.label
                          }}</el-tag>
                        <el-tag v-else-if="!scope.row.product && !(!!JSON.parse(item.value))"
                                type="danger">{{ item.label }}</el-tag>
                    </span>
        </template>
        <template #conceal="scope">
                    <span v-for="(item,index) in dictStore.data['button_whether_bool']">
                        <el-tag v-if="scope.row.conceal && !!JSON.parse(item.value)" type="success">{{
                            item.label
                          }}</el-tag>
                        <el-tag v-else-if="!scope.row.conceal && !(!!JSON.parse(item.value))"
                                type="danger">{{ item.label }}</el-tag>
                    </span>
        </template>
        <template #issue="scope">
                    <span v-for="(item,index) in dictStore.data['button_whether_bool']">
                        <el-tag v-if="scope.row.issue && !!JSON.parse(item.value)" type="success">{{
                            item.label
                          }}</el-tag>
                        <el-tag v-else-if="!scope.row.issue && !(!!JSON.parse(item.value))" type="danger">{{
                            item.label
                          }}</el-tag>
                    </span>
        </template>
        <template #img_url="scope">
          <div v-if="!!scope.row.img_url">
            <el-link type="primary" @click="downloadFileURL(scope.row.img_url)">
              原图
            </el-link>
          </div>
        </template>
        <template #url="scope">
          <div v-if="!!scope.row.url">
            <el-link type="primary" @click="downloadFileURL(scope.row.url)">
              来源
            </el-link>
          </div>
        </template>
        <template #screen_contrast="scope">
                    <span v-for="(item,index) in dictStore.data['button_whether_bool']">
                        <el-tag v-if="scope.row.screen_contrast && !!JSON.parse(item.value)"
                                type="success">{{ item.label }}</el-tag>
                        <el-tag v-else-if="!scope.row.screen_contrast && !(!!JSON.parse(item.value))"
                                type="danger">{{ item.label }}</el-tag>
                    </span>
        </template>
        <template #front_camera_contrast="scope">
                    <span v-for="(item,index) in dictStore.data['button_whether_bool']">
                        <el-tag v-if="scope.row.front_camera_contrast && !!JSON.parse(item.value)"
                                type="success">{{ item.label }}</el-tag>
                        <el-tag v-else-if="!scope.row.front_camera_contrast && !(!!JSON.parse(item.value))"
                                type="danger">{{ item.label }}</el-tag>
                    </span>
        </template>
        <template #gather="scope">
                    <span v-for="(item,index) in dictStore.data['button_whether_bool']">
                        <el-tag v-if="scope.row.gather && !!JSON.parse(item.value)" type="success">{{
                            item.label
                          }}</el-tag>
                        <el-tag v-else-if="!scope.row.gather && !(!!JSON.parse(item.value))" type="danger">{{
                            item.label
                          }}</el-tag>
                    </span>
        </template>
                <template #gather_thumbnail_img="scope">
                    <span v-for="(item,index) in dictStore.data['button_whether_bool']">
                        <el-tag v-if="scope.row.gather_thumbnail_img && !!JSON.parse(item.value)"
                                type="success">{{ item.label }}</el-tag>
                        <el-tag v-else-if="!scope.row.gather_thumbnail_img && !(!!JSON.parse(item.value))"
                                type="danger">{{ item.label }}</el-tag>
                    </span>
        </template>
        <template #gather_detail_img="scope">
                    <span v-for="(item,index) in dictStore.data['button_whether_bool']">
                        <el-tag v-if="scope.row.gather_detail_img && !!JSON.parse(item.value)"
                                type="success">{{ item.label }}</el-tag>
                        <el-tag v-else-if="!scope.row.gather_detail_img && !(!!JSON.parse(item.value))"
                                type="danger">{{ item.label }}</el-tag>
                    </span>
        </template>
        <el-table-column label="操作" fixed="right" width="180">
          <template #header>
            <div style="display: flex;justify-content: space-between;align-items: center;">
              <div>操作</div>
              <div @click="setFull">
                <el-tooltip content="全屏" placement="bottom">
                  <el-icon>
                    <full-screen/>
                  </el-icon>
                </el-tooltip>
              </div>
            </div>
          </template>
          <template #default="scope">
            <span class="table-operate-btn" @click="handleEdit(scope.row,'detail')"
                  v-show="hasPermission(this.$route.name,'Retrieve')">详情</span>
            <span class="table-operate-btn" @click="handleEdit(scope.row,'edit')"
                  v-show="hasPermission(this.$route.name,'Update')">编辑</span>
            <span class="table-operate-btn" @click="handleEdit(scope.row,'delete')"
                  v-show="hasPermission(this.$route.name,'Delete')">删除</span>
            <span class="table-operate-btn" @click="handleEdit(scope.row,'disable')"
                  v-show="hasPermission(this.$route.name,'Update')">
                            <span v-if="scope.row.gather">取采</span>
                            <span v-else>重采</span>
                        </span>
          </template>
        </el-table-column>
      </ly-table>
    </div>
    <ly-dialog v-model="dialogVisible" :before-close="handleDialogClose" :fullscreen="fullscreen" :title="dialogTitle"
               :top="dialogTop" :width="dialogWidth">
      <el-form ref="rulesForm" :disabled="formDisabled" :label-position="formLabelPosition"
               :label-width="formLabelWidth"
               :model="formData" :rules="rules">
        <el-row :gutter="20">
          <el-col :span="8?8:24">
            <el-form-item v-if="true" label="主图" prop="img_path">
              <ly-public-pictrue-single-upload v-model="formData.img_path"
                                               :aspect-ratio="NaN"
                                               :compress="1"
                                               :cropper="false"
                                               :disabled="isFormItemEditDisabled(false)"
                                               :height="148"
                                               :max-size="10"
                                               :round="false"
                                               :show-file-list="true"
                                               :width="148"
                                               icon="plus"
                                               title="">
              </ly-public-pictrue-single-upload>
            </el-form-item>
          </el-col>
          <el-col :span="16?16:24">
            <el-form-item v-if="true" label="搜索关键字" prop="search_keyword">
              <el-input v-model="formData.search_keyword" :disabled="isFormItemEditDisabled(false)"
                        placeholder="请输入搜索关键字"
                        style="width: 100%;"/>
            </el-form-item>
          </el-col>
          <el-col :span="8?8:24">
            <el-form-item v-if="true" label="id" prop="id">
              <el-input v-model="formData.id" :disabled="isFormItemEditDisabled(true)" placeholder="请输入id"
                        style="width: 100%;"/>
            </el-form-item>
          </el-col>
          <el-col :span="8?8:24">
            <el-form-item v-if="true" label="品牌" prop="brand">
              <el-input v-model="formData.brand" :disabled="isFormItemEditDisabled(false)" placeholder="请输入品牌"
                        style="width: 100%;"/>
            </el-form-item>
          </el-col>
          <el-col :span="8?8:24">
            <el-form-item v-if="true" label="型号" prop="model">
              <el-input v-model="formData.model" :disabled="isFormItemEditDisabled(false)" placeholder="请输入型号"
                        style="width: 100%;"/>
            </el-form-item>
          </el-col>
          <el-col :span="8?8:24">
            <el-form-item v-if="true" label="别名" prop="author_name">
              <el-input v-model="formData.author_name" :disabled="isFormItemEditDisabled(false)"
                        placeholder="请输入别名"
                        style="width: 100%;"/>
            </el-form-item>
          </el-col>
          <el-col :span="8?8:24">
            <el-form-item v-if="true" label="中文名称" prop="name_yourself">
              <el-input v-model="formData.name_yourself" :disabled="isFormItemEditDisabled(false)"
                        placeholder="请输入中文名称"
                        style="width: 100%;"/>
            </el-form-item>
          </el-col>
          <el-col :span="8?8:24">
            <el-form-item v-if="true" label="无空格名称" prop="not_nlank_name">
              <el-input v-model="formData.not_nlank_name" :disabled="isFormItemEditDisabled(false)"
                        placeholder="请输入无空格名称"
                        style="width: 100%;"/>
            </el-form-item>
          </el-col>
          <el-col :span="8?8:24">
            <el-form-item v-if="true" label="长" prop="lang">
              <el-input v-model="formData.lang" :disabled="isFormItemEditDisabled(false)" placeholder="请输入长"
                        style="width: 100%;"/>
            </el-form-item>
          </el-col>
          <el-col :span="8?8:24">
            <el-form-item v-if="true" label="宽" prop="wide">
              <el-input v-model="formData.wide" :disabled="isFormItemEditDisabled(false)" placeholder="请输入宽"
                        style="width: 100%;"/>
            </el-form-item>
          </el-col>
          <el-col :span="8?8:24">
            <el-form-item v-if="true" label="高" prop="high">
              <el-input v-model="formData.high" :disabled="isFormItemEditDisabled(false)" placeholder="请输入高"
                        style="width: 100%;"/>
            </el-form-item>
          </el-col>
          <el-col :span="8?8:24">
            <el-form-item v-if="true" label="分类" prop="classify">
              <el-select v-model="formData.classify" :disabled="isFormItemEditDisabled(false)" clearable filterable
                         placeholder="请选择分类" style="width: 100%;">
                <el-option
                    v-for="item in dictStore.data['phone_type_dict']"
                    :key="item.id"
                    :label="item.label"
                    :value="convertStrNum(formData.classify,item.value)"/>
              </el-select>
            </el-form-item>
          </el-col>
          <el-col :span="8?8:24">
            <el-form-item v-if="true" label="屏幕" prop="screen_type">
              <el-select v-model="formData.screen_type" :disabled="isFormItemEditDisabled(false)" clearable filterable
                         placeholder="请选择屏幕" style="width: 100%;">
                <el-option
                    v-for="item in dictStore.data['screen_type_dict']"
                    :key="item.id"
                    :label="item.label"
                    :value="convertStrNum(formData.screen_type,item.value)"/>
              </el-select>
            </el-form-item>
          </el-col>
          <el-col :span="8?8:24">
            <el-form-item v-if="true" label="前摄" prop="proactive_type">
              <el-select v-model="formData.proactive_type" :disabled="isFormItemEditDisabled(false)" clearable
                         filterable placeholder="请选择前摄" style="width: 100%;">
                <el-option
                    v-for="item in dictStore.data['proactive_type_dict']"
                    :key="item.id"
                    :label="item.label"
                    :value="convertStrNum(formData.proactive_type,item.value)"/>
              </el-select>
            </el-form-item>
          </el-col>
          <el-col :span="8?8:24">
            <el-form-item v-if="true" label="售价" prop="price">
              <el-input v-model="formData.price" :disabled="isFormItemEditDisabled(false)" placeholder="请输入售价"
                        style="width: 100%;"/>
            </el-form-item>
          </el-col>
          <el-col :span="8?8:24">
            <el-form-item v-if="true" label="地区" prop="district">
              <el-select v-model="formData.district" :disabled="isFormItemEditDisabled(false)" clearable filterable
                         placeholder="请选择地区" style="width: 100%;">
                <el-option
                    v-for="item in dictStore.data['district_type_dict']"
                    :key="item.id"
                    :label="item.label"
                    :value="convertStrNum(formData.district,item.value)"/>
              </el-select>
            </el-form-item>
          </el-col>
          <el-col :span="8?8:24">
            <el-form-item v-if="true" label="主屏幕尺寸" prop="screen_size_main">
              <el-input v-model="formData.screen_size_main" :disabled="isFormItemEditDisabled(false)"
                        placeholder="请输入主屏幕尺寸" style="width: 100%;"/>
            </el-form-item>
          </el-col>
          <el-col :span="5?5:24">
            <el-form-item v-if="true" label="产品" prop="product">
              <el-switch v-model="formData.product" :disabled="isFormItemEditDisabled(false)" active-color="#13ce66"
                         inactive-color="#ff4949"></el-switch>
            </el-form-item>
          </el-col>
          <el-col :span="5?5:24">
            <el-form-item v-if="true" label="隐藏" prop="conceal">
              <el-switch v-model="formData.conceal" :disabled="isFormItemEditDisabled(false)" active-color="#13ce66"
                         inactive-color="#ff4949"></el-switch>
            </el-form-item>
          </el-col>
          <el-col :span="6?6:24">
            <el-form-item v-if="true" label="发布" prop="issue">
              <el-switch v-model="formData.issue" :disabled="isFormItemEditDisabled(false)" active-color="#13ce66"
                         inactive-color="#ff4949"></el-switch>
            </el-form-item>
          </el-col>
          <el-col :span="8?8:24">
            <el-form-item v-if="true" label="副屏幕尺寸" prop="screen_size_second">
              <el-input v-model="formData.screen_size_second" :disabled="isFormItemEditDisabled(false)"
                        placeholder="请输入副屏幕尺寸" style="width: 100%;"/>
            </el-form-item>
          </el-col>
          <el-col :span="12?12:24">
            <el-form-item v-if="true" label="需求一二链接" prop="one_two_join">
              <el-input v-model="formData.one_two_join" :disabled="isFormItemEditDisabled(false)"
                        placeholder="请输入需求一二链接"
                        style="width: 100%;"/>
            </el-form-item>
          </el-col>
          <el-col :span="12?12:24">
            <el-form-item v-if="true" label="唯一校验" prop="url_md5">
              <el-input v-model="formData.url_md5" :disabled="isFormItemEditDisabled(false)"
                        placeholder="请输入唯一校验"
                        style="width: 100%;"/>
            </el-form-item>
          </el-col>
          <el-col :span="12?12:24">
            <div>
              <el-radio-group v-model="publishDateType" @change="handleTypeChange">
                <el-radio-button label="date">日期</el-radio-button>
                <el-radio-button label="month">月份</el-radio-button>
              </el-radio-group>
              <el-form-item label="发布时间" prop="publish_date">
                <el-date-picker
                    v-if="publishDateType === 'date'"
                    v-model="formData.publish_date"
                    clearable
                    format="YYYY-MM-DD"
                    placeholder="请选择发布日期"
                    style="width: 100%;"
                    type="date"
                    value-format="YYYY-MM-DD">
                </el-date-picker>
                <el-date-picker
                    v-else
                    v-model="formData.publish_date"
                    clearable
                    format="YYYY-MM"
                    placeholder="请选择发布月份"
                    style="width: 100%;"
                    type="month"
                    value-format="YYYY-MM">
                </el-date-picker>
              </el-form-item>
            </div>
          </el-col>
          <el-col :span="12?12:24">
            <el-form-item v-if="true" label="采集时间" prop="gather_date">
              <el-date-picker v-model="formData.gather_date" :disabled="isFormItemEditDisabled(false)" clearable
                              format="YYYY-MM-DD" placeholder="请选择采集时间" style="width: 100%;" type="date"
                              value-format="YYYY-MM-DD">
              </el-date-picker>
            </el-form-item>
          </el-col>
          <el-col :span="24?24:24">
            <el-form-item v-if="true" label="主图" prop="img_url">
              <el-input v-model="formData.img_url" :disabled="isFormItemEditDisabled(false)" placeholder="请输入主图url"
                        style="width: 100%;"/>
            </el-form-item>
          </el-col>
          <el-col :span="24?24:24">
            <el-form-item v-if="true" label="来源" prop="url">
              <el-input v-model="formData.url" :disabled="isFormItemEditDisabled(false)" placeholder="请输入来源"
                        style="width: 100%;"/>
            </el-form-item>
          </el-col>
          <el-col :span="3?3:24">
            <el-form-item v-if="true" label="屏幕对比" prop="screen_contrast">
              <el-switch v-model="formData.screen_contrast" :disabled="isFormItemEditDisabled(false)"
                         active-color="#13ce66" inactive-color="#ff4949"></el-switch>
            </el-form-item>
          </el-col>
          <el-col :span="3?3:24">
            <el-form-item v-if="true" label="前摄对比" prop="front_camera_contrast">
              <el-switch v-model="formData.front_camera_contrast" :disabled="isFormItemEditDisabled(false)"
                         active-color="#13ce66" inactive-color="#ff4949"></el-switch>
            </el-form-item>
          </el-col>
          <el-col :span="3?3:24">
            <el-form-item label="重新采集" prop="gather" v-if="true">
              <el-switch v-model="formData.gather" :disabled="isFormItemEditDisabled(false)" active-color="#13ce66"
                         inactive-color="#ff4949"></el-switch>
            </el-form-item>
          </el-col>
          <el-col :span="3?3:24">
            <el-form-item label="重采缩略图" prop="gather_thumbnail_img" v-if="true">
              <el-switch v-model="formData.gather_thumbnail_img" :disabled="isFormItemEditDisabled(false)"
                         active-color="#13ce66" inactive-color="#ff4949"></el-switch>
            </el-form-item>
          </el-col>
          <el-col :span="3?3:24">
            <el-form-item label="重采详情图" prop="gather_detail_img" v-if="true">
              <el-switch v-model="formData.gather_detail_img" :disabled="isFormItemEditDisabled(false)"
                         active-color="#13ce66" inactive-color="#ff4949"></el-switch>
            </el-form-item>
          </el-col>
          <el-col :span="6?6:24">
            <el-form-item v-if="true" label="屏幕尺寸" prop="screen_size">
              <el-input v-model="formData.screen_size" :disabled="isFormItemEditDisabled(false)"
                        placeholder="请输入屏幕尺寸"
                        style="width: 100%;"/>
            </el-form-item>
          </el-col>
          <el-col :span="24?24:24">
            <el-form-item v-if="true" label="主屏幕尺寸1" prop="screen_size_former">
              <el-input v-model="formData.screen_size_former" :disabled="isFormItemEditDisabled(false)"
                        placeholder="请输入主屏幕尺寸1" style="width: 100%;"/>
            </el-form-item>
          </el-col>
          <el-col :span="24?24:24">
            <el-form-item v-if="true" label="主屏幕尺寸1" prop="screen_size_after">
              <el-input v-model="formData.screen_size_after" :disabled="isFormItemEditDisabled(false)"
                        placeholder="请输入主屏幕尺寸1" style="width: 100%;"/>
            </el-form-item>
          </el-col>
          <el-col :span="24?24:24">
            <el-form-item v-if="true" label="详情" prop="details_text">
              <div style="width:100%">
                <ly-public-teditor v-model="formData.details_text" :disabled="isFormItemEditDisabled(false)"
                                   :height="300" placeholder="请输入内容"></ly-public-teditor>
              </div>
            </el-form-item>
          </el-col>
        </el-row>
      </el-form>


      <!--        子表编辑页面 start -->
      <div :class="{'ly-is-full':isFull2}">
        <div ref="tableSelect" class="tableSelect">
          <el-form :model="formInline2">
            <el-form-item label="是否展示：">
              <el-select v-model="formInline2.status" clearable filterable placeholder="请选择是否展示"
                         style="width:180px;" @change="search2">
                <el-option
                    v-for="item in dictStore.data['button_whether_bool']"
                    :key="item.id"
                    :label="item.label"
                    :value="item.value"/>
              </el-select>
            </el-form-item>
            <el-form-item v-show="hasPermission(this.$route.name,'Search')" label="">
              <el-button icon="Search" type="primary" @click="search2">查询</el-button>
            </el-form-item>
            <el-form-item label="">
              <el-button icon="Refresh" @click="handleEdit2('','reset')">重置</el-button>
            </el-form-item>
            <el-form-item v-show="hasPermission(this.$route.name,'Create')" label="">
              <el-button icon="Plus" type="primary" @click="handelAdd2">新增</el-button>
            </el-form-item>
            <el-form-item v-if="hasPermission(this.$route.name,'Delete') && false" label="">
              <el-button :disabled="multiple2" icon="Delete" type="danger" @click="handleDelete2">删除</el-button>
            </el-form-item>
            <!--            <el-form-item label="" v-if="hasPermission(this.$route.name,'Export') && true">-->
            <!--              <el-button type="primary" @click="exportDataBackend2" :disabled="loadingPage2" icon="Download">导出-->
            <!--              </el-button>-->
            <!--            </el-form-item>-->
          </el-form>
        </div>
        <div class="table">
          <el-table ref="tableref2" v-loading="loadingPage2" :data="tableData2" border
                    style="width: 100%" @selection-change="handleSelectionChange2">
            <el-table-column align="center" type="selection" width="60"></el-table-column>
            <!--            <el-table-column min-width="80" prop="phone_management_id" label="手机名称" v-if="true"-->
            <!--                             show-overflow-tooltip>-->
            <!--              <template #default="scope">-->
            <!--                <span>{{ scope.row.phone_management_id_lyautocode_name }}</span>-->
            <!--              </template>-->
            <!--            </el-table-column>-->
            <el-table-column v-if="true" label="备注图片/文件" min-width="100" prop="remark_file" show-overflow-tooltip>
              <template #default="scope">
                <el-image v-for="(item,index) in scope.row.remark_file.split(',')" v-if="scope.row.remark_file"
                          v-bind:key="index"
                          :preview-src-list="[item]" :src="item" preview-teleported
                          style="width: 30px;height:30px;margin-right: 5px;"></el-image>
              </template>
            </el-table-column>
            <el-table-column v-if="true" label="备注内容" min-width="100" prop="remark_tips"
                             show-overflow-tooltip></el-table-column>
            <el-table-column v-if="true" label="展示" min-width="60" prop="status" show-overflow-tooltip>
              <template #default="scope">
                        <span v-for="(item,index) in dictStore.data['button_whether_bool']">
                            <el-tag v-if="scope.row.status && !!JSON.parse(item.value)" type="success">{{
                                item.label
                              }}</el-tag>
                            <el-tag v-else-if="!scope.row.status && !(!!JSON.parse(item.value))"
                                    type="danger">{{ item.label }}</el-tag>
                        </span>
              </template>
            </el-table-column>
            <el-table-column label="创建时间" min-width="150" prop="create_datetime"
                             show-overflow-tooltip></el-table-column>
            <el-table-column fixed="right" label="操作" width="180">
              <template #header>
                <div style="display: flex;justify-content: space-between;align-items: center;">
                  <div>操作</div>
                  <div @click="setFull2">
                    <el-tooltip content="全屏" placement="bottom">
                      <el-icon>
                        <full-screen/>
                      </el-icon>
                    </el-tooltip>
                  </div>
                </div>
              </template>
              <template #default="scope">
              <span v-show="hasPermission(this.$route.name,'Retrieve')" class="table-operate-btn"
                    @click="handleEdit2(scope.row,'detail')">详情</span>
                <span v-show="hasPermission(this.$route.name,'Update')" class="table-operate-btn"
                      @click="handleEdit2(scope.row,'edit')">编辑</span>
                <span v-show="hasPermission(this.$route.name,'Delete')" class="table-operate-btn"
                      @click="handleEdit2(scope.row,'delete')">删除</span>
              </template>
            </el-table-column>
          </el-table>
        </div>
        <ly-dialog v-model="dialogVisible2" :before-close="handleDialogClose2" :fullscreen="fullscreen2"
                   :title="dialogTitle2"
                   :top="dialogTop2" :width="dialogWidth2">
          <el-form ref="rulesForm" :disabled="formDisabled2" :label-position="formLabelPosition2"
                   :label-width="formLabelWidth2"
                   :model="formData2" :rules="rules2">
            <el-row :gutter="20">
              <!--              <el-col :span="24?24:24">-->
              <!--                <el-form-item label="ID" prop="id" v-if="false">-->
              <!--                  <el-input v-model="formData2.id" :disabled="isFormItemEditDisabled2(true)" style="width: 100%;"-->
              <!--                            placeholder="请输入ID"/>-->
              <!--                </el-form-item>-->
              <!--              </el-col>-->
              <!--              <el-col :span="16?16:24">-->
              <!--                <el-form-item label="手机名称" prop="phone_management_id" v-if="true">-->
              <!--                  <el-select v-model="formData2.phone_management_id" :disabled="isFormItemEditDisabled2(false)" clearable-->
              <!--                             filterable placeholder="请选择手机名称" style="width: 100%;">-->
              <!--                    <el-option-->
              <!--                        v-for="item in phone_management_idLyAudoCodeAPIList2"-->
              <!--                        :key="item.id"-->
              <!--                        :label="item.model"-->
              <!--                        :value="item.id"/>-->
              <!--                  </el-select>-->
              <!--                </el-form-item>-->
              <!--              </el-col>-->
              <!--              <el-table-column min-width="80" prop="phone_management_id" label="手机名称" v-if="true">-->
              <!--                <template #default="scope">-->
              <!--                  <span>{{ scope.row.phone_management_id_lyautocode_name }}</span>-->
              <!--                </template>-->
              <!--              </el-table-column>-->
              <el-col :span="24?24:24">
                <el-form-item v-if="true" label="备注内容" prop="remark_tips">
                  <el-input v-model="formData2.remark_tips" :disabled="isFormItemEditDisabled2(false)"
                            placeholder="请输入备注内容" style="width: 100%;"/>
                </el-form-item>
              </el-col>
              <el-col :span="24?24:24">
                <el-form-item v-if="true" label="备注图片/文件" prop="remark_file">
                  <ly-public-pictrue-multiple-upload v-model="formData2.remark_file"
                                                     :disabled="isFormItemEditDisabled2(false)"
                                                     :draggable="true" :limit="10" :max-size="50" :multiple="true"
                                                     :show-file-list="true"
                                                     tip="">
                  </ly-public-pictrue-multiple-upload>
                </el-form-item>
              </el-col>
              <el-col :span="24?24:24">
                <el-form-item v-if="true" label="是否展示" prop="status">
                  <el-switch v-model="formData2.status" :disabled="isFormItemEditDisabled2(false)"
                             active-color="#13ce66"
                             inactive-color="#ff4949"></el-switch>
                </el-form-item>
              </el-col>
            </el-row>
          </el-form>
          <template v-slot:footer>
            <el-button @click="handleDialogClose2">取消</el-button>
            <el-button v-if="dialogTitle2!='详情'" :loading="dialogLoadingSave2" type="primary"
                       @click="handleDialogSubmit2">确定
            </el-button>
          </template>
        </ly-dialog>
        <Pagination v-bind:child-msg="pageparm2" @callFather="callFather2"></Pagination>
      </div>
      <!--        子表编辑页面 end -->
      <template v-slot:footer>
        <el-button @click="handleDialogClose">取消</el-button>
        <el-button v-if="dialogTitle!='详情'" :loading="dialogLoadingSave" type="primary" @click="handleDialogSubmit">
          确定
        </el-button>
      </template>
    </ly-dialog>
  </div>
</template>

<script>

import {ajaxDelete, ajaxDownloadExcel, ajaxGet, ajaxPost, ajaxPut} from '@/api/request'
import {useDictStore} from "@/store/dict";

import Pagination from "@/components/Pagination";
import {dateFormats, deepClone, getTableHeight} from "@/utils/util";
import LyDialog from "@/components/dialog/dialog";
// 列表
const lyAutoCodeList = params => ajaxGet({url: `autocode/PhoneManagement/`, params})
// 新增
const lyAutoCodeAdd = params => ajaxPost({url: `autocode/PhoneManagement/`, params})
// 编辑
const lyAutoCodeEdit = params => ajaxPut({url: `autocode/PhoneManagement/`, params})
// 删除
const lyAutoCodeDelete = params => ajaxDelete({url: `autocode/PhoneManagement/`, params})
// 导出
const lyAutoCodeExport = params => ajaxDownloadExcel({url: `autocode/PhoneManagement/export/`, params})
// 重新采集数据 更新数据
const lyAutoCodeRefreshData = params => ajaxPut({url: `autocode/PhoneManagement/refreshGather/`, params})
// 添加子表增删改查的函数方法
// 列表
const lyAutoCodeListRemark = params => ajaxGet({url: `autocode/PhoneManagementRemark/`, params})
// 新增
const lyAutoCodeAddRemark = params => ajaxPost({url: `autocode/PhoneManagementRemark/`, params})
// 编辑
const lyAutoCodeEditRemark = params => ajaxPut({url: `autocode/PhoneManagementRemark/`, params})
// 删除
const lyAutoCodeDeleteRemark = params => ajaxDelete({url: `autocode/PhoneManagementRemark/`, params})
// 导出
const lyAutoCodeExportRemark = params => ajaxDownloadExcel({url: `autocode/PhoneManagementRemark/export/`, params})
const phone_management_idLyAudoCodeAPI = params => ajaxGet({url: `autocode/PhoneManagement/`, params})

export default {
  name: "lyAutoCodePhoneManagement",
  components: {LyDialog, Pagination},
  setup() {
    const dictStore = useDictStore()
    return {dictStore}
  },
  data() {
    return {
      retainPublishDate: '', // 保留发布日期 date 格式
      retainPublishMonth: '', // 保留发布日期 month 格式
      publishDateType: 'date', // 初始化发布日期类型
      isFull: false,
      tableHeight: 500,
      loadingPage: false,
      apiObjList: lyAutoCodeList,
      // 选项框选中数组
      ids: [],
      // 选项框非单个禁用
      single: true,
      // 非多个禁用
      multiple: true,
      formInline: {
        // page: 1,
        // limit: 10,
      },
      // pageparm: {
      //   page: 1,
      //   limit: 10,
      //   total: 0
      // },
      timers: [],
      tableData: [],
      column: [
        {
          label: "主图",
          prop: "img_path",
          minWidth: "55",
          hide: !true,
          style: "object-fit: contain;"
        },
        {
          label: "搜索关键字",
          prop: "search_keyword",
          minWidth: "60",
          hide: !true
        },
        {
          label: "id",
          prop: "id",
          minWidth: "100",
          hide: !false
        },
        {
          label: "品牌",
          prop: "brand",
          minWidth: "100",
          hide: !false
        },
        {
          label: "型号",
          prop: "model",
          minWidth: "80",
          hide: !true
        },
        {
          label: "别名",
          prop: "author_name",
          minWidth: "60",
          hide: !true
        },
        {
          label: "中文名称",
          prop: "name_yourself",
          minWidth: "80",
          hide: !true
        },
        {
          label: "无空格名称",
          prop: "not_nlank_name",
          minWidth: "100",
          hide: !false
        },
        {
          label: "长",
          prop: "lang",
          minWidth: "50",
          hide: !true
        },
        {
          label: "宽",
          prop: "wide",
          minWidth: "50",
          hide: !true
        },
        {
          label: "高",
          prop: "high",
          minWidth: "50",
          hide: !true
        },
        {
          label: "分类",
          prop: "classify",
          minWidth: "60",
          hide: !true
        },
        {
          label: "屏幕",
          prop: "screen_type",
          minWidth: "60",
          hide: !true
        },
        {
          label: "前摄",
          prop: "proactive_type",
          minWidth: "60",
          hide: !true
        },
        {
          label: "售价",
          prop: "price",
          minWidth: "60",
          hide: !false
        },
        {
          label: "地区",
          prop: "district",
          minWidth: "60",
          hide: !true
        },
        {
          label: "主屏幕尺寸",
          prop: "screen_size_main",
          minWidth: "80",
          hide: !true
        },
        {
          label: "产品",
          prop: "product",
          minWidth: "60",
          hide: !true
        },
        {
          label: "隐藏",
          prop: "conceal",
          minWidth: "60",
          hide: !true
        },
        {
          label: "发布",
          prop: "issue",
          minWidth: "60",
          hide: !false
        },
        {
          label: "副屏幕尺寸",
          prop: "screen_size_second",
          minWidth: "80",
          hide: !false
        },
        {
          label: "需求一二链接",
          prop: "one_two_join",
          minWidth: "10",
          hide: !false
        },
        {
          label: "唯一校验",
          prop: "url_md5",
          minWidth: "100",
          hide: !false
        },
        {
          label: "发布时间",
          prop: "publish_date",
          minWidth: "70",
          hide: !true
        },
        {
          label: "采集时间",
          prop: "gather_date",
          minWidth: "80",
          hide: !true
        },
        {
          label: "主图",
          prop: "img_url",
          minWidth: "60",
          hide: !true
        },
        {
          label: "来源",
          prop: "url",
          minWidth: "60",
          hide: !true
        },
        {
          label: "屏幕对比",
          prop: "screen_contrast",
          minWidth: "50",
          hide: !false
        },
        {
          label: "前摄对比",
          prop: "front_camera_contrast",
          minWidth: "50",
          hide: !false
        },
        {
          label: "屏幕尺寸",
          prop: "screen_size",
          minWidth: "50",
          hide: !false
        },
        {
          label: "主屏幕尺寸1",
          prop: "screen_size_former",
          minWidth: "100",
          hide: !false
        },
        {
          label: "主屏幕尺寸1",
          prop: "screen_size_after",
          minWidth: "100",
          hide: !false
        },
        {
          label: "详情",
          prop: "details_text",
          minWidth: "100",
          hide: !true
        },
        {
          label: "重采",
          prop: "gather",
          minWidth: "100",
          hide: !true
        },
        {
          label: "重采缩略图",
          prop: "gather_thumbnail_img",
          minWidth: "100",
          hide: !true
        },
        {
          label: "重采详情图",
          prop: "gather_detail_img",
          minWidth: "100",
          hide: !true
        },
        {
          label: "创建时间",
          prop: "create_datetime",
          minWidth: "180"
        }
      ],
      //弹窗
      dialogVisible: false,
      dialogTitle: "",
      dialogLoadingSave: false,
      fullscreen: false,
      dialogWidth: "50%",
      dialogTop: "15vh",
      formDisabled: false,
      formLabelPosition: "right",
      formLabelWidth: "auto",
      formData: {
        "img_path": " ",
        "search_keyword": null,
        "id": null,
        "brand": "",
        "model": "",
        "author_name": "",
        "name_yourself": "",
        "not_nlank_name": "",
        "lang": "",
        "wide": "",
        "high": "",
        "classify": " null",
        "screen_type": " null",
        "proactive_type": " null",
        "price": "",
        "district": "",
        "screen_size_main": "",
        "product": true,
        "conceal": true,
        "issue": true,
        "screen_size_second": null,
        "one_two_join": null,
        "url_md5": "",
        "publish_date": "",
        "gather_date": "",
        "img_url": null,
        "url": "",
        "screen_contrast": false,
        "front_camera_contrast": false,
        "screen_size": null,
        "screen_size_former": null,
        "screen_size_after": null,
        "details_text": null,
        "gather": false,
        "gather_thumbnail_img": true,
        "gather_detail_img": true
      },
      rules: {
        id: [{required: true, message: "id不能为空", trigger: 'blur'}],
      },
      publish_dateTimers: [],
      gather_dateTimers: [],
      langDatas: [],
      wideDatas: [],
      highDatas: [],
      screen_size_mainDatas: [],
      screen_size_secondDatas: [],
      screen_sizeDatas: [],

      // 子表数据
      isFull2: false,
      tableHeight2: 500,
      loadingPage2: false,
      // 选项框选中数组
      ids2: [],
      // 选项框非单个禁用
      single2: true,
      // 非多个禁用
      multiple2: true,
      formInline2: {
        page: 1,
        limit: 10,
      },
      pageparm2: {
        page: 1,
        limit: 10,
        total: 0
      },
      timers2: [],
      tableData2: [],
      phone_management_id_id: '',
      //弹窗
      dialogVisible2: false,
      dialogTitle2: "",
      dialogLoadingSave2: false,
      fullscreen2: false,
      dialogWidth2: "50%",
      dialogTop2: "15vh",
      formDisabled2: false,
      formLabelPosition2: "right",
      formLabelWidth2: "auto",
      formData2: {"id": null, "phone_management_id": null, "remark_tips": null, "remark_file": null, "status": true},
      rules2: {
        id: [{required: true, message: "ID不能为空", trigger: 'blur'}],
        phone_management_id: [{required: true, message: "手机名称不能为空", trigger: 'change'}],
      },
      phone_management_idLyAudoCodeAPIList2: [],
    }
  },
  methods: {
    handleTypeChange(value) {
      // 当切换类型时，可以添加额外的逻辑处理。
      //   console.log('开始: 发布日期 ' + this.formData.publish_date)
      //   console.log('开始: 当前组件类型 '+value)
      //   console.log('开始：保留值date '+this.retainPublishDate)
      //   console.log('开始：保留值month '+this.retainPublishMonth)
      if (value === 'month') {
        // 如果原来的发布日期为date格式则将其赋值给保留至
        if (this.checkDateFormat(this.formData.publish_date) === 'date') {
          this.retainPublishDate = this.formData.publish_date          // 将发布时间转换成month格式
          if(this.retainPublishMonth===''){
            this.retainPublishMonth = `${this.formData.publish_date.split('-')[0]}-${this.formData.publish_date.split('-')[1]}`
          }
        }
        this.formData.publish_date   = this.retainPublishMonth
        this.publishDateType = 'month'
      } else if (value === 'date') {
        // 如果原来的日期为为date格式
        if (this.checkDateFormat(this.formData.publish_date) === 'month') {
          this.retainPublishMonth = this.formData.publish_date
        }
        this.publishDateType = 'date'
        this.formData.publish_date = this.retainPublishDate
      }
    },
    setLoadingPage(bools) {
      this.loadingPage = bools
      this.$refs.tableref.loadingPage(bools)
    },
    setFull() {
      this.isFull = !this.isFull
      window.dispatchEvent(new Event('resize'))
    },
    //多选项框被选中数据
    handleSelectionChange(selection) {
      this.ids = selection.map(item => item.id);
      this.single = selection.length !== 1;
      this.multiple = !selection.length;
    },
    /** 批量删除按钮操作 */
    handleDelete(row) {
      const ids = this.ids;
      let vm = this
      vm.$confirm('是否确认删除选中的数据项?', "警告", {
        confirmButtonText: "确定",
        cancelButtonText: "取消",
        type: "warning"
      }).then(function () {
        return lyAutoCodeDelete({id: ids}).then(res => {
          if (res.code == 2000) {
            vm.$message.success(res.msg)
            vm.getData()
            vm.getData2(ids)
          } else {
            vm.$message.warning(res.msg)
          }
        })
      })
    },
    convertStrNum(model, value) {
      if (model === "" || model === null || model === undefined) {
        return value
      } else if (Object.prototype.toString.call(model) === "[object String]") {
        return value.toString()
      } else if (Object.prototype.toString.call(model) === "[object Number]") {
        return value * 1
      } else {
        return value
      }
    },
    //判断单个组件编辑时是否禁用disabled参数为bool值
    isFormItemEditDisabled(disabled = false) {
      if (disabled == "" || disabled == null || disabled == undefined) {
        return false
      } else if ((disabled == true || disabled == 'True') && this.dialogTitle === '编辑') {
        return true
      }
      return false
    },
    handelAdd() {
      this.dialogTitle = "添加"
      this.dialogVisible = true
    },
    handleDialogClose() {
      this.dialogTitle = ""
      this.dialogVisible = false
      this.dialogLoadingSave = false
      this.formDisabled = false
      this.formData = {
        "img_path": " ",
        "search_keyword": null,
        "id": null,
        "brand": "",
        "model": "",
        "author_name": "",
        "name_yourself": "",
        "not_nlank_name": "",
        "lang": "",
        "wide": "",
        "high": "",
        "classify": " null",
        "screen_type": " null",
        "proactive_type": " null",
        "price": "",
        "district": "",
        "screen_size_main": "",
        "product": true,
        "conceal": true,
        "issue": true,
        "screen_size_second": null,
        "one_two_join": null,
        "url_md5": "",
        "publish_date": "",
        "gather_date": "",
        "img_url": null,
        "url": "",
        "screen_contrast": false,
        "front_camera_contrast": false,
        "screen_size": null,
        "screen_size_former": null,
        "screen_size_after": null,
        "details_text": null,
        "gather": false,
        "gather_thumbnail_img": true,
        "gather_detail_img": true
      }
    },
    handleDialogSubmit() {
      this.$refs['rulesForm'].validate((obj) => {
        if (obj) {
          this.dialogLoadingSave = true
          let param = {
            ...this.formData
          }
          // 强制截断采集时间超过15位的数据
          param.gather_date = this.convertStrNum(param.gather_date, param.gather_date).toString().substring(0, 15)
          console.log(param)
          if (this.dialogTitle == "编辑") {
            lyAutoCodeEdit(param).then(res => {
              this.dialogLoadingSave = false
              if (res.code == 2000) {
                this.$message.success(res.msg)
                this.handleDialogClose()
                this.getData()
              } else {
                this.$message.warning(res.msg)
              }
            })
          } else {
            lyAutoCodeAdd(param).then(res => {
              this.dialogLoadingSave = false
              if (res.code == 2000) {
                this.$message.success(res.msg)
                this.handleDialogClose()
                this.getData()
              } else {
                this.$message.warning(res.msg)
              }
            })
          }

        }
      })
      this.dialogLoadingSave = false
    },
    // 判断日期格式
    checkDateFormat(dateString) {
      // 正则表达式匹配'YYYY-MM-DD'格式
      const regexForYYYYMMDD = /^\d{4}-\d{2}-\d{2}$/;
      // 正则表达式匹配'YYYY-MM'格式
      const regexForYYYYMM = /^\d{4}-\d{2}$/;
      // 检查日期字符串是否符合'YYYY-MM-DD'格式
      if (regexForYYYYMMDD.test(dateString)) {
        // console.log(`${dateString} 符合 'YYYY-MM-DD' 格式`);
        return 'date';
      }
      // 检查日期字符串是否符合'YYYY-MM'格式
      else if (regexForYYYYMM.test(dateString)) {
        // console.log(`${dateString} 符合 'YYYY-MM' 格式`);
        return 'month';
      }
      // 如果都不符合，返回错误信息
      else {
        // console.log(`${dateString} 不符合指定的日期格式`);
        return 'date';
      }
    },
    handleEdit(row, flag) {
      if (flag == 'edit') {
        this.dialogTitle = "编辑"
        this.dialogVisible = true
        this.formData = deepClone(row)
        this.publishDateType = this.checkDateFormat(row.publish_date)
        // 重置发布日期保留值
        this.retainPublishDate = ''
        this.retainPublishMonth = ''
        // 获取子表数据
        this.getData2(row.id)
      } else if (flag == 'delete') {
        let vm = this
        vm.$confirm('您确定要删除选中的数据吗？', '警告', {
          closeOnClickModal: false,
          type: "warning"
        }).then(res => {
          lyAutoCodeDelete({id: row.id}).then(res => {
            if (res.code == 2000) {
              vm.$message.success(res.msg)
              vm.getData()
            } else {
              vm.$message.warning(res.msg)
            }
          })
        }).catch(() => {

        })
      } else if (flag == 'detail') {
        this.dialogTitle = "详情"
        this.dialogVisible = true
        this.formDisabled = true
        this.formData = deepClone(row)
        this.publishDateType = this.checkDateFormat(row.publish_date)
        // 重置发布日期保留值
        this.retainPublishDate = ''
        this.retainPublishMonth=''
        // 获取子表数据
        this.getData2(row.id)
      } else if (flag == 'disable') {
        let vm = this
        lyAutoCodeRefreshData({id: row.id}).then(res => {
          if (res.code == 2000) {
            vm.$message.success(res.msg)
            vm.search()
          } else {
            vm.$message.warning(res.msg)
          }
        })
      } else if (flag == "reset") {
        this.formInline = {}
        this.timers = []
        this.publish_dateTimers = []
        this.gather_dateTimers = []
        this.langDatas = []
        this.wideDatas = []
        this.highDatas = []
        this.screen_size_mainDatas = []
        this.screen_size_secondDatas = []
        this.screen_sizeDatas = []
        this.search()
      }
    },
    /**
     * 从URL里下载文件
     */
    // 下载文件
    downloadFileURL(url) {
      var a = document.createElement("a")
      var event = new MouseEvent('click')
      a.href = url
      a.target = "_blank"
      a.dispatchEvent(event)
    },
    //excel文件流下载
    downloadExcelBlob(res) {
      let fileName = new Date().getTime() + ".xlsx"
      let dispositionStr = res.headers["content-disposition"];
      if (dispositionStr == null || dispositionStr === "") {

      } else {
        // 获取文件名
        let dispositionArr = dispositionStr.split(";");
        fileName = decodeURIComponent(dispositionArr[1]);
        fileName = fileName.split("=")[1];
      }
      const blob = new Blob([res.data], {
        type: "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet",
      });
      let href = window.URL.createObjectURL(blob); //下载链接
      let link = document.createElement("a")
      link.href = href
      link.download = fileName //下载后文件名
      document.body.appendChild(link);
      link.click(); //点击下载
      document.body.removeChild(link); //下载完成移除元素
      window.URL.revokeObjectURL(href);  //释放blob对象
      this.$message.success('导出成功')
    },
    //导出
    exportDataBackend() {
      let params = {}
      if (this.ids.length > 0) {
        params = {
          ids: this.ids,
        }
      }
      this.setLoadingPage(true)
      lyAutoCodeExport(params).then(res => {
        this.setLoadingPage(false)
        this.downloadExcelBlob(res)
      })
    },
    callFather(parm) {
      this.formInline.page = parm.page
      this.formInline.limit = parm.limit
      this.getData()
    },
    search() {
      this.$refs.tableref.reload(this.formInline)
    },
    //获取列表
    async getData() {
      this.$refs.tableref.getData()
    },
    timeChange(val) {
      if (val) {
        this.formInline.beginAt = dateFormats(val[0], 'yyyy-MM-dd hh:mm:ss');
        this.formInline.endAt = dateFormats(val[1], 'yyyy-MM-dd hh:mm:ss');
      } else {
        this.formInline.beginAt = null
        this.formInline.endAt = null
      }
      this.search()
    },
    publish_dateTimeChange(val) {
      if (val) {
        this.formInline.publish_date_beginAt = dateFormats(val[0], 'yyyy-MM-dd');
        this.formInline.publish_date_endAt = dateFormats(val[1], 'yyyy-MM-dd');
      } else {
        this.formInline.publish_date_beginAt = null
        this.formInline.publish_date_endAt = null
      }
      this.search()
    },
    gather_dateTimeChange(val) {
      if (val) {
        this.formInline.gather_date_beginAt = dateFormats(val[0], 'yyyy-MM-dd');
        this.formInline.gather_date_endAt = dateFormats(val[1], 'yyyy-MM-dd');
      } else {
        this.formInline.gather_date_beginAt = null
        this.formInline.gather_date_endAt = null
      }
      this.search()
    },
    langDatasChange(val) {
      if (val) {
        this.formInline.lang_min = val[0];
        this.formInline.lang_max = val[1];
      } else {
        this.formInline.lang_min = null
        this.formInline.lang_max = null
      }
      this.search()
    },
    wideDatasChange(val) {
      if (val) {
        this.formInline.wide_min = val[0];
        this.formInline.wide_max = val[1];
      } else {
        this.formInline.wide_min = null
        this.formInline.wide_max = null
      }
      this.search()
    },
    highDatasChange(val) {
      if (val) {
        this.formInline.high_min = val[0];
        this.formInline.high_max = val[1];
      } else {
        this.formInline.high_min = null
        this.formInline.high_max = null
      }
      this.search()
    },
    screen_size_mainDatasChange(val) {
      if (val) {
        this.formInline.screen_size_main_min = val[0];
        this.formInline.screen_size_main_max = val[1];
      } else {
        this.formInline.screen_size_main_min = null
        this.formInline.screen_size_main_max = null
      }
      this.search()
    },
    screen_size_secondDatasChange(val) {
      if (val) {
        this.formInline.screen_size_second_min = val[0];
        this.formInline.screen_size_second_max = val[1];
      } else {
        this.formInline.screen_size_second_min = null
        this.formInline.screen_size_second_max = null
      }
      this.search()
    },
    screen_sizeDatasChange(val) {
      if (val) {
        this.formInline.screen_size_min = val[0];
        this.formInline.screen_size_max = val[1];
      } else {
        this.formInline.screen_size_min = null
        this.formInline.screen_size_max = null
      }
      this.search()
    },
    // 计算搜索栏的高度
    listenResize() {
      this.$nextTick(() => {
        this.getTheTableHeight()
      })
    },
    getTheTableHeight() {
      let tabSelectHeight = this.$refs.tableSelect ? this.$refs.tableSelect.offsetHeight : 0
      tabSelectHeight = this.isFull ? tabSelectHeight - 110 : tabSelectHeight
      this.tableHeight = getTableHeight(tabSelectHeight, false) - 125
    },

    // 子表的方法
    // 表格序列号
    getIndex2($index) {
      // (当前页 - 1) * 当前显示数据条数 + 当前行数据的索引 + 1
      return (this.pageparm2.page - 1) * this.pageparm2.limit + $index + 1
    },
    setFull2() {
      this.isFull2 = !this.isFull2
      window.dispatchEvent(new Event('resize'))
    },

    /** 批量删除按钮操作 */
    handleDelete2(row) {
      const ids = this.ids2;
      let vm = this
      vm.$confirm('是否确认删除选中的数据项?', "警告", {
        confirmButtonText: "确定",
        cancelButtonText: "取消",
        type: "warning"
      }).then(function () {
        return lyAutoCodeDeleteRemark({id: ids}).then(res => {
          if (res.code == 2000) {
            vm.$message.success(res.msg)
            vm.getData2(this.phone_management_id_id)
          } else {
            vm.$message.warning(res.msg)
          }
        })
      })
    },
    convertStrNum2(model, value) {
      if (model === "" || model === null || model === undefined) {
        return value
      } else if (Object.prototype.toString.call(model) === "[object String]") {
        return value.toString()
      } else if (Object.prototype.toString.call(model) === "[object Number]") {
        return value * 1
      } else {
        return value
      }
    },
    //判断单个组件编辑时是否禁用disabled参数为bool值
    isFormItemEditDisabled2(disabled = false) {
      if (disabled == "" || disabled == null || disabled == undefined) {
        return false
      } else if ((disabled == true || disabled == 'True') && this.dialogTitle2 === '编辑') {
        return true
      }
      return false
    },
    handelAdd2(row) {
      this.dialogTitle2 = "添加"
      this.dialogVisible2 = true
      this.phone_management_idLyAudoCodeAPIFunction()
    },
    handleDialogClose2() {
      this.dialogTitle2 = ""
      this.dialogVisible2 = false
      this.dialogLoadingSave2 = false
      this.formDisabled2 = false
      this.formData2 = {
        "id": null,
        "phone_management_id": null,
        "remark_tips": null,
        "remark_file": null,
        "status": true
      }
    },
    handleDialogSubmit2() {
      this.$refs['rulesForm'].validate((obj) => {
        if (obj) {
          this.dialogLoadingSave2 = true
          let param = {
            ...this.formData2
          }
          param.phone_management_id = this.phone_management_id_id
          // console.log(param)
          if (this.dialogTitle2 == "编辑") {
            lyAutoCodeEditRemark(param).then(res => {
              this.dialogLoadingSave2 = false
              if (res.code == 2000) {
                this.$message.success(res.msg)
                this.handleDialogClose2()
                this.getData2(this.phone_management_id_id)
              } else {
                this.$message.warning(res.msg)
              }
            })
          } else {
            lyAutoCodeAddRemark(param).then(res => {
              this.dialogLoadingSave2 = false
              if (res.code == 2000) {
                this.$message.success(res.msg)
                this.handleDialogClose2()
                this.getData2(this.phone_management_id_id)
              } else {
                this.$message.warning(res.msg)
              }
            })
          }

        }
      })
      this.dialogLoadingSave2 = false
    },
    handleEdit2(row, flag) {
      if (flag == 'edit') {
        this.dialogTitle2 = "编辑"
        this.dialogVisible2 = true
        this.formData2 = deepClone(row)
        this.phone_management_idLyAudoCodeAPIFunction()
      } else if (flag == 'delete') {
        let vm = this
        vm.$confirm('您确定要删除选中的数据吗？', {
          closeOnClickModal: false
        }).then(res => {
          lyAutoCodeDeleteRemark({id: row.id}).then(res => {
            if (res.code == 2000) {
              vm.$message.success(res.msg)
              vm.search2()
            } else {
              vm.$message.warning(res.msg)
            }
          })
        }).catch(() => {

        })
      } else if (flag == 'detail') {
        this.dialogTitle2 = "详情"
        this.dialogVisible2 = true
        this.formDisabled2 = true
        this.formData2 = deepClone(row)
        this.phone_management_idLyAudoCodeAPIFunction()
      } else if (flag == "reset") {
        this.formInline2 = {
          page: 1,
          limit: 10
        }
        this.pageparm2 = {
          page: 1,
          limit: 10,
          total: 0
        }
        this.timers2 = []
        this.getData2(this.phone_management_id_id)
      }
    },
    /**
     * 从URL里下载文件
     */
    // 下载文件
    downloadFileURL2(url) {
      var a = document.createElement("a")
      var event = new MouseEvent('click')
      a.href = url
      a.target = "_blank"
      a.dispatchEvent(event)
    },
    //excel文件流下载
    downloadExcelBlob2(res) {
      let fileName = new Date().getTime() + ".xlsx"
      let dispositionStr = res.headers["content-disposition"];
      if (dispositionStr == null || dispositionStr === "") {

      } else {
        // 获取文件名
        let dispositionArr = dispositionStr.split(";");
        fileName = decodeURIComponent(dispositionArr[1]);
        fileName = fileName.split("=")[1];
      }
      const blob = new Blob([res.data], {
        type: "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet",
      });
      let href = window.URL.createObjectURL(blob); //下载链接
      let link = document.createElement("a")
      link.href = href
      link.download = fileName //下载后文件名
      document.body.appendChild(link);
      link.click(); //点击下载
      document.body.removeChild(link); //下载完成移除元素
      window.URL.revokeObjectURL(href);  //释放blob对象
      this.$message.success('导出成功')
    },
    //导出
    exportDataBackend2() {
      let params = {}
      if (this.ids2.length > 0) {
        params = {
          ids: this.ids2,
        }
      }
      this.loadingPage2 = true
      lyAutoCodeExportRemark(params).then(res => {
        this.loadingPage2 = false
        this.downloadExcelBlob2(res)
      })
    },
    callFather2(parm) {
      this.formInline2.page = parm.page
      this.formInline2.limit = parm.limit
      this.getData2()
    },
    search2() {
      this.formInline2.page = 1
      this.formInline2.limit = 10
      this.getData2(this.phone_management_id_id)
    },
    //多选项框被选中数据
    handleSelectionChange2(selection) {
      this.ids2 = selection.map(item => item.id);
      this.single2 = selection.length !== 1;
      this.multiple2 = !selection.length;
    },
    //获取子表数据列表
    async getData2(phone_management_id) {
      this.loadingPage2 = true
      // 先将 this.formInline 的值复制出来
      const formInlineChild = {...this.formInline2};
      // 添加一个新的 phone_management_id_id 键和值
      if (phone_management_id) {
        formInlineChild.phone_management_id_id = phone_management_id; // rphone_management_id 是你要添加的新值
      } else {
        formInlineChild.phone_management_id_id = this.phone_management_id_id;
      }
      formInlineChild.page = 1; // 目前永远取第一页数据
      lyAutoCodeListRemark(formInlineChild).then(res => {
        this.loadingPage2 = false
        if (res.code == 2000) {
          this.tableData2 = res.data.data
          this.phone_management_id_id = phone_management_id
          // this.pageparm.page = res.data.page;
          // this.pageparm.limit = res.data.limit;
          // this.pageparm.total = res.data.total;
        }
      })
    },
    timeChange2(val) {
      if (val) {
        this.formInline2.beginAt = dateFormats(val[0], 'yyyy-MM-dd hh:mm:ss');
        this.formInline2.endAt = dateFormats(val[1], 'yyyy-MM-dd hh:mm:ss');
      } else {
        this.formInline2.beginAt = null
        this.formInline2.endAt = null
      }
      this.search2()
    },
    // 计算搜索栏的高度
    listenResize2() {
      this.$nextTick(() => {
        this.getTheTableHeight2()
      })
    },
    getTheTableHeight2() {
      let tabSelectHeight = this.$refs.tableSelect ? this.$refs.tableSelect.offsetHeight : 0
      tabSelectHeight = this.isFull2 ? tabSelectHeight - 110 : tabSelectHeight
      this.tableHeight2 = getTableHeight(tabSelectHeight)
    },
    phone_management_idLyAudoCodeAPIFunction() {
      phone_management_idLyAudoCodeAPI({page: 1, limit: 999}).then(res => {
        if (res.code == 2000) {
          this.phone_management_idLyAudoCodeAPIList2 = res.data.data
        }
      })
    },

    // 子表方法结束
  },

  created() {
    // 这里不要调用依赖于 DOM 或组件引用的方法
    // this.getData()
  },
  mounted() {
    // 监听页面宽度变化搜索框的高度
    window.addEventListener('resize', this.listenResize);
    this.$nextTick(() => {
      this.getTheTableHeight()
      // 将 getData 调用移动到这里
      this.getData();
    })
  },

  unmounted() {
    // 页面销毁，去掉监听事件
    window.removeEventListener("resize", this.listenResize);
  },

}
</script>

<style lang="scss" scoped>
::v-deep(.el-dialog__body) {
  padding: 0 30px !important;
}

.add-specs-inner {
  padding: 10px;
  background: #fffcfc;
  border: 1px solid #e0e0e0;

  .tips {
    font-size: 12px;
    color: #999999;
  }

  .add-specs-item {
    display: flex;
    align-items: center;

    .name {
      width: 60px;
      font-size: 12px;
    }
  }
}

.uploadImg {
  display: flex;
  line-height: 36px;
  font-size: 14px;

  .photoNum {
    font-weight: bold;

    em {
      font-style: normal;
      color: #ff0000;
    }
  }

  .input-fileup {
    width: 120px;
    height: 36px;
    line-height: 36px;
    text-align: center;
    overflow: hidden;
    position: relative;
    background: #409EFF;
    color: #ffffff;
    border: 1px solid #dcdfe6;
    text-decoration: none;
    display: block;
    margin: 0 15px 0 5px;
    border-radius: 3px;
    /*color: #666666;*/
    input {
      position: absolute;
      left: 0;
      top: 0;
      width: 100%;
      height: 100%;
      opacity: 0;
      cursor: pointer;
    }
  }
}

.pickAlbumPreListO {
  width: 100%;
  background: #f1f1f1;
  /*height: 224px;*/
  .tipsCon {
    text-align: center;
    color: #999999;
  }
}

.pickAlbumPreList {
  display: table;
  margin: 0;
  padding: 0;
  /*height: 174px;*/
  li {
    width: 110px;
    padding: 10px;
    box-sizing: border-box;
    /*height: 140px;*/
    display: block;
    float: left;
    margin: 5px;
    position: relative;
    font-size: 12px;
    text-align: center;

    img, .el-image {
      display: block;
      width: 90px;
      height: 90px;
      border: 1px solid #d6d6d6;
    }

    i.el-icon-close {
      display: none;
      position: absolute;
      right: 0;
      top: 0;
      background: #409eff;
      color: #ffffff;
      font-size: 20px;
      cursor: pointer;
    }

    .btnchang {
      display: flex;
      margin-top: 10px;
      align-items: center;
      align-content: center;
    }

    .sizeImg {
      color: #ff0000;
    }

    button {
      width: 40px;
      margin: 0 auto;
      padding: 0;
      height: 28px;
    }

    &:hover i.el-icon-close {
      display: block;
    }

    i.el-icon-picture {
      width: 90px;
      height: 90px;
      display: block;
      text-align: center;
      line-height: 90px;
      border: 1px solid #e1e1e1;
      font-size: 20px;
      border-radius: 4px;
      background: #fff;
    }
  }
}

// 规格样式
.tag-item {
  display: flex;
  flex-direction: row;
  column-gap: 10px;
  background: #f1f1f1;
  padding: 8px;
  margin-bottom: 10px;

  .el-icon-s-grid {
    color: #e6ddd8;
    font-size: 32px;
    cursor: move;
  }

  .input-item {
    flex: 1;
    display: flex;
    flex-direction: column;
    column-gap: 10px;

    .el-icon-error {
      font-size: 14px;
      color: #000000;
      cursor: pointer;
    }

    .spec-item {
      flex: 1;
      display: flex;
      flex-direction: row;
      align-items: center;
      column-gap: 10px;

    }
  }
}

.avatar-uploader .el-upload {
  border: 1px dashed #d9d9d9;
  border-radius: 6px;
  cursor: pointer;
  position: relative;
  overflow: hidden;
}

.avatar-uploader .el-upload:hover {
  border-color: #409EFF;
}

.avatar-uploader-icon {
  font-size: 28px;
  color: #8c939d;
  width: 128px;
  height: 128px;
  line-height: 128px;
  text-align: center;
}

.avatar {
  width: 128px;
  height: 128px;
  display: block;
}

.form-data {
  height: calc(100vh - 170px);
  overflow-y: auto;
  border: 1px solid #b3d8ff;

  .form-title {
    color: #409eff;
    background: #ecf5ff;
    padding: 10px;
    margin-bottom: 20px;
  }
}
</style>
