<!--
/**
 * 表单渲染组件
 * version: 1.2
 * author: lybbn
 * program django-vue-lyadmin 专业版
 * email: 1042594286@qq.com
 * website: http://www.lybbn.cn
 * date: 2023.10.17
 * remark: 如果要分发django-vue-lyadmin源码或其中组件等，需在本文件顶部保留此文件头信息！！！
 */
-->
<template>
    <el-form :label-position="labelPosition" :size="size" :class="[customClass]" class="lyrenderform" :disabled="formConfig.disabled" :label-width="labelWidth" :validate-on-rule-change="false" :model="globalFormModel" ref="lyrenderForm" @submit.prevent>
        <template v-for="(widget, index) in widgetList">
            <template v-if="widget.category === 'container'">
                <component :is="getContainerWidgetName(widget)" :widget="widget" :key="widget.id" :parent-list="widgetList" :index-of-parent-list="index" :parent-widget="null" :design-state="false"></component>
            </template>
            <template v-else>
                <component :is="getWidgetName(widget)" :form-config="formConfig" :field="widget" :design-state="false" :key="widget.id" :parent-list="widgetList" :index-of-parent-list="index" :parent-widget="null"></component>
            </template>
        </template>
    </el-form>
</template>

<script setup>
    import {ref, onMounted ,reactive,watch,computed,provide,getCurrentInstance} from 'vue'
    import {buildDefaultFormJson,deepClone} from '@/utils/util'
    import {randomId,insertCustomCssToHead,insertGlobalFunctionsToHtml} from '@/utils/util'

    const props = defineProps({
        formJson: { //prop传入的表单JSON配置
            type: Object,
            default: () => buildDefaultFormJson()
        },
        formData: { //prop传入的表单数据
            type: Object,
            default: () => ({})
        },
        previewState: { //是否表单预览状态
            type: Boolean,
            default: false
        }
    })
    let bus = getCurrentInstance().appContext.config.globalProperties.$Bus; // 声明$Bus
    const { proxy }  = getCurrentInstance(); 
    let formJsonObj = ref(props.formJson)
    let globalFormModel = ref({})
    let widgetRefList = ref({})
    //响应式传递父组件数据到子组件
    provide("globalFormModel",globalFormModel)
    provide("refList",widgetRefList)

    function registerFormToRefList() {
        widgetRefList.value['lyformbuilder_ref'] = proxy
    }

    function getContainerWidgetName(widget){
        return widget.type + '-widget-ly'
    }

    function getWidgetName(widget) {
        return widget.type + '-widget-ly'
    }

    const formConfig = computed(() => {
        return formJsonObj.value.formConfig
    })
    const widgetList = computed(() => {
        return formJsonObj.value.widgetList
    })
    const labelPosition = computed(() => {
        if (!!formConfig.value && !!formConfig.value.labelPosition) {
          return formConfig.value.labelPosition
        }
        return 'left'
    })
    const labelWidth = computed(() => {
        if (!!formConfig.value && !!formConfig.value.labelWidth) {
          return formConfig.value.labelWidth
        }
        return 'auto'
    })
    const size = computed(() => {
        if (!!formConfig.value && !!formConfig.value.size) {
          return formConfig.value.size
        }
        return 'default'
    })
    const customClass = computed(() => {
        return !!formConfig.value && !!formConfig.value.customClass ? formConfig.value.customClass : ''
    })

    let formId = ref(null)  //表单唯一Id，用于区分页面上的多个render组件！！

    function initFormObject(insertHtmlCodeFlag = true){
        formId.value = 'lyRender' + randomId()
        if (!!insertHtmlCodeFlag) {
            insertCustomStyleAndScriptNode()
        }
        addFieldValidateEventHandler()
        handleOnFormMounted()
        registerFormToRefList()
    }
    function insertCustomStyleAndScriptNode() {
        if (!!formConfig.value && !!formConfig.value.cssCode) {
            insertCustomCssToHead(formConfig.value.cssCode, !!props.previewState ? '' : formId.value)
        }

        if (!!formConfig.value && !!formConfig.value.functions) {
            insertGlobalFunctionsToHtml(formConfig.value.functions, !!props.previewState ? '' : formId.value)
        }
    }
    function handleOnFormMounted(){
        if (!!formConfig.value && !!formConfig.value.onFormMounted) {
            let onFormMountedFunc = new Function(formConfig.value.onFormMounted)
            onFormMountedFunc.call()
        }
    }

    let lyrenderForm = ref(null)
    //触发表单指定的单个动态组件表单验证，用于清除可能存在的校验错误提示
    function addFieldValidateEventHandler() {
        bus.off('fieldValidation')  //移除原有事件监听
        bus.on("fieldValidation", fieldName => {
            if(!!lyrenderForm.value){
                lyrenderForm.value.validateField(fieldName)
            }
        });
    }
    //清除表单model数据
    function clearGlobalFormModel() {
        for (let k in globalFormModel.value) {
            delete globalFormModel.value[k]
        }
    }
    //递归获取组件widgetList中的form的model字段
    function filterGlobalFormField(item) {
        if (item.category === 'container') {
            if (item.type === 'grid') {
                if (!!item.cols && (item.cols.length > 0)) {
                    item.cols.forEach((citem) => {
                        filterGlobalFormField(citem)
                    })
                }
            } else if (item.type === 'table') {
                if (!!item.rows && (item.rows.length > 0)) {
                    item.rows.forEach((ritem) => {
                        if (!!ritem.cols && (ritem.cols.length > 0)) {
                            ritem.cols.forEach((citem) => {
                                filterGlobalFormField(citem)
                            })
                        }
                    })
                }
            } else if (item.type === 'tab') {
                if (!!item.tabs && (item.tabs.length > 0)) {
                    item.tabs.forEach((titem) => {
                        if (!!titem.widgetList && (titem.widgetList.length > 0)) {
                            titem.widgetList.forEach((citem) => {
                                filterGlobalFormField(citem)
                            })
                        }
                    })
                }
            } else if ((item.type === 'grid-col') || (item.type === 'table-cell')) {
                if (!!item.widgetList && (item.widgetList.length > 0)) {
                    item.widgetList.forEach((citem) => {
                        filterGlobalFormField(citem)
                    })
                }
            }
        }else if(!!item.formItemFlag){
            if (!props.formData.hasOwnProperty(item.options.name)) {
                globalFormModel.value[item.options.name] = deepClone(item.options.defaultValue)
            } else {
                let initValue = props.formData[item.options.name]
                globalFormModel.value[item.options.name] = deepClone(initValue)
            }
        }
    }

    //初始化映射获取formJson下widgetList组件中form的model字段
    function initGlobalFormModel(widgetList){
        clearGlobalFormModel()
        if (!!widgetList && (widgetList.length > 0)) {
            widgetList.forEach((item) => {
                filterGlobalFormField(item)
            })
        }
    }

    //设置表单数据
    function setFormData(formData) {
        //存不存在皆赋值
        globalFormModel.value = deepClone(formData)
        // 存在组件才赋值
        // Object.keys(globalFormModel.value).forEach(pName => {
        //     if (!!formData && formData.hasOwnProperty(pName)) {
        //         globalFormModel.value[pName] = deepClone(formData[pName])
        //     }
        // })
    }
    //获取表单数据
    function getFormData(needValidation = true) {
        if (!needValidation) {
            return globalFormModel.value
        }

        let callback = function nullFunc() {}
        let promise = new window.Promise(function (resolve, reject) {
            callback = function(formData, error) {
                !error ? resolve(formData) : reject(error);
            };
        });

        lyrenderForm.value.validate((valid) => {
            if (valid) {
                callback(globalFormModel.value)
            } else {
                callback(globalFormModel.value, "表单数据校验失败")
            }
        })

        return promise
    }

    //获取表单ref
    function getFormRef(){
        return lyrenderForm.value
    }

    //设置表单下拉组件option的选项值
    function setFormJsonSelectOption(name,data) {
        function filterFormFieldMethod(item,name,data){
            if (!(!!(item.formItemFlag))) {
                if(item.category === 'container'){
                    if (item.type === 'grid') {
                        if (!!item.cols && (item.cols.length > 0)) {
                            item.cols.forEach((citem) => {
                                filterFormFieldMethod(citem,name,data)
                            })
                        }
                    } else if (item.type === 'table') {
                        if (!!item.rows && (item.rows.length > 0)) {
                            item.rows.forEach((ritem) => {
                                if (!!ritem.cols && (ritem.cols.length > 0)) {
                                    ritem.cols.forEach((citem) => {
                                        filterFormFieldMethod(citem,name,data)
                                    })
                                }
                            })
                        }
                    } else if (item.type === 'tab') {
                        if (!!item.tabs && (item.tabs.length > 0)) {
                            item.tabs.forEach((titem) => {
                                if (!!titem.widgetList && (titem.widgetList.length > 0)) {
                                    titem.widgetList.forEach((citem) => {
                                        filterFormFieldMethod(citem,name,data)
                                    })
                                }
                            })
                        }
                    } else if ((item.type === 'grid-col') || (item.type === 'table-cell')) {
                        if (!!item.widgetList && (item.widgetList.length > 0)) {
                            item.widgetList.forEach((citem) => {
                                filterFormFieldMethod(citem,name,data)
                            })
                        }
                    }
                }
            }else{
                // if(item.type === "select" && item.options.useForeignData && item.options.name === name){
                if(item.type === "select" && item.options.name === name){
                    item.options.optionItems = data
                }
            }
        }

        for(var i=0;i<props.formJson.widgetList.length;i++){
            let widgetItem = props.formJson.widgetList[i]
            filterFormFieldMethod(widgetItem,name,data)
        }
    }

    //created执行
    formJsonObj.value = props.formJson
    if(JSON.stringify(props.formData)=='{}' || Object.keys(props.formData).length==0){//添加模式
        initGlobalFormModel(!formJsonObj.value ? null : formJsonObj.value.widgetList)
    }else{//编辑模式
        setFormData(props.formData)
    }

    onMounted(()=>{
        initFormObject()
    })

    defineExpose({
        setFormData,
        getFormData,
        getFormRef,
        setFormJsonSelectOption
    })


</script>

<style lang="scss" scoped>

</style>