<!--
 * @Descripttion: 范围输入组件
 * @version: 1.0
 * @program: django-vue-lyadmin 专业版
 * @Author: lybbn
 * @Date: 2023.09.20
-->
<template>
    <div>
        <el-input v-model="datarange[0]" :placeholder="startPlaceholder" :type="type" :style="{width:width}" @change="startChange" clearable></el-input>
		{{ rangeSeparator }}
        <el-input v-model="datarange[1]" :placeholder="endPlaceholder" :type="type" :style="{width:width}" @change="endChange" clearable></el-input>
    </div>
</template>

<script>

	export default {
	    name: 'lyInputRange',
		props: {
            modelValue: null,
			name: { type: String, required: true, default: "" },
			width: { type: String, default: "100px" },
			rangeSeparator: { type: String, default: "-" },
			startPlaceholder: { type: String, default: "" },
            endPlaceholder: { type: String, default: "" },
            type: { type: String, default: "text" },
		},
        watch: {
			modelValue:{
				handler(){
					this.datarange = this.modelValue
				},
				deep: true
			}
		},
		data() {
			return {
				datarange: [null,null]
			}
		},
		mounted() {
            this.datarange =  (!!this.modelValue &&this.modelValue.length==2) ?this.modelValue:[null,null]
		},
		methods: {
            startChange(val){
                if(!!val && !!this.datarange[1]){
                    this.$emit('update:modelValue', this.datarange);
                    this.$emit('onchange', this.datarange);
                }

            },
            endChange(val){
                if(!!val && !!this.datarange[0]){
                    this.$emit('update:modelValue', this.datarange);
                    this.$emit('onchange', this.datarange);
                }
            },
		}
	}
</script>

<style scoped>
</style>
