<template>
    <lyInitiator v-if="nodeConfig.type==0" v-model="nodeConfig" :isDesign="isDesign"></lyInitiator>
    <lyApprover v-if="nodeConfig.type==1" v-model="nodeConfig" :isDesign="isDesign"></lyApprover>
    <lySend v-if="nodeConfig.type==2" v-model="nodeConfig" :isDesign="isDesign"></lySend>
    <lyBranch v-if="nodeConfig.type==4 && nodeConfig.conditionNodes.length>0" v-model="nodeConfig">
        <template v-slot="slot">
			<lyNodeWrap v-if="slot.node" v-model="slot.node.childNode" :isDesign="isDesign"></lyNodeWrap>
		</template>
    </lyBranch>
    <lyNotice v-if="nodeConfig.type==5" v-model="nodeConfig" :isDesign="isDesign"></lyNotice>
    <lyNodeWrap v-if="nodeConfig.childNode" v-model="nodeConfig.childNode" :isDesign="isDesign"></lyNodeWrap>
</template>

<script setup>
    import { ref, onMounted, watch, computed } from 'vue'
    import lyInitiator from "./lyworkflow-nodes/lyInitiator"
    import lyApprover from "./lyworkflow-nodes/lyApprover"
    import lyBranch from "./lyworkflow-nodes/lyBranch"
    import lySend from "./lyworkflow-nodes/lySend"
    import lyNotice from "./lyworkflow-nodes/lyNotice"

    const props = defineProps({
        modelValue: { type: Object, default: () => {}  },
        isDesign: { //是否设计模式
            type: Boolean,
            default: true
        }
    })

    const emits = defineEmits(['update:modelValue'])

    let nodeConfig = ref(props.modelValue)
    watch(()=>props.modelValue,()=>{
        nodeConfig.value = props.modelValue
    },{deep:true})
    watch(()=>nodeConfig.value,()=>{
        emits("update:modelValue", nodeConfig.value)
    },{deep:true})

</script>

<style scoped>

</style>