<!--
 * Descripttion: 流程设计器组件
 * version: 1.0
 * Author: lybbn
 * Email: 1042594286@qq.com
 * Program: django-vue-lyadmin 专业版
 * Website: http://doc.lybbn.cn
 * gitee: https://gitee.com/lybbn/django-vue-lyadmin
 * Date: 2023-11-29
 * LastEditDate: 2023-12-09
 * 部分UI原型参考来源：https://gitee.com/lolicode/scui/tree/master/src/components/scWorkflow
 * Remark: 如果要分发django-vue-lyadmin源码或其中组件等，需在本文件顶部保留此文件头信息！！！
-->
<template>
    <div class="ly-workflow-design" ref="lyWorkflowDesign" :style="{height:height == 'auto'?'100%':height}">
        <el-scrollbar class="lyworkflow-scrow" :height="scrollerHeight" ref="desinScrollbar">
            <div class="lyworkflow-zoom">
                <el-button icon="Minus" circle @click="handelZoomScaleMinusClick"></el-button>
                <span>{{zoomScale}}%</span>
                <el-button icon="Plus" circle @click="handelZoomScaleAddClick"></el-button>
            </div>
            <div class="lyworkflow-design-box" ref="desinBox">
                <lyNodeWrap v-if="flowConfig" v-model="flowConfig.nodeConfig" :isDesign="isDesign"></lyNodeWrap>
                <div class="lyworkflow-endnode">
                    <div class="lyworkflow-endnode-circle">结束</div>
                </div>
            </div>
            <lySelect v-if="selectVisible" ref="lySelectRef" @closed="selectVisible=false"></lySelect>
        </el-scrollbar>
    </div>
    <lyDialog v-model="showExportJSONDialog" title="导出JSON" top="20px" width="60%" :before-close="handleDialogExportJSONClose" :destroy-on-close="true" :append-to-body="true">
        <div>
            <lyCodeEditor ref="lyFormExportJSON" v-model="jsonContent" mode="json" height="600" :read-only="true"></lyCodeEditor>
        </div>
        <template #footer>
            <el-button type="primary"  @click="copyFormJson">复制JSON</el-button>
            <el-button  @click="saveJSONCode">保存为文件</el-button>
            <el-button @click="handleDialogExportJSONClose">关闭</el-button>
        </template>
    </lyDialog>
    <lyDialog v-model="showImportJSONDialog" title="导入JSON" top="20px" width="60%" :before-close="handleDialogImportJSONClose" :destroy-on-close="true" :append-to-body="true">
        <div>
            <el-alert title="请按如下格式导入，否则可能报错无法正常导入！！！" type="info"  show-icon />
            <lyCodeEditor ref="lyFormImportJSON" v-model="importJsonContent" mode="json" height="600" :read-only="false"></lyCodeEditor>
        </div>
        <template #footer>
            <el-button type="primary" @click="saveImportJson">导入</el-button>
            <el-button @click="handleDialogImportJSONClose">关闭</el-button>
        </template>
    </lyDialog>
</template>

<script setup>
    import {ref, onMounted ,reactive,nextTick,onBeforeUnmount,watch,computed,provide,watchEffect,defineAsyncComponent } from 'vue'
    import lyNodeWrap from './lyNodeWrap'
    import lySelect from './lySelect'
    import html2canvas from 'html2canvas'
    import {randomId,getDefaultWorkflowConfig} from '@/utils/util'
    import { saveAs } from 'file-saver'
    import useClipboard from "vue-clipboard3";
    import lyDialog from '@/components/dialog/dialog'
    import { ElMessage,ElMessageBox } from 'element-plus'

    const lyCodeEditor = defineAsyncComponent(() => import('@/components/lyform-builder/code-editor'));

    const props = defineProps({
        modelValue: { type: Object, default: () => getDefaultWorkflowConfig() },
        isFull:{ type:Boolean, default:false },
        height:{ type:String, default:"auto" },//流程设计器高度，auto自动，其他如500px固定高度
        isDesign: { //是否设计模式
            type: Boolean,
            default: true
        }
    })

    const emits = defineEmits(['update:modelValue'])

    let flowConfig = ref(props.modelValue)
    watch(()=>props.modelValue,()=>{
        flowConfig.value = props.modelValue
    },{deep:true})
    watch(()=>flowConfig.value,()=>{
        emits("update:modelValue", flowConfig.value)
    },{deep:true})

    const scrollerHeight = ref(0)

    provide("lySelectHandle",selectHandle)

    let lyWorkflowDesign = ref(null)
    let selectVisible = ref(false)
    let lySelectRef = ref(null)
    function selectHandle(type, data){
        selectVisible.value = true
        nextTick(() => {
            lySelectRef.value.open(type, data)
        })
    }

    let zoomScale = ref(100)
    let minZoomScale = 50
    let maxZoomScale = 300
    let desinBox = ref(null)
    let desinScrollbar = ref(null)

    function handelZoomScaleAddClick(){
        zoomScale.value = (zoomScale.value  + 10) >= maxZoomScale ? maxZoomScale : zoomScale.value  + 10
        const newScale = zoomScale.value/100
        desinBox.value.style.setProperty("transform","scale("+newScale+")") 
        // 计算缩放后的滚动条高度，解决el-scrollbar缩放后滚动条异常问题
        const scaledHeight = desinScrollbar.value.wrapRef.scrollHeight * newScale;
        // 设置缩放后的滚动条高度
        desinScrollbar.value.wrapRef.style.height = `${scaledHeight}px`;
    }

    function handelZoomScaleMinusClick(){
        zoomScale.value = (zoomScale.value  - 10) <= minZoomScale ? minZoomScale : zoomScale.value  - 10
        const newScale = zoomScale.value/100
        desinBox.value.style.setProperty("transform","scale("+newScale+")")
        // 计算缩放后的滚动条高度，解决el-scrollbar缩放后滚动条异常问题
        const scaledHeight = desinScrollbar.value.wrapRef.scrollHeight * newScale;
        // 设置缩放后的滚动条高度
        desinScrollbar.value.wrapRef.style.height = `${scaledHeight}px`;
    }

    function lyHandleResize(){
        nextTick(() => {
            if(props.isFull){
                const designHeight = window.innerHeight - 90
                scrollerHeight.value = designHeight
            }else{
                setTimeout(() => {
                    const designHeight = lyWorkflowDesign.value.offsetHeight
                    scrollerHeight.value = designHeight
                }, 100);
            }
        })
    }

    watch(() => props.isFull, (n) => {
        lyHandleResize()
    })
    let isDragging = ref(false)
    let startMouseX = 0
    let startMouseY = 0
    
    const handleMouseDown = (e) => {
        isDragging.value = true;
        startMouseX = e.clientX;
        startMouseY = e.clientY;
    }

    const handleMouseMove = (e) => {
        //解决拖放选中页面文字问题
        e.preventDefault();
        if (isDragging.value) {

            const deltaX = e.clientX - startMouseX;
            const deltaY = e.clientY - startMouseY;
          
            const disX = desinScrollbar.value.wrapRef.scrollLeft - deltaX;
            const disY = desinScrollbar.value.wrapRef.scrollTop - deltaY;
            desinScrollbar.value.setScrollLeft(disX)
            desinScrollbar.value.setScrollTop(disY)
  
            startMouseX = e.clientX;
            startMouseY = e.clientY;
        }
    }

    const handleMouseUp = () => {
        isDragging.value = false;
    }

    onMounted(()=>{
        lyHandleResize()
        window.addEventListener('resize',lyHandleResize)
        desinScrollbar.value.wrapRef.addEventListener('mousedown', handleMouseDown);
        // 添加鼠标移动和释放事件监听
        document.addEventListener('mousemove', handleMouseMove);
        document.addEventListener('mouseup', handleMouseUp);
        lyWorkflowDesign.value.style.setProperty("cursor","grab")
    })
    
    onBeforeUnmount(() => {
        window.removeEventListener('resize',lyHandleResize)
        desinScrollbar.value.wrapRef.removeEventListener('mousedown', handleMouseDown);
        // 移除鼠标移动和释放事件监听
        document.removeEventListener('mousemove', handleMouseMove);
        document.removeEventListener('mouseup', handleMouseUp);
    })

    watchEffect(() => {
        if(!!lyWorkflowDesign.value){
            if (isDragging.value) {
                lyWorkflowDesign.value.style.setProperty("cursor","grabbing")
            } else {
                lyWorkflowDesign.value.style.setProperty("cursor","grab")
            }
        }
    });

    //保存为图片
    function saveAsImg(){
        const element = desinBox.value
        // 使用 async/await 和 setTimeout 延迟执行截图操作
        setTimeout(async () => {
            const canvas = await html2canvas(element,{backgroundColor: '#f5f6fa',logging: false})
            const img = canvas.toDataURL('image/png').replace('image/png', 'image/octet-stream')
            let link = document.createElement('a')
            link.download = `lyWrokflow_${randomId()}.png`
            link.href = img
            link.click()
        }, 600);
    }

    let showImportJSONDialog = ref(false)
    let showExportJSONDialog = ref(false)
    let jsonContent = ref("")
    let importJsonContent = ref("")

    function handleDialogImportJSONClose() {
        showImportJSONDialog.value = false
    }
    function handleDialogExportJSONClose() {
        showExportJSONDialog.value = false
    }
    const { toClipboard } = useClipboard()
    function copyFormJson(e) {
        toClipboard(jsonContent.value).then(()=>{
            ElMessage.success("复制成功")
        }).catch(()=>{
            ElMessage.warning("复制失败")
        })
    }

    function saveAsFile(fileContent, defaultFileName) {
        ElMessageBox.prompt("文件名", "保存为文件", {
            inputValue: defaultFileName,
            closeOnClickModal: false,
            inputPlaceholder: "请输入文件名"
        }).then(({ value }) => {
            if (!value) {
                value = defaultFileName
            }

            const fileBlob = new Blob([fileContent], { type: 'text/plain;charset=utf-8' })
            saveAs(fileBlob ,value)
        }).catch(() => {
        //
        })
    }
    //保存为文件
    function saveJSONCode(){
        saveAsFile(jsonContent.value, `dvlyadmin_pro_lyworkflow_${randomId()}.json`)
    }

    //导出json
    function exportJsonCode(){
        showExportJSONDialog.value = true
        jsonContent.value = JSON.stringify(flowConfig.value, null, '  ')
    }

    //导入json
    function importJsonCode(){
        importJsonContent.value = JSON.stringify(getDefaultWorkflowConfig(),null, '  ')
        showImportJSONDialog.value = true
    }

    //保存json导入
    function saveImportJson(){
        try {
            let importObj= JSON.parse(importJsonContent.value)
            if (!importObj|| !importObj.nodeConfig) {
                throw new Error("nodeConfig配置错误")
            }
            let lyJsonVersion = importObj.jsonVersion
            if (!lyJsonVersion || (lyJsonVersion !== 1)) {
                throw new Error("导入json版本错误")
            }
            showImportJSONDialog.value = false
            flowConfig.value = importObj
            ElMessage.success("导入JSON配置成功")
        } catch(ex) {
            ElMessage.error(ex + '')
        }
    }

    //清空初始化flowConfig
    function clearFlowConfig(){
        ElMessageBox.confirm('确定要清空吗？','提示',{
            confirmButtonText: "确定",
            cancelButtonText: "取消",
            type: "warning"
        }).then(res=>{
            flowConfig.value = getDefaultWorkflowConfig()
        }).catch(()=>{})
    }

    defineExpose({
        saveAsImg,
        exportJsonCode,
        importJsonCode,
        clearFlowConfig
    })

</script>

<style lang="scss">
    .ly-workflow-design{
        cursor: grab;
        .el-container{
            height: 100%;
        }
    }
    .lyworkflow-scrow{
        background-color: var(--l-main-bg);
        font-size: 12px;
        background-image: radial-gradient(#cbcdcf 10%,transparent 0);
        background-size: 15px 15px;
    }
    .lyworkflow-zoom{
        display: flex;
        position: fixed;
        -webkit-box-align: center;
        -ms-flex-align: center;
        align-items: center;
        -webkit-box-pack: justify;
        -ms-flex-pack: justify;
        justify-content: space-between;
        height: 40px;
        width: 125px;
        right: 70px;
        margin-top: 30px;
        z-index: 20;
    }
    .lyworkflow-design-box{
        display: inline-block;
        position: relative;
        width: 100%;
        padding: 50px 0px;
        align-items: flex-start;
        justify-content: center;
        flex-wrap: wrap;
        min-width: min-content;
        transform: scale(1);
        transform-origin: 50% 0px 0px;
    }
    .lyworkflow-endnode{
        border-radius: 50%;
        font-size: 14px;
        color: rgba(29, 23, 23, 0.5);
        text-align: left;
    }
    .lyworkflow-endnode-circle{
        text-align: center;
        width: 50px;
        height: 50px;
        line-height: 50px;
        margin: auto;
        border-radius: 50%;
        background: #b1b4b4;
        box-shadow: 0 2px 10px rgba(146, 146, 153, 0.5);
        font-size: 16px;
        color:#fff;
    }
    .lyworkflow-wrap{
        display: inline-flex;
        width: 100%;
        flex-flow: column wrap;
        justify-content: flex-start;
        align-items: center;
        position: relative;
        z-index: 1;
    }
    .lyworkflow-wrap-box{
        display: inline-flex;
        flex-direction: column;
        position: relative;
        width: 220px;
        min-height: 72px;
        flex-shrink: 0;
        background: #fff;
        border-radius: 4px;
        cursor: pointer;
        box-shadow: 0 2px 5px 0 rgba(0,0,0,.1);
    }
    .lyworkflow-wrap-box.start-node:before {
        content: none
    }
    .lyworkflow-wrap-box::before {
        content: "";
        position: absolute;
        top: -12px;
        left: 50%;
        transform: translateX(-50%);
        width: 0px;
        border-style: solid;
        border-width: 8px 6px 4px;
        border-color: rgb(202, 202, 202) transparent transparent;
        background: #f6f8f9;
    }
    .lyworkflow-wrap-box:after {
        pointer-events: none;
        content: "";
        position: absolute;
        top:0;
        bottom:0;
        left:0;
        right:0;
        z-index: 2;
        border-radius: 4px;
    }
    .lyworkflow-wrap-box-lyInitiator:hover:after {
        border: 1px solid #163d47;
        box-shadow: 0 0 6px 0 rgba(22, 61, 71, 0.3);
    }
    .lyworkflow-wrap-box-lySend:hover:after {
        border: 1px solid #419efa;
        box-shadow: 0 0 6px 0 rgba(65, 158, 250, 0.3);
    }
    .lyworkflow-wrap-box-lyNotice:hover:after {
        border: 1px solid #f06705;
        box-shadow: 0 0 6px 0 rgba(65, 158, 250, 0.3);
    }
    .lyworkflow-wrap-box-lyApprover:hover:after {
        border: 1px solid #e6a23c;
        box-shadow: 0 0 6px 0 rgba(230, 162, 60, 0.3);
    }
    .lyworkflow-wrap-box .title {
        height:24px;
        line-height: 24px;
        color: #fff;
        padding-left: 16px;
        padding-right: 30px;
        border-radius: 4px 4px 0 0;
        position: relative;
        display: flex;
        align-items: center;
    }
    .lyworkflow-wrap-box .title .icon {
        margin-right: 5px;
    }
	.lyworkflow-wrap-box .title .close {
        font-size: 15px;
        position: absolute;
        top:50%;
        transform: translateY(-50%);
        right:10px;
        display: none;
    }
	.lyworkflow-wrap-box .content {
        position: relative;
        padding: 15px;
    }
	.lyworkflow-wrap-box .content .placeholder {
        color: #999;
    }
	.lyworkflow-wrap-box:hover .close {
        display: block;
    }
    .lyworkflow-wrap-drawer__title {
        padding-right:40px;
    }
	.lyworkflow-wrap-drawer__title label {
        cursor: pointer;
    }
	.lyworkflow-wrap-drawer__title label:hover {
        border-bottom: 1px dashed #409ef1;
    }
	.lyworkflow-wrap-drawer__title .node-wrap-drawer__title-edit {
        color: #409ef1;
        margin-left: 10px;
        vertical-align: middle;
    }
    .lyworkflow-drawer{
        .el-drawer__body {
            overflow: auto;
            padding: 0;
        }
        .el-container{
            height: 100%;
        }
        .el-drawer__header{
            flex-direction:row-reverse;
        }
    }
    .lyworkflow-drawer-footer {
        background: var(--el-bg-color);
        border-top: 1px solid var(--el-border-color-light);
        padding: 13px 15px;
    }
    .lyworkflow-tags-list {
        margin-top: 15px;
        width: 100%;
    }
    .lyworkflow-addnode-btn-box{
        width: 240px;
        display: inline-flex;
        flex-shrink: 0;
        position: relative;
        z-index: 1;
    }
    .lyworkflow-addnode-btn-box:before {
        content: "";
        position: absolute;
        top: 0px;
        left: 0px;
        right: 0px;
        bottom: 0px;
        z-index: -1;
        margin: auto;
        width: 2px;
        height: 100%;
        background-color: rgb(202, 202, 202);
    }
    .lyworkflow-addnode-btn {
        user-select: none;
        width: 240px;
        padding: 20px 0px 32px;
        display: flex;
        justify-content: center;
        flex-shrink: 0;
        flex-grow: 1;
        .el-button.is-circle{
            width: 26px;
            height: 26px;
        }
    }
    .lyworkflow-addnode-popover-body{
        .el-menu{
            border-right: none;
            .el-menu-item{
                height: 45px;
                line-height: 45px;
            }
        }
    }
    .lyworkflow-branch {
        display: inline-flex;
        width: 100%;
    }
    .lyworkflow-branch-box-wrap {
        display: flex;
        flex-flow: column wrap;
        align-items: center;
        min-height: 270px;
        width: 100%;
        flex-shrink: 0;
    }
    .lyworkflow-branch-box{
        display: flex;
        overflow: visible;
        min-height: 180px;
        height: auto;
        border-bottom: 2px solid #ccc;
        border-top: 2px solid #ccc;
        position: relative;
        margin-top: 15px;
    }
    .lyworkflow-branch-box{
        .add-branch {
            justify-content: center;
            padding: 0px 10px;
            position: absolute;
            top: -16px;
            left: 50%;
            transform: translateX(-50%);
            transform-origin: center center;
            z-index: 1;
            display: inline-flex;
            align-items: center;
        }
        .col-box::before {
            content: "";
            position: absolute;
            top: 0px;
            left: 0px;
            right: 0px;
            bottom: 0px;
            z-index: 0;
            margin: auto;
            width: 2px;
            height: 100%;
            background-color: rgb(202, 202, 202);
        }
        .col-box{
            display: inline-flex;
            flex-direction: column;
            align-items: center;
            position: relative;
            background: #f6f8f9;
        }
        .condition-node {
            display: inline-flex;
            flex-direction: column;
            min-height: 220px;
        }
        .condition-node-box {
            padding-top: 30px;
            padding-right: 50px;
            padding-left: 50px;
            justify-content: center;
            align-items: center;
            flex-grow: 1;
            position: relative;
            display: inline-flex;
            flex-direction: column;
        }
        .condition-node-box::before {
            content: "";
            position: absolute;
            top: 0px;
            left: 0px;
            right: 0px;
            bottom: 0px;
            margin: auto;
            width: 2px;
            height: 100%;
            background-color: rgb(202, 202, 202);
        }
        .lyworkflow-auto-judge {
            position: relative;
            width: 220px;
            min-height: 72px;
            background: rgb(255, 255, 255);
            border-radius: 4px;
            padding: 15px 15px;
            cursor: pointer;
            box-shadow: 0 2px 5px 0 rgba(0,0,0,.1);
            box-sizing: border-box;
        }
        .lyworkflow-auto-judge::before {
            content: "";
            position: absolute;
            top: -12px;
            left: 50%;
            transform: translateX(-50%);
            width: 0px;
            border-style: solid;
            border-width: 8px 6px 4px;
            border-color: rgb(202, 202, 202) transparent transparent;
            background: rgb(245, 245, 247);
        }
        .lyworkflow-auto-judge .title {
            line-height: 16px;
        }
        .lyworkflow-auto-judge .title .node-title {
            color: #67c23a;
        }
        .lyworkflow-auto-judge .title .close {
            font-size: 15px;
            position: absolute;
            top:15px;
            right:15px;
            color: #999;display: none;
        }
        .lyworkflow-auto-judge .title .priority-title {
            position: absolute;
            top:15px;
            right:15px;
            color: #999;
        }
        .lyworkflow-auto-judge .content {
            position: relative;
            padding-top: 15px;
        }
        .lyworkflow-auto-judge .content .placeholder {
            color: #999;
        }
        .lyworkflow-auto-judge:hover .close {
            display: block;
        }
        .lyworkflow-auto-judge:hover .priority-title {
            display: none;
        }
        .top-left-cover-line, .top-right-cover-line {
            position: absolute;
            height: 3px;
            width: 50%;
            background-color: #f6f8f9;
            top: -2px;
        }
        .bottom-left-cover-line, .bottom-right-cover-line {
            position: absolute;
            height: 3px;
            width: 50%;
            background-color: #f6f8f9;
            bottom: -2px;
        }
        .top-left-cover-line {
            left: -1px;
        }
        .top-right-cover-line {
            right: -1px;
        }
        .bottom-left-cover-line {
            left: -1px;
        }
        .bottom-right-cover-line {
            right: -1px;
        }
        .lyworkflow-auto-judge:hover {
			.sort-left {
                display: flex;
            }
			.sort-right {
                display: flex;
            }
		}
        .lyworkflow-auto-judge .sort-left {
            position: absolute;
            top: 0;
            bottom: 0;
            z-index: 1;
            left: 0;
            display: none;
            justify-content: center;
            align-items: center;
            flex-direction: column;
        }
        .lyworkflow-auto-judge .sort-right {
            position: absolute;
            top: 0;
            bottom: 0;
            z-index: 1;
            right: 0;
            display: none;
            justify-content: center;
            align-items: center;
            flex-direction: column;
        }
        .lyworkflow-auto-judge .sort-left:hover{
            background: #eee;
        }
        .lyworkflow-auto-judge .sort-right:hover{
            background: #eee;
        }
        .lyworkflow-auto-judge:after {
            pointer-events: none;
            content: "";
            position: absolute;
            top:0;
            bottom:0;
            left:0;
            right:0;
            z-index: 2;
            border-radius: 4px;
            transition: all .1s;
        }
        .lyworkflow-auto-judge:hover:after {
            border: 1px solid #67c23a;
            box-shadow: 0 0 6px 0 rgba(21, 197, 74, 0.3);
        }
    }
    .lyworkflow-member-select-dialog .el-dialog__body{
        padding: 20px;
    }
    .dark .lyworkflow-scrow{
        background-image: radial-gradient(#484a4b 10%,transparent 0);
    }
    .dark .lyworkflow-addnode-popover-body{
        .el-menu .el-menu-item:hover{
            background-color: #505861;
        }
    }
    .dark .lyworkflow-member-select-selected li:hover {
		background: var(--el-color-primary-dark-5);
	}
    .dark .lyworkflow-member-select-tree .el-tree--highlight-current .el-tree-node.is-current>.el-tree-node__content {
        background-color:var(--el-color-primary-dark-5);
    }
    .dark .lyworkflow-design-box {
		.lyworkflow-wrap-box,.lyworkflow-auto-judge {
            background: rgb(46, 45, 45);
        }
		.col-box {
            background: var(--el-bg-color);
        }
		.top-left-cover-line,.top-right-cover-line,.bottom-left-cover-line,.bottom-right-cover-line {
            background-color: var(--el-bg-color);
        }
		.lyworkflow-wrap-box::before,.lyworkflow-auto-judge::before {
            background-color: var(--el-bg-color);
        }
		.lyworkflow-branch-box .add-branch {
            background: var(--el-bg-color);
        }
		.lyworkflow-endnode .lyworkflow-endnode-circle {
            color: #fff;
        }
		.lyworkflow-auto-judge .sort-left:hover, .lyworkflow-auto-judge .sort-right:hover {
            background: var(--el-bg-color);
        }
	}
</style>