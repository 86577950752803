<template>
	<el-scrollbar>
		<el-card shadow="never" class="aboutTop">
			<div class="aboutTop-info">
				<img src="static/img/logo.png">
				<h2>{{data.name}}</h2>
				<p>{{data.version}}</p>
			</div>
		</el-card>
		<el-card shadow="hover" header="dependencies" class="marigintop">
			<el-descriptions border :column="3">
				<el-descriptions-item v-for="(value, key) in data.dependencies" :key="key" :label="key">{{value}}</el-descriptions-item>
			</el-descriptions>
		</el-card>
		<el-card shadow="hover" header="devDependencies" class="marigintop">
			<el-descriptions border :column="3">
				<el-descriptions-item v-for="(value, key) in data.devDependencies" :key="key" :label="key">{{value}}</el-descriptions-item>
			</el-descriptions>
		</el-card>
	</el-scrollbar>
</template>

<script setup>
    import {ref} from 'vue'
	import packageJson from '../../../package.json'
	let data = ref(packageJson)
</script>

<style scoped>
	.aboutTop {border:0;background: linear-gradient( 135deg, #3C8CE7 20%, #00EAFF 90%);color: #fff;}
	.aboutTop-info {text-align: center}
	.aboutTop-info img {width: 100px;}
	.aboutTop-info h2 {font-size: 26px;margin-top: 15px;}
	.aboutTop-info p {font-size: 16px;margin-top: 10px;}
    .marigintop{margin-top: 10px;}
</style>
