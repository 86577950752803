<template>
	<el-dialog class="lyworkflow-member-select-dialog" v-model="dialogVisible" :title="titleMap[type-1]" :width="type==1?840:605" :destroy-on-close="true" :append-to-body="true" @closed="handleDialogClose">
		<template v-if="type==1">
			<div class="lyworkflow-member-select">
				<div class="lyworkflow-member-select-body">
					<div class="lyworkflow-member-select-search">
						<el-input v-model="keyword" placeholder="请输入姓名或账号"></el-input>
						<el-button  @click="search" type="primary" icon="Search" style="margin-left: 12px;">查询</el-button>
						<el-button  @click="resetSearch" icon="Refresh">重置</el-button>
					</div>
					<div class="lyworkflow-member-select-select">
						<div class="lyworkflow-member-select-tree" v-loading="loadingDeptPage">
							<el-scrollbar>
								<el-tree class="menu" ref="deptTree" :data="group" :node-key="deptProps.key" :props="deptProps" highlight-current :expand-on-click-node="false" :current-node-key="deptId" @node-click="handleDeptClick"/>
							</el-scrollbar>
						</div>
						<div class="lyworkflow-member-select-user" v-loading="loadingUserPage">
							<div class="lyworkflow-member-select-userlist">
								<el-scrollbar ref="userScrollbar">
									<el-tree class="menu" ref="userTree" :data="user" :node-key="userProps.key" :props="userProps" :default-checked-keys="selectedIds" show-checkbox check-on-click-node @check-change="handleUserClick">
										<template #default="{ node}">
											<span>{{ node.data.name+"("+node.data.username+")" }}</span>
										</template>
									</el-tree>
								</el-scrollbar>
							</div>
						</div>
					</div>
					<div class="lyworkflow-member-select-pagination">
						<el-pagination background layout="prev,next" small :total="total" :page-size="pageSize" v-model:currentPage="currentPage" @current-change="handlePaginationChange"></el-pagination>
					</div>
				</div>
				<div class="lyworkflow-member-select-toicon"><el-icon><Arrow-right /></el-icon></div>
				<div class="lyworkflow-member-select-selected">
					<header>已选 ({{selected.length}})</header>
					<ul>
						<el-scrollbar>
							<li v-for="(item, index) in selected" :key="item.id" v-if="selected.length>0">
								<span class="name">
									<el-avatar size="small">{{item.name.substring(0,1)}}</el-avatar>
									<label>{{item.name}}</label>
								</span>
								<span class="delete">
									<el-button type="danger" icon="delete" circle size="small" @click="handleDeleteSelected(index)"></el-button>
								</span>
							</li>
							<el-empty v-else description="请在左侧选择" />
						</el-scrollbar>
					</ul>
				</div>
			</div>
		</template>

		<template v-if="type==2">
			<div v-loading="loadingRolePage">
				<el-transfer v-model="roleSelectedIdsModel"  :titles="['待选择', '已选择']" :props=roleProps :data="role" :left-default-checked="selectedIds" @change="handleRoleClick"></el-transfer>
			</div>
		</template>

		<template #footer>
			<el-button @click="dialogVisible = false">取 消</el-button>
			<el-button type="primary" @click="handleSave">确 认</el-button>
		</template>
	</el-dialog>
</template>

<script setup>
	import {apiSystemDept,apiSystemRole,apiSystemUser} from '@/api/api'
	import { ref, onMounted, watch, computed , nextTick} from 'vue'
	import { ElMessage } from 'element-plus'
	import XEUtils from 'xe-utils'

	const props = defineProps({
        modelValue: { type: Boolean, default: false },
        isDesign: { //是否设计模式
            type: Boolean,
            default: true
        }
    })

	const emits = defineEmits(['update:modelValue','closed'])

	function handleDialogClose(){
		emits("closed")
	}

	let deptProps = {key: 'id',label: 'name',children: 'children'}
	let userProps = {key: 'id',label: 'name'}
	let roleProps = {key: 'id',label: 'name'}
	let titleMap = ['人员选择', '角色选择']
	let dialogVisible = ref(false)
	let loadingDeptPage = ref(false)
	let loadingUserPage = ref(false)
	let loadingRolePage = ref(false)
	let keyword = ref("")
	let deptId = ref("")
	let pageSize = 10
	let total = 0
	let currentPage = 1
	let group = ref([])
	let user = ref([])
	let role = ref([])
	let type = ref(1)
	let selected = ref([])
	let value = ref([])

	let roleSelectedIdsModel = ref([])
	let selectedIds = computed(()=>{
		return selected.value.map(t => t.id)
	})

	//打开赋值
	function open(typenum, data){
		type.value = typenum
		value.value = data||[]
		selected.value = JSON.parse(JSON.stringify(data||[]))
		dialogVisible.value = true

		if(type.value==1){
			getDeptList()
			getUserList()
		}else if(type.value==2){
			getRoleList()
			roleSelectedIdsModel.value = selectedIds.value
		}

	}

	//获取部门
	function getDeptList(){
		loadingDeptPage.value = true;
		apiSystemDept({page:1,limit:999}).then(res => {
			loadingDeptPage.value = false;
			if(res.code == 2000) {
				// 将列表数据转换为树形数据
				var ret = XEUtils.toArrayTree(res.data.data, { parentKey: 'parent', strict: false })
				ret.unshift({id: '', name: '所有'});
				group.value = ret
			} else {
				ElMessage.warning(res.msg)
			}
		})
	}

	let userScrollbar = ref(null)
	//获取用户
	async function getUserList(){
		loadingUserPage.value = true;
		var params = {
			search: keyword.value || null,
			dept_id: deptId.value || null,
			page: currentPage,
			limit: pageSize
		}
		apiSystemUser(params).then(res => {
			loadingUserPage.value = false;
			if(res.code == 2000) {
				user.value = res.data.data
				total = res.data.total || 0;
				userScrollbar.value.setScrollTop(0)
			} else {
				ElMessage.warning(res.msg)
			}
		})
	}

	//获取角色
	async function getRoleList(){
		loadingRolePage.value = true;
		apiSystemRole({page:1,limit:999,simple:true}).then(res => {
			loadingRolePage.value = false;
			if(res.code == 2000) {
				role.value = res.data.data
			} else {
				ElMessage.warning(res.msg)
			}
		})
	}

	//部门点击
	function handleDeptClick(data){
		keyword.value = ''
		currentPage = 1
		deptId.value = data.id
		getUserList()
	}

	//用户点击
	function handleUserClick(data, checked){
		if(checked){
			selected.value.push({
				id: data.id,
				name: data.name
			})
		}else{
			selected.value = selected.value.filter(item => item.id != data.id)
		}
	}

	//用户分页点击
	function handlePaginationChange(){
		getUserList()
	}

	let deptTree = ref(null)
	//用户搜索
	function search(){
		deptId.value = ''
		deptTree.value.setCurrentKey(deptId.value)
		currentPage = 1
		getUserList()
	}
	//重置用户搜索
	function resetSearch(){
		keyword.value = ''
		deptTree.value.setCurrentKey(deptId.value)
		currentPage = 1
		getUserList()
	}

	let userTree = ref(null)
	//删除已选
	function handleDeleteSelected(index){
		selected.value.splice(index,1);
		if(type.value==1){
			userTree.value.setCheckedKeys(selectedIds.value)
		}else if(type.value==2){
			deptTree.value.setCheckedKeys(selectedIds.value)
		}
	}

	//角色选择点击
	function handleRoleClick(TransferKey, direction, movedKeys){
		selected.value = []
		if(!!roleSelectedIdsModel.value && roleSelectedIdsModel.value.length>0){
			roleSelectedIdsModel.value.forEach(item=>{
				role.value.forEach(itemr=>{
					if(item == itemr.id){
						selected.value.push({
							id: itemr.id,
							name: itemr.name
						})
					}
				})
			})
		}
	}

	//提交保存
	function handleSave(){
		value.value.splice(0,value.value.length);
		selected.value.map(item => {
			value.value.push(item)
		})
		dialogVisible.value = false
	}

	defineExpose({
		open
	})

</script>

<style scoped>
	.lyworkflow-member-select {
		display: flex;
	}
	.lyworkflow-member-select-body {
		width: 500px;
	}

	.lyworkflow-member-select-search {
		display: flex;
		padding-bottom:10px;
	}

	.lyworkflow-member-select-select {
		display: flex;
		background: var(--el-bg-color);
	}
	.lyworkflow-member-select-tree {
		width: 300px;
		height:300px;
		border: 1px solid var(--el-border-color-light);
		margin-right: 2px;
	}
	.lyworkflow-member-select-user {
		width: 300px;
		height:300px;
		display: flex;
		flex-direction: column;
		border: 1px solid var(--el-border-color-light);
		margin-left: 2px;
	}
	.lyworkflow-member-select-userlist {
		flex: 1;
		overflow: auto;
	}

	.lyworkflow-member-select-pagination{
		margin-top: 10px;
		float: right;
	}

	.lyworkflow-member-select-toicon {
		display: flex;
		justify-content: center;
		align-items: center;
		margin:0 10px;
	}
	.lyworkflow-member-select-toicon i {
		display: flex;
		justify-content: center;
		align-items: center;
		background: #ccc;
		width: 20px;
		height: 20px;
		text-align: center;
		line-height: 20px;
		border-radius:50%;
		color: #fff;
	}

	.lyworkflow-member-select-selected {
		height:345px;
		width: 260px;
		border: 1px solid var(--el-border-color-light);
		background: var(--el-bg-color);
	}
	.lyworkflow-member-select-selected header {
		height:43px;
		line-height: 43px;
		border-bottom: 1px solid var(--el-border-color-light);
		padding:0 15px;
		font-size: 12px;
	}
	.lyworkflow-member-select-selected ul {
		height:300px;
		overflow: auto;
	}
	.lyworkflow-member-select-selected li {
		display: flex;
		align-items: center;
		justify-content: space-between;
		padding:5px 5px 5px 15px;
		height:28px;
	}
	.lyworkflow-member-select-selected li .name {
		display: flex;
		align-items: center;
	}
	.lyworkflow-member-select-selected li .name .el-avatar {
		background: var(--el-color-primary);
		margin-right: 10px;
	}
	.lyworkflow-member-select-selected li .delete {
		display: none;
	}
	.lyworkflow-member-select-selected li:hover {
		background: var(--el-color-primary-light-9);
	}
	.lyworkflow-member-select-selected li:hover .delete {
		display: inline-block;
	}

</style>
