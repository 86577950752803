<template>
    <div>
        <ly-dialog v-model="dialogVisible" :title="loadingTitle" width="560px" @closed="handleClose">
            <div v-if="identity === '抖客'">
<!--                <iframe :src="codeUrl" width="100%" height="600px" frameborder="0"></iframe>-->
                <div>请在浏览器登录抖客账号后，使用以下授权链接进行授权（复制到浏览器访问），授权成功后关闭此弹窗即可！！！</div>
                <el-link style="margin-top: 60px;" target="_blank"  href="https://buyin.jinritemai.com/dashboard/institution/power-manage" type="danger">步骤一：点击登录抖客平台</el-link>
                <div style="margin-top: 60px;"><div style="margin-bottom: 20px;">步骤二：复制以下链接在登录过抖客平台浏览器访问并点击授权</div><el-link :href="codeUrl" target="_blank" type="primary">{{codeUrl}}</el-link></div>
                <div style="height: 100px;"></div>
            </div>
            <div v-if="identity === '团长'">
<!--                <iframe :src="codeUrl" width="100%" height="600px" frameborder="0"></iframe>-->
                <div>请在浏览器登录百应团长账号后，使用以下授权链接到进行授权（复制到浏览器访问），授权成功后关闭此弹窗即可！！！</div>
                <el-link style="margin-top: 60px;" target="_blank"  href="https://buyin.jinritemai.com/mpa/account/institution-role-select" type="danger">步骤一：点击登录百应团长平台（团长身份）</el-link>
                <div style="margin-top: 60px;"><div style="margin-bottom: 20px;">步骤二：【自研型团长应用】复制以下链接在登录过百应团长平台浏览器（浏览器在百应团长页面）访问并点击【授权管理】-【自研型】-【团长】的【使用】按钮，出现授权成功提示即可</div><el-link href="https://buyin.jinritemai.com/dashboard/institution/power-manage" target="_blank" type="primary">https://buyin.jinritemai.com/dashboard/institution/power-manage</el-link></div>
                <div style="height: 100px;"></div>
            </div>
        </ly-dialog>
    </div>
</template>

<script>
    import {lytiktokunionSystemaccountCreateCodeDK} from "@/api/api";
    import LyDialog from "@/components/dialog/dialog";
    export default {
        components: {LyDialog},
        emits: ['refreshData', 'closed'],
        name: "addDouyinCodeModule",
        data() {
            return {
                dialogVisible:false,
                loadingSave:false,
                loadingTitle:'',
                codeUrl:"",
                identity:"抖客",
            }
        },
        methods:{
            handleClose() {
                this.$emit('closed')
            },
            handleOpen(item,flag) {
                this.loadingTitle=flag
                this.dialogVisible=true
                if(item){
                    this.identity = item.identity
                    if(this.identity === "抖客"){
                        this.getCodeUrl(item.id)
                    }
                    this.loadingTitle = this.identity+this.loadingTitle
                }else{
                    this.getCodeUrl()
                }

            },
            getCodeUrl(id){
                var params = {}
                if(id){
                    params["id"] = id
                }
                lytiktokunionSystemaccountCreateCodeDK(params).then(res=>{
                    if(res.code === 2000){
                        this.codeUrl = res.data.data
                    }else{
                        this.$message.warning(res.msg)
                    }
                })
            },
        }
    }
</script>
<style scoped>
</style>

