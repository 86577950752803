<!--
 * @Descripttion: docx预览
 * @version: 1.0
 * @program: django-vue-lyadmin
 * @Author: lybbn
 * @Email: 1042594286@qq.com
 * @Date: 2023.06.01
-->
<template>
    <vue-office-docx class="lyvueofficedocx" :src="url" style="height: 100vh" @rendered="rendered" v-if="mode === 'office'"/>
</template>

<script setup>
    import {ref, onMounted ,reactive} from 'vue'
    import VueOfficeDocx from '@vue-office/docx'
    import '@vue-office/docx/lib/index.css'
    const props = defineProps({
        url: {
            type: String,
            default: ""
        },
        mode: {
            type: String,
            default: "office"
        },
    })

    function rendered(){
        console.log("渲染完成")
    }

</script>

<style scoped>

</style>