<template>
  <el-form ref="loginForm" :model="formData" :rules="rules" label-width="0" size="large" @keyup.enter="submitLogin">
    <el-form-item prop="username">
      <el-input v-model="formData.username" prefix-icon="user" clearable :placeholder="$t('login.loginAccount')">
        <template #append>
          <el-select v-model="userType" style="width: 130px;">
            <el-option :label="$t('login.admin')" value="admin"></el-option>
          </el-select>
        </template>
      </el-input>
    </el-form-item>
    <el-form-item prop="password">
      <el-input v-model="formData.password" prefix-icon="lock" clearable show-password
                :placeholder="$t('login.loginPWD')"></el-input>
    </el-form-item>
    <el-form-item prop="captcha">
      <el-input type="text" prefix-icon="circle-check" v-model.trim="formData.captcha" auto-complete="off"
                :placeholder="$t('login.code')">
        <template #append>
          <img class="login-captcha" :src="image_base" @click="getCaptchas"/>
        </template>
      </el-input>
    </el-form-item>
    <!--		<el-form-item style="margin-bottom: 10px;">-->
    <!--				<el-col :span="12">-->
    <!--					<el-checkbox :label="$t('login.rememberMe')" v-model="rememberpassword"></el-checkbox>-->
    <!--				</el-col>-->
    <!--		</el-form-item>-->
    <el-form-item>
      <el-button type="primary" style="width: 100%;" :loading="islogin" round @click="submitLogin">{{
          $t('login.login')
        }}
      </el-button>
    </el-form-item>
  </el-form>
</template>

<script setup>
import {nextTick, onMounted, ref} from 'vue'
import {apiSystemWebRouter, getCaptcha, login} from '@/api/api'
import {delCookie, getCookie, setCookie, setStorage, transArrayMenuToTree} from '@/utils/util'
import {useMutitabsStore} from "@/store/mutitabs";
import {useSiteThemeStore} from "@/store/siteTheme";
import i18n from '@/locales'
import {ElMessage} from 'element-plus'
import {useRouter} from 'vue-router'

const router = useRouter()

const mutitabsstore = useMutitabsStore()
const siteThemeStore = useSiteThemeStore()
const {t} = i18n.global

let userType = ref('admin')
let formData = ref({
  username: "",
  password: "",
  captcha: "",
  captchaKey: null,
})
let rememberpassword = ref(false)
let image_base = ref(null)
let rules = ref({
  username: [
    {required: true, message: t('login.AccountError'), trigger: 'blur'}
  ],
  password: [
    {required: true, message: t('login.PWError'), trigger: 'blur'}
  ],
  captcha: [
    {required: true, message: t('login.codeError'), trigger: 'blur'}
  ],
})
let islogin = ref(false)

function getuserpassword() {
  if (getCookie('username') != '' && getCookie('password') != '') {
    formData.value.username = getCookie('username')
    formData.value.password = getCookie('password')
    rememberpassword.value = true
  }
}

/**
 * 获取验证码
 */
function getCaptchas() {
  getCaptcha().then((res) => {
    if (res.code == 2000) {
      formData.value.captcha = null
      formData.value.captchaKey = res.data.data.key
      image_base.value = res.data.data.image_base
    }
  })
}

// 获取菜单
function getMenu() {
  let allmenu = []
  islogin.value = true
  apiSystemWebRouter().then(res => {
    if (res.code == 2000) {
      let menuTree = []
      if (res.data.data.length > 0) {
        menuTree = transArrayMenuToTree(res.data.data)
        // 操作权限管控
        let menuList = []
        res.data.data.forEach(item => {
          //console.log(item,'item---- 菜单权限---')
          menuList.push({
            url: item.web_path,
            moduleName: item.name,
            menuPermission: item.menuPermission
          })
        })
        setStorage('menuList', JSON.stringify(menuList))
      }
      // console.log(menuTree,'menuTree-----')
      allmenu = menuTree
      if (allmenu.length > 0) {
        nextTick(() => {
          router.replace({path: `/${allmenu[0].attributes.url}`})
        })
      } else {
        mutitabsstore.logout('false')
        router.push({path: '/login'})
        sessionStorage.clear()
        localStorage.clear()
        islogin.value = false
        ElMessage.warning('暂无授权任何菜单权限~')
      }

      setStorage('allmenu', JSON.stringify(allmenu))
      //优化首次登录第一个标签显示问题
      let tabsPage = ""
      let TabsValue = ""
      if (menuTree[0].hasChildren) {
        tabsPage = [{
          "title": menuTree[0].children[0].text,
          "name": menuTree[0].children[0].attributes.url,
          "query": {}
        }]
        TabsValue = menuTree[0].children[0].attributes.url
      } else {
        tabsPage = [{"title": menuTree[0].text, "name": menuTree[0].attributes.url, "query": {}}]
        TabsValue = menuTree[0].attributes.url
      }
      mutitabsstore.firstTabs([tabsPage, TabsValue])
    } else {
      ElMessage.warning(res.msg)
    }

    islogin.value = false
  })
}

let loginForm = ref(null)

async function submitLogin() {
  var validate = await loginForm.value.validate().catch(() => {
  })
  if (!validate) {
    return false
  }
  islogin.value = true
  login(formData.value).then(async res => {
    islogin.value = false
    if (res.code === 2000) {
      if (rememberpassword.value) {
        //保存帐号到cookie，有效期7天
        await setCookie('username', formData.value.username, 7)
        //保存密码到cookie，有效期7天
        await setCookie('password', formData.value.password, 7)
      } else {
        await delCookie('username')
        await delCookie('password')
      }
      mutitabsstore.setLogintoken(res.data.access)
      mutitabsstore.refreshUserinfo(res.data)
      mutitabsstore.setUserId(res.data.userId)
      mutitabsstore.setRefresh(res.data.refresh)
      getMenu()
    } else {
      getCaptchas()
      ElMessage.error(res.msg)
      return false
    }
  })
  islogin.value = false
}

onMounted(() => {
  //请求数据
  getuserpassword()
  getCaptchas()
})

</script>

<style scoped>
.login-captcha {
  cursor: pointer;
  height: 38px;
  width: 128px;
  display: block;
  margin: 0px -19px;
  border-top-right-radius: 2px;
  border-bottom-right-radius: 2px;
}
</style>