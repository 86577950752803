<!--
 * Descripttion: 流程设计器
 * version: 1.0
 * Author: lybbn
 * Email: 1042594286@qq.com
 * Program: django-vue-lyadmin 专业版
 * Website: http://doc.lybbn.cn
 * gitee: https://gitee.com/lybbn/django-vue-lyadmin
 * Date: 2023-11-29
 * LastEditDate: 2023-11-29
 * Remark: 如果要分发django-vue-lyadmin源码或其中组件等，需在本文件顶部保留此文件头信息！！！
-->
<template>
    <div :class="{'ly-is-full-design':isFull}" style="height: 100%;overflow:none;">
        <el-container class="lyworkflowmain">
            <el-header class="lyworkflowheader">
                <div class="lyworkflowheader__title">
                    <span style="display: flex"><el-icon size="32px" color="#409eff"><Connection /></el-icon></span>
                    <span>lyWorkflow</span>
                    <span>流程设计器</span>
                    <span>Ver {{LYWORKFLOW_VERSION}}</span>
                </div>
                <div class="lyworkflowheader__dobutton">
                    <el-button type="primary" link @click="handleClick('delete')">
                        <el-icon size="15px"><Delete/></el-icon>清空
                    </el-button>
                    <el-button type="primary" link @click="handleClick('importJson')">
                        <el-icon size="15px"><Upload/></el-icon>导入JSON
                    </el-button>
                    <el-button type="primary" link @click="handleClick('exportjson')">
                        <el-icon size="15px"><Download/></el-icon>导出JSON
                    </el-button>
                    <el-button type="primary" link @click="handleClick('saveImg')">
                        <el-icon size="15px"><Picture/></el-icon>保存图片
                    </el-button>
                    <el-button type="primary" link @click="setFull">
                        <el-icon size="15px"><full-screen/></el-icon>全屏
                    </el-button>
                    <el-button type="primary" link @click="handleClick('newtab')">
                        <el-icon size="15px"><ChromeFilled/></el-icon>新窗口打开
                    </el-button>
                    <el-button type="primary" link @click="handleClick('opendocment')">
                        <el-icon size="15px"><Reading/></el-icon>文档
                    </el-button>
                    </div>
            </el-header>
            <el-container class="lyworkflow_design_container">
                <el-main class="lyworkflowdesign__main">
                    <lyworkflowDesign :isFull="isFull" v-model="flowConfig" ref="lyWrokflow" :isDesign="isDesign"></lyworkflowDesign>
                </el-main>
            </el-container>
        </el-container>
    </div>
</template>

<script setup>
    import {ref, onMounted ,reactive,provide,watch,computed} from 'vue'
    import lyworkflowDesign from '@/components/lyworkflow/lyworkflow-design/lyworkflowDesign'
    import {useRouter } from 'vue-router'
    import {LYWORKFLOW_VERSION} from "@/components/lyworkflow/config"
    import {getDefaultWorkflowConfig} from "@/utils/util"

    const props = defineProps({
        modelValue: { //prop传入的流程配置
            type: Object,
            default: () => getDefaultWorkflowConfig()
        },
        isDesign: { //是否设计模式
            type: Boolean,
            default: true
        }
    })

    let lyWrokflow = ref(null)

    const emits = defineEmits(['update:modelValue'])

    const flowConfig = computed({
        get() {
            return props.modelValue
        },
        set(value) {
            emits('update:modelValue', value)
        }
    })

    const router = useRouter()

    const isFull = ref(false)

    function setFull(){
        isFull.value=!isFull.value
        window.dispatchEvent(new Event('resize'))
    }

    function handleClick(flag) {
        if(flag==="opendocment"){
            window.open("https://doc.lybbn.cn")
        }else if(flag==="exportjson"){
            lyWrokflow.value.exportJsonCode()
        }else if(flag==="importJson"){
            lyWrokflow.value.importJsonCode()
        }else if(flag==="delete"){
            lyWrokflow.value.clearFlowConfig()
        }else if(flag==="saveImg"){
            lyWrokflow.value.saveAsImg()
        }
        else if(flag==="newtab"){
            let currentPath = router.currentRoute.value.path
            let routeData = router.resolve({path:currentPath})
            window.open(routeData.href,'_blank')
        }
    }

    defineExpose({
        setFull
    })

</script>

<style scoped>
    .lyworkflowheader{
        display: flex;
        align-items: center;
        justify-content: space-between;
        background: var(--l-changetab-bg);
        height: 48px;
        line-height: 48px;
        border-bottom: 2px dotted var(--l-lyformbuilder-border-color);
    }
    .lyworkflowheader__title{
        font-size: 18px;
        color: var(--l-lyformbuilder-font-color);
        display: flex;
        align-items: center;
        justify-content: center;
    }
    .lyworkflowheader__title span:nth-child(2){
        font-weight: bold;
        font-size: 20px;
        margin: 0 6px 0 6px;
    }
    .lyworkflowheader__title span:last-child{
        font-size: 14px;
        margin-left: 6px;
    }
    .lyworkflowheader__dobutton{
        display: flex;
        align-items: center;
        font-size: 13px;
    }
    .lyworkflowdesign__main{
        background: var(--l-changetab-bg);
    }
    .el-container{
        height: 100%;
    }
    .lyworkflow_design_container{
        height: calc(100% - 48px);
    }
</style>