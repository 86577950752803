<!--
/**
 * 表单构建组件
 * version: 1.1
 * author: lybbn
 * program django-vue-lyadmin
 * email: 1042594286@qq.com
 * website: http://www.lybbn.cn
 * date: 2022.12.17
 * remark: 如果要分发django-vue-lyadmin源码或其中组件等，需在本文件顶部保留此文件头信息！！！
 */
-->
<template>
  <div :class="{'ly-is-full-design':isFull}" style="overflow: overlay;">
    <el-container class="lyformbuildermain">
      <el-header class="lyformbuilderheader">
        <div class="lyfbhtitle">
          <span style="display: flex"><el-icon size="32px" color="#409eff"><Cpu /></el-icon></span>
          <span>lyFormBuilder</span>
          <span>表单构建器</span>
          <span>Ver {{LYFORMBUILDER_VERSION}}</span>
        </div>
        <div class="lyfbhlink">
          <el-button type="primary" link @click="handleClick('importJson')" v-if="builderConfig.importJsonButton">
              <el-icon size="15px"><Upload/></el-icon>导入JSON
          </el-button>
          <el-button type="primary" link @click="handleClick('delete')" v-if="builderConfig.clearDesignerButton">
              <el-icon size="15px"><Delete/></el-icon>清空
          </el-button>
          <el-button type="primary" link @click="handleClick('preview')" v-if="builderConfig.previewFormButton">
              <el-icon size="15px"><View/></el-icon>预览
          </el-button>
          <el-button type="primary" link @click="handleClick('exportjson')" v-if="builderConfig.exportJsonButton">
              <el-icon size="15px"><Download/></el-icon>导出JSON
          </el-button>
          <el-button type="primary" link @click="handleClick('generateCode')" v-if="builderConfig.exportCodeButton">
              <el-icon size="15px"><Document/></el-icon>导出代码
          </el-button>
          <el-button type="primary" link @click="handleClick('saveForm')" v-if="builderConfig.exportCodeButton">
              <el-icon size="15px"><Select/></el-icon>保存表单
          </el-button>
<!--          <el-button type="primary" link @click="handleClick('generateSFC')" style="margin-right: 8px">-->
<!--              <el-icon size="15px"><Document/></el-icon>生成SFC-->
<!--          </el-button>-->
          <el-button type="primary" link @click="setFull" v-if="builderConfig.showFullButton">
              <el-icon size="15px"><full-screen/></el-icon>全屏
          </el-button>
          <el-button type="primary" link @click="handleClick('newtab')" v-if="builderConfig.openNewWindowButton">
              <el-icon size="15px"><ChromeFilled/></el-icon>新窗口打开
          </el-button>
          <el-button type="primary" link @click="handleClick('opendocment')" v-if="builderConfig.showDocumentButton">
              <el-icon size="15px"><Reading/></el-icon>文档
          </el-button>
        </div>
      </el-header>
      <el-container>
        <el-aside class="ly-left-pannel" >
          <lyWidgetPannel :isFull="isFull"></lyWidgetPannel>
          <div class="ly-left-pannel-resize shape" title="拖拽工具栏" @mousedown="leftDropResize($event)" v-show="!leftPannelHide" ref="lyLeftPannelLine">
			⋮
		  </div>
          <div :class="leftPannelHide?'container-left-arrow-hide':'container-left-arrow'" @click="handleChangeLeftPannel" title="收缩工具栏">
          </div>
        </el-aside>
        <el-container class="ly-center-containers">
          <el-main>
            <lyFormDesign :isFull="isFull"></lyFormDesign>
          </el-main>
        </el-container>
        <el-aside class="ly-right-containers">
          <lySettingPannel :isFull="isFull" :selected-widget="builderStore.selectedWidget" :form-config="builderStore.formConfig"></lySettingPannel>
        </el-aside>
      </el-container>
    </el-container>
    <lyDialog v-model="showImportJSONDialog" title="导入JSON" top="20px" width="60%" :before-close="handleDialogImportJSONClose" :destroy-on-close="true" :append-to-body="true">
      <div>
        <el-alert title="请按如下格式导入，否则可能报错无法正常导入！！！" type="info"  show-icon />
        <lyCodeEditor ref="lyFormImportJSON" v-model="importJsonContent" mode="javascript" height="600" :read-only="false"></lyCodeEditor>
      </div>
      <template #footer>
        <el-button type="primary" @click="saveImportJson">导入</el-button>
        <el-button @click="handleDialogImportJSONClose">关闭</el-button>
        <!-- 自定义dialog底部按钮 -->
        <slot name="customDialogHandle"></slot>
      </template>
    </lyDialog>
    <lyDialog v-model="showPreviewDialog" v-if="showPreviewDialog" title="预览" top="20px" width="60%" :before-close="handleDialogClose">
      <div>
        <lyFormRender ref="lyFormPreview" :form-json="formJson" :preview-state="true"></lyFormRender>
      </div>
      <template #footer>
        <el-button @click="handleDialogClose">关闭</el-button>
        <!-- 自定义dialog底部按钮 -->
        <slot name="customDialogHandle"></slot>
      </template>
    </lyDialog>
    <lyDialog v-model="showExportJSONDialog" title="导出JSON" top="20px" width="60%" :before-close="handleDialogJSONClose" :destroy-on-close="true" :append-to-body="true">
      <div>
        <lyCodeEditor ref="lyFormExportJSON" v-model="jsonContent" mode="javascript" height="600" :read-only="true"></lyCodeEditor>
      </div>
      <template #footer>
        <el-button type="primary"  @click="copyFormJson">复制JSON</el-button>
        <el-button  @click="saveJSONCode">保存为文件</el-button>
        <el-button @click="handleDialogJSONClose">关闭</el-button>
        <!-- 自定义dialog底部按钮 -->
        <slot name="customDialogHandle"></slot>
      </template>
    </lyDialog>
    <lyDialog v-model="showExportCodeDialog" title="导出代码" width="60%" top="20px" :before-close="handleDialogVueCodeClose" :destroy-on-close="true" :append-to-body="true">
      <div>
        <el-tabs v-model="exportCodeActiveName">
          <el-tab-pane label="场景一：作为弹窗组件" :name="1">
            <el-link :underline="false" icon="DocumentCopy"  @click="copyCode(vueCodeContent)" style="position:absolute;top: 10px;right: 2vh;z-index: 3333;" type="primary">复制</el-link>
            <lyCodeEditor ref="lyFormExportVueCode" v-model="vueCodeContent" mode="vue" height="600" :read-only="true"></lyCodeEditor>
          </el-tab-pane>
          <el-tab-pane :label="'场景二：CRUD-VUE前端'+curdCodeContent[3].file" :name="2" v-if="curdCodeContent.length>0">
            <el-link :underline="false" icon="DocumentCopy"  @click="copyCode(curdCodeContent[3].code)" style="position:absolute;top: 10px;right: 2vh;z-index: 3333;" type="primary">复制</el-link>
            <lyCodeEditor ref="lyFormExportVueCode" v-model="curdCodeContent[3].code" mode="vue" height="600" :read-only="true"></lyCodeEditor>
          </el-tab-pane>
          <el-tab-pane :label="'场景二：CRUD-后端'+curdCodeContent[0].file" :name="3" v-if="curdCodeContent.length>0">
            <el-link :underline="false" icon="DocumentCopy"  @click="copyCode(curdCodeContent[0].code)" style="position:absolute;top: 10px;right: 2vh;z-index: 3333;" type="primary">复制</el-link>
            <lyCodeEditor ref="lyFormExportVueCode" v-model="curdCodeContent[0].code" mode="python" height="600" :read-only="true"></lyCodeEditor>
          </el-tab-pane>
          <el-tab-pane :label="'场景二：CRUD-后端'+curdCodeContent[1].file" :name="4" v-if="curdCodeContent.length>0">
            <el-link :underline="false" icon="DocumentCopy"  @click="copyCode(curdCodeContent[1].code)" style="position:absolute;top: 10px;right: 2vh;z-index: 3333;" type="primary">复制</el-link>
            <lyCodeEditor ref="lyFormExportVueCode" v-model="curdCodeContent[1].code" mode="python" height="600" :read-only="true"></lyCodeEditor>
          </el-tab-pane>
          <el-tab-pane :label="'场景二：CRUD-后端'+curdCodeContent[2].file" :name="5" v-if="curdCodeContent.length>0">
            <el-link :underline="false" icon="DocumentCopy"  @click="copyCode(curdCodeContent[2].code)" style="position:absolute;top: 10px;right: 2vh;z-index: 3333;" type="primary">复制</el-link>
            <lyCodeEditor ref="lyFormExportVueCode" v-model="curdCodeContent[2].code" mode="python" height="600" :read-only="true"></lyCodeEditor>
          </el-tab-pane>
        </el-tabs>

      </div>
      <template #footer>
<!--        <el-button type="primary"  @click="copyFormVueCode">复制代码</el-button>-->
<!--        <el-button  @click="saveVueCode">保存Vue文件</el-button>-->
        <el-button @click="handleDialogVueCodeClose">关闭</el-button>
        <!-- 自定义dialog底部按钮 -->
        <slot name="customDialogHandle"></slot>
      </template>
    </lyDialog>
    <lyDialog v-model="showGenerateSFCDialog" title="生成SFC" top="20px" width="60%" :before-close="handleGenerateSFCClose" :destroy-on-close="true" :append-to-body="true">
      <div>
        <lyCodeEditor ref="lyFormGenerateSFCCode" v-model="sfcCodeContent" mode="vue"  height="600" :read-only="true"></lyCodeEditor>
      </div>
      <template #footer>
        <el-button type="primary"  @click="copySFCCode">复制代码</el-button>
        <el-button  @click="saveVueCode">保存Vue文件</el-button>
        <el-button @click="handleGenerateSFCClose">关闭</el-button>
        <!-- 自定义dialog底部按钮 -->
        <slot name="customDialogHandle"></slot>
      </template>
    </lyDialog>

  </div>
</template>

<script setup>
  import {ref, onMounted ,reactive,provide,watch,computed,defineAsyncComponent} from 'vue'
  import lyWidgetPannel from '@/components/lyform-builder/lywidget-pannel/lyWidgetPannel'
  import lySettingPannel from '@/components/lyform-builder/lysetting-pannel/lySettingPannel'
  import lyFormDesign from './lyform-design/lyFormDesign'
  import {LYFORMBUILDER_VERSION} from "@/components/lyform-builder/config"
  import {deepClone,randomId} from '@/utils/util'
  import lyDialog from '@/components/dialog/dialog'
  import lyFormRender from '@/components/lyform-builder/lyform-render/index'
  import {lyGenerateCode} from '@/components/lyform-builder/lygenerate/lycode-generate'
  import { ElMessage,ElMessageBox } from 'element-plus'
  import useClipboard from "vue-clipboard3";
  import { useBuilderStore } from "@/store/lyFormBuilder";
  import { useSiteThemeStore } from "@/store/siteTheme";
  import {useRouter } from 'vue-router'
  import { saveAs } from 'file-saver'
  import {lyformbuilderPreviewcodejson,lyformbuilderAdd,lyformbuilderEdit,lyformbuilderDetail} from '@/api/api'

  const props = defineProps({
    builderConfig :{
      type:Object,
      default:{
        showFormTemplates:true,//是否显示表单模板
        showEventCollapse:true,//是否显示组件事件属性折叠面板
        showDocumentButton:true,//是否显示文档按钮
        clearDesignerButton: true,  //是否显示清空设计器按钮
        previewFormButton: true,  //是否显示预览表单按钮
        exportJsonButton: true,  //是否显示导出JSON器按钮
        exportCodeButton: true,  //是否显示导出代码按钮
        importJsonButton:true, //是否显示导入代码按钮
        showFullButton: true,  //是否显示全屏按钮
        openNewWindowButton: true,  //是否显示新窗口按钮
      }
    },

  })

  const lyCodeEditor = defineAsyncComponent(() => import('@/components/lyform-builder/code-editor'));
  const router = useRouter()
  const isFull = ref(false)
  let showImportJSONDialog = ref(false)
  let showPreviewDialog = ref(false)
  let showExportJSONDialog = ref(false)
  let showExportCodeDialog = ref(false)
  let showGenerateSFCDialog = ref(false)

  const builderStore = useBuilderStore()
  builderStore.builderConfig = props.builderConfig

  const formJson = computed(() => {
    return {
      widgetList: deepClone(builderStore.widgetList),
      formConfig: deepClone(builderStore.formConfig)
    }
  })
  
  function handleDialogClose() {
    showPreviewDialog.value = false
  }
  function handleDialogImportJSONClose() {
    showImportJSONDialog.value = false
  }
  function handleDialogJSONClose() {
    showExportJSONDialog.value = false
  }
  function handleDialogVueCodeClose() {
    showExportCodeDialog.value = false
    exportCodeActiveName.value = 1
  }
  function handleGenerateSFCClose() {
    showGenerateSFCDialog.value = false
  }

  const siteThemeStore = useSiteThemeStore()
  let leftMenuWidth = computed(() => {
    return siteThemeStore.menuWidth
  })
  let leftPannelHide = ref(false)
  var baseLeftPannelWidth = "300px"
  let leftPannelWidth = ref(baseLeftPannelWidth)
  let leftPannelHideToolLeft = ref(baseLeftPannelWidth)
  function handleChangeLeftPannel(){
    leftPannelHide.value = !leftPannelHide.value
    if(leftPannelHide.value){
      baseLeftPannelWidth = leftPannelWidth.value
      leftPannelWidth.value = "0px"
    }else{
      leftPannelWidth.value = baseLeftPannelWidth
    }
  }
  let lyLeftPannelLine = ref(null)
  let lyLeftPannelLineState = ref(false)//标志鼠标是否在当前dom按下，避免没有按下鼠标也触发mousemove事件的问题出现
  function leftDropResize(e) {
    lyLeftPannelLineState.value = true
    var startX = e.pageX;
    var line_left =  lyLeftPannelLine.value.offsetLeft
    document.onselectstart = function() { return false; };//解决拖动会选中文字的问题
    document.ondragstart = function() { return false; };
    if (e.preventDefault) {
      e.preventDefault();
    } else{
      e.returnValue=false;
    }
    document.onmousemove = function (ev) {
      //如果没有按下鼠标，就不用执行后边的代码
      if(!lyLeftPannelLineState.value) return;
      var event=ev||window.event;
      var endX = event.pageX;
      var moveLen = endX - startX
      let clientX = line_left + moveLen
      // 最大宽度
      if(clientX>=520){
        clientX = 520;
      }
      // 最小宽度
      if(clientX<=300){
        clientX = 300;
      }
      leftPannelWidth.value = clientX +"px";
      leftPannelHideToolLeft.value = clientX +"px"
    }

    document.onmouseup = function () {
      if(!lyLeftPannelLineState.value) return;
      document.onmousemove = null;
      document.onmouseup = null;
      document.onselectstart = null;
      document.ondragstart = null;
      lyLeftPannelLineState.value = false;
    }
  }

  function setFull(){
    isFull.value=!isFull.value
    window.dispatchEvent(new Event('resize'))
    if(isFull.value){
      leftPannelHideToolLeft.value = leftPannelWidth.value
    }else{
      leftPannelHideToolLeft.value = parseInt(leftPannelWidth.value) +"px"
    }
  }

  let exportCodeActiveName = ref(1)
  let jsonContent = ref("")
  let importJsonContent = ref("")
  let vueCodeContent = ref("")
  let curdCodeContent = ref([])
  let lyFormExportVueCode = ref(null)
  let sfcCodeContent = ref("")
  function handleClick(flag) {
    if(flag === "generateCode"){
      if(formJson.value.formConfig.modelDbTable === "" || formJson.value.formConfig.modelClassName === "" || formJson.value.formConfig.modelVerboseName === ""){
        ElMessageBox.confirm('表单的CRUD属性参数为空，只能生成弹窗组件代码，无法生成CRUD代码，确定要生成代码吗？','提示',{
          confirmButtonText: "确定",
          cancelButtonText: "取消",
          type: "warning"
        }).then(res=>{
          showExportCodeDialog.value = true
          vueCodeContent.value = lyGenerateCode(formJson.value)
          getCrudCode()
        }).catch(()=>{})
      }else{
        showExportCodeDialog.value = true
        vueCodeContent.value = lyGenerateCode(formJson.value)
        getCrudCode()
      }
    }
    else if(flag === "saveForm"){
      if(formJson.value.formConfig.modelDbTable === "" || formJson.value.formConfig.modelClassName === "" || formJson.value.formConfig.modelVerboseName === ""){
        messageConfirmBox("提示", "表单的CRUD属性参数为空，请填写表单CRUD属性再保存！！！", "warning");
      }else{
        ElMessageBox.confirm('该操作会保存此表单到数据库【表单模板】中，是否确定此操作！！！','提示',{
          confirmButtonText: "确定",
          cancelButtonText: "取消",
          type: "warning"
        }).then(red=>{
          if(!!builderStore.formTemplateID && builderStore.formTemplateID != null && builderStore.formTemplateID != 'null'){
            lyformbuilderEdit({id:builderStore.formTemplateID,verbose_name:formJson.value.formConfig.modelVerboseName,class_name:formJson.value.formConfig.modelClassName,db_table:formJson.value.formConfig.modelDbTable,formJson:formJson.value}).then(res=>{
              if(res.code === 2000){
                builderStore.saveCurrentHistoryStep()
                messageConfirmBox("保存成功", "请到【表单模板】中查看！！！", "success");
              }else{
                ElMessage.warning(res.msg)
              }
            })
          }else{
            lyformbuilderAdd({verbose_name:formJson.value.formConfig.modelVerboseName,class_name:formJson.value.formConfig.modelClassName,db_table:formJson.value.formConfig.modelDbTable,formJson:formJson.value}).then(res=>{
              if(res.code === 2000){
                builderStore.formTemplateID = res.data.id
                builderStore.saveCurrentHistoryStep()
                messageConfirmBox("保存成功", "请到【表单模板】中查看！！！", "success");
                // setTimeout(function(){
                //     router.push({name:'lyFormBuilders',query:{id:res.data.id}})
                // },2000);
              }else{
                ElMessage.warning(res.msg)
              }
            })
          }
        }).catch(()=>{})
      }
    }
    else if(flag === "generateSFC"){
      console.log("生成sfc")
    }
    else if(flag==="importJson"){
      importJsonContent.value = JSON.stringify(builderStore.getDefaultJsonTemplate(),null, '  ')
      showImportJSONDialog.value = true
    }
    else if(flag==="exportjson"){
      showExportJSONDialog.value = true
      let widgetList = builderStore.widgetList
      let formConfig = builderStore.formConfig
      jsonContent.value = JSON.stringify({widgetList, formConfig}, null, '  ')

      // console.log(jsonContent.value)
    }else if(flag==="opendocment"){
      window.open("https://doc.lybbn.cn")
    }else if(flag==="delete"){
      ElMessageBox.confirm('确定要清空吗？',{
          confirmButtonText: "确定",
          cancelButtonText: "取消",
          type: "warning"
      }).then(res=>{
           builderStore.clearBuilder()
      }).catch(()=>{})
    }else if(flag==="preview"){
      showPreviewDialog.value = true
    }else if(flag==="newtab"){
      let currentPath = router.currentRoute.value.path
      let routeData = router.resolve({path:currentPath})
      window.open(routeData.href,'_blank')
    }
  }

  function messageConfirmBox(tips="提示",content="",type="warning"){
    ElMessageBox.confirm(content,tips,{
      showCancelButton: false,
      confirmButtonText: '确定',
      type:type,
    }).then(() => {
    }).catch(() => {
    });
  }

  function getCrudCode() {
    curdCodeContent.value = []
    lyformbuilderPreviewcodejson({formJson:{widgetList: builderStore.widgetList,formConfig: builderStore.formConfig}}).then(res=>{
      if(res.code == 2000){
        curdCodeContent.value = res.data.data
      }else{
        ElMessage.warning(res.msg)
      }
    })
  }

  function getLyformBuilderDetail(id) {
    lyformbuilderDetail({id:id}).then(res=>{
      if(res.code === 2000){
        try {
          let importObj= res.data.data.formJson
          if (!importObj|| !importObj.formConfig) {
            throw new Error("formConfig配置错误")
          }
          let lyJsonVersion = importObj.formConfig.jsonVersion
          if (!lyJsonVersion || (lyJsonVersion !== 1)) {
            throw new Error("导入json版本错误")
          }
          builderStore.importFormJson(importObj)
          ElMessage.success("导入表单模板配置成功")
          builderStore.emitHistoryChange()
        } catch(ex) {
          ElMessage.error(ex + '')
        }
      }else{
        ElMessage.warning(res.msg)
      }
    })
  }

  const { toClipboard } = useClipboard()
  function copyCode(code) {
      toClipboard(code).then(()=>{
          ElMessage.success("复制成功")
      }).catch(()=>{
          ElMessage.warning("复制失败")
      })
  }
  function copyFormVueCode(e) {
    toClipboard(vueCodeContent.value).then(()=>{
      ElMessage.success("复制成功")
    }).catch(()=>{
      ElMessage.warning("复制失败")
    })
  }
  function copySFCCode(e) {
    toClipboard(sfcCodeContent.value).then(()=>{
      ElMessage.success("复制成功")
    }).catch(()=>{
      ElMessage.warning("复制失败")
    })
  }
  function copyFormJson(e) {
    toClipboard(jsonContent.value).then(()=>{
      ElMessage.success("复制成功")
    }).catch(()=>{
      ElMessage.warning("复制失败")
    })
  }

  function saveAsFile(fileContent, defaultFileName) {
    ElMessageBox.prompt("文件名", "保存为文件", {
        inputValue: defaultFileName,
        closeOnClickModal: false,
        inputPlaceholder: "请输入文件名"
    }).then(({ value }) => {
    if (!value) {
        value = defaultFileName
    }

    const fileBlob = new Blob([fileContent], { type: 'text/plain;charset=utf-8' })
    saveAs(fileBlob ,value)
    }).catch(() => {
    //
    })
  }

  //保存json导入
  function saveImportJson(){
    try {
      let importObj= JSON.parse(importJsonContent.value)
      if (!importObj|| !importObj.formConfig) {
        throw new Error("formConfig配置错误")
      }
      let lyJsonVersion = importObj.formConfig.jsonVersion
      if (!lyJsonVersion || (lyJsonVersion !== 1)) {
        throw new Error("导入json版本错误")
      }
      builderStore.importFormJson(importObj)
      showImportJSONDialog.value = false
      ElMessage.success("导入JSON配置成功")
      builderStore.emitHistoryChange()
    } catch(ex) {
      ElMessage.error(ex + '')
    }
  }

  function saveJSONCode(){
    saveAsFile(jsonContent.value, `dvlyadmin_pro_formData_${randomId()}.json`)
  }

  function saveVueCode(){
    saveAsFile(vueCodeContent.value, `dvlyadmin_pro_formData_${randomId()}.vue`)
  }

  onMounted(()=>{
    if(!!router.currentRoute.value.query.id){
      let id = router.currentRoute.value.query.id
      getLyformBuilderDetail(id)
      builderStore.formTemplateID = id
      builderStore.saveCurrentHistoryStep()
    }else if(!!builderStore.formTemplateID && builderStore.formTemplateID!='null' && builderStore.formTemplateID!='undefined'){
      getLyformBuilderDetail(builderStore.formTemplateID)
    }
  })

  defineExpose({
      setFull
  });

</script>

<style lang="scss" scoped>
  // 设置元素不可复制 解决了拖动时会拖掉的问题
  .shape {
    -moz-user-select:none; /* Firefox私有属性 */
    -webkit-user-select:none; /* WebKit内核私有属性 */
    -ms-user-select:none; /* IE私有属性(IE10及以后) */
    -khtml-user-select:none; /* KHTML内核私有属性 */
    -o-user-select:none; /* Opera私有属性 */
    user-select:none; /* CSS3属性 */
  }
  .lyformbuilderheader{
    display: flex;
    align-items: center;
    justify-content: space-between;
    background: var(--l-changetab-bg);
    height: 48px;
    line-height: 48px;
    min-width: 1100px;
    border-bottom: 2px dotted var(--l-lyformbuilder-border-color);
  }
  .lyfbhtitle{
    font-size: 18px;
    color: var(--l-lyformbuilder-font-color);
    display: flex;
    align-items: center;
    justify-content: center;
    span:nth-child(2){
      font-weight: bold;
      font-size: 20px;
      margin: 0 6px 0 6px;
    }
    span:last-child{
      font-size: 14px;
      margin-left: 6px;
    }
  }
  .lyfbhlink{
    display: flex;
    align-items: center;
    font-size: 13px;
  }
  .ly-left-pannel{
    width: v-bind(leftPannelWidth);
    /*overflow-y: hidden;*/
    position: relative;
    overflow: revert;
    /*transition: width .3s;*/
    .ly-left-pannel-resize{
      cursor: col-resize;
      position: absolute;
      top: 55%;
      background-color: white;
      border-radius: 5px;
      box-sizing: border-box;
      border-right: 1px solid #e0e0e0;
      width: 12px;
      height: 60px;
      line-height: 50px;
      background-size: cover;
      background-position: center;
      font-size: 32px;
      color: #a1a6b3;
      z-index: 101;
      left: v-bind(leftPannelHideToolLeft);
    }
    /*拖拽区鼠标悬停样式*/
    .ly-left-pannel-resize:hover {
        color: #444445;
    }
    .container-left-arrow{
      position: absolute;
      width: 12px;
      height: 60px;
      top: 40%;
      left: v-bind(leftPannelHideToolLeft);
      z-index: 101;
      cursor: pointer;
      content: "";
      border-radius: 0 5px 5px 0;
      box-sizing: border-box;
      border-right: 1px solid #e0e0e0;
      background: #fff;
      /*transform: perspective(50px) rotateY(30deg);*/
      /*transition: all .3s;*/
    }
    .container-left-arrow:after {
        content: "";
        display: block;
        position: absolute;
        width: 0px;
        height: 0px;
        border-width: 7px;
        cursor: pointer;
        border-color: transparent;
        border-right-color: #a1a6b3;
        border-style: solid;
        left: 20%;
        top: 50%;
        -webkit-mask-size: 100% 100%;
        mask-size: 100% 100%;
        -webkit-mask-repeat: no-repeat;
        mask-repeat: no-repeat;
        transform: translate(-50%,-50%) rotate(0);
    }
    .container-left-arrow-hide{
        position: absolute;
        width: 12px;
        height: 60px;
        top: 40%;
        z-index: 101;
        cursor: pointer;
        content: "";
        border-radius: 0 5px 5px 0;
        box-sizing: border-box;
        border-right: 1px solid #e0e0e0;
        background: #fff;
        transform: perspective(50px) rotateY(30deg);
        /*transition: all .3s;*/
    }
    .container-left-arrow-hide:after {
        content: "";
        display: block;
        position: absolute;
        width: 0px;
        height: 0px;
        border-width: 7px;
        cursor: pointer;
        border-color: transparent;
        border-right-color: #a1a6b3;
        border-style: solid;
        left: 70%;
        top: 50%;
        -webkit-mask-size: 100% 100%;
        mask-size: 100% 100%;
        -webkit-mask-repeat: no-repeat;
        mask-repeat: no-repeat;
        transform: translate(-50%,-50%) rotate(180deg);
    }
  }
  .ly-right-containers{
    width: 300px!important;
    overflow-y: hidden;
  }
  .ly-center-containers{
    min-width: 500px;
    border-left: 2px dotted var(--l-lyformbuilder-border-color);
    border-right: 2px dotted var(--l-lyformbuilder-border-color);
    ::v-deep(.el-main){
      padding: 0px;
    }
  }
</style>