<template>
    <div>
        <el-form size="small" label-position="left" label-width="100px" class="lywidgetsetting" @submit.prevent>
            <el-collapse v-model="lyCollapseActiveList" class="lyformsetting-collapse">
                <el-collapse-item name="lyws01" title="组件属性">
                    <el-form-item label="字段标识">
                        <el-input  v-model.trim="optionModel.name" @change="handleChange"></el-input>
                    </el-form-item>
                    <el-form-item label="隐藏">
                        <el-switch v-model="optionModel.hidden" @change="handleChange"></el-switch>
                    </el-form-item>
                    <el-form-item label="禁用">
                        <el-switch v-model="optionModel.disabled" @change="handleChange"></el-switch>
                    </el-form-item>
                    <el-form-item label="只读">
                        <el-switch v-model="optionModel.readonly" @change="handleChange"></el-switch>
                    </el-form-item>
                    <el-form-item label="可清除">
                        <el-switch v-model="optionModel.clearable" @change="handleChange"></el-switch>
                    </el-form-item>
                    <el-form-item label="可输入">
                        <el-switch v-model="optionModel.editable" @change="handleChange"></el-switch>
                    </el-form-item>
                    <el-form-item label="自适应宽度">
                        <el-switch v-model="optionModel.autoFullWidth" @change="handleChange"></el-switch>
                    </el-form-item>
                    <el-form-item label="标签文本">
                        <el-input v-model="optionModel.label"  style="width: 100%" @change="handleChange"></el-input>
                    </el-form-item>
                    <el-form-item label="标签宽度">
                        <el-input v-model="optionModel.labelWidth"  style="width: 100%" @change="handleChange"></el-input>
                    </el-form-item>
                    <el-form-item label="隐藏字段标签">
                        <el-switch v-model="optionModel.labelHidden" @change="handleChange"></el-switch>
                    </el-form-item>
                    <el-form-item label="显示类型">
                        <el-select v-model="optionModel.type" @change="handleChange">
                            <el-option label="datetime" value="datetime"></el-option>
                            <el-option label="date" value="date"></el-option>
                            <el-option label="dates" value="dates"></el-option>
                            <el-option label="year" value="year"></el-option>
                            <el-option label="month" value="month"></el-option>
                            <el-option label="week" value="week"></el-option>
                        </el-select>
                    </el-form-item>
                    <el-form-item label="默认值">
                        <el-date-picker :type="optionModel.type" v-model="optionModel.defaultValue" @change="handleChange" style="width: 100%"  :format="optionModel.format" :value-format="optionModel.valueFormat"></el-date-picker>
                    </el-form-item>
                    <el-form-item label="占位提示">
                        <el-input v-model="optionModel.placeholder"  style="width: 100%" @change="handleChange"></el-input>
                    </el-form-item>
                    <el-form-item label="组件大小">
                        <el-select v-model="optionModel.size" @change="handleChange">
                            <el-option v-for="item in formSizes" :key="item.value" :label="item.label"
                            :value="item.value">
                            </el-option>
                        </el-select>
                    </el-form-item>
                    <el-form-item label="必填字段">
                        <el-switch v-model="optionModel.required" @change="handleChange"></el-switch>
                    </el-form-item>
                    <el-form-item label="必填校验提示">
                        <el-input v-model="optionModel.requiredHint"  style="width: 100%" @change="handleChange"></el-input>
                    </el-form-item>
                    <validationCommonLy :selected-widget="selectedWidget" :option-model="optionModel"></validationCommonLy>
                    <el-form-item label="校验失败提示">
                        <el-input v-model="optionModel.validationHint"  style="width: 100%" @change="handleChange"></el-input>
                    </el-form-item>
                    <customClassLy :selected-widget="selectedWidget" :option-model="optionModel"></customClassLy>
                    <el-form-item label-width="0">
                        <el-divider>高级属性</el-divider>
                    </el-form-item>
                    <el-form-item label="显示格式">
                        <el-select v-model="optionModel.format" filterable allow-create @change="handleChange">
                            <el-option label="YYYY" value="YYYY"></el-option>
                            <el-option label="YYYY-MM" value="YYYY-MM"></el-option>
                            <el-option label="YYYY/MM/DD" value="YYYY/MM/DD"></el-option>
                            <el-option label="YYYY-MM-DD" value="YYYY-MM-DD"></el-option>
                            <el-option label="YYYY年MM月DD日" value="YYYY年MM月DD日"></el-option>
                            <el-option label="YYYY-MM-DD HH:mm:ss" value="YYYY-MM-DD HH:mm:ss"></el-option>
                            <el-option label="YYYY-MM-DD hh:mm:ss" value="YYYY-MM-DD hh:mm:ss"></el-option>
                        </el-select>
                    </el-form-item>
                    <el-form-item label="绑定值格式">
                        <el-select v-model="optionModel.valueFormat" filterable allow-create @change="handleChange">
                            <el-option label="YYYY" value="YYYY"></el-option>
                            <el-option label="YYYY-MM" value="YYYY-MM"></el-option>
                            <el-option label="YYYY-MM-DD" value="YYYY-MM-DD"></el-option>
                            <el-option label="YYYY-MM-DD HH:mm:ss" value="YYYY-MM-DD HH:mm:ss"></el-option>
                        </el-select>
                    </el-form-item>
                </el-collapse-item>
                <el-collapse-item name="lyws02" title="事件属性" v-if="showEventCollapse">
                    <el-form-item label="onChange" label-width="150px">
                        <el-button type="info" icon="edit" plain round @click="editFormEventHandler('onChange',['value'])">编写代码</el-button>
                    </el-form-item>
                    <el-form-item label="onFocus" label-width="150px">
                        <el-button type="info" icon="edit" plain round @click="editFormEventHandler('onFocus',['event'])">编写代码</el-button>
                    </el-form-item>
                    <el-form-item label="onBlur" label-width="150px">
                        <el-button type="info" icon="edit" plain round @click="editFormEventHandler('onBlur',['event'])">编写代码</el-button>
                    </el-form-item>
                </el-collapse-item>
                <el-collapse-item name="lyws03" title="CRUD属性">
                    <template #title>
                        <div style="display: flex;align-items: center;">
                            CRUD属性
                            <el-tooltip effect="dark" content="后端自动生成CRUD代码，用于控制是否列表显示、可查询、可空、可编辑等属性" placement="top-start">
                                <el-icon><QuestionFilled /></el-icon>
                            </el-tooltip>
                        </div>
                    </template>
                    <crudCommonLy :selected-widget="selectedWidget" :option-model="optionModel"></crudCommonLy>
                </el-collapse-item>
            </el-collapse>
            <lyDialog v-model="showFormEventDialog" title="组件事件" width="60%" top="20px" :before-close="handleFormEventHandlerClose">
                <div>
                    <el-alert :title="currentEventNameTitle" type="info" :closable="false" />
                    <lyCodeEditor ref="lyFormEditCss" v-model="formEventCode" mode="javascript" height="600" :read-only="false"></lyCodeEditor>
                    <el-alert title="}"  :closable="false" type="info"></el-alert>
                </div>
                <template v-slot:footer>
                    <el-button @click="handleFormEventHandlerClose" size="default">关闭</el-button>
                    <el-button type="primary" @click="saveFormEvent" size="default">确定</el-button>
                </template>
            </lyDialog>
        </el-form>
    </div>
</template>

<script setup>
    import {ref, onMounted, reactive, computed, watch,defineAsyncComponent} from 'vue'
    import { useBuilderStore } from "@/store/lyFormBuilder";
    import validationCommonLy from "@/components/lyform-builder/lysetting-pannel/lywidget-common/validation-common-ly"
    import customClassLy from "@/components/lyform-builder/lysetting-pannel/lywidget-common/custom-class-ly"
    import crudCommonLy from "@/components/lyform-builder/lysetting-pannel/lywidget-common/crud-common-ly"
    import lyDialog from '@/components/dialog/dialog'
    const lyCodeEditor = defineAsyncComponent(() => import('@/components/lyform-builder/code-editor'));

    const props = defineProps({
        selectedWidget: Object,
        optionModel: Object,
    })
    const builderStore = useBuilderStore()
    function handleChange(newValue) {
         builderStore.saveCurrentHistoryStep()
    }
    let lyCollapseActiveList =  ref(['lyws01','lyws02','lyws03'])
    const formSizes = ref([
        {label: 'default', value: ''},
        {label: 'large', value: 'large'},
        {label: 'small', value: 'small'},
    ])
    function showEventCollapse(){
        if (builderStore.builderConfig['showEventCollapse'] === undefined) {
          return true
        }
        return !!builderStore.builderConfig['showEventCollapse']
    }

    let currentEventName = ref("")//编辑选中当前事件名称
    let currentEventNameTitle = ref("")//编辑框提示事件函数全名
    let showFormEventDialog = ref(false)
    let formEventCode = ref("")
    function editFormEventHandler(flag,eventParamsName){//flag事件名、eventParamsName事件回调参数名使用数组[]
        currentEventName.value = flag
        currentEventNameTitle.value = `${props.optionModel.name}.${flag}(${eventParamsName.join(', ')}) {`
        formEventCode.value = props.selectedWidget.options[flag] || ''
        showFormEventDialog.value = true
    }
    function handleFormEventHandlerClose(){
        showFormEventDialog.value = false
    }
    function saveFormEvent(){
        builderStore.selectedWidget.options[currentEventName.value] = formEventCode.value
        builderStore.saveCurrentHistoryStep()
        showFormEventDialog.value = false
    }

</script>

<style lang="scss" scoped>
    .lywidgetsetting{
        ::v-deep(.el-form-item__label) {
          font-size: 13px;
          overflow: hidden;
          white-space: nowrap;
        }
        .lyformsetting-collapse{
            ::v-deep(.el-collapse-item__header) {
                font-weight: bold;
            }
        }
    }

</style>