<template>
    <div>
        <ly-dialog v-model="dialogVisible" :title="loadingTitle" width="560px" :before-close="handleClose">
            <el-form :inline="false" :model="formData" :rules="rules" ref="rulesForm" label-position="right" label-width="auto">
                <el-form-item label="排序：" prop="sort">
                    <el-input-number style="width:100%" v-model="formData.sort" placeholder="数字越大越靠前"></el-input-number>
                </el-form-item>
            </el-form>
            <template #footer>
                <el-button @click="handleClose" :loading="loadingSave">取消</el-button>
                <el-button type="primary" @click="submitData" :loading="loadingSave">确定</el-button>
            </template>
        </ly-dialog>
    </div>
</template>

<script>
    import LyDialog from "@/components/dialog/dialog";
    export default {
        name: "editSortModule",
        components: {LyDialog},
        emits: ['refreshData'],
        data() {
            return {
                dialogVisible:false,
                loadingSave:false,
                loadingTitle:'',
                formData:{
                    id:"",
                    sort:null
                },
                rules:{
                },
                apiObj:null,//api
            }
        },
        methods:{
            handleClose() {
                this.dialogVisible=false
                this.loadingSave=false
                this.$emit('refreshData')
            },
            addModuleFn(item,flag,apiObj) {
                this.loadingTitle=flag
                this.dialogVisible=true
                if(item){
                    this.formData.id = item.id
                    this.formData.sort = item.sort
                    this.apiObj = apiObj
                }
            },
            submitData() {
                this.$refs['rulesForm'].validate(obj=>{
                    if(obj) {
                        this.loadingSave=true
                        let param = {
                            ...this.formData
                        }
                        this.apiObj(param).then(res=>{
                            this.loadingSave=false
                            if(res.code ==2000) {
                                this.$message.success(res.msg)
                                this.handleClose()
                            } else {
                                this.$message.warning(res.msg)
                            }
                        })

                    }
                })
            },
        }
    }
</script>
<style scoped>
</style>

