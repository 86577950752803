<template>
  <div>
    <div :class="{'ly-is-full':isFull2}">
      <ly-dialog v-model="dialogVisible" :title="loadingTitle" width="80%" @closed="handleClose">
        <el-table :height="tableHeight2" border :data="tableData2" ref="tableref" v-loading="loadingPage2"
                  style="width: 100%">
          <el-table-column type="index" width="60" align="center" label="序号">
            <template #default="scope">
              <span v-text="getIndex(scope.$index)"></span>
            </template>
          </el-table-column>
          <el-table-column min-width="100" prop="request_modular" label="请求模块"
                           show-overflow-tooltip></el-table-column>
          <el-table-column min-width="160" prop="request_path" label="请求地址" show-overflow-tooltip></el-table-column>
          <el-table-column width="100" prop="request_method" label="请求方法" show-overflow-tooltip></el-table-column>
          <el-table-column min-width="100" prop="request_ip" label="IP地址" show-overflow-tooltip></el-table-column>
          <el-table-column min-width="130" prop="request_browser" label="请求浏览器"
                           show-overflow-tooltip></el-table-column>
          <el-table-column width="90" prop="request_body" label="请求数据">
            <template #default="scope">
              <div>
                <el-popover v-if="scope.row.request_body" placement="left-start" trigger="click" width="450px">
                  <div class="lypopoverbox">
                    <pre>{{ formatBody(scope.row.request_body) }}</pre>
                  </div>
                  <template #reference>
                    <el-icon style="cursor: pointer;">
                      <warning/>
                    </el-icon>
                  </template>
                </el-popover>
                <span v-else>无</span>
              </div>
            </template>
          </el-table-column>
          <el-table-column width="80" prop="response_code" label="响应码" show-overflow-tooltip>
            <template #default="scope">
              <el-tag :type="scope.row.response_code === '2000'?'success':'warning'">{{
                  scope.row.response_code
                }}
              </el-tag>
            </template>
          </el-table-column>
          <el-table-column width="90" prop="json_result" label="返回信息">
            <template #default="scope">
              <div>
                <el-popover v-if="scope.row.json_result" placement="left-start" trigger="click" width="450px">
                  <div class="lypopoverbox">
                    <pre>{{ formatBody(scope.row.json_result) }}</pre>
                  </div>
                  <template #reference>
                    <el-icon style="cursor: pointer;">
                      <warning/>
                    </el-icon>
                  </template>
                </el-popover>
                <span v-else>无</span>
              </div>
            </template>
          </el-table-column>
          <el-table-column width="150" prop="creator_name" label="操作人"></el-table-column>
          <el-table-column width="170" prop="create_datetime" label="创建时间" show-overflow-tooltip></el-table-column>
          <el-table-column label="操作" fixed="right" width="120">
            <template #header>
              <div style="display: flex;justify-content: space-between;align-items: center;">
                <div>操作</div>
                <div @click="setFull">
<!--                  <el-tooltip content="全屏" placement="bottom">-->
<!--                    <el-icon>-->
<!--                      <full-screen/>-->
<!--                    </el-icon>-->
<!--                  </el-tooltip>-->
                </div>
              </div>
            </template>
            <template #default="scope">
            <span class="table-operate-btn" @click="handleEdit(scope.row,'detail')"
                  v-show="hasPermission(this.$route.name,'Retrieve')">详情</span>
              <span class="table-operate-btn" @click="handleEdit(scope.row,'delete')"
                    v-show="hasPermission(this.$route.name,'Delete')">删除</span>
            </template>
          </el-table-column>
        </el-table>
        <Pagination v-bind:child-msg="pageparm2" @callFather="callFather"></Pagination>
        <journal-manage-detail ref="journalManageDetailFlag"></journal-manage-detail>
      </ly-dialog>
    </div>
  </div>
</template>


<script>
import lyTable from '@/components/lyTable/index.vue'
import lyTableColumn from '@/components/lyTable/column.js'
import {dateFormats} from "@/utils/util";
import {systemGetUserLogs, systemOperationlogDelete, systemOperationlogDeletealllogsDelete} from "@/api/api";
import LyDialog from "@/components/dialog/dialog.vue";
import Pagination from "@/components/Pagination";
import journalManageDetail from "../../journalManage/journalManageDetail";

export default {
  name: "journalManage",
  emits: ['refreshData', 'closed'],
  components: {
    LyDialog,
    lyTable,
    lyTableColumn,
    Pagination,
    journalManageDetail
  },
  data() {
    return {
      isDetailDialogVisible: false,
      dialogVisible: true,
      isDialogVisible: false,
      isFull2: false,
      loadingTitle: '日志',
      tableHeight2: "auto",
      loadingPage2: false,
      showOtherSearch2: false,//隐藏过长的搜索条件
      formInline2: {
        page: 1,
        limit: 20
      },
      pageparm2: {
        page: 1,
        limit: 20,
        total: 0
      },
      timers2: [],
      tableData2: []
    }
  },
  created() {
    // this.getData2()
  },
  methods: {
    handleClose() {
      this.$emit('closed')
    },
    setFull() {
      this.isFull2 = !this.isFull2
      window.dispatchEvent(new Event('resize'))
    },
    // 表格序列号
    getIndex($index) {
      // (当前页 - 1) * 当前显示数据条数 + 当前行数据的索引 + 1
      return (this.pageparm2.page - 1) * this.pageparm2.limit + $index + 1
    },
    formatBody(value) {
      try {
        return JSON.parse(value, null, 2); // 格式化显示数据，使用2个空格进行缩进
      } catch (err) {
        console.error('Invalid JSON string:', err);
        return value
      }
    },
    clickMore() {
      this.showOtherSearch2 = !this.showOtherSearch2
      window.dispatchEvent(new Event('resize'))
    },
    deleteAlllogs() {
      let vm = this
      vm.$confirm('是否确认清空全部日志数据', "警告", {
        confirmButtonText: "确定",
        cancelButtonText: "取消",
        type: "warning"
      }).then(function () {
        return systemOperationlogDeletealllogsDelete().then(res => {
          if (res.code == 2000) {
            vm.$message.success(res.msg)
            vm.search()
          } else {
            vm.$message.warning(res.msg)
          }
        })
      })
    },
    handleEdit(row, flag) {
      let vm = this
      if (flag == 'detail') {
        this.$refs.journalManageDetailFlag.journalManageDetailFn(row)
      } else if (flag == 'delete') {
        vm.$confirm('您确定要删除选中的数据吗？', {
          closeOnClickModal: false
        }).then(res => {
          systemOperationlogDelete({id: row.id}).then(res => {
            if (res.code == 2000) {
              vm.$message.success(res.msg)
              vm.getData2()
            } else {
              vm.$message.warning(res.msg)
            }
          })
        }).catch(() => {

        })
      } else if (flag == "reset") {
        this.formInline2 = {
          page: 1,
          limit: 20
        }
        this.pageparm2 = {
          page: 1,
          limit: 20,
          total: 0
        }
        this.timers2 = []
        this.getData2()
      }
    },
    callFather(parm) {
      this.formInline2.page = parm.page
      this.formInline2.limit = parm.limit
      this.getData2()
    },
    search() {
      this.formInline2.page = 1
      this.formInline2.limit = 20
      this.getData2()
    },
    timeChange(val) {
      if (val) {
        this.formInline2.beginAt = dateFormats(val[0], 'yyyy-MM-dd hh:mm:ss');
        this.formInline2.endAt = dateFormats(val[1], 'yyyy-MM-dd hh:mm:ss');
      } else {
        this.formInline2.beginAt = null
        this.formInline2.endAt = null
      }
      this.search()
    },
    handleOpen(item, flag) {
      this.loadingTitle = flag
      this.loadingPage2 = true
      this.dialogVisible = true
      console.log(item)
      if (item) {
        this.formInline2.id = item.id
      }
      this.getData2()
    },
    getData2() {
      this.loadingPage2 = true
      systemGetUserLogs(this.formInline2).then(res => {
        if (res.code == 2000) {
          this.tableData2 = res.data.data
          this.pageparm2.page = res.data.page;
          this.pageparm2.limit = res.data.limit;
          this.pageparm2.total = res.data.total;
        }
        this.loadingPage2 = false
      })
    },
    // 计算搜索栏的高度
    listenResize() {
      this.$nextTick(() => {
        this.getTheTableHeight()
      })
    },
    getTheTableHeight() {
      let tabSelectHeight = this.$refs.tableSelect ? this.$refs.tableSelect.offsetHeight : 0
      tabSelectHeight = this.isFull2 ? tabSelectHeight - 110 : tabSelectHeight
      // this.tableHeight =  getTableHeight(tabSelectHeight)
    }
  },
  mounted() {
    // 监听页面宽度变化搜索框的高度
    window.addEventListener('resize', this.listenResize);
    this.$nextTick(() => {
      this.getTheTableHeight()
    })
  },
  unmounted() {
    // 页面销毁，去掉监听事件
    window.removeEventListener("resize", this.listenResize);
  }
}
</script>

<style scoped>
.lypopoverbox {
  background: #132738;
  color: #c26a3e;
  height: 560px;
  width: 425px;
  overflow: auto;
}

.lypopoverbox::-webkit-scrollbar {
  display: none; /* Chrome Safari */
}
</style>





