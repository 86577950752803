<template>
    <div>
        <el-form :model="formConfig" size="small" label-position="left" label-width="105px" class="lyformsetting" @submit.prevent>
            <el-collapse v-model="lyCollapseActiveList" class="lyformsetting-collapse">
                <el-collapse-item name="lyfs01" title="基本属性">
                    <el-form-item label="表单尺寸">
                        <el-select v-model="formConfig.size" @change="handleChange">
                            <el-option v-for="item in formSizes" :key="item.value" :label="item.label"
                            :value="item.value">
                            </el-option>
                        </el-select>
                    </el-form-item>
                    <el-form-item label="标签位置">
                        <el-radio-group v-model="formConfig.labelPosition" class="radio-group-custom" @change="handleChange">
                            <el-radio-button label="left">左边</el-radio-button>
                            <el-radio-button label="right">右边</el-radio-button>
                            <el-radio-button label="top">顶部</el-radio-button>
                        </el-radio-group>
                    </el-form-item>
                    <el-form-item label="标签宽度(px)">
                        <el-input v-model="formConfig.labelWidth"  style="width: 100%" @change="handleChange"></el-input>
                    </el-form-item>
                    <el-form-item label="禁用表单">
                        <el-switch v-model="formConfig.disabled" @change="handleChange"></el-switch>
                    </el-form-item>
                    <el-form-item label="表单全局CSS">
                        <el-button type="info" icon="edit" plain round @click="editFormCss">编写CSS</el-button>
                    </el-form-item>
                    <el-form-item label="自定义CSS样式">
                        <el-select v-model="formConfig.customClass" multiple filterable allow-create default-first-option>
                            <el-option v-for="(item, idx) in cssClassList" :key="idx" :label="item" :value="item"></el-option>
                        </el-select>
                    </el-form-item>
                    <el-form-item label="表单全局方法">
                        <el-button type="info" icon="edit" plain round @click="editGlobalFunctions">编写代码</el-button>
                    </el-form-item>
                    <el-form-item label-width="0">
                        <el-divider>生成SFC设置</el-divider>
                    </el-form-item>
                    <el-form-item label="数据对象名称">
                        <el-input v-model="formConfig.modelName" @change="handleChange"></el-input>
                    </el-form-item>
                    <el-form-item label="引用名称">
                        <el-input v-model="formConfig.refName" @change="handleChange"></el-input>
                    </el-form-item>
                    <el-form-item label="验证规则名称">
                        <el-input v-model="formConfig.rulesName" @change="handleChange"></el-input>
                    </el-form-item>
                </el-collapse-item>
                <el-collapse-item v-if="showEventCollapse()" name="lyfs02" title="事件属性">
                    <el-form-item label="onFormMounted" label-width="150px">
                        <el-button type="info" icon="edit" plain round @click="editFormEventHandler('onFormMounted')">编写代码</el-button>
                    </el-form-item>
                </el-collapse-item>
                <el-collapse-item name="lyfs03" title="CRUD属性">
                    <template #title>
                        <div style="display: flex;align-items: center;">
                            CRUD属性
                            <el-tooltip effect="dark" content="用于自动生成后端model及crud功能" placement="top-start">
                                <el-icon><QuestionFilled /></el-icon>
                            </el-tooltip>
                        </div>
                    </template>
                    <el-form-item label="表中文名称">
                        <template #label>
                            <div style="display: flex;align-items: center;">
                                表中文名称
                                <el-tooltip effect="dark" content="此名称会作为model类的verbose_name、菜单名称" placement="top-start">
                                    <el-icon><QuestionFilled /></el-icon>
                                </el-tooltip>
                            </div>
                        </template>
                        <el-input v-model="formConfig.modelVerboseName" placeholder="请输入表中文名称" @change="handleChange"></el-input>
                    </el-form-item>
                    <el-form-item label="类名(class)">
                        <template #label>
                            <div style="display: flex;align-items: center;">
                                类名(class)
                                <el-tooltip effect="dark" content="此名称会作为model的类名、后端路由前缀、前端路由通用名、前后端通用文件名" placement="top-start">
                                    <el-icon><QuestionFilled /></el-icon>
                                </el-tooltip>
                            </div>
                        </template>
                        <el-input v-model="formConfig.modelClassName" placeholder="请输入Model模型类名" @change="handleChange"></el-input>
                    </el-form-item>
                    <el-form-item label="表名(db_table)">
                        <el-input v-model="formConfig.modelDbTable" placeholder="请输入英文表名" @change="handleChange"></el-input>
                    </el-form-item>
                    <el-form-item label="表单模板ID">
                        <el-input v-model="builderStore.formTemplateID" placeholder="表单模板ID" :disabled="true"></el-input>
                    </el-form-item>
                    <el-form-item label="启用批量删除" v-if="formConfig.other_config">
                        <el-switch v-model="formConfig.other_config.mutiple_delete" @change="handleChange"></el-switch>
                    </el-form-item>
                    <el-form-item label="过滤查询" v-if="formConfig.other_config">
                        <el-switch v-model="formConfig.other_config.create_datetime_filter"  size="default" inline-prompt  style="--el-switch-on-color: #13ce66; --el-switch-off-color: #ff4949;height: auto" active-text="启用create_datetime过滤" inactive-text="关闭create_datetime过滤" @change="handleChange"></el-switch>
                    </el-form-item>
                </el-collapse-item>

            </el-collapse>
            <lyDialog v-model="showEditFormCssDialog" title="表单全局CSS" width="60%" top="20px" :before-close="handleEditFormCssClose">
                <div>
                    <lyCodeEditor ref="lyFormEditCss" v-model="formCssCode" mode="css" height="600" :read-only="false"></lyCodeEditor>
                </div>
                <template v-slot:footer>
                    <el-button @click="handleEditFormCssClose" size="default">关闭</el-button>
                    <el-button type="primary" @click="saveFormCss" size="default">确定</el-button>
                </template>
            </lyDialog>
            <lyDialog v-model="showEditFormFunctionDialog" title="表单全局方法" width="60%" top="20px" :before-close="handleEditFormFunctionClose">
                <div>
                    <el-alert title="表单全局方法会被追加到window对象（script标签），因此命名上应考虑不会与其他全局函数名称冲突！！！" type="info"  show-icon />
                    <lyCodeEditor ref="lyFormEditCss" v-model="formFunctionCode" mode="javascript" height="600" :read-only="false"></lyCodeEditor>
                </div>
                <template v-slot:footer>
                    <el-button @click="handleEditFormFunctionClose" size="default">关闭</el-button>
                    <el-button type="primary" @click="saveFormFunction" size="default">确定</el-button>
                </template>
            </lyDialog>
            <lyDialog v-model="showFormEventDialog" title="表单事件" width="60%" top="20px" :before-close="handleFormEventHandlerClose">
                <div>
                    <el-alert :title="'form.' + eventParamsMap[currentEventName]" type="info" :closable="false" />
                    <lyCodeEditor ref="lyFormEditCss" v-model="formEventCode" mode="javascript" height="600" :read-only="false"></lyCodeEditor>
                    <el-alert title="}"  :closable="false" type="info"></el-alert>
                </div>
                <template v-slot:footer>
                    <el-button @click="handleFormEventHandlerClose" size="default">关闭</el-button>
                    <el-button type="primary" @click="saveFormEvent" size="default">确定</el-button>
                </template>
            </lyDialog>
        </el-form>
    </div>
</template>

<script setup>
    import {ref, onMounted, reactive, computed, nextTick, watch, defineAsyncComponent} from 'vue'
    import { useBuilderStore } from "@/store/lyFormBuilder";
    import lyDialog from '@/components/dialog/dialog'
    import {insertCustomCssToHead,insertGlobalFunctionsToHtml} from '@/utils/util'
    const lyCodeEditor = defineAsyncComponent(() => import('@/components/lyform-builder/code-editor'));

    const props = defineProps({
        formConfig: Object
    })
    let lyCollapseActiveList =  ref([])
    const formSizes = ref([
        {label: 'default', value: ''},
        {label: 'large', value: 'large'},
        {label: 'small', value: 'small'},
    ])
    let cssClassList = ref([])
    let currentEventName = ref("")//编辑选中当前表单事件名称
    let eventParamsMap = {
      'onFormCreated':      'onFormCreated() {',
      'onFormMounted':      'onFormMounted() {',
    }

    const builderStore = useBuilderStore()

    function handleChange(newValue) {
         builderStore.saveCurrentHistoryStep()
    }
    function extractCssClass() {
        let regExp = /\..*{/g
        let result = formCssCode.value.match(regExp)
        let cssNameArray = []

        if (!!result && result.length > 0) {
          result.forEach((rItem) => {
            let classArray = rItem.split(',')  //切分逗号分割的多个class
            if (classArray.length > 0) {
              classArray.forEach((cItem) => {
                let caItem = cItem.trim()
                if (caItem.indexOf('.', 1) !== -1) {  //查找第二个.位置
                  let newClass = caItem.substring(caItem.indexOf('.') + 1, caItem.indexOf('.', 1))  //仅截取第一、二个.号之间的class
                  if (!!newClass) {
                    cssNameArray.push(newClass.trim())
                  }
                } else if (caItem.indexOf(' ') !== -1) {  //查找第一个空格位置
                  let newClass = caItem.substring(caItem.indexOf('.') + 1, caItem.indexOf(' '))  //仅截取第一、二个.号之间的class
                  if (!!newClass) {
                    cssNameArray.push(newClass.trim())
                  }
                } else {
                  if (caItem.indexOf('{') !== -1) {  //查找第一个{位置
                    let newClass = caItem.substring(caItem.indexOf('.') + 1, caItem.indexOf('{'))
                    cssNameArray.push( newClass.trim() )
                  } else {
                    let newClass = caItem.substring(caItem.indexOf('.') + 1)
                    cssNameArray.push( newClass.trim() )
                  }
                }
              })
            }
          })
        }
        cssClassList.value.splice(0, cssClassList.value.length)  //清除数组必须用splice，length=0不会响应式更新！！
        cssClassList.value = Array.from( new Set(cssNameArray) )  //数组去重
    }
    let formCssCode = ref("")
    let showEditFormCssDialog = ref(false)
    function editFormCss() {
        formCssCode.value = builderStore.formConfig.cssCode
        showEditFormCssDialog.value = true
    }
    function saveFormCss() {
        extractCssClass()
        builderStore.formConfig.cssCode = formCssCode.value
        insertCustomCssToHead(formCssCode.value)
        showEditFormCssDialog.value = false
        builderStore.setCssClassList(cssClassList.value)
        builderStore.saveCurrentHistoryStep()
    }
    function handleEditFormCssClose() {
        showEditFormCssDialog.value = false
    }

    function showEventCollapse(){
        if (builderStore.builderConfig['showEventCollapse'] === undefined) {
          return true
        }
        return !!builderStore.builderConfig['showEventCollapse']
    }

    let showEditFormFunctionDialog = ref(false)
    let formFunctionCode = ref("")
    function editGlobalFunctions() {
        formFunctionCode.value = builderStore.formConfig.functions
        showEditFormFunctionDialog.value = true
    }
    function saveFormFunction(){
        builderStore.formConfig.functions = formFunctionCode.value
        insertGlobalFunctionsToHtml(formFunctionCode.value)
        builderStore.saveCurrentHistoryStep()
        showEditFormFunctionDialog.value = false
    }
    function handleEditFormFunctionClose(){
        showEditFormFunctionDialog.value = false
    }

    let showFormEventDialog = ref(false)
    let formEventCode = ref("")
    function editFormEventHandler(flag){
        currentEventName.value = flag
        formEventCode.value = props.formConfig[flag]
        showFormEventDialog.value = true
    }
    function handleFormEventHandlerClose(){
        showFormEventDialog.value = false
    }
    function saveFormEvent(){
        builderStore.formConfig[currentEventName.value] = formEventCode.value
        builderStore.saveCurrentHistoryStep()
        showFormEventDialog.value = false
    }


    onMounted(() => {
        lyCollapseActiveList.value = ['lyfs01','lyfs02',,'lyfs03']
        setTimeout(() => {
            formCssCode.value = props.formConfig.cssCode
            insertCustomCssToHead(formCssCode.value)
            extractCssClass()
            builderStore.setCssClassList(cssClassList.value)
      }, 600)
    })

</script>

<style lang="scss" scoped>
    .lyformsetting{
        ::v-deep(.el-form-item__label) {
          font-size: 13px;
          overflow: hidden;
          white-space: nowrap;
        }
        .lyformsetting-collapse{
            ::v-deep(.el-collapse-item__header) {
                font-weight: bold;
            }
        }
    }
</style>