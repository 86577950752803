<template>
	<el-card shadow="never" header="修改密码">
		<el-form ref="userPasswordForm" :model="userPasswordInfo" :rules="rules" label-width="120px" style="margin-top:20px;">
			<el-form-item label="当前密码" prop="oldPassword">
				<el-input v-model="userPasswordInfo.oldPassword" type="password" show-password placeholder="请输入当前密码"></el-input>
			</el-form-item>
			<el-form-item label="新密码" prop="newPassword">
				<el-input v-model="userPasswordInfo.newPassword" type="password" show-password placeholder="请输入新密码"></el-input>
				<lyPasswordStrength v-model="userPasswordInfo.newPassword"></lyPasswordStrength>
				<div class="el-form-item-msg">请输入8-20位包含英文、数字的密码</div>
			</el-form-item>
			<el-form-item label="确认新密码" prop="newPassword2">
				<el-input v-model="userPasswordInfo.newPassword2" type="password" show-password placeholder="请再次输入新密码"></el-input>
			</el-form-item>
			<el-form-item>
				<el-button type="primary" @click="settingPassword">保存密码</el-button>
			</el-form-item>
		</el-form>
	</el-card>
</template>

<script>
	import {systemUserChangePassword} from '@/api/api'
	import lyPasswordStrength from "@/components/password/lyPasswordStrength";
	import {useMutitabsStore} from "@/store/mutitabs";

	export default {
		setup(){
            const mutitabsstore = useMutitabsStore()
            return { mutitabsstore}
        },
		components: {
			lyPasswordStrength
		},
		data() {
			// 密码校验 长度不能小于8位且不能大于20位字符,必须包含大写字母、小写字母、数字和特殊符号
			var pwdRegex =/^(?=.*[0-9])(?=.*[a-zA-Z]).{8,20}$/;
			// 密码校验
			const validatePassword = (rule, value, callback) =>{
				if (!pwdRegex.test(this.userPasswordInfo.newPassword)) {
					callback(new Error("用户密码必须包含字母、数字"));
				} else {
					callback();
				}
			}
			return {
				userPasswordInfo: {
					oldPassword: "",
					newPassword: "",
					newPassword2: ""
				},
				rules: {
					oldPassword: [
						{ required: true, message: '请输入当前密码'}
					],
					newPassword: [
						{ required: true, message: '请输入新密码'},
						{ min: 8, max: 20, message: '密码长度应大于等于8个小于20位', trigger: "blur" },
						{ required: true, validator: validatePassword, trigger: 'blur' }
					],
					newPassword2: [
						{ required: true, message: '请再次输入新密码'},
						{validator: (rule, value, callback) => {
							if (value !== this.userPasswordInfo.newPassword) {
								callback(new Error('两次输入密码不一致'));
							}else{
								callback();
							}
						}}
					]
				}
			}
		},
		methods: {
			resetFields () {
				this.$nextTick(() => {
					this.$refs.userPasswordForm.resetFields();
				})
			},
			settingPassword(){
				this.$refs.userPasswordForm.validate(valid => {
					if (valid) {
						const userId = this.mutitabsstore.getUserId
						if (userId) {
							const params = JSON.parse(JSON.stringify(this.userPasswordInfo))
							params.id = userId
							systemUserChangePassword(params).then(res=>{
								if(res.code ==2000) {
									this.$message.success(res.msg)
									this.resetFields()
								} else {
									this.$message.warning(res.msg)
								}
							})
						}
					} else {
						// 校验失败
						return false
					}
				})
			}
		}
	}
</script>

<style scoped>
	.el-form-item-msg{
		font-size: 12px;
		color: #999;
		clear: both;
		width: 100%;
	}
</style>
