<template>
  <div :class="{'ly-is-full':isFull}">
    <div class="tableSelect" ref="tableSelect">
      <el-form :inline="true" :model="formInline" label-position="left">
        <el-form-item label="商品运费/名称/标题：">
          <el-input v-model.trim="formInline.search" maxlength="60" clearable placeholder="请输入商品名称或标题"
                    @change="search"
                    style="width:150px"></el-input>
        </el-form-item>
        <el-form-item label="产品分类：">
          <el-select v-model="formInline.category_product_many" placeholder="请选择" filterable clearable
                     @change="search"
                     style="width:150px">
            <el-option
                v-for="item in categoryProductList"
                :key="item.id"
                :label="item.name"
                :value="item.id">
            </el-option>
          </el-select>
        </el-form-item>
        <el-form-item label="状态：">
          <el-select v-model="formInline.is_launched" placeholder="请选择" clearable style="width: 100px"
                     @change="search">
            <el-option
                v-for="item in islaunchedList"
                :key="item.id"
                :label="item.name"
                :value="item.id">
            </el-option>
          </el-select>
        </el-form-item>
        <el-form-item label="是否显示通用表：">
          <el-select v-model="formInline.is_general_list" placeholder="请选择" clearable style="width: 100px"
                     @change="search">
            <el-option
                v-for="item in isGeneralList"
                :key="item.id"
                :label="item.name"
                :value="item.id">
            </el-option>
          </el-select>
        </el-form-item>
        <el-form-item label="是否显示型号表：">
          <el-select v-model="formInline.is_model_list" placeholder="请选择" clearable style="width: 100px"
                     @change="search">
            <el-option
                v-for="item in isModelList"
                :key="item.id"
                :label="item.name"
                :value="item.id">
            </el-option>
          </el-select>
        </el-form-item>
        <el-form-item label="是否包邮：">
          <el-select v-model="formInline.is_free_shipping" placeholder="请选择" clearable style="width: 100px"
                     @change="search">
            <el-option
                v-for="item in isFreeShippingList"
                :key="item.id"
                :label="item.name"
                :value="item.id">
            </el-option>
          </el-select>
        </el-form-item>
        <el-form-item label="创建时间：">
          <el-date-picker
              style="width:100% !important;"
              v-model="timers"
              size="small"
              type="datetimerange"
              @change="timeChange"
              range-separator="至"
              start-placeholder="开始日期"
              end-placeholder="结束日期">
          </el-date-picker>
        </el-form-item>
        <el-form-item label="" v-if="hasPermission(this.$route.name,'Search')">
          <el-button @click="search" type="primary" icon="Search">查询</el-button>
        </el-form-item>
        <el-form-item label="">
          <el-button @click="handleEdit('','reset')" icon="Refresh">重置</el-button>
        </el-form-item>
        <el-form-item label="" v-if="hasPermission(this.$route.name,'Create')">
          <el-button @click="addModule" type="primary">新增</el-button>
        </el-form-item>
        <el-form-item label="" v-if="hasPermission(this.$route.name,'Delete')">
          <el-button @click="handleDelete" type="danger" :disabled="multiple">删除</el-button>
        </el-form-item>
        <el-form-item label="" v-if="hasPermission(this.$route.name,'Disable')">
          <el-button @click="handleDisable" type="info" :disabled="multiple">批量下架</el-button>
        </el-form-item>
        <el-form-item label="" v-if="hasPermission(this.$route.name,'Export')">
          <el-button type="primary" @click="exportDataBackend" :disabled="loadingPage">导出</el-button>
        </el-form-item>
      </el-form>
    </div>
    <div class="table">
      <el-table :height="tableHeight" border :data="tableData" v-loading="loadingPage" ref="tableref"
                style="width: 100%" @selection-change="handleSelectionChange">
        <el-table-column type="selection" width="60" align="center"></el-table-column>
        <el-table-column type="index" width="60" align="center" label="序号">
          <template #default="scope">
            <span v-text="getIndex(scope.$index)"></span>
          </template>
        </el-table-column>
        <el-table-column min-width="40" prop="id" label="id" show-overflow-tooltip></el-table-column>
        <el-table-column min-width="40" prop="home_video" label="视频">
          <template #default="scope">
            <!-- 视频预览弹窗 -->
            <div @click="showVideo(scope.row)">查看</div>
          </template>
        </el-table-column>
        <el-table-column min-width="50" prop="default_image" label="主图">
          <template #default="scope">
            <el-image
                :src=scope.row.default_image
                :preview-src-list="[scope.row.default_image]"
                preview-teleported
                style="width: 40px;height: 40px">

            </el-image>
          </template>
        </el-table-column>
        <el-table-column min-width="120" prop="name" label="商品名称" show-overflow-tooltip></el-table-column>
        <el-table-column min-width="120" prop="sub_name" label="商品标题" show-overflow-tooltip></el-table-column>
        <!--        <el-table-column min-width="80" prop="category1_name" label="商品分类"></el-table-column>-->
        <el-table-column min-width="80" prop="category_product_many_name" label="产品分类"></el-table-column>
        <el-table-column min-width="80" prop="price" label="普通售价"></el-table-column>
        <el-table-column min-width="80" prop="price_vip" label="会员售价"></el-table-column>
        <el-table-column min-width="80" prop="price_svip" label="超级会员价"></el-table-column>
        <el-table-column min-width="80" prop="model_list_lyautocode_name" label="关联型号表" show-overflow-tooltip>
          <template #model_list="scope">
            <span>{{ scope.row.model_list_lyautocode_name }}</span>
          </template>
        </el-table-column>
        <el-table-column min-width="80" prop="general_list_lyautocode_name" label="关联通用表" show-overflow-tooltip>
          <template #general_list="scope">
            <span>{{ scope.row.general_list_lyautocode_name }}</span>
          </template>
        </el-table-column>
        <el-table-column min-width="80" prop="serve_product_name__list" label="服务" show-overflow-tooltip>
          <template #serve_product_name__list="scope">
            <span>{{ scope.row.serve_product_name__list }}</span>
          </template>
        </el-table-column>
        <!--        <el-table-column min-width="80" prop="serve_product__list" label="服务">-->
        <!--          <template #serve_product__list="scope">-->
        <!--            <span v-for="(item,index) in scope.row.serve_product__list" :key="index">-->
        <!--        <span v-if="item" class="el-tag is-closable el-tag&#45;&#45;info el-tag&#45;&#45;default el-tag&#45;&#45;light"-->
        <!--              :style="{backgroundColor:item.colour}">-->
        <!--        <span class="el-tag__content">-->
        <!--            <span class="el-select__tags-text" style="max-width: 283px;">-->
        <!--                {{ item.name }}-->
        <!--            </span>-->
        <!--        </span>-->
        <!--    </span>-->
        <!--            </span>-->
        <!--          </template>-->
        <!--        </el-table-column>-->
        <el-table-column min-width="80" prop="characteristic_product_name__list" label="特点" show-overflow-tooltip>
          <template #characteristic_product_name__list="scope">
            <span>{{ scope.row.characteristic_product_name__list }}</span>
          </template>
        </el-table-column>
        <!--        <el-table-column min-width="80" prop="characteristic_product__list" label="特点">-->
        <!--          <template #characteristic_product="scope">-->
        <!--                    <span v-for="(item,index) in scope.row.characteristic_product__list"-->
        <!--                          class="el-select-tags-wrapper has-prefix"-->
        <!--                          style="margin-left: 11px;">-->
        <!--    <span v-if="item" class="el-tag is-closable el-tag&#45;&#45;info el-tag&#45;&#45;default el-tag&#45;&#45;light"-->
        <!--          :style="{backgroundColor:item.colour}">-->
        <!--        <span class="el-tag__content">-->
        <!--            <span class="el-select__tags-text" style="max-width: 283px;">-->
        <!--                {{ item.name }}-->
        <!--            </span>-->
        <!--        </span>-->
        <!--    </span>-->
        <!--</span>-->
        <!--          </template>-->
        <!--        </el-table-column>-->
        <el-table-column min-width="60" sortable prop="stock" label="库存"></el-table-column>
        <el-table-column min-width="60" prop="is_tuijian" label="推荐" sortable>
          <template #default="scope">
            <span v-if="scope.row.is_tuijian">是</span>
            <span v-else>否</span>
          </template>
        </el-table-column>
        <el-table-column min-width="60" prop="is_vip_price" sortable label="显示会员价">
          <template #default="scope">
            <span v-if="scope.row.is_vip_price">是</span>
            <span v-else>否</span>
          </template>
        </el-table-column>
        <el-table-column min-width="60" sortable prop="sort" label="排序"></el-table-column>
        <el-table-column min-width="60" label="状态" sortable>
          <template #default="scope">
            <el-tag v-if="scope.row.is_launched">上架</el-tag>
            <el-tag v-else type="danger">下架</el-tag>
          </template>
        </el-table-column>
        <el-table-column min-width="60" label="显示通用表" sortable>
          <template #default="scope">
            <el-tag v-if="scope.row.is_general_list">是</el-tag>
            <el-tag v-else type="danger">否</el-tag>
          </template>
        </el-table-column>
        <el-table-column min-width="60" label="显示型号表" sortable>
          <template #default="scope">
            <el-tag v-if="scope.row.is_model_list">是</el-tag>
            <el-tag v-else type="danger">否</el-tag>
          </template>
        </el-table-column>
        <el-table-column min-width="60" label="包邮" sortable>
          <template #default="scope">
            <el-tag v-if="scope.row.is_free_shipping">是</el-tag>
            <el-tag v-else type="danger">否</el-tag>
          </template>
        </el-table-column>
        <el-table-column min-width="100" prop="create_datetime" label="创建时间" sortable
                         show-overflow-tooltip></el-table-column>
        <el-table-column label="操作" fixed="right" width="150">
          <template #header>
            <div style="display: flex;justify-content: space-between;align-items: center;">
              <div>操作</div>
              <div @click="setFull">
                <el-tooltip content="全屏" placement="bottom">
                  <el-icon>
                    <full-screen/>
                  </el-icon>
                </el-tooltip>
              </div>
            </div>
          </template>
          <template #default="scope">
                        <span class="table-operate-btn" @click="handleEdit(scope.row,'status')"
                              v-show="hasPermission(this.$route.name,'Disable')">
                            <span v-if="scope.row.is_launched">下架</span>
                            <span v-else>上架</span>
                        </span>
            <span class="table-operate-btn" @click="handleEdit(scope.row,'edit')"
                  v-show="hasPermission(this.$route.name,'Update')">编辑</span>
            <span class="table-operate-btn" @click="handleEdit(scope.row,'delete')"
                  v-show="hasPermission(this.$route.name,'Delete')">删除</span>
          </template>
        </el-table-column>
      </el-table>
      <!-- 视频弹窗部分 -->
      <el-dialog
          v-model="videoDialogVisible"
          width="60%"
          :before-close="handleCloseVideo"
          append-to-body
          style="border: none; /* 无边框 */ background: transparent; /* 透明背景 */"
      >
        <ly-video controls :source=currentVideoSource :options="{ width: '100%', height: '500px'}" :buttonCenter="true">
        </ly-video>
      </el-dialog>
    </div>
    <Pagination v-bind:child-msg="pageparm" @callFather="callFather"></Pagination>
    <add-module-goods-manage ref="addModuleFlag" @refreshData="getData"></add-module-goods-manage>
  </div>
</template>
<script>
import Pagination from "@/components/Pagination";
import {dateFormats, getTableHeight} from "@/utils/util";
import {
  classificationLyAudoCodeAPI,
  mallGoodsspu,
  mallGoodsspuDelete,
  mallGoodsspuExport,
  mallGoodsspuIslaunched
} from '@/api/api'
import AddModuleGoodsManage from "./components/addModuleGoodsManage";
import {lyMixins} from "@/mixins/mixins"
import {useDictStore} from "@/store/dict";
import LyDialog from "@/components/dialog/dialog.vue";
import lyVideo from "@/components/lyvideo/lyVideo"

export default {
  name: 'goodsManage',
  mixins: [lyMixins],
  components: {
    AddModuleGoodsManage,
    Pagination,
    LyDialog,
    lyVideo
  },
  setup() {
    const dictStore = useDictStore()
    return {dictStore}
  },
  data() {
    return {
      videoDialogVisible: false,
      currentVideoSource: '',
      isFull: false,
      tableHeight: 500,
      loadingPage: false,
      // 选项框选中数组
      ids: [],
      // 选项框非单个禁用
      single: true,
      // 非多个禁用
      multiple: true,
      formInline: {
        page: 1,
        limit: 10,
      },
      timers: [],
      pageparm: {
        page: 1,
        limit: 10,
        total: 0
      },
      statusList: [
        {id: 1, name: '正常'},
        {id: 0, name: '禁用'}
      ],
      islaunchedList: [
        {id: 1, name: '上架'},
        {id: 0, name: '下架'}
      ],
      isGeneralList: [
        {id: 1, name: '是'},
        {id: 0, name: '否'}
      ],
      isModelList: [
        {id: 1, name: '是'},
        {id: 0, name: '否'}
      ],
      isFreeShippingList: [
        {id: 1, name: '是'},
        {id: 0, name: '否'}
      ],
      tableData: [],
      // categoryList: [],
      categoryProductList: [],

      //弹窗
      dialogVisible: false,
      dialogTitle: "",
      dialogLoadingSave: false,
      fullscreen: false,
      dialogWidth: "50%",
      dialogTop: "15vh",
      formDisabled: false,
      formLabelPosition: "right",
      formLabelWidth: "auto",
      formData: {"id": null, "model_name": null, "brand_phone": null, "model_phone": null},
      rules: {
        id: [{required: true, message: "ID不能为空", trigger: 'blur'}],
        brand_phone: [{required: true, message: "手机品牌不能为空", trigger: 'blur'}],
      },
    }
  },
  methods: {
    // 显示视频弹窗，并设置视频源地址
    showVideo(row) {
      this.videoDialogVisible = true; // 显示弹窗
      this.currentVideoSource = row.home_video; // 设置当前行的视频源地址到播放器中
    },
    // 处理弹窗关闭前的事件（如果需要的话）
    handleCloseVideo(done) {
      this.$confirm('确认关闭？')
          .then(_ => {
            done();
          })
          .catch(_ => {
          });
    },


    setLoadingPage(bools) {
      this.loadingPage = bools
      this.$refs.tableref.loadingPage(bools)
    },
    setFull() {
      this.isFull = !this.isFull
      window.dispatchEvent(new Event('resize'))
    },
    // 表格序列号
    getIndex($index) {
      // (当前页 - 1) * 当前显示数据条数 + 当前行数据的索引 + 1
      return (this.pageparm.page - 1) * this.pageparm.limit + $index + 1
    },
    //多选项框被选中数据
    handleSelectionChange(selection) {
      this.ids = selection.map(item => item.id);
      this.single = selection.length !== 1;
      this.multiple = !selection.length;
    },
    /** 批量删除按钮操作 */
    handleDelete(row) {
      const ids = this.ids;
      let vm = this
      vm.$confirm('是否确认删除选中的数据项?', "警告", {
        confirmButtonText: "确定",
        cancelButtonText: "取消",
        type: "warning"
      }).then(function () {
        return mallGoodsspuDelete({id: ids}).then(res => {
          if (res.code === 2000) {
            vm.$message.success(res.msg)
            vm.search()
          } else {
            vm.$message.warning(res.msg)
          }
        })
      })
    },
    /** 批量下架按钮操作 */
    handleDisable(row) {
      const ids = this.ids;
      let vm = this
      vm.$confirm('是否确认下架/上架选中的数据项?', "警告", {
        confirmButtonText: "确定",
        cancelButtonText: "取消",
        type: "warning"
      }).then(function () {
        return mallGoodsspuIslaunched({id: ids}).then(res => {
          if (res.code === 2000) {
            vm.$message.success(res.msg)
            vm.search()
          } else {
            vm.$message.warning(res.msg)
          }
        })
      })
    },
    convertStrNum(model, value) {
      if (model === "" || model === null || model === undefined) {
        return value
      } else if (Object.prototype.toString.call(model) === "[object String]") {
        return value.toString()
      } else if (Object.prototype.toString.call(model) === "[object Number]") {
        return value * 1
      } else {
        return value
      }
    },
    addModule() {
      this.$refs.addModuleFlag.addModuleFn(null, '新增',
          // this.categoryList,
          this.categoryProductList)
    },
    handleDialogClose() {
      this.dialogTitle = ""
      this.dialogVisible = false
      this.dialogLoadingSave = false
      this.formDisabled = false
      this.formData = {"id": null, "model_name": null, "brand_phone": null, "model_phone": null}
    },
    handleEdit(row, flag) {
      if (flag === 'status') {
        let vm = this
        var titext = ""
        if (row.is_launched) {
          titext = "您确定要下架操作吗？"
        } else {
          titext = "您确定要上架操作吗？"
        }
        vm.$confirm(titext, {
          closeOnClickModal: false
        }).then(res => {
          mallGoodsspuIslaunched({id: row.id}).then(res => {
            if (res.code === 2000) {
              // 这里应该是将数据库返回的数据替换原来表格内的数据 并 更新页面
              row.is_launched = !row.is_launched;
              vm.$message.success(res.msg)
              // vm.search()
            } else {
              vm.$message.warning(res.msg)
            }
          })
        }).catch(() => {

        })
      } else if (flag === 'edit') {
        this.$refs.addModuleFlag.addModuleFn(row, '编辑',
            // this.categoryList,
            this.categoryProductList)
      } else if (flag === 'delete') {
        let vm = this
        vm.$confirm('您确定要删除选中的数据吗？', {
          closeOnClickModal: false
        }).then(res => {
          mallGoodsspuDelete({id: row.id}).then(res => {
            if (res.code === 2000) {
              vm.$message.success(res.msg)
              vm.search()
            } else {
              vm.$message.warning(res.msg)
            }
          })
        }).catch(() => {

        })
      } else if (flag === "reset") {
        this.formInline = {
          page: 1,
          limit: 10
        }
        this.pageparm = {
          page: 1,
          limit: 10,
          total: 0
        }
        this.getData()
      }
    },

    callFather(parm) {
      this.formInline.page = parm.page
      this.formInline.limit = parm.limit
      this.getData()
    },
    search() {
      this.formInline.page = 1
      this.formInline.limit = 10
      this.getData()
    },
    //获取列表
    async getData() {
      this.loadingPage = true
      mallGoodsspu(this.formInline).then(res => {
        this.loadingPage = false
        if (res.code === 2000) {
          this.tableData = res.data.data
          this.pageparm.page = res.data.page;
          this.pageparm.limit = res.data.limit;
          this.pageparm.total = res.data.total;
        }
      })
    },
    // //获取商品分类列表
    // getMallGoodscategoryList() {
    //   let param = {
    //     page: 1,
    //     limit: 9999,
    //   }
    //   mallGoodstype(param).then(res => {
    //     if (res.code===2000) {
    //       this.categoryList = res.data.data
    //     }
    //   })
    // },

    //获取 产品分类列表
    getCategoryProductList() {
      let param = {
        page: 1,
        limit: 9999999,
      }
      classificationLyAudoCodeAPI(param).then(res => {
        if (res.code === 2000) {
          this.categoryProductList = res.data.data
        }
      })
    },

    timeChange(val) {
      if (val) {
        this.formInline.beginAt = dateFormats(val[0], 'yyyy-MM-dd hh:mm:ss');
        this.formInline.endAt = dateFormats(val[1], 'yyyy-MM-dd hh:mm:ss');
      } else {
        this.formInline.beginAt = null
        this.formInline.endAt = null
      }
      this.search()
    },
    //excel文件流下载
    downloadExcelBlob(res) {
      let fileName = new Date().getTime() + ".xlsx"
      let dispositionStr = res.headers["content-disposition"];
      if (dispositionStr === null || dispositionStr === "") {

      } else {
        // 获取文件名
        let dispositionArr = dispositionStr.split(";");
        fileName = decodeURIComponent(dispositionArr[1]);
        fileName = fileName.split("=")[1];
      }
      const blob = new Blob([res.data], {
        type: "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet",
      });
      let href = window.URL.createObjectURL(blob); //下载链接
      let link = document.createElement("a")
      link.href = href
      link.download = fileName //下载后文件名
      document.body.appendChild(link);
      link.click(); //点击下载
      document.body.removeChild(link); //下载完成移除元素
      window.URL.revokeObjectURL(href);  //释放blob对象
      this.$message.success('导出成功')
    },
    //导出
    exportDataBackend() {
      let params = {}
      if (this.ids.length > 0) {
        params = {
          ids: this.ids,
        }
      }
      this.loadingPage = true
      mallGoodsspuExport(params).then(res => {
        this.loadingPage = false
        this.downloadExcelBlob(res)
      })
    },


    // 计算搜索栏的高度
    listenResize() {
      this.$nextTick(() => {
        this.getTheTableHeight()
      })
    },
    getTheTableHeight() {
      let tabSelectHeight = this.$refs.tableSelect ? this.$refs.tableSelect.offsetHeight : 0
      tabSelectHeight = this.isFull ? tabSelectHeight - 110 : tabSelectHeight
      this.tableHeight = getTableHeight(tabSelectHeight, false) - 125
    },
  },
  created() {
    this.getData()
    // this.getMallGoodscategoryList()
    this.getCategoryProductList()
  },
  mounted() {
    // 监听页面宽度变化搜索框的高度
    window.addEventListener('resize', this.listenResize);
    this.$nextTick(() => {
      this.getTheTableHeight()
    })
  },

  unmounted() {
    // 页面销毁，去掉监听事件
    window.removeEventListener("resize", this.listenResize);
  },
}
</script>

<style lang="scss" scoped>

</style>
