<!--
/**
 * 代码生成器
 * version：1.3
 * author: lybbn
 * program django-vue-lyadmin 专业版
 * email: 1042594286@qq.com
 * website: https://doc.lybbn.cn
 * date: 2023.04.25
 * remark: 如果要分发django-vue-lyadmin 专业版源码或其中组件等，需在本文件顶部保留此文件头信息！！！
 */
-->
<template>
    <div>
        <ly-dialog v-model="dialogVisible" :title="loadingTitle" width="78%" :center="false" :fullscreen="fullscreen" :before-close="handleClose" top="20px">
            <el-form :inline="false" :model="formData" :rules="rules" ref="rulesForm" label-position="top" label-width="auto">
                <div class="ly-title">
                    基础信息
                </div>
                <el-row :gutter="20">
                    <el-col :span="4">
                        <el-form-item label="名称(verbose_name)：" prop="verbose_name">
                            <template #label>
                                名称(verbose_name)：
                                <el-tooltip effect="dark" content="此名称会作为model类的verbose_name、菜单名称" placement="top-start">
                                    <el-icon><QuestionFilled /></el-icon>
                                </el-tooltip>
                            </template>
                            <el-input v-model="formData.verbose_name" placeholder="请输入表中文名称"></el-input>
                        </el-form-item>
                    </el-col>
                    <el-col :span="4">
                        <el-form-item label="类名(class)：" prop="class_name">
                            <template #label>
                                类名(class)：
                                <el-tooltip effect="dark" content="此名称会作为model的类名、后端路由前缀、前端路由通用名、前后端通用文件名" placement="top-start">
                                    <el-icon><QuestionFilled /></el-icon>
                                </el-tooltip>
                            </template>
                            <el-input v-model="formData.class_name" placeholder="请输入Model模型类名"></el-input>
                        </el-form-item>
                    </el-col>
                    <el-col :span="4">
                        <el-form-item label="表名(db_table)：" prop="db_table">
                            <el-input v-model="formData.db_table" placeholder="请输入英文表名"></el-input>
                        </el-form-item>
                    </el-col>
                    <el-col :span="4">
                        <el-form-item label="备注：" prop="remark">
                            <el-input v-model="formData.remark" type="textarea" :rows="1" placeholder="请输入备注内容"></el-input>
                        </el-form-item>
                    </el-col>
                    <el-col :span="4">
                        <el-form-item label="上级菜单：" prop="parent_menu">
                            <template #label>
                                <span style="display: flex;align-items: center">
                                    上级菜单：
                                    <el-tooltip effect="dark" content="分配到指定菜单下：默认生成到【系统工具】菜单下" placement="top-start">
                                        <el-icon><QuestionFilled /></el-icon>
                                    </el-tooltip>
                                </span>
                            </template>
                            <el-tree-select v-model="formData.parent_menu" node-key="id" :data="menuData"
                                            check-strictly filterable clearable :render-after-expand="false"
                                            :props="{label:'name',value: 'id'}"
                                            style="width: 100%" placeholder="请选择/为空则为顶级" />
                        </el-form-item>
                    </el-col>
                    <el-col :span="4">
                        <el-form-item label="菜单排序：" prop="menu_sort">
                            <template #label>
                                <span style="display: flex;align-items: center">
                                    菜单排序：
                                    <el-tooltip effect="dark" content="指定菜单顺序，默认为99" placement="top-start">
                                        <el-icon><QuestionFilled /></el-icon>
                                    </el-tooltip>
                                </span>
                            </template>
                            <el-input-number v-model="formData.menu_sort" :step="1" style="width: 100%" />
                        </el-form-item>
                    </el-col>
                </el-row>
                <div class="ly-title" style="margin-bottom: 10px;">
                    通用配置
                </div>
                <div style="margin-bottom: 10px;">
                    <el-checkbox v-model="formData.other_config.create_datetime_filter" label="启用create_datetime过滤" size="large" />
                    <el-checkbox v-model="formData.other_config.mutiple_delete" label="启用批量删除" size="large" />
                    <el-checkbox v-model="formData.other_config.dialogFullscreen" label="弹窗全屏" size="large" />
                    <el-row :gutter="20">
                        <el-col :span="3">
                            <el-form-item label="弹窗宽度(px或%)：">
                                <el-input v-model="formData.other_config.dialogWidth"></el-input>
                            </el-form-item>
                        </el-col>
                        <el-col :span="3">
                            <el-form-item label="弹窗顶部(px或vh)：">
                                <el-input v-model="formData.other_config.dialogTop"></el-input>
                            </el-form-item>
                        </el-col>
                        <el-col :span="3">
                            <el-form-item label="表单标签位置：">
                                <el-select v-model="formData.other_config.formLabelPosition" clearable filterable>
                                    <el-option label="居右" value="right" />
                                    <el-option label="居左" value="left" />
                                    <el-option label="顶部" value="top" />
                                </el-select>
                            </el-form-item>
                        </el-col>
                        <el-col :span="3">
                            <el-form-item label="表单标签长度：">
                                <el-input v-model="formData.other_config.formLabelWidth"></el-input>
                            </el-form-item>
                        </el-col>
                    </el-row>
                </div>
                <div class="ly-title2">
                    字段信息
                </div>
                <el-alert title="系统默认继承CoreModel已设计了字段id\creator\modifier\dept_belong_id\update_datetime\create_datetime，请勿重复设计！！！" type="warning" show-icon/>
                <el-tabs v-model="tabsActiveName">
                    <el-tab-pane label="基础配置" :name="1">
                        <el-form-item prop="column">
                            <ly-form-table v-model="formData.column" drag-sort :add-template="addTemplate">
                                <el-table-column prop="field_name" label="字段名" min-width="170">
                                    <template #default="scope">
                                        <el-input v-model.trim="scope.row.field_name" placeholder="请输入字段列名(英文)" maxlength="50"></el-input>
                                    </template>
                                </el-table-column>
                                <el-table-column prop="label" label="显示名称" min-width="160">
                                    <template #default="scope">
                                        <el-input v-model="scope.row.verbose_name" placeholder="显示名称"></el-input>
                                    </template>
                                </el-table-column>
                                <el-table-column prop="field_type" label="类型" min-width="180">
                                    <template #default="scope">
                                        <el-select v-model="scope.row.field_type" placeholder="请选择" clearable filterable style="width: 100%" @change="handelFieldTypeChage(scope.row.field_type,scope.$index)">
                                            <el-option
                                                v-for="item in fieldTypeList"
                                                :key="item.value"
                                                :label="item.label"
                                                :value="item.value"
                                            >
                                            </el-option>
                                        </el-select>
                                    </template>
                                </el-table-column>
                                <el-table-column prop="foreign_key" label="外键项" min-width="85" align="center">
                                    <template #default="scope">
                                        <el-button text size="small" :type="scope.row.foreign_key.class_name==''?'':'primary'" @click="setForeignKey(scope.row.foreign_key)" v-if="scope.row.field_type == 'ForeignKey' || scope.row.field_type == 'ManyToManyField' || scope.row.field_type == 'OneToOneField'">外键项</el-button>
                                        <span v-else></span>
                                    </template>
                                </el-table-column>
                                <el-table-column prop="max_length" label="最大长度" min-width="90">
                                    <template #default="scope">
                                        <el-input v-model="scope.row.max_length" placeholder="最大长度" v-if="scope.row.field_type == 'CharField'"></el-input>
                                    </template>
                                </el-table-column>
                                <el-table-column prop="default" label="默认值" min-width="100">
                                    <template #default="scope">
                                        <el-input-number :controls="false" v-model="scope.row.default" placeholder="数值型" style="width: 100%;" v-if="scope.row.field_type == 'IntegerField' || scope.row.field_type == 'SmallIntegerField' || scope.row.field_type == 'PositiveIntegerField' || scope.row.field_type == 'PositiveSmallIntegerField' || scope.row.field_type == 'BigIntegerField' || scope.row.field_type == 'FloatField'" ></el-input-number>
                                        <el-switch  v-else-if="scope.row.field_type == 'BooleanField'" v-model="scope.row.default" placeholder="默认值"></el-switch>
                                        <el-input  v-else v-model="scope.row.default" placeholder="默认值"></el-input>
                                    </template>
                                </el-table-column>
                                <el-table-column prop="nullable" label="允许空" min-width="90" align="center">
                                    <template #header>
                                        <span style="display: flex;align-items: center">
                                            允许空
                                            <el-tooltip effect="dark" content="允许空时后端会创建null=True,blank=True 同时前端表单为非必填" placement="top-start">
                                                <el-icon><QuestionFilled /></el-icon>
                                            </el-tooltip>
                                        </span>
                                    </template>
                                    <template #default="scope">
                                        <el-checkbox v-model="scope.row.nullable"></el-checkbox>
                                    </template>
                                </el-table-column>
                                <el-table-column prop="unique" label="唯一" min-width="80" align="center">
                                    <template #header>
                                        <span style="display: flex;align-items: center">
                                            唯一
                                            <el-tooltip effect="dark" content="同unique属性" placement="top-start">
                                                <el-icon><QuestionFilled /></el-icon>
                                            </el-tooltip>
                                        </span>
                                    </template>
                                    <template #default="scope">
                                        <el-checkbox v-model="scope.row.unique"></el-checkbox>
                                    </template>
                                </el-table-column>
                                <el-table-column prop="db_index" label="索引" min-width="80" align="center">
                                    <template #header>
                                        <span style="display: flex;align-items: center">
                                            索引
                                            <el-tooltip effect="dark" content="为该字段创建索引，同db_index属性" placement="top-start">
                                                <el-icon><QuestionFilled /></el-icon>
                                            </el-tooltip>
                                        </span>
                                    </template>
                                    <template #default="scope">
                                        <el-checkbox v-model="scope.row.unique"></el-checkbox>
                                    </template>
                                </el-table-column>
                                <el-table-column prop="can_edit" label="编辑" min-width="80" align="center">
                                    <template #header>
                                        <span style="display: flex;align-items: center">
                                            编辑
                                            <el-tooltip effect="dark" content="新增/编辑时该字段是否在弹窗中显示" placement="top-start">
                                                <el-icon><QuestionFilled /></el-icon>
                                            </el-tooltip>
                                        </span>
                                    </template>
                                    <template #default="scope">
                                        <el-checkbox v-model="scope.row.can_edit"></el-checkbox>
                                    </template>
                                </el-table-column>
                                <el-table-column prop="can_search" label="编辑禁用" min-width="100" align="center">
                                    <template #header>
                                        <span style="display: flex;align-items: center">
                                            编辑禁用
                                            <el-tooltip effect="dark" content="编辑时禁用该字段（disabled属性），新增时不受此影响" placement="top-start">
                                                <el-icon><QuestionFilled /></el-icon>
                                            </el-tooltip>
                                        </span>
                                    </template>
                                    <template #default="scope">
                                        <el-checkbox v-model="scope.row.edit_disabled"></el-checkbox>
                                    </template>
                                </el-table-column>
                                <el-table-column prop="can_list" label="列表" min-width="80" align="center">
                                    <template #default="scope">
                                        <el-checkbox v-model="scope.row.can_list"></el-checkbox>
                                    </template>
                                </el-table-column>
                                <el-table-column prop="can_search" label="查询" min-width="80" align="center">
                                    <template #default="scope">
                                        <el-checkbox v-model="scope.row.can_search"></el-checkbox>
                                    </template>
                                </el-table-column>
                                <el-table-column prop="can_search" label="导出" min-width="80" align="center">
                                    <template #default="scope">
                                        <el-checkbox v-model="scope.row.can_export"></el-checkbox>
                                    </template>
                                </el-table-column>
                                <el-table-column label="查询方式" min-width="140" prop="search_type">
                                    <template #default="scope">
                                        <el-select v-model="scope.row.search_type" clearable filterable>
                                            <el-option label="=" value="=" />
                                            <el-option label="iexact" value="iexact" />
                                            <el-option label=">" value=">" />
                                            <el-option label=">=" value=">=" />
                                            <el-option label="<" value="<" />
                                            <el-option label="<=" value="<=" />
                                            <el-option label="in" value="in" />
                                            <el-option label="isnull" value="isnull" />
                                            <el-option label="icontains" value="icontains" />
                                            <el-option label="contains" value="contains" />
                                            <el-option label="startswith" value="startswith" />
                                            <el-option label="istartswith" value="istartswith" />
                                            <el-option label="endswith" value="endswith" />
                                            <el-option label="iendswith" value="iendswith" />
                                            <el-option label="range" value="range" />
                                            <el-option label="year" value="year" />
                                            <el-option label="year__lt" value="year__lt" />
                                            <el-option label="year__lt" value="year__lte" />
                                            <el-option label="year__gt" value="year__gt" />
                                            <el-option label="year__gt" value="year__gte" />
                                            <el-option label="month" value="month" />
                                            <el-option label="day" value="day" />
                                        </el-select>
                                    </template>
                              </el-table-column>
                                <el-table-column prop="form_type" label="表单类型" min-width="150">
                                    <template #default="scope">
                                        <el-select v-model="scope.row.form_type" placeholder="请选择" clearable filterable :disabled="scope.row.field_type === 'ForeignKey' || scope.row.field_type === 'OneToOneField' || scope.row.field_type === 'ManyToManyField'" style="width: 100%">
                                            <el-option
                                                v-for="item in formTypeList"
                                                :key="item.value"
                                                :label="item.label"
                                                :value="item.value"
                                            />
                                        </el-select>
                                    </template>
                                </el-table-column>
                                <el-table-column prop="dic_type" label="字典类型" min-width="160">
                                    <template #default="scope">
                                        <el-select v-model="scope.row.dic_type" placeholder="请选择" clearable filterable style="width: 100%">
                                            <el-option
                                                v-for="item in dicList"
                                                :key="item.value"
                                                :label="item.label"
                                                :value="item.value"
                                            />
                                        </el-select>
                                    </template>
                                </el-table-column>
                            </ly-form-table>
                        </el-form-item>
                    </el-tab-pane>
                    <el-tab-pane label="高级配置" :name="2">
                        <el-form-item prop="column" v-if="tabsActiveName==2">
                           <el-table :data="formData.column" border stripe rowKey="field_name">
                                <el-table-column type="index" width="60" align="center" label="排序"></el-table-column>
                                <el-table-column prop="field_name" label="字段名" width="170"></el-table-column>
                                <el-table-column prop="verbose_name" label="显示名称" width="160"></el-table-column>
                                <el-table-column prop="field_type" label="类型" width="180"></el-table-column>
                                <el-table-column prop="width" label="表单栅格列数" width="120">
                                    <template #default="scope">
                                        <el-input-number v-model="scope.row.form_span" :controls="false" placeholder="栅格列数" style="width: 100%"></el-input-number>
                                    </template>
                                </el-table-column>
                                <el-table-column prop="hide" label="列表显示" width="120" align="center">
                                    <template #header>
                                        <span style="display: flex;align-items: center">
                                            列表显示
                                            <el-tooltip effect="dark" content="el-table列自定义显示内容（写代码）,为空则正常显示该列信息" placement="top-start">
                                                <el-icon><QuestionFilled /></el-icon>
                                            </el-tooltip>
                                        </span>
                                    </template>
                                    <template #default="scope">
                                        <el-button :type="!!scope.row.list_display_custom?'primary':'info'" icon="edit" size="small" plain round @click="editListDisplayCustom(scope.row.list_display_custom,scope.$index)">编写代码</el-button>
                                    </template>
                                </el-table-column>
                               <el-table-column prop="width" label="列表宽度" width="100">
                                    <template #default="scope">
                                        <el-input v-model="scope.row.width" placeholder="请输入宽度"></el-input>
                                    </template>
                                </el-table-column>
                            </el-table>
                        </el-form-item>
                    </el-tab-pane>
                </el-tabs>

            </el-form>
            <template #footer>
                <el-button @click="handleClose" :loading="loadingSave">取消</el-button>
                <el-button type="primary" @click="submitData" :loading="loadingSave">确定</el-button>
            </template>
        </ly-dialog>
        <ly-dialog v-model="showListDisplayCustomDialog" v-if="showListDisplayCustomDialog" title="列自定义显示" width="60%" top="20px" :before-close="handleListDisplayCustomDialogClose">
            <div>
                <el-alert title="<template #default='scope'>" type="info" :closable="false" />
                <lyCodeEditor v-model="listDisplayCustomCode" mode="vue" height="500" :read-only="false"></lyCodeEditor>
                <el-alert title="</template>"  :closable="false" type="info"></el-alert>
            </div>
            <template v-slot:footer>
                <el-button @click="handleListDisplayCustomDialogClose" size="default">关闭</el-button>
                <el-button type="primary" @click="saveListDisplayCustom" size="default">确定</el-button>
            </template>
        </ly-dialog>
        <el-drawer title="外键配置" v-model="foreignKeyVisible" :size="500" destroy-on-close v-if="foreignKeyVisible">
			<el-main style="padding:0 20px 20px 20px">
                <el-empty description="description" v-if="foreignKeyVisible=='' || foreignKeyVisible.length == 0" />
                <el-form v-else label-width="160px" :model="foreignKeySetting">
                    <el-form-item label="外键类名：">
                        <el-select v-model="foreignKeySetting.class_name" style="width: 100%" clearable filterable placeholder="请选择">
                            <el-option
                                v-for="item in modelsData"
                                :key="item.class_name"
                                :label="item.table_name"
                                :value="item.class_name">
                                 <span>{{ item.class_name+':'+item.table_name }}</span>
                            </el-option>
                        </el-select>
                    </el-form-item>
                    <el-form-item label="删除方法：">
                        <el-select v-model="foreignKeySetting.on_delete" style="width: 100%" clearable filterable placeholder="请选择">
                            <el-option label="CASCADE" value="CASCADE" />
                            <el-option label="PROTECT" value="PROTECT" />
                            <el-option label="SET_NULL" value="SET_NULL" />
                        </el-select>
                    </el-form-item>
                    <el-form-item label="主键字段（value）：">
                        <template #label>
                            <span style="display: flex;align-items: center">
                                主键字段（value）：
                                <el-tooltip effect="dark" content="该外键的主键model字段名" placement="top-start">
                                    <el-icon><QuestionFilled /></el-icon>
                                </el-tooltip>
                            </span>
                        </template>
                        <el-input v-model.trim="foreignKeySetting.value" placeholder="该外键的主键model字段名"></el-input>
                    </el-form-item>
                    <el-form-item label="显示字段（label）：">
                        <template #label>
                            <span style="display: flex;align-items: center">
                                显示字段（label）：
                                <el-tooltip effect="dark" content="该外键的前端新增/编辑时要显示的字段，默认若【外键字段（可选）】不配置，则列表显示该字段信息" placement="top-start">
                                    <el-icon><QuestionFilled /></el-icon>
                                </el-tooltip>
                            </span>
                        </template>
                        <el-input v-model.trim="foreignKeySetting.label" placeholder="该外键的前端新增/编辑时要显示的字段"></el-input>
                    </el-form-item>
                    <el-form-item label="API地址PATH：">
                        <template #label>
                            <span style="display: flex;align-items: center">
                                API地址PATH：
                                <el-tooltip effect="dark" content="用户新增编辑时选择外键时使用" placement="top-start">
                                    <el-icon><QuestionFilled /></el-icon>
                                </el-tooltip>
                            </span>
                        </template>
                        <el-select  v-model.trim="foreignKeySetting.api_path" filterable clearable  allow-create style="width: 100%;" placeholder="请选择或手动输入">
                            <el-option
                                v-for="item in apiList"
                                :key="item.value"
                                :label="item.label"
                                :value="item.value">
                            </el-option>
                        </el-select>
                    </el-form-item>
                    <el-form-item label="搜索字段（可选）：">
                        <template #label>
                            <span style="display: flex;align-items: center">
                                搜索字段（可选）：
                                <el-tooltip effect="dark" content="配合【查询方式】搜索，可为空，为空则默认为该字段的主键搜索" placement="top-start">
                                    <el-icon><QuestionFilled /></el-icon>
                                </el-tooltip>
                            </span>
                        </template>
                        <el-input v-model.trim="foreignKeySetting.search_field"  clearable placeholder="输入要搜素的字段名"></el-input>
                    </el-form-item>
                    <el-form-item label="外键字段（可选）：">
                        <template #label>
                            <span style="display: flex;align-items: center">
                                外键字段（可选）：
                                <el-tooltip effect="dark" content="配合【高级配置】->【列表展示】可自定义列表显示，多个字段用,逗号分割（英文逗号）,如：name,mobile" placement="top-start">
                                    <el-icon><QuestionFilled /></el-icon>
                                </el-tooltip>
                            </span>
                        </template>
                        <el-input v-model="foreignKeySetting.field_list" :rows="2" type="textarea" placeholder="多个字段用,逗号分割（英文逗号）,如：name,mobile" @change="checkDot"></el-input>
                    </el-form-item>
                </el-form>
			</el-main>
		</el-drawer>
    </div>
</template>

<script>
    import {autocodeAutocodeAdd,autocodeAutocodeEdit,apiGetdictionary,systemDictionary,apiSystemMenu,platformsettingsSysconfigGetmodelsInfoList,getSystemLyapiList} from "@/api/api";
    import LyDialog from "@/components/dialog/dialog";
    import LyFormTable from "@/components/lyFormTable";
    import XEUtils from "xe-utils";
    import {deepClone} from "@/utils/util";
    import {useDictStore} from "@/store/dict";
    import lyCodeEditor from '@/components/lyform-builder/code-editor';

    export default {
        name: "addModuleCodeGenerate",
        components: {LyFormTable, LyDialog,lyCodeEditor},
        emits: ['refreshData'],
        setup(){
            const dictStore = useDictStore()
            return { dictStore }
        },
        data() {
            return {
                dialogVisible:false,
                loadingSave:false,
                loadingPage:false,
                loadingTitle:'',
                fullscreen:true,
                tabsActiveName:1,
                showListDisplayCustomDialog:false,
                listDisplayCustomCode:"",
                listDisplayCustomCodeIndex:0,
                formData:{
                    verbose_name:'',
                    class_name:'',
                    db_table:'',
                    remark:'',
                    parent_menu:'0ec714765ec64611ad8396089b30257e',
                    menu_sort:99,
                    column:[],
                    other_config:{
                        create_datetime_filter:false,
                        mutiple_delete:false,
                        dialogWidth:"50%",
                        dialogTop:"15vh",
                        dialogFullscreen:false,
                        formLabelPosition:"right",
                        formLabelWidth:"auto",
                    }
                },
                rules:{
                    verbose_name: [
                        {required: true, message: '请输入标题名称',trigger: 'blur'}
                    ],
                    class_name: [
                        {required: true, message: '请输入Model类名',trigger: 'blur'}
                    ],
                    db_table: [
                        {required: true, message: '请输入英文表名',trigger: 'blur'}
                    ],
                },
                addTemplate: {
					field_name: '',
                    verbose_name: '',
					field_type: '',
                    foreign_key:{
					    class_name:'',
                        on_delete:'',
                        label:'',//显示
                        value:'id',//主键
                        api_path:'',//前端api的去除api前缀的地址
                        field_list:'',//可选，外键详情字段，多个字段用,(英文)逗号分割
                        search_field:'',//可选，需要搜索的字段
                    },
                    max_length: '',
                    default:null,
                    nullable:true,
                    unique:false,
                    db_index:false,//后端创建索引
                    can_edit:true,
                    edit_disabled:false,//编辑时禁用
                    can_list:true,
                    can_search:false,
                    can_export:false,//该字段是否允许导出
                    search_type:'',
                    form_type:'',
                    dic_type:'',
					width: '100',
                    list_display_custom:'',//el-table列自定义显示
                    form_span:24,//layout布局栅格列数
				},
                fieldTypeList:[],
                formTypeList:[],
                dicList:[],
                filtersAddTemplate: {
					label: '',
					value: ''
				},
                setFiltersVisible: false,
                foreignKeySetting:{},
                foreignKeyVisible:false,
                menuData:[],
                modelsData:[],
                apiList:[]
            }
        },
        methods:{
            handleClose() {
                this.dialogVisible=false
                this.loadingSave=false
                this.loadingPage=false
                this.tabsActiveName = 1
                this.formData = {
                    verbose_name:'',
                    class_name:'',
                    db_table:'',
                    remark:'',
                    parent_menu:'0ec714765ec64611ad8396089b30257e',
                    menu_sort:99,
                    column:[],
                    other_config:{
                        create_datetime_filter:false,
                        mutiple_delete:false,
                        dialogWidth:"50%",
                        dialogTop:"15vh",
                        dialogFullscreen:false,
                        formLabelPosition:"right",
                        formLabelWidth:"auto",
                    }
                }
                this.showListDisplayCustomDialog = false
                this.listDisplayCustomCode = ""
                this.listDisplayCustomCodeIndex = 0
                this.$emit('refreshData')
            },
            addModuleFn(item,flag) {
                this.dialogVisible=true
                this.loadingTitle=flag
                this.loadingPage = true

                this.getFieldType()
                this.getFormType()
                this.getDicList()
                this.getMenuData()
                this.getModlesListInfo()

                if(item){
                    this.formData = deepClone(item)
                }
            },
            getSystemLyapi(){
                getSystemLyapiList().then(res=>{
                    var result = Object.keys(res.paths)
                    var data = []
                    for (const item of result) {
                        const obj = {}
                        if(item.indexOf("{id}") == -1 && item.indexOf("/multiple_delete/") == -1){
                            let newurl = item.replace("/api/","")
                            obj.label = newurl
                            obj.value = newurl
                            data.push(obj)
                        }

                    }
                    this.apiList = data
                })
            },
            //获取models列表信息
            async getModlesListInfo(){
                platformsettingsSysconfigGetmodelsInfoList().then(res=>{
                    if(res.code == 2000){
                        this.modelsData = res.data
                        this.modelsData.push({
                            class_name:'self',
                            db_table:'',
                            import_path:'',
                            tableFields:[],
                            table_name:'self'
                        })
                    }
                })
            },
            //获取列表
            async getMenuData(){
                this.loadingPage = true
                apiSystemMenu({page:1, limit:999}).then(res => {
                    this.loadingPage = false
                     if(res.code ==2000) {
                         // 将列表数据转换为树形数据
                         this.menuData = XEUtils.toArrayTree(res.data.data, { parentKey: 'parent' })
                     } else {
                         this.$message.warning(res.msg)
                     }
                 })
            },
            getFieldType(){
                this.fieldTypeList = this.dictStore.data['model_field_type']
                // apiGetdictionary({code:'model_field_type'}).then(res=>{
                //     if(res.code == 2000){
                //         this.fieldTypeList = res.data
                //     }
                // })
            },
            getFormType(){
                this.formTypeList = this.dictStore.data['form_type']
                // apiGetdictionary({code:'form_type'}).then(res=>{
                //     if(res.code == 2000){
                //         this.formTypeList = res.data
                //     }
                // })
            },
            async getDicList(){
                systemDictionary({parent_isnull:true,page:1,limit:999}).then(res => {
                     if(res.code ==2000) {
                         this.dicList = res.data.data
                     }
                 })
            },
            checkDot(val){
                if(!!val){
                    if(val.indexOf("，")>0){
                        this.$message.warning("【外键字段】请使用英文逗号,")
                        return
                    }else if(val.endsWith(",")>0){
                        this.$message.warning("【外键字段】不能以逗号结尾")
                        return
                    } else if(val.startWith(",")>0){
                        this.$message.warning("【外键字段】不能以逗号开始")
                        return
                    }
                }
            },
            //设置外键项
			setForeignKey(data){
                this.getSystemLyapi()
                this.foreignKeySetting = data
				this.foreignKeyVisible = true
			},
            //外键表单类型锁定
            handelFieldTypeChage(field,index){
                if(field === 'ForeignKey' || field === 'OneToOneField' || field === 'ManyToManyField'){
                    this.formData.column[index].form_type = "select"
                }
            },
            editListDisplayCustom(val,index){
                this.showListDisplayCustomDialog = true
                this.listDisplayCustomCode = val
                this.listDisplayCustomCodeIndex = index
            },
            handleListDisplayCustomDialogClose(){
                this.showListDisplayCustomDialog = false
                this.listDisplayCustomCode = ''
                this.listDisplayCustomCodeIndex = 0
            },
            saveListDisplayCustom() {
                this.formData.column[this.listDisplayCustomCodeIndex].list_display_custom = this.listDisplayCustomCode
                this.handleListDisplayCustomDialogClose()
            },
            submitData() {
                this.$refs['rulesForm'].validate(obj=>{
                    if(obj) {
                        this.loadingSave=true
                        let param = {
                            ...this.formData
                        }
                        if(this.formData.id){
                            autocodeAutocodeEdit(param).then(res=>{
                                this.loadingSave=false
                                if(res.code ==2000) {
                                    this.$message.success(res.msg)
                                    this.handleClose()
                                } else {
                                    this.$message.warning(res.msg)
                                }
                            })
                        }else{
                            autocodeAutocodeAdd(param).then(res=>{
                                this.loadingSave=false
                                if(res.code ==2000) {
                                    this.$message.success(res.msg)
                                    this.handleClose()
                                } else {
                                    this.$message.warning(res.msg)
                                }
                            })
                        }

                    }
                })
            },
        }
    }
</script>

<style scoped>
    .ly-title{
        border-bottom: 1px solid #eee;
        margin-bottom: 20px;
        font-size: 17px;
        padding-bottom: 15px;
        color: #3c4a54;
        font-weight: bold;
    }
    .ly-title2{
        border-bottom: 1px solid #eee;
        margin-bottom: 10px;
        font-size: 17px;
        padding-bottom: 15px;
        color: #3c4a54;
        font-weight: bold;
    }
</style>

