<template>
  <div>
    <ly-dialog v-model="dialogVisible" :title="loadingTitle" width="680px" :before-close="handleClose">
      <el-form :inline="false" :model="formData" :rules="rules" ref="rulesForm" label-position="right"
               label-width="auto">
        <el-form-item label="指定区域：" prop="region_name">
          <div style="display: flex;width: 100%;">
            <el-input v-model.trim="formData.region_name" type="textarea" autosize :disabled="true"
                      :autosize="{ minRows: 2, maxRows: 4 }" style="margin-right: 10px;"></el-input>
            <el-button
                type="primary" @click="handleClickChoiceArea" :disabled="formData.is_default">选择
            </el-button>
          </div>
        </el-form-item>
        <!--                <el-form-item label="首重（kg）：" prop="sz">-->
        <!--                    <el-input-number v-model="formData.sz" style="width: 100%;" :controls="false"></el-input-number>-->
        <!--                </el-form-item>-->
        <el-form-item label="首数量(份)：" prop="sz">
          <el-input-number v-model="formData.first_num" style="width: 100%;" :controls="false"></el-input-number>
        </el-form-item>
        <el-form-item label="运费（元）：" prop="yf">
          <el-input-number v-model="formData.yf" style="width: 100%;" :controls="false"></el-input-number>
        </el-form-item>
        <!--                <el-form-item label="续重（kg）：" prop="xz">-->
        <!--                    <el-input-number v-model="formData.xz" style="width: 100%;" :controls="false"></el-input-number>-->
        <!--                </el-form-item>-->
        <el-form-item label="续数量(份)：" prop="sz">
          <el-input-number v-model="formData.add_num" style="width: 100%;" :controls="false"></el-input-number>
        </el-form-item>
        <el-form-item label="续费（元）：" prop="xf">
          <el-input-number v-model="formData.xf" style="width: 100%;" :controls="false"></el-input-number>
        </el-form-item>
        <el-form-item label="运费备注：" prop="name">
          <el-input v-model.trim="formData.comment" placeholder="请输入备注" style="width: 100%;"></el-input>
        </el-form-item>
      </el-form>
      <template #footer>
        <el-button @click="handleClose" :loading="loadingSave">取消</el-button>
        <el-button type="primary" @click="submitData" :loading="loadingSave">确定</el-button>
      </template>
    </ly-dialog>
    <lyAreaSelectTree ref="addModuleFlag" @refreshData="handleAreaSelect" v-if="selectDialogVisible"
                      @closed="selectDialogVisible = false">
    </lyAreaSelectTree>
  </div>
</template>

<script>
import {mallFreightcfgAdd, mallFreightcfgEdit, mallFreightcfgSeleted} from "@/api/api";
import LyDialog from "@/components/dialog/dialog";
import {deepClone} from "@/utils/util"
import lyAreaSelectTree from "@/components/lyAreaSelectTreeV2"

export default {
  components: {LyDialog, lyAreaSelectTree},
  emits: ['refreshData'],
  name: "addModuleFreight",
  data() {
    return {
      dialogVisible: false,
      loadingSave: false,
      loadingTitle: '',
      selectDialogVisible: false,
      formData: {
        region_name: '',
        sz: null,
        yf: null,
        xz: null,
        xf: null,
        area: null,
        is_default: false,
      },
      selectedList: [],
      rules: {
        region_name: [
          {required: true, message: '请选择指定区域', trigger: 'blur'}
        ],
        sz: [
          {required: true, message: '请输入首重', trigger: 'blur'}
        ],
        yf: [
          {required: true, message: '请输入运费', trigger: 'blur'}
        ],
        xz: [
          {required: true, message: '请输入续重', trigger: 'blur'}
        ],
        xf: [
          {required: true, message: '请输入续费', trigger: 'blur'}
        ],
      }
    }
  },
  mounted() {
    window.addEventListener("focusin", this.onFocusIn, true);
  },
  unmounted() {
    window.removeEventListener("focusin", this.onFocusIn);
  },
  methods: {
    onFocusIn(e) {
      e.stopImmediatePropagation()//阻止当前和后面的一系列事件
    },
    handleClose() {
      this.dialogVisible = false
      this.loadingSave = false
      this.selectDialogVisible = false
      this.formData = {
        region_name: '',
        sz: null,
        yf: null,
        xz: null,
        xf: null,
        area: null,
        is_default: false,
      }
      this.selectedList = []
    },
    addModuleFn(item, flag) {
      this.loadingTitle = flag
      this.dialogVisible = true
      let id = ""
      if (item) {
        this.formData = deepClone(item)
        id = this.formData.id
      }
      this.getfmallFreightcSelected(id)
    },
    getfmallFreightcSelected(id) {
      mallFreightcfgSeleted({id: id}).then(res => {
        if (res.code === 2000) {
          this.selectedList = res.data
        }
      })
    },
    handleAreaSelect(selectIdArr, selectNames) {
      this.formData.area = selectIdArr
      this.formData.region_name = selectNames
    },
    handleClickChoiceArea() {
      this.selectDialogVisible = true
      this.$nextTick(() => {
        this.$refs.addModuleFlag.handleOpen(this.formData.area, this.formData.region_name, this.selectedList, "选择区域")
      })
    },
    submitData() {
      this.$refs['rulesForm'].validate(obj => {
        if (obj) {
          this.loadingSave = true
          let param = {
            ...this.formData
          }
          if (param.min === "" || param.max === "") {
            this.$message.warning("请输入入位范围")
            return
          }
          if (this.formData.id) {
            mallFreightcfgEdit(param).then(res => {
              this.loadingSave = false
              if (res.code == 2000) {
                this.$message.success(res.msg)
                this.handleClose()
                this.$emit('refreshData')
              } else {
                this.$message.warning(res.msg)
              }
            })
          } else {
            mallFreightcfgAdd(param).then(res => {
              this.loadingSave = false
              if (res.code == 2000) {
                this.$message.success(res.msg)
                this.handleClose()
                this.$emit('refreshData')
              } else {
                this.$message.warning(res.msg)
              }
            })
          }

        }
      })
    },

  }
}
</script>

<style>
.set-specs .el-form-item__content {
  background: #e6e6e6 !important;
}
</style>

