<template>
  <el-menu class="el-menu-demo" mode="horizontal" background-color="var(--l-header-bg)" text-color="#ffffff"
           active-text-color="#ffffff" :ellipsis="false">
    <div :style="collapsed ? collapsedWidth : 'width:90px;margin-left:0'" class="divleft" v-if="lyLayout=='simple'">
      <p class="login-inner" @click.stop="" style="text-align: center;"
         :style="collapsed ? loginInnerWidth : 'width:90px'">
        <img class="logoimg" src="../assets/logo.png" alt=""
             :style="collapsed ? 'width:40px' : 'width:24px;height:24px;margin-left:0'">
      </p>
      <img class="showimg" :src="collapsed?imgsq:imgshow" @click.stop="toggle(collapsed)">
    </div>
    <div style="padding-left: 18px;" class="divleft" v-if="lyLayout=='msimple'">
      <p class="login-inner" @click.stop="">
        <img class="logoimg" src="../assets/logo.png" alt="" style="width:40px;margin-right: 10px;">
      </p>
      <span style="font-size: 20px;color: white">{{ config.APP_TITLE }}</span>
    </div>
    <!--      <span class="site-title">Django-Vue-Lyadmin后台管理系统</span>-->
    <!--      <el-row class="buttonimg" type="info">-->
    <!--      </el-row>-->
    <div class="ly-header-right">
      <el-link style="font-size: 15px;" type="warning" icon="Document" target="_blank"
               href="https://doc.lybbn.cn/articles/%E6%96%87%E6%A1%A3/%E6%9B%B4%E6%96%B0%E6%97%A5%E5%BF%97-%E4%B8%93%E4%B8%9A%E7%89%88.html">
        更新日志
      </el-link>
      <span style="padding: 0 10px;" @click="handleFullScreen" v-if="!isFullscreen">
                <el-tooltip
                    class="box-item"
                    effect="dark"
                    content="全屏"
                    placement="bottom">
                    <el-icon style="font-size: 16px;color: white;"><full-screen/></el-icon>
                </el-tooltip>
            </span>
      <span style="padding: 0 10px;" @click="handleFullScreen" v-if="isFullscreen">
                <el-tooltip
                    class="box-item"
                    effect="dark"
                    content="退出全屏"
                    placement="bottom">
                    <el-icon style="font-size: 16px;color: white;"><Minus/></el-icon>
                </el-tooltip>
            </span>
      <span style="padding: 0 10px;" @click="setSiteTheme">
                <el-tooltip
                    class="box-item"
                    effect="dark"
                    content="暗黑模式"
                    placement="bottom">
                    <el-icon style="font-size: 16px;color: white;" v-if="siteThemeStore.siteTheme == 'dark'"><Sunny/></el-icon>
                    <el-icon style="font-size: 16px;color: white;" v-if="siteThemeStore.siteTheme == 'light'"><Moon/></el-icon>
                </el-tooltip>
            </span>
      <span style="padding: 0 10px;">
                <el-color-picker v-model="colorPrimary" :predefine="colorList" size="small"
                                 @change="setColorPrimary"></el-color-picker>
            </span>
      <span>
              <el-dropdown trigger="click" class="right-dropdown-center">
                <span class="el-dropdown-link">
                  <el-avatar :size="30">{{ userNameF }}</el-avatar>
                  {{ userName }}
                  <el-icon class="el-icon--right">
                    <arrow-down/>
                  </el-icon>
                </span>
                <template #dropdown>
                  <el-dropdown-menu>
                      <div v-show="hasPermission('personalCenter','Search')">
                          <el-dropdown-item @click="jumpto"><el-icon><User/></el-icon>个人中心</el-dropdown-item>
                      </div>
                      <div>
                          <el-dropdown-item @click="settingDialog=true"><el-icon><Setting/></el-icon>布局设置</el-dropdown-item>
                      </div>
                      <div>
                          <el-dropdown-item @click="clearCache"><el-icon><DeleteFilled/></el-icon>清除缓存</el-dropdown-item>
                      </div>
                      <el-dropdown-item @click="exit"><el-icon><Close/></el-icon>退出登录</el-dropdown-item>
                  </el-dropdown-menu>
                </template>
              </el-dropdown>
          </span>
      <el-drawer title="布局设置" v-model="settingDialog" :size="400" append-to-body destroy-on-close>
        <lysettings></lysettings>
      </el-drawer>
    </div>
  </el-menu>
</template>
<script setup>
import {computed, getCurrentInstance, nextTick, onBeforeUnmount, onMounted, ref} from 'vue'
import {ElMessage, ElMessageBox} from 'element-plus'
import screenfull from 'screenfull'
import {useMutitabsStore} from "@/store/mutitabs";
import {useSiteThemeStore} from "@/store/siteTheme";
import {useRouter} from 'vue-router'
import config from "@/config"
import lysettings from "@/components/layout/lysettings";

let settingDialog = ref(false)

const router = useRouter()
let bus = getCurrentInstance().appContext.config.globalProperties.$Bus; // 声明$Bus
const mutitabsStore = useMutitabsStore()
const siteThemeStore = useSiteThemeStore()

const lyLayout = computed(() => {
  return siteThemeStore.programLayout
})

let collapsed = ref(siteThemeStore.collapsed)
let imgshow = require('../assets/img/show.png')
let imgsq = require('../assets/img/sq.png')
let userName = ref("")
let userNameF = ref("")
let mobileWidth = ref(992)
let isFullscreen = ref(mutitabsStore.isFullscreen)
let colorList = ref(['#409EFF', '#536dfe', '#722ed1', '#009688', '#52c41a', '#faad14', '#ff5c93', '#c62f2f', '#fd726d'])
let colorPrimary = ref(siteThemeStore.colorPrimary || '#409EFF')

function setColorPrimary() {
  siteThemeStore.setColorPrimary(colorPrimary.value)
}

let collapsedWidth = computed(() => {
  return 'width:' + (siteThemeStore.menuWidth + 25) + 'px'
})
let loginInnerWidth = computed(() => {
  return 'width:' + (siteThemeStore.menuWidth + 15) + 'px'
})

//路由跳转
function jumpto() {
  mutitabsStore.switchtab('personalCenter')
}

// 清除缓存
function clearCache() {
  ElMessageBox.confirm('清除缓存会清除本地所有缓存并退出登录?', '提示', {
    confirmButtonText: '确定',
    cancelButtonText: '取消',
    type: 'warning'
  }).then(() => {
    mutitabsStore.logout('false')
    sessionStorage.clear()
    localStorage.clear()
    siteThemeStore.$reset()
    ElMessage.success('已退出登录!')
    // window.location.pathname = '/login'
    router.replace({path: '/login'}).then(() => {
      window.location.reload()
    })
  }).catch(() => {
  })
}

// 退出登录
function exit(e) {
  ElMessageBox.confirm('退出登录, 是否继续?', '提示', {
    confirmButtonText: '确定',
    cancelButtonText: '取消',
    type: 'warning'
  }).then(() => {
    mutitabsStore.logout()
    ElMessage.success('已退出登录!')
    // window.location.pathname = '/login'
    router.replace({path: '/login'}).then(() => {
      window.location.reload()
    })
  }).catch(() => {
  })
}

// 切换显示
function toggle(showtype) {
  collapsed.value = !showtype
  siteThemeStore.setCollapsed(collapsed.value)
  bus.emit('toggle', collapsed.value)
}

//全屏显示
function handleFullScreen() {
  if (!screenfull.isEnabled) {
    ElMessage.warning('您的浏览器不支持全屏!')
    return false
  } else {
    screenfull.toggle()
    isFullscreen.value = !screenfull.isFullscreen
  }

}

//设置主题
function setSiteTheme() {
  if (siteThemeStore.siteTheme == 'light') {
    siteThemeStore.setSiteTheme('dark')
  } else {
    siteThemeStore.setSiteTheme('light')
  }
}

//解决table 表格缩放错位问题
function handleResize() {
  collapsed.value = isMobile()
  toggle(collapsed.value)
}

function isMobile() {
  let htmlWidth = document.documentElement.clientWidth || document.body.clientWidth;
  if (htmlWidth > mobileWidth.value) {
    return false
  }
  return true
}

onMounted(() => {
  userName.value = mutitabsStore.userName
  userNameF.value = userName.value.substring(0, 1);
  window.addEventListener('resize', handleResize);
  nextTick(() => {
    handleResize
  })
  bus.on('fullScreen', value => {
    isFullscreen.value = value
  })
})

onBeforeUnmount(() => {
  window.removeEventListener("resize", handleResize);
})


</script>
<style lang="scss" scoped>
.divleft {
  display: flex;
  align-items: center;
}

.site-title {
  color: #ffffff;
  font-size: 20px;
}

.right-dropdown-center {
  color: #ffffff;
  display: flex;
  align-items: center;
  margin-left: 10px;
}

::v-deep(.el-dropdown--default) {
  height: 18px !important;
}

::v-deep(.el-dropdown) {
  line-height: unset !important;
}

::v-deep(.el-icon--right) {
  margin-left: 0 !important;
}

.ly-header-right {
  height: 100%;
  display: flex;
  align-items: center;
  cursor: pointer;
  justify-content: center;
  padding-right: 15px;
  background-color: var(--l-header-bg) !important;
}

.ly-header-right:hover {
  background: var(--l-header-bg) !important;
}

.el-menu-vertical-demo:not(.el-menu--collapse) {
  border: none;
}

.is-console {
  float: right;
  margin: 14px;
}

.buttonimg {
  height: 60px;
  background-color: transparent;
  border: none;
  position: relative;
  float: left;
  cursor: pointer;
}

.showimg {
  width: 26px;
  height: 26px;
  cursor: pointer;
  /*position: absolute;*/
  /*top: 17px;*/
  /*left: 0;*/
}

.showimg:active {
  border: none;
}

.logobox {
  height: 40px;
  line-height: 40px;
  color: #9d9d9d;
  /*color: #FFFFFF;*/
  font-size: 20px;
  text-align: center;
  /*margin-left: 20px;*/
  display: inline-block;
  outline: none;
  float: left;
}

.logoimg {
  height: 40px;
  cursor: pointer;
}

.el-menu-demo {
  height: 60px;
  display: flex;
  justify-content: space-between;
  align-items: center;

  .el-menu-item.is-active {
    position: relative;
    background-color: var(--l-header-bg) !important;
  }

  li {
    height: 100%;

    &.logo-outer {
      display: flex;
      justify-content: space-between;
      align-items: center;
      background: none !important;
      margin: 0;
      padding: 0;

      .login-inner {
        flex: 1;
        display: flex;
        align-items: center;
        justify-content: center;

      }
    }
  }
}

.el-menu.el-menu--horizontal {
  border-bottom: 0;
}
</style>