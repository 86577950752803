<template>
    <div :class="{'ly-is-full':isFull}">
        <div class="tableSelect" ref="tableSelect">
            <el-form :model="formInline">
                <el-form-item label="关键字：">
                    <el-input v-model="formInline.search" maxlength="60"  clearable placeholder="请输入表名称/类名称/显示名称" @change="search" style="width:200px"></el-input>
                </el-form-item>
                <el-form-item label="" v-if="hasPermission(route.name,'Search')"><el-button  @click="search" type="primary" icon="Search">查询</el-button></el-form-item>
                <el-form-item label=""><el-button  @click="handleEdit('','reset')" icon="Refresh">重置</el-button></el-form-item>
                <el-form-item label="" v-if="hasPermission(route.name,'Syncdb')"><el-button type="warning" icon="Refresh" @click="syncdb">同步数据库</el-button></el-form-item>
            </el-form>
        </div>
        <div class="table">
            <el-table  :height="tableHeight" border :data="tableData" ref="tableref" v-loading="loadingPage" style="width: 100%">
                <el-table-column type="index" width="60" align="center" label="序号">
                    <template #default="scope">
                        <span v-text="getIndex(scope.$index)"></span>
                    </template>
                </el-table-column>
                <el-table-column min-width="90" prop="id" label="ID"></el-table-column>
                <el-table-column min-width="130" prop="verbose_name" label="名称"></el-table-column>
                <el-table-column min-width="130" prop="class_name" label="类名"></el-table-column>
                <el-table-column min-width="130" prop="db_table" label="表名"></el-table-column>
<!--                <el-table-column min-width="150" prop="remark" label="备注" show-overflow-tooltip></el-table-column>-->
                <el-table-column min-width="150" prop="create_datetime" label="创建时间"></el-table-column>
                <el-table-column min-width="150" prop="update_datetime" label="更新时间"></el-table-column>
                <el-table-column label="操作" fixed="right" width="270">
                    <template #header>
                        <div style="display: flex;justify-content: space-between;align-items: center;">
                            <div>操作</div>
                            <div @click="setFull">
                                <el-tooltip content="全屏" placement="bottom">
                                    <el-icon ><full-screen /></el-icon>
                                </el-tooltip>
                            </div>
                        </div>
                    </template>
                    <template #default="scope">
                        <span class="table-operate-btn" @click="handleEdit(scope.row,'goDesign')" v-if="hasPermission(route.name,'Retrieve')">设计模板</span>
                        <el-tooltip effect="dark" content="分配到指定菜单下：默认生成到【系统工具】菜单下" placement="top" v-if="hasPermission(route.name,'MenuConfig')">
                            <span class="table-operate-btn" @click="handleEdit(scope.row,'Edit')">菜单配置</span>
                        </el-tooltip>
                        <el-tooltip effect="dark" content="生成并覆盖前后端代码的相关CRUD文件，并在数据库生成菜单/路由/按钮/" placement="top" v-if="hasPermission(route.name,'MountSync')">
                            <span class="table-operate-btn" @click="generateMount(scope.row)">同步挂载</span>
                        </el-tooltip>
                        <span class="table-operate-btn" @click="handleEdit(scope.row,'delete')" v-if="hasPermission(route.name,'Delete')">删除</span>
                    </template>
                </el-table-column>
            </el-table>
        </div>
        <ly-dialog v-model="showMenuConfigDialog" v-if="showMenuConfigDialog" :title="dialogTitle" width="680px" :before-close="handleDialogClose">
            <el-form :inline="false" :model="formData" :rules="rules" ref="rulesForm" label-position="top" label-width="auto">
                <el-row :gutter="20">
                    <el-col :span="24">
                        <el-form-item label="上级菜单：" prop="parent_menu">
                            <template #label>
                                <span style="display: flex;align-items: center">
                                    上级菜单：
                                    <el-tooltip effect="dark" content="分配到指定菜单下：默认生成到【系统工具】菜单下" placement="top-start">
                                        <el-icon><QuestionFilled /></el-icon>
                                    </el-tooltip>
                                </span>
                            </template>
                            <el-tree-select v-model="formData.parent_menu" node-key="id" :data="menuData"
                                            check-strictly filterable clearable :render-after-expand="false"
                                            :props="{label:'name',value: 'id'}"
                                            style="width: 100%" placeholder="请选择/为空则为顶级" />
                        </el-form-item>
                    </el-col>
                    <el-col :span="24">
                        <el-form-item label="菜单排序：" prop="menu_sort">
                            <template #label>
                                <span style="display: flex;align-items: center">
                                    菜单排序：
                                    <el-tooltip effect="dark" content="指定菜单顺序，默认为88" placement="top-start">
                                        <el-icon><QuestionFilled /></el-icon>
                                    </el-tooltip>
                                </span>
                            </template>
                            <el-input-number v-model="formData.menu_sort" :step="1" style="width: 100%" />
                        </el-form-item>
                    </el-col>
                </el-row>
            </el-form>
            <template #footer>
                <el-button @click="handleDialogClose" :loading="loadingSave">取消</el-button>
                <el-button type="primary" @click="submitEditMenuData" :loading="loadingSave">确定</el-button>
            </template>
        </ly-dialog>
        <Pagination v-bind:child-msg="pageparm" @callFather="callFather"></Pagination>
    </div>
</template>

<script setup>
    import {ref,reactive,onMounted,nextTick} from 'vue'
    import Pagination from "@/components/Pagination";
    import { ElMessage,ElMessageBox } from 'element-plus';
    import {dateFormats,getTableHeight,hasPermission} from "@/utils/util";
    import {
        apiSystemMenu,
        lyformbuilder,
        lyformbuilderEditMenu,
        lyformbuilderDelete,
        lyformbuilderGeneratemount,
        lyformbuilderSyncdb,
    } from '@/api/api'
    import {useMutitabsStore} from "@/store/mutitabs";
    import LyDialog from "@/components/dialog/dialog";
    import { useRouter,useRoute } from 'vue-router'
    import useTableHight from '@/mixins/useTableHight';
    import XEUtils from "xe-utils";

    const route = useRoute()
    const router = useRouter()
    const mutitabsstore = useMutitabsStore()

    let isFull = ref(false)
    let orderStatic = ref(null)
    let tableSelect = ref(null)
    let tableHeight = useTableHight(orderStatic,tableSelect,isFull.value)
    let loadingPage = ref(false)
    let formInline = ref({
        page: 1,
        limit: 10,
    })

    let pageparm = ref({
        page: 1,
        limit: 10,
        total: 0
    })

    let timers = ref([])
    let tableData = ref([])

    let rulesForm = ref(null)
    let loadingSave = ref(false)
    let dialogTitle = ref("菜单配置")
    let formData = ref({})
    let showMenuConfigDialog = ref(false)
    let rules = ref({})
    let menuData = ref([])

    //获取列表
    function getMenuData(){
        apiSystemMenu({page:1, limit:999}).then(res => {
             if(res.code ==2000) {
                 // 将列表数据转换为树形数据
                 menuData.value = XEUtils.toArrayTree(res.data.data, { parentKey: 'parent' })
             } else {
                 ElMessage.warning(res.msg)
             }
        })
    }

    function handleDialogClose(){
        showMenuConfigDialog.value = false
        formData.value = {}
    }

    // 表格序列号
    function getIndex($index) {
        // (当前页 - 1) * 当前显示数据条数 + 当前行数据的索引 + 1
        return (pageparm.value.page-1)*pageparm.value.limit + $index +1
    }

    function setFull(){
        isFull.value=!isFull.value
        window.dispatchEvent(new Event('resize'))
    }

    function callFather(parm) {
        formInline.value.page = parm.page
        formInline.value.limit = parm.limit
        getData()
    }
    function search() {
        formInline.value.page = 1
        formInline.value.limit = 10
        getData()
    }
    //获取列表
    function getData() {
        loadingPage.value = true
        lyformbuilder(formInline.value).then(res => {
             loadingPage.value = false
             if(res.code ==2000) {
                 tableData.value= res.data.data
                 pageparm.value.page = res.data.page;
                 pageparm.value.limit = res.data.limit;
                 pageparm.value.total = res.data.total;
             }
         })
    }

    function timeChange(val){
        if (val) {
            formInline.value.beginAt=dateFormats(val[0],'yyyy-MM-dd hh:mm:ss');
            formInline.value.endAt=dateFormats(val[1],'yyyy-MM-dd hh:mm:ss');
        } else {
            formInline.value.beginAt = null
            formInline.value.endAt = null
        }
        search()
    }
    function generateMount(row){
        let tips = "是否确认同步挂载文件，该操作会生成并覆盖前后端代码的相关CRUD文件，并在数据库生成菜单/路由/按钮/，是否确认执行该操作?"
        ElMessageBox.confirm(tips, "警告", {
            confirmButtonText: "确定",
            cancelButtonText: "取消",
            type: "warning"
        }).then(function() {
            loadingPage.value = true
            return  lyformbuilderGeneratemount({id:row.id}).then(res=>{
                loadingPage.value = false
                if(res.code == 2000){
                    ElMessage.success(res.msg)
                    getData()
                    mutitabsstore.getMenu()
                    location.reload();
                }else{
                    ElMessageBox.alert(res.msg, "提示", {type: 'error'})
                }
            })
        })
    }
    function syncdb(){
        let tips = "该操作会同步Models修改到数据库，是否确认同步?"
        ElMessageBox.confirm(tips, "警告", {
            confirmButtonText: "确定",
            cancelButtonText: "取消",
            type: "warning"
        }).then(function() {
            loadingPage.value = true
            return  lyformbuilderSyncdb().then(res=>{
                loadingPage.value = false
                if(res.code == 2000){
                    ElMessage.success(res.msg+",如果无效果请重启后端IDE项目再试")
                    getData()
                    mutitabsstore.getMenu()
                }else{
                    ElMessageBox.alert(res.msg, "提示", {type: 'error'})
                }
            })
        })
    }
    function submitEditMenuData() {
        rulesForm.value.validate(obj=>{
            if(obj) {
                loadingSave.value=true
                let param = {
                    ...formData.value
                }
                if(formData.value.id){
                    lyformbuilderEditMenu(param).then(res=>{
                        loadingSave.value=false
                        if(res.code ==2000) {
                            ElMessage.success(res.msg)
                            handleDialogClose()
                            getData()
                        } else {
                            ElMessage.warning(res.msg)
                        }
                    })
                }

            }
        })
    }
    function handleEdit(row,flag) {
        if(flag=='delete') {
            ElMessageBox.prompt('要完全删除请输入"完全删除"，不填写则为逻辑删除！','提示',{
                closeOnClickModal:false,
                type: 'warning'
            }).then(({ value })=>{
                let pamars = {id:row.id}
                if(value === "完全删除"){
                    pamars.real_delete = true
                }
                lyformbuilderDelete(pamars).then(res=>{
                    if(res.code == 2000) {
                        ElMessage.success(res.msg)
                        getData()
                        mutitabsstore.getMenu()
                        location.reload()
                    } else {
                        ElMessage.warning(res.msg)
                    }
                })
            }).catch(()=>{

            })
        }
        else if(flag === "Edit"){
            showMenuConfigDialog.value = true
            getMenuData()
            nextTick(()=>{
                formData.value.id = row.id
                formData.value.parent_menu = row.parent_menu
                formData.value.menu_sort = row.menu_sort
            })
        }
        else if(flag === "goDesign"){
            ElMessageBox.confirm('点击【设计模板】后，会跳转至【表单构建】设计此表单，此操作会清空【表单构建】之前本地缓存的设计缓存，是否继续？',"提示",{
                confirmButtonText: '确定',
                cancelButtonText: '取消',
                type:"warning",
            }).then(() => {
                router.push({name:'lyFormBuilders',query:{id:row.id}})
            }).catch(() => {
            });

        }
        else if(flag=="reset"){
            formInline.value = {
                page:1,
                limit: 10
            }
            pageparm.value={
                page: 1,
                limit: 10,
                total: 0
            }
            timers.value = []
            getData()
            mutitabsstore.getMenu()
        }
    }

    onMounted(()=>{
        getData()
    })

    defineExpose({
        setFull
    })
</script>

<style lang="scss" scoped>

</style>