/**
 * author: lybbn
 * program django-vue-lyadmin
 * email: 1042594286@qq.com
 * website: http://www.lybbn.cn
 * date: 2022.11.17
 * remark: 如果要分发django-vue-lyadmin源码或其中组件等，需在本文件顶部保留此文件头信息！！！
 */
import { defineStore } from 'pinia'
import {randomId, deepClone,getDefaultFormConfig} from '@/utils/util'
import {lyContainers, lyBasicFields, lyAdvancedFields, lyCustomFields} from "@/components/lyform-builder/lywidget-pannel/lyWidgetConfig"
import {LYFORMBUILDER_VERSION} from "@/components/lyform-builder/config"

export const useBuilderStore = defineStore('lyFormBuilder', {
    state:() => {
        return {
            widgetList: [],
            formConfig: {},
            selectedId: null,
            selectedWidget: {},
            selectedWidgetName: null,
            formWidget:null,
            cssClassList: [],
            allWidgets:[...lyContainers, ...lyBasicFields, ...lyAdvancedFields, ...lyCustomFields],
            historyData: {
                index: -1,
                maxStep: 20,
                steps: [],
            },
            builderConfig:null,
            defaultFormConfig:deepClone(getDefaultFormConfig()),
            formTemplateID:null,//编辑表单模板时的id
        }
    },
    getters:{

    },
    actions: {
        initBuilder(){
            this.widgetList = []
            this.formConfig = deepClone(this.defaultFormConfig)
            this.allWidgets = [...lyContainers, ...lyBasicFields, ...lyAdvancedFields, ...lyCustomFields]
            console.info(`%clyFormBuilder %cVer${LYFORMBUILDER_VERSION} %chttps://doc.lybbn.cn/`,
              "color:#409EFF;font-size: 22px;font-weight:bolder",
              "color:#999;font-size: 12px",
              "color:#333"
            )
            this.initHistoryData()
        },
        initHistoryData(){
            this.loadFormContentFromStorage()
            this.historyData.index++
            this.historyData.steps[this.historyData.index] = ({
                widgetList: deepClone(this.widgetList),
                formConfig: deepClone(this.formConfig)
            })
        },
        clearBuilder(skipHistoryChange){
            let emptyWidgetListFlag = (this.widgetList.length === 0)
            this.widgetList = []
            this.selectedId = null
            this.selectedWidgetName = null
            this.selectedWidget = {}
            this.formConfig = deepClone(this.defaultFormConfig)
            this.formTemplateID = null

            if (!!skipHistoryChange) {
            } else if (!emptyWidgetListFlag) {
                this.emitHistoryChange()
            } else {
                this.saveCurrentHistoryStep()
            }
        },
        registerFormWidget(formWidget) {
            this.formWidget = formWidget
        },
        getDefaultJsonTemplate(){
            return {
                widgetList: [],
                formConfig: deepClone(getDefaultFormConfig())
            }
        },
        //导入json配置
        importFormJson(formJson) {
            let mFlag = false
            if (!!formJson && !!formJson.widgetList) {
                if (!!formJson.widgetList) {
                    this.widgetList = formJson.widgetList
                    mFlag = true
                }
                if (!!formJson.formConfig) {
                    this.formConfig = formJson.formConfig
                    mFlag = true
                }
            }
            return mFlag
        },
        setCssClassList(cssClassList) {
            this.cssClassList = cssClassList
        },
        getCssClassList() {
          return this.cssClassList
        },
        setSelected(selected) {
            if (!selected) {
                this.clearSelected()
                return
            }
            this.selectedWidget = selected
            if (!!selected.id) {
                this.selectedId = selected.id
                this.selectedWidgetName = selected.options.name
            }
        },
        clearSelected() {
            this.selectedId = null
            this.selectedWidgetName = null
            this.selectedWidget = {}
        },
        //保存变动到存储
        emitHistoryChange() {
            if (this.historyData.index === this.historyData.maxStep - 1) {
                this.historyData.steps.shift()
            } else {
                this.historyData.index++
            }
            this.historyData.steps[this.historyData.index] = ({
                widgetList: deepClone(this.widgetList),
                formConfig: deepClone(this.formConfig)
            })

            this.saveFormContentToStorage()

            if (this.historyData.index < this.historyData.steps.length - 1) {
                this.historyData.steps = this.historyData.steps.slice(0, this.historyData.index + 1)
            }
        },
        handleWidgetMove(e) {
            if (!!e.draggedContext && !!e.draggedContext.element) {
                let category = e.draggedContext.element.category
                if (!!e.to) {
                    if ((e.to.className === 'sub-form-table') && (category === 'container')) {
                        return false
                    }
                }
            }
            return true
        },
        handleFieldMove(e) {
            if (!!e.draggedContext && !!e.draggedContext.element) {
                let wgCategory = e.draggedContext.element.category
                let wgType = e.draggedContext.element.type + ''
                if (!!e.to) {
                    if ((e.to.className === 'sub-form-table') && (wgType === 'slot')) {
                        return false
                    }
                }
            }
            return true
        },
        cloneContainer(containWidget) {
            if (containWidget.type === 'grid') {
                let newGrid = deepClone(this.getContainerByType('grid'))
                newGrid.id = newGrid.type + randomId()
                newGrid.options.name = newGrid.id
                containWidget.cols.forEach(gridCol => {
                    let newGridCol = deepClone(this.getContainerByType('grid-col'))
                    let tmpId = randomId()
                    newGridCol.id = 'grid-col-' + tmpId
                    newGridCol.options.name = 'gridCol' + tmpId
                    newGridCol.options.span = gridCol.options.span
                    newGrid.cols.push(newGridCol)
                })
                return newGrid
            }
            else if (containWidget.type === 'table') {
                let newTable = deepClone(this.getContainerByType('table'))
                newTable.id = newTable.type + randomId()
                newTable.options.name = newTable.id
                containWidget.rows.forEach(tRow => {
                    let newRow = deepClone(tRow)
                    newRow.id = 'table-row-' + randomId()
                    newRow.cols.forEach(col => {
                        col.id = 'table-cell-' + randomId()
                        col.options.name = col.id
                        col.widgetList = []  //清空组件列表
                    })
                    newTable.rows.push(newRow)
                })
                return newTable
            }
            else {
                return null
            }
        },
        moveUpWidget(parentList, indexOfParentList) {
            if (!!parentList) {
                if (indexOfParentList === 0) {
                    return
                }

                let tempWidget = parentList[indexOfParentList]
                parentList.splice(indexOfParentList, 1)
                parentList.splice(indexOfParentList - 1, 0, tempWidget)
            }
        },

        moveDownWidget(parentList, indexOfParentList) {
            if (!!parentList) {
                if (indexOfParentList === parentList.length - 1) {
                    return
                }

                let tempWidget = parentList[indexOfParentList]
                parentList.splice(indexOfParentList, 1)
                parentList.splice(indexOfParentList + 1, 0, tempWidget)
            }
        },
        addTabPaneOfTabs(tabsWidget) {
            const tabPanes = tabsWidget.tabs
            let newTabPane = deepClone( this.getContainerByType('tab-pane') )
            newTabPane.id = 'tab-pane-' + randomId()
            newTabPane.options.name = newTabPane.id
            newTabPane.options.label = 'tab ' + (tabPanes.length + 1)
            tabPanes.push(newTabPane)
        },

        deleteTabPaneOfTabs(tabsWidget, tpIdx) {
            tabsWidget.tabs.splice(tpIdx, 1)
        },
        appendTableRow(widget) {
            let rowIdx = widget.rows.length//确定插入行位置
            let newRow = deepClone(widget.rows[widget.rows.length - 1])
            newRow.id = 'table-row-' + randomId()
            newRow.merged = false
            newRow.cols.forEach(col => {
                col.id = 'table-cell-' + randomId()
                col.options.name = col.id
                col.merged = false
                col.options.colspan = 1
                col.options.rowspan = 1
                col.widgetList.length = 0
            })
            widget.rows.splice(rowIdx, 0, newRow)
            this.emitHistoryChange()
        },
        appendTableCol(widget) {
            let colIdx = widget.rows[0].cols.length  //确定插入列位置
            widget.rows.forEach(row => {
                let newCol = deepClone(this.getContainerByType('table-cell'))
                newCol.id = 'table-cell-' + randomId()
                newCol.options.name = newCol.id
                newCol.merged = false
                newCol.options.colspan = 1
                newCol.options.rowspan = 1
                newCol.widgetList.length = 0
                row.cols.splice(colIdx, 0, newCol)
            })

            this.emitHistoryChange()
        },

        insertTableRow(widget, insertPos, cloneRowIdx, curCol, aboveFlag) {
            let newRowIdx = !!aboveFlag ? insertPos : (insertPos + 1)  //初步确定插入行位置
            if (!aboveFlag) {  //继续向下寻找同列第一个未被合并的单元格
                let tmpRowIdx = newRowIdx
                let rowFoundFlag = false
                while (tmpRowIdx < widget.rows.length) {
                    if (!widget.rows[tmpRowIdx].cols[curCol].merged) {
                        newRowIdx = tmpRowIdx
                        rowFoundFlag = true
                        break
                    } else {
                        tmpRowIdx++
                    }
                }

                if (!rowFoundFlag) {
                    newRowIdx = widget.rows.length
                }
            }

            let newRow = deepClone( widget.rows[cloneRowIdx] )
            newRow.id = 'table-row-' + randomId()
            newRow.merged = false
            newRow.cols.forEach(col => {
                col.id = 'table-cell-' + randomId()
                col.options.name = col.id
                col.merged = false
                col.options.colspan = 1
                col.options.rowspan = 1
                col.widgetList.length = 0
            })
            widget.rows.splice(newRowIdx, 0, newRow)

            let colNo = 0
            while ((newRowIdx < widget.rows.length - 1) && (colNo < widget.rows[0].cols.length)) {  //越界判断
                const cellOfNextRow = widget.rows[newRowIdx + 1].cols[colNo]
                const rowMerged = cellOfNextRow.merged  //确定插入位置下一行的单元格是否为合并单元格
                if (!!rowMerged) {
                    let rowArray = widget.rows
                    let unMergedCell = {}
                    let startRowIndex = null
                    for (let i = newRowIdx; i >= 0; i--) {  //查找该行已合并的主单元格
                        if (!rowArray[i].cols[colNo].merged && (rowArray[i].cols[colNo].options.rowspan > 1)) {
                            startRowIndex = i
                            unMergedCell = rowArray[i].cols[colNo]
                            break
                        }
                    }

                    if (!!unMergedCell.options) {  //如果有符合条件的unMergedCell
                        let newRowspan = unMergedCell.options.rowspan + 1
                        this.setPropsOfMergedRows(widget.rows, startRowIndex, colNo, unMergedCell.options.colspan, newRowspan)
                        colNo += unMergedCell.options.colspan
                    } else {
                        colNo += 1
                    }
                } else {
                    colNo += cellOfNextRow.options.colspan || 1
                }
            }
            this.emitHistoryChange()
        },

        insertTableCol(widget, insertPos, curRow, leftFlag) {
            let newColIdx = !!leftFlag ? insertPos : (insertPos + 1)  //初步确定插入列位置
            if (!leftFlag) {  //继续向右寻找同行第一个未被合并的单元格
                let tmpColIdx = newColIdx
                let colFoundFlag = false
                while (tmpColIdx < widget.rows[curRow].cols.length) {
                    if (!widget.rows[curRow].cols[tmpColIdx].merged) {
                        newColIdx = tmpColIdx
                        colFoundFlag = true
                        break
                    } else {
                        tmpColIdx++
                    }

                    if (!colFoundFlag) {
                        newColIdx = widget.rows[curRow].cols.length
                    }
                }
            }

            widget.rows.forEach(row => {
                let newCol = deepClone(this.getContainerByType('table-cell'))
                newCol.id = 'table-cell-' + randomId()
                newCol.options.name = newCol.id
                newCol.merged = false
                newCol.options.colspan = 1
                newCol.options.rowspan = 1
                newCol.widgetList.length = 0
                row.cols.splice(newColIdx, 0, newCol)
            })

            let rowNo = 0
            while((newColIdx < widget.rows[0].cols.length - 1) && (rowNo < widget.rows.length)) {  //越界判断
                const cellOfNextCol = widget.rows[rowNo].cols[newColIdx + 1]
                const colMerged = cellOfNextCol.merged  //确定插入位置右侧列的单元格是否为合并单元格
                if (!!colMerged) {
                    let colArray = widget.rows[rowNo].cols
                    let unMergedCell = {}
                    let startColIndex = null
                    for (let i = newColIdx; i >= 0; i--) {  //查找该行已合并的主单元格
                        if (!colArray[i].merged && (colArray[i].options.colspan > 1)) {
                            startColIndex = i
                            unMergedCell = colArray[i]
                            break
                        }
                    }

                    if (!!unMergedCell.options) {  //如果有符合条件的unMergedCell
                        let newColspan = unMergedCell.options.colspan + 1
                        this.setPropsOfMergedCols(widget.rows, rowNo, startColIndex, newColspan, unMergedCell.options.rowspan)
                        rowNo += unMergedCell.options.rowspan
                    } else {
                        rowNo += 1
                    }
                } else {
                    rowNo += cellOfNextCol.options.rowspan || 1
                }
            }
            this.emitHistoryChange()
        },
        setPropsOfMergedCols(rowArray, startRowIndex, startColIndex, newColspan, rowspan) {
            for (let i = startRowIndex; i < startRowIndex + rowspan; i++) {
                for (let j = startColIndex; j < startColIndex + newColspan; j++) {
                    if ((i === startRowIndex) && (j === startColIndex)) {
                        rowArray[i].cols[j].options.colspan = newColspan  //合并后的主单元格
                        continue
                    }
                    rowArray[i].cols[j].merged = true
                    rowArray[i].cols[j].options.colspan = newColspan
                    rowArray[i].cols[j].widgetList = []
                }
            }
        },

        setPropsOfMergedRows(rowArray, startRowIndex, startColIndex, colspan, newRowspan) {
            for (let i = startRowIndex; i < startRowIndex + newRowspan; i++) {
                for (let j = startColIndex; j < startColIndex + colspan; j++) {
                if ((i === startRowIndex) && (j === startColIndex)) {
                    rowArray[i].cols[j].options.rowspan = newRowspan
                    continue
                }

                rowArray[i].cols[j].merged = true
                rowArray[i].cols[j].options.rowspan = newRowspan
                rowArray[i].cols[j].widgetList = []
                }
            }
        },

        setPropsOfSplitCol(rowArray, startRowIndex, startColIndex, colspan, rowspan) {
            for (let i = startRowIndex; i < startRowIndex + rowspan; i++) {
                for (let j = startColIndex; j < startColIndex + colspan; j++) {
                    rowArray[i].cols[j].merged = false;
                    rowArray[i].cols[j].options.rowspan = 1
                    rowArray[i].cols[j].options.colspan = 1
                }
            }
        },

        setPropsOfSplitRow(rowArray, startRowIndex, startColIndex, colspan, rowspan) {
            for (let i = startRowIndex; i < startRowIndex + rowspan; i++) {
                for (let j = startColIndex; j < startColIndex + colspan; j++) {
                    rowArray[i].cols[j].merged = false;
                    rowArray[i].cols[j].options.rowspan = 1
                    rowArray[i].cols[j].options.colspan = 1
                }
            }
        },

        mergeTableCol(rowArray, colArray, curRow, curCol, leftFlag, cellWidget) {
            let mergedColIdx = !!leftFlag ? curCol : curCol + colArray[curCol].options.colspan
            let remainedColIdx = !!leftFlag ? curCol - 1 : curCol
            if (!!leftFlag) {  //继续向左寻找同行未被合并的第一个单元格
                let tmpColIdx = remainedColIdx
                while (tmpColIdx >= 0) {
                    if (!rowArray[curRow].cols[tmpColIdx].merged) {
                        remainedColIdx = tmpColIdx
                        break;
                    } else {
                        tmpColIdx--
                    }
                }
            }

            if (!!colArray[mergedColIdx].widgetList && (colArray[mergedColIdx].widgetList.length > 0)) { //保留widgetList
                if (!colArray[remainedColIdx].widgetList || (colArray[remainedColIdx].widgetList.length === 0)) {
                    colArray[remainedColIdx].widgetList = deepClone(colArray[mergedColIdx].widgetList)
                }
            }

            let newColspan = colArray[mergedColIdx].options.colspan * 1 + colArray[remainedColIdx].options.colspan * 1
            this.setPropsOfMergedCols(rowArray, curRow, remainedColIdx, newColspan, cellWidget.options.rowspan)

            this.emitHistoryChange()
        },

        mergeTableWholeRow(rowArray, colArray, rowIndex, colIndex) { //需要考虑操作的行存在已合并的单元格！！
            //整行所有单元格行高不一致不可合并！！
            let startRowspan = rowArray[rowIndex].cols[0].options.rowspan
            let unmatchedFlag = false
            for (let i = 1; i < rowArray[rowIndex].cols.length; i++) {
                if (rowArray[rowIndex].cols[i].options.rowspan !== startRowspan) {
                    unmatchedFlag = true
                    break;
                }
            }
            if (unmatchedFlag) {
                return
            }

            let widgetListCols = colArray.filter((colItem) => {
                return !colItem.merged && !!colItem.widgetList && (colItem.widgetList.length > 0)
            })
            if (!!widgetListCols && (widgetListCols.length > 0)) { //保留widgetList
                if ((widgetListCols[0].id !== colArray[0].id) && (!colArray[0].widgetList ||
                colArray[0].widgetList.length <= 0)) {
                    colArray[0].widgetList = deepClone( widgetListCols[0].widgetList )
                }
            }

            this.setPropsOfMergedCols(rowArray, rowIndex, 0, colArray.length, colArray[colIndex].options.rowspan)

            this.emitHistoryChange()
        },

        mergeTableRow(rowArray, curRow, curCol, aboveFlag, cellWidget) {
            let mergedRowIdx = !!aboveFlag ? curRow : curRow + cellWidget.options.rowspan

            //let remainedRowIdx = !!aboveFlag ? curRow - cellWidget.options.rowspan : curRow
            let remainedRowIdx = !!aboveFlag ? curRow - 1 : curRow
            if (!!aboveFlag) {  //继续向上寻找同列未被合并的第一个单元格
                let tmpRowIdx = remainedRowIdx
                while (tmpRowIdx >= 0) {
                    if (!rowArray[tmpRowIdx].cols[curCol].merged) {
                        remainedRowIdx = tmpRowIdx
                        break;
                    } else {
                        tmpRowIdx--
                    }
                }
            }

            if (!!rowArray[mergedRowIdx].cols[curCol].widgetList && (rowArray[mergedRowIdx].cols[curCol].widgetList.length > 0)) { //保留widgetList
                if (!rowArray[remainedRowIdx].cols[curCol].widgetList || (rowArray[remainedRowIdx].cols[curCol].widgetList.length === 0)) {
                    rowArray[remainedRowIdx].cols[curCol].widgetList = deepClone(rowArray[mergedRowIdx].cols[curCol].widgetList)
                }
            }

            let newRowspan = rowArray[mergedRowIdx].cols[curCol].options.rowspan * 1 + rowArray[remainedRowIdx].cols[curCol].options.rowspan * 1
            this.setPropsOfMergedRows(rowArray, remainedRowIdx, curCol, cellWidget.options.colspan, newRowspan)

            this.emitHistoryChange()
        },

        mergeTableWholeCol(rowArray, colArray, rowIndex, colIndex) { //需要考虑操作的列存在已合并的单元格！！
            //整列所有单元格列宽不一致不可合并！！
            let startColspan = rowArray[0].cols[colIndex].options.colspan
            let unmatchedFlag = false
            for (let i = 1; i < rowArray.length; i++) {
                if (rowArray[i].cols[colIndex].options.colspan !== startColspan) {
                    unmatchedFlag = true
                    break;
                }
            }
            if (unmatchedFlag) {
                return
            }

            let widgetListCols = []
            rowArray.forEach(rowItem => {
            let tempCell = rowItem.cols[colIndex]
            if (!tempCell.merged && !!tempCell.widgetList && (tempCell.widgetList.length > 0)) {
                widgetListCols.push(tempCell)
            }
            })

            let firstCellOfCol = rowArray[0].cols[colIndex]
            if (!!widgetListCols && (widgetListCols.length > 0)) { //保留widgetList
                if ((widgetListCols[0].id !== firstCellOfCol.id) && (!firstCellOfCol.widgetList ||
                    firstCellOfCol.widgetList.length <= 0)) {
                    firstCellOfCol.widgetList = deepClone( widgetListCols[0].widgetList )
                }
            }

            this.setPropsOfMergedRows(rowArray, 0, colIndex, firstCellOfCol.options.colspan, rowArray.length)

            this.emitHistoryChange()
        },

        undoMergeTableCol(rowArray, rowIndex, colIndex, colspan, rowspan) {
            this.setPropsOfSplitCol(rowArray, rowIndex, colIndex, colspan, rowspan)

            this.emitHistoryChange()
        },

        undoMergeTableRow(rowArray, rowIndex, colIndex, colspan, rowspan) {
            this.setPropsOfSplitRow(rowArray, rowIndex, colIndex, colspan, rowspan)

            this.emitHistoryChange()
        },

        deleteTableWholeCol(rowArray, colIndex) { //需考虑删除的是合并列！！
            let onlyOneColFlag = true
            rowArray.forEach(ri => {
                if (ri.cols[0].options.colspan !== rowArray[0].cols.length) {
                    onlyOneColFlag = false
                }
            })
            //仅剩一列则不可删除！！
            if (onlyOneColFlag) {
                return
            }

            //整列所有单元格列宽不一致不可删除！！
            let startColspan = rowArray[0].cols[colIndex].options.colspan
            let unmatchedFlag = false
            for (let i = 1; i < rowArray.length; i++) {
                if (rowArray[i].cols[colIndex].options.colspan !== startColspan) {
                    unmatchedFlag = true
                    break;
                }
            }
            if (unmatchedFlag) {
                return
            }

            rowArray.forEach((rItem) => {
                rItem.cols.splice(colIndex, startColspan)
            })

            this.emitHistoryChange()
        },

        deleteTableWholeRow(rowArray, rowIndex) { //需考虑删除的是合并行！！
            let onlyOneRowFlag = true
            rowArray[0].cols.forEach(ci => {
                if (ci.options.rowspan !== rowArray.length) {
                    onlyOneRowFlag = false
                }
            })
            //仅剩一行则不可删除！！
            if (onlyOneRowFlag) {
                return
            }

            //整行所有单元格行高不一致不可删除！！
            let startRowspan = rowArray[rowIndex].cols[0].options.rowspan
            let unmatchedFlag = false
            for (let i = 1; i < rowArray[rowIndex].cols.length; i++) {
                if (rowArray[rowIndex].cols[i].options.rowspan !== startRowspan) {
                    unmatchedFlag = true
                    break;
                }
            }
            if (unmatchedFlag) {
                return
            }

            rowArray.splice(rowIndex, startRowspan)

            this.emitHistoryChange()
        },
        addContainerByDbClick(container) {
            let newCon = this.cloneNewContainerWidget(container)
            this.widgetList.push(newCon)
            this.setSelected(newCon)
            this.saveCurrentHistoryStep()
        },
        addFieldByDbClick(widget) {
            let newWidget = this.cloneNewFieldWidget(widget)
            if (!!this.selectedWidget && this.selectedWidget.type === 'tab') {
                //获取当前激活的tabPane
                let activeTab = this.selectedWidget.tabs[0]
                this.selectedWidget.tabs.forEach(tabPane => {
                    if (!!tabPane.options.active) {
                        activeTab = tabPane
                    }
                })

                !!activeTab && activeTab.widgetList.push(newWidget)
            } else if (!!this.selectedWidget && !!this.selectedWidget.widgetList) {
                this.selectedWidget.widgetList.push(newWidget)
            } else {
                this.widgetList.push(newWidget)
            }

            this.setSelected(newWidget)
            this.emitHistoryChange()
        },
        cloneGridCol(widget, parentWidget) {
            let newGridCol = deepClone(this.getContainerByType('grid-col'))
            newGridCol.options.span = widget.options.span
            let tmpId = randomId()
            newGridCol.id = 'grid-col-' + tmpId
            newGridCol.options.name = 'gridCol' + tmpId
            parentWidget.cols.push(newGridCol)
        },
        deleteColOfGrid(gridWidget, colIdx) {
            if (!!gridWidget && !!gridWidget.cols) {
                gridWidget.cols.splice(colIdx, 1)
            }
        },

        addNewColOfGrid(gridWidget) {
            const cols = gridWidget.cols
            let newGridCol = deepClone(this.getContainerByType('grid-col'))
            let tmpId = randomId()
            newGridCol.id = 'grid-col-' + tmpId
            newGridCol.options.name = 'gridCol' + tmpId
            if ((!!cols) && (cols.length > 0)) {
                let spanSum = 0
                cols.forEach((col) => {
                    spanSum += col.options.span
                })

                if (spanSum >= 24) {
                    console.log('列栅格之和超出24')
                    gridWidget.cols.push(newGridCol)
                } else {
                    newGridCol.options.span = (24 - spanSum) > 12 ? 12 : (24 - spanSum)
                    gridWidget.cols.push(newGridCol)
                }
            } else {
                gridWidget.cols = [newGridCol]
            }
        },
        cloneNewFieldWidget(origin) {
            let newWidget = deepClone(origin)
            let tempId = randomId()
            newWidget.id = newWidget.type.replace(/-/g, '') + tempId
            newWidget.options.name = newWidget.id
            newWidget.options.label = newWidget.options.label || newWidget.type.toLowerCase()
            // delete newWidget.displayName
            return newWidget
        },
        cloneNewContainerWidget(item) {
            let newCW = deepClone(item)
            newCW.id = newCW.type.replace(/-/g, '') + randomId()
            newCW.options.name = newCW.id
            if (newCW.type === 'grid') {
                let newCol = deepClone( this.getContainerByType('grid-col') )
                let tmpId = randomId()
                newCol.id = 'grid-col-' + tmpId
                newCol.options.name = 'gridCol'+ tmpId
                newCW.cols.push(newCol)
                newCol = deepClone(newCol)
                tmpId = randomId()
                newCol.id = 'grid-col-' + tmpId
                newCol.options.name = 'gridCol' + tmpId
                newCW.cols.push(newCol)
            } else if (newCW.type === 'table') {
                let newRow = {cols: []}
                newRow.id = 'table-row-' + randomId()
                newRow.merged = false
                let newCell = deepClone( this.getContainerByType('table-cell') )
                newCell.id = 'table-cell-' + randomId()
                newCell.options.name = newCell.id
                newCell.merged = false
                newCell.options.colspan = 1
                newCell.options.rowspan = 1
                newRow.cols.push(newCell)
                newCW.rows.push(newRow)
            } else if (newCW.type === 'tab') {
                let newTabPane = deepClone( this.getContainerByType('tab-pane') )
                newTabPane.id = 'tab-pane-' + randomId()
                newTabPane.options.name = 'tab1'
                newTabPane.options.label = 'tab1'
                newCW.tabs.push(newTabPane)
            }
            // delete newCW.displayName
            return newCW
        },
        getContainerByType(typeName) {
            return  this.allWidgets.find(item => !!item.type && (item.type === typeName))
        },
        saveFormContentToStorage() {
            window.localStorage.setItem('lyformbuilder_widget__list__backup', JSON.stringify(this.widgetList))
            window.localStorage.setItem('lyformbuilder_form__config__backup', JSON.stringify(this.formConfig))
            window.localStorage.setItem('lyformbuilder_form__templateId__backup',this.formTemplateID)
        },
        loadFormContentFromStorage() {
            let widgetListBackup = window.localStorage.getItem('lyformbuilder_widget__list__backup')
            if (!!widgetListBackup) {
                this.widgetList = JSON.parse(widgetListBackup)
            }
            let formConfigBackup = window.localStorage.getItem('lyformbuilder_form__config__backup')
            if (!!formConfigBackup) {
                this.formConfig = JSON.parse(formConfigBackup)
            }
            let formTemplateIDBackup = window.localStorage.getItem('lyformbuilder_form__templateId__backup')
            if (!!formTemplateIDBackup) {
                this.formTemplateID = formTemplateIDBackup
            }
        },
        saveCurrentHistoryStep() {
            this.historyData.steps[this.historyData.index] = deepClone({
                widgetList: this.widgetList,
                formConfig: this.formConfig
            })
            this.saveFormContentToStorage()
        }
    },
})