<template>
    <el-scrollbar class="lycontainer">
        <el-card shadow="never" header="分类筛选器">
            <lySelectFilter :data="data" :selected-values="selectedValues" size="default" :label-width="100" @on-change="change"></lySelectFilter>
        </el-card>
        <el-card shadow="never" :header="'内容展示'+filterData.state" style="margin-top: 10px;">
            <div v-if="filterData.state === '1'">
                <el-button @click="generateMaitou" type="primary">生成条形码唛头</el-button>
                <el-button @click="handleOfficePreview('pdf')" type="primary">IFRAME预览PDF文件</el-button>
                <el-button @click="handleOfficePreview('docx')" type="primary">OFFICE预览DOCX文件</el-button>
<!--                <el-button @click="" type="primary">生成认证证书</el-button>-->
            </div>
            <div v-if="filterData.state === '2'">
                <el-tabs tab-position="top">
                    <el-tab-pane label="普通打印">
                        <el-alert title="打印当前页面已存在的元素,如包含.no-print的class样式就忽略该标签不打印。page-break-after: always;为分页打印" type="success" style="margin-bottom:20px;"></el-alert>
                        <el-button type="primary" @click="handlePrint">普通打印</el-button>
                        <el-button type="primary" @click="handlePrint2">去除页眉页脚打印</el-button>
                        <div style="height:20px"></div>
                        <div class="printMain" ref="lyPrintrRef">
                            <div class="item">第一页打印内容1<el-icon><VideoPlay /></el-icon><p class="no-print">.no-print类忽略打印内容</p></div>
                            <div style="page-break-after: always;"></div>
                            <div class="item">第二页打印内容2</div>
                        </div>
                    </el-tab-pane>
                    <el-tab-pane label="动态打印">
                        <el-alert title="动态创建的DOM打印" type="success" style="margin-bottom:20px;"></el-alert>
                        <el-button type="primary" @click="dynamicPrint">动态打印</el-button>
                    </el-tab-pane>
                    <el-tab-pane label="前端水印">
                        <div style="margin-bottom: 10px;">
                            <el-button type="primary" @click="waterMarkCreate">创建水印</el-button>
			                <el-button type="primary" @click="waterMarkClear">移除水印</el-button>
                        </div>
                        <div>
                            <lyWaterMark ref="waterMark" text="dvlyadmin_pro专业版" subtext="https://doc.lybbn.cn">
                                <el-table :data="tableData" border stripe style="width: 100%">
                                    <el-table-column prop="date" label="Date" width="180" />
                                    <el-table-column prop="name" label="Name" width="180" />
                                    <el-table-column prop="address" label="Address" />
                                </el-table>
                            </lyWaterMark>
                        </div>
                    </el-tab-pane>
                </el-tabs>
            </div>
            <div v-if="filterData.state === '3'">
                <el-row :gutter="15">
                    <el-col :lg="8">
                        <el-card shadow="never" header="常用">
                            <ly-qrcode text="django-vue-lyadmin-pro"></ly-qrcode>
                        </el-card>
                    </el-col>
                    <el-col :lg="8">
                        <el-card shadow="never" header="带Logo">
                            <ly-qrcode text="django-vue-lyadmin-pro" logo="static/img/logo.png"></ly-qrcode>
                        </el-card>
                    </el-col>
                    <el-col :lg="8">
                        <el-card shadow="never" header="自定义颜色大小">
                            <ly-qrcode text="django-vue-lyadmin-pro" :size="150" colorDark="#587200" colorLight="#fff"></ly-qrcode>
                        </el-card>
                    </el-col>
                </el-row>
            </div>
            <div v-if="filterData.state === '4'">
                <el-row :gutter="15">
                    <el-col :lg="12">
                        <el-card shadow="never" header="阿里云MP4视频播放器">
                            <lyVideo source="//player.alicdn.com/video/aliyunmedia.mp4" :options="{ width: '100%', height: '500px'}"></lyVideo>
                        </el-card>
                    </el-col>
                    <el-col :lg="12">
                        <el-card shadow="never" header="直播">
                            <el-row :gutter="1">
                                <el-col :span="12">
                                    <lyVideo source="//player.alicdn.com/video/aliyunmedia.mp4" :options="{ width: '100%', height: '250px', isLive: true}" :buttonCenter="true"></lyVideo>
                                </el-col>
                                <el-col :span="12">
                                    <lyVideo source="//player.alicdn.com/video/aliyunmedia.mp4" :options="{ width: '100%', height: '250px', isLive: true}" :buttonCenter="true"></lyVideo>
                                </el-col>
                            </el-row>
                            <el-row :gutter="1" style="margin-top: 1px;">
                                <el-col :span="12">
                                    <lyVideo source="//player.alicdn.com/video/aliyunmedia.mp4" :options="{ width: '100%', height: '250px', isLive: true}" :buttonCenter="true"></lyVideo>
                                </el-col>
                                <el-col :span="12">
                                    <lyVideo source="//player.alicdn.com/video/aliyunmedia.mp4" :options="{ width: '100%', height: '250px', isLive: true}" :buttonCenter="true"></lyVideo>
                                </el-col>
                            </el-row>
                        </el-card>
                    </el-col>
                </el-row>
            </div>
        </el-card>
        <ly-dialog v-model="dialogVisible" :title="dialogTitle" width="680px" :before-close="handleClose">
            <div v-if="currentFunction ==='maitou'" style="text-align: center">
                <el-image style="width: 600px;height: 420px;"  :src="dialogContent" :preview-teleported="true" :preview-srcList=[dialogContent] alt="唛头" />
            </div>
            <template #footer>
                <el-button @click="handleDownloadMT" v-if="currentFunction ==='maitou'" type="primary">下载</el-button>
            </template>
        </ly-dialog>
    </el-scrollbar>
</template>

<script setup>
    import {ref, onMounted, reactive, nextTick, watch, computed} from 'vue'
    import lySelectFilter from "@/components/lySelectFilter"
    import { ElMessage } from 'element-plus';
    import { platformsettingsOtherFunctionSets } from "@/api/api"
    import LyDialog from "@/components/dialog/dialog";
    import lyWaterMark from "@/components/lyWaterMark"
    import lyqrcode from "@/components/qrcode/lyqrcode";
    import print from '@/utils/print'
    import LyQrcode from "@/components/qrcode/lyqrcode";
    import lyVideo from "@/components/lyvideo/lyVideo"

    let data = ref([
        {
            title: "状态筛选(单)",
            key: "state",
            multiple: false,
            options: [
                {
                    label: "功能按钮",
                    value: "1",
                    icon: "PictureFilled",
                    type:"primary",
                    nums:3,
                },
                {
                    label: "打印/水印",
                    value: "2",
                    icon: "Printer",
                    type:"warning",
                    nums:3,
                },
                {
                    label: "二维码",
                    value: "3",
                    icon: "Menu",
                    type:"success",
                    nums:1,
                },
                {
                    label: "视频播放器",
                    value: "4",
                    icon: "Menu",
                    type:"success",
                    nums:1,
                },
                {
                    label: "其他",
                    value: "5",
                    icon: "Opportunity",
                    type:"danger",
                    nums:8,
                }
            ],
        },
    ])
    let filterData = ref({state:'1'})
    let selectedValues = ref({state: ["1"]})
    function change(selected){
        filterData.value = selected
    }
    let dialogVisible = ref(false)
    let dialogTitle = ref("")
    let dialogContent = ref("")
    let currentFunction = ref("maitou")
    function handleClose() {
        dialogVisible.value = false
        dialogTitle.value = ""
        dialogContent.value = ""
        currentFunction.value = ""
    }
    function generateMaitou() {
        platformsettingsOtherFunctionSets({type:'dlmaitou'}).then(res=>{
            if(res.code === 2000){
                dialogVisible.value = true
                dialogTitle.value = "生成条形码唛头"
                currentFunction.value = "maitou"
                dialogContent.value = res.data
            }else{
                ElMessage.warning(res.msg)
            }
        })
    }
    //qrBase64是后台传回来的base64数据
    function handleDownloadQrIMg(qrBase64) {
        // 这里是获取到的图片base64编码,这里只是个例子哈，要自行编码图片替换这里才能测试看到效果
        const imgUrl = qrBase64
        // 如果浏览器支持msSaveOrOpenBlob方法（也就是使用IE浏览器的时候），那么调用该方法去下载图片
        if (window.navigator.msSaveOrOpenBlob) {
            const bstr = atob(imgUrl.split(',')[1])
            let n = bstr.length
            const u8arr = new Uint8Array(n)
            while (n--) {
                u8arr[n] = bstr.charCodeAt(n)
            }
            const blob = new Blob([u8arr])
            window.navigator.msSaveOrOpenBlob(blob,   '唛头.' + 'png')
        } else {
            // 这里就按照chrome等新版浏览器来处理
            const a = document.createElement('a')
            a.href = imgUrl
            a.setAttribute('download', '唛头')
            a.click()
        }
        ElMessage.success("下载成功")
    }
    function handleDownloadMT() {
        handleDownloadQrIMg(dialogContent.value)
    }

    let lyPrintrRef = ref(null)
    function handlePrint(){
        //直接传入REF或者querySelector
        print(lyPrintrRef.value)
    }
    function handlePrint2(){
        //直接传入REF或者querySelector
        print(lyPrintrRef.value,{hideHeaderFooter:true})
    }
    function dynamicPrint(){
        //创建虚拟DOM结构后再传入
        var dom = "<h2>我是标题</h2><p>我是后创建的DOM内容</p>"
        print(dom)
    }

    let waterMark = ref(null)
    let tableData = ref([
        {
            date: '2016-05-03',
            name: 'Tom',
            address: 'No. 189, Grove St, Los Angeles',
        },
        {
            date: '2016-05-02',
            name: 'Tom',
            address: 'No. 189, Grove St, Los Angeles',
        },
        {
            date: '2016-05-04',
            name: 'Tom',
            address: 'No. 189, Grove St, Los Angeles',
        },
        {
            date: '2016-05-01',
            name: 'Tom',
            address: 'No. 189, Grove St, Los Angeles',
        },
    ])
    function waterMarkCreate() {
        waterMark.value.create()
    }
    function waterMarkClear() {
        waterMark.value.clear()
    }
    
    function handleOfficePreview(flag) {
        if(flag === "pdf"){
            let Base64 = require('js-base64').Base64
            let baseurl = window.location.protocol+"//"+window.location.host
            window.open(baseurl+"/#/lyFilePreview?mode=pdf&fileUrl="+Base64.encode("static/demo.pdf"),'_blank')
        }
        else if(flag === "docx"){
            let Base64 = require('js-base64').Base64
            let baseurl = window.location.protocol+"//"+window.location.host
            window.open(baseurl+"/#/lyFilePreview?mode=docx&fileUrl="+Base64.encode("static/demo.docx"),'_blank')
        }
    }


</script>

<style scoped>
    .lycontainer:deep(.el-card__header){
        font-size: 17px;
        font-weight: 700;
        border-bottom: 0;
        padding: 15px 20px 0 20px;
    }
    .printMain .item {
        padding:20px;
        border: 1px solid #409eff;
        margin-bottom: 20px;
        border-radius: 4px;
    }
	.printMain p {
        margin-top: 20px;
        color: #999;
    }
</style>