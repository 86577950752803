<template>
    <div>
        <el-form size="small" label-position="left" label-width="100px" class="lywidgetsetting" @submit.prevent>
            <el-collapse v-model="lyCollapseActiveList" class="lyformsetting-collapse">
                <el-collapse-item name="lyws01" title="组件属性">
                    <el-form-item label="字段标识">
                        <el-input  v-model.trim="optionModel.name" @change="handleChange"></el-input>
                    </el-form-item>
                    <el-form-item label="隐藏">
                        <el-switch v-model="optionModel.hidden" @change="handleChange"></el-switch>
                    </el-form-item>
                    <el-form-item label="HTML">
                    </el-form-item>
                    <el-form-item label-width="0">
                        <el-input type="textarea" v-model="optionModel.htmlContent" :rows="5" @change="handleChange" class="html-content-editor"></el-input>
                    </el-form-item>
                    <customClassLy :selected-widget="selectedWidget" :option-model="optionModel"></customClassLy>
                </el-collapse-item>
            </el-collapse>
        </el-form>
    </div>
</template>

<script setup>
    import {ref, onMounted, reactive, computed, watch} from 'vue'
    import { useBuilderStore } from "@/store/lyFormBuilder";
    import validationCommonLy from "@/components/lyform-builder/lysetting-pannel/lywidget-common/validation-common-ly"
    import customClassLy from "@/components/lyform-builder/lysetting-pannel/lywidget-common/custom-class-ly"
    const props = defineProps({
        selectedWidget: Object,
        optionModel: Object,
    })
    const builderStore = useBuilderStore()
    function handleChange(newValue) {
         builderStore.saveCurrentHistoryStep()
    }
    let lyCollapseActiveList =  ref(['lyws01','lyws02','lyws03'])
    const formSizes = ref([
        {label: 'default', value: ''},
        {label: 'large', value: 'large'},
        {label: 'small', value: 'small'},
    ])
    function showEventCollapse(){
        if (builderStore.builderConfig['showEventCollapse'] === undefined) {
          return true
        }
        return !!builderStore.builderConfig['showEventCollapse']
    }

</script>

<style lang="scss" scoped>
    .lywidgetsetting{
        ::v-deep(.el-form-item__label) {
          font-size: 13px;
          overflow: hidden;
          white-space: nowrap;
        }
        .lyformsetting-collapse{
            ::v-deep(.el-collapse-item__header) {
                font-weight: bold;
            }
        }
        .html-content-editor {
            font-size: 13px;
        }
    }

</style>