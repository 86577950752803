<template>
    <el-scrollbar class="lycontainer">
        <el-card shadow="never" header="基本信息">
            <el-descriptions :column="4" border>
                <el-descriptions-item label="Redis版本：">{{info.redis_version}}</el-descriptions-item>
                <el-descriptions-item label="运行系统：">{{info.os}}</el-descriptions-item>
                <el-descriptions-item label="运行模式：">{{info.redis_mode}}</el-descriptions-item>
                <el-descriptions-item label="角色：">{{info.role}}</el-descriptions-item>
                <el-descriptions-item label="运行时间(天)：">{{info.uptime_in_days}}</el-descriptions-item>
                <el-descriptions-item label="运行端口：">{{info.tcp_port}}</el-descriptions-item>
                <el-descriptions-item label="连接的客户端数量：">{{info.connected_clients}}</el-descriptions-item>
                <el-descriptions-item label="已使用内存(used_memory)：">{{info.used_memory_human}}</el-descriptions-item>
                <el-descriptions-item label="已使用物理内存(used_memory_rss)：">{{formatUnitSize(info.used_memory_rss)}}</el-descriptions-item>
                <el-descriptions-item label="历史分配内存的峰值：">{{info.used_memory_peak_human}}</el-descriptions-item>
                <el-descriptions-item label="内存碎片比率：">{{info.mem_fragmentation_ratio}}</el-descriptions-item>
                <el-descriptions-item label="使用CPU(used_cpu_sys)：">{{parseFloat(info.used_cpu_sys).toFixed(2)}}s</el-descriptions-item>
                <el-descriptions-item label="AOF是否开启：">{{info.aof_enabled}}</el-descriptions-item>
                <el-descriptions-item label="查找数据库键成功的次数：">{{info.keyspace_hits}}</el-descriptions-item>
                <el-descriptions-item label="查找数据库键失败的次数：">{{info.keyspace_misses}}</el-descriptions-item>
                <el-descriptions-item label="查找数据库键命中率：">{{parseFloat(info.keyspace_hits/(info.keyspace_hits+info.keyspace_misses)).toFixed(4)*100}}</el-descriptions-item>
                <el-descriptions-item label="自运行连接过的客户端的总数量：">{{info.total_connections_received}}</el-descriptions-item>
                <el-descriptions-item label="自运行执行过的命令的总数量：">{{info.total_commands_processed}}</el-descriptions-item>
                <el-descriptions-item label="服务器每秒钟执行的命令数量：">{{info.instantaneous_ops_per_sec}}</el-descriptions-item>
                <el-descriptions-item label="最后一次fork操作耗费的微秒数：">{{info.latest_fork_usec}}</el-descriptions-item>
                <el-descriptions-item label="网络入口/出口：">{{ info.instantaneous_input_kbps }}kps/{{info.instantaneous_output_kbps}}kps</el-descriptions-item>
            </el-descriptions>
        </el-card>
        <el-row :gutter="15" style="margin-top: 10px;">
            <el-col :lg="12">
                <el-card shadow="never">
                    <lyEcharts height="300px" :option="option2"></lyEcharts>
                </el-card>
            </el-col>
            <el-col :lg="12">
                <el-card shadow="never">
                    <lyEcharts height="300px" :option="option3"></lyEcharts>
                </el-card>
            </el-col>
        </el-row>
    </el-scrollbar>
</template>

<script setup>
    import {ref,reactive,onMounted} from 'vue'
    import { ElMessage,ElMessageBox } from 'element-plus';
    import {monitorGetredisinfo} from '@/api/api'
    import {formatUnitSize} from '@/utils/util'
    import lyEcharts from '@/components/analysis/lySmallEcharts/index';
    let info = ref({
    })

    let option2 = ref( {
        title: {
            text: '内存使用情况',
        },
        tooltip: {
            formatter: 0,
        },
        series: [{
            name: "峰值",
            type: 'gauge',
            min: 0,
            max: 100,
            radius:'100%',
            progress: {
                show: true
            },
            detail: {
                formatter: '',
                offsetCenter: [0, '75%']
            },
            data: [{
                value: 0,
                name:"内存消耗"
            }]
        }]
    })

    let option3 = ref({
        title: {
            text: 'Redis keys数量（db0）',
        },
        series: [
            {
                name: '访问来源',
                type: 'pie',
                radius: '100%',
                label: {
                    show:true,
                    formatter(param) {
                      return param.name + ' (' + param.value + '个)';
                    }
                },
                data: [
                    {value: 0, name: 'key数量'},
                    {value: 0, name: 'key过期数量'},
                ]
            }
        ]
    })

    function getData(){
        monitorGetredisinfo().then(res => {
            if(res.code ==2000) {
                info.value = res.data
                option2.value.tooltip.formatter = "{b} <br/>{a} : " + info.value.used_memory_human
                option2.value.series[0].detail.formatter = info.value.used_memory_human
                option2.value.series[0].data[0].value = parseInt(info.value.used_memory/info.value.total_memory)
                option3.value.series[0].data[0].value = info.value.db0.keys
                option3.value.series[0].data[1].value = info.value.db0.expires
            }else{
                ElMessage.warning(res.msg)
            }
        })
    }

    onMounted(()=>{
        getData()
    })

</script>

<style scoped>
    .lycontainer:deep(.el-card__header){
        font-size: 17px;
        font-weight: 700;
        border-bottom: 0;
        padding: 15px 20px 0 20px;
    }
    ::v-deep(.el-scrollbar__bar.is-horizontal) {
        display: none;
    }
</style>