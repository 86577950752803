<template>
  <div :class="{'ly-is-full':isFull}">
    <div class="tableSelect" ref="tableSelect">
      <el-form :model="formInline">
        <el-form-item label="关键字：">
          <el-input v-model="formInline.search" maxlength="60" clearable placeholder="请输入表名称/类名称/显示名称"
                    @change="search" style="width:300px"></el-input>
        </el-form-item>
        <el-form-item label="" v-show="hasPermission(this.$route.name,'Search')">
          <el-button @click="search" type="primary" icon="Search">查询</el-button>
        </el-form-item>
        <el-form-item label="">
          <el-button @click="handleEdit('','reset')" icon="Refresh">重置</el-button>
        </el-form-item>
        <el-form-item label="" v-show="hasPermission(this.$route.name,'Create')">
          <el-button type="primary" icon="Plus" @click="addAdmin">新增</el-button>
        </el-form-item>
        <el-form-item label="" v-show="hasPermission(this.$route.name,'Syncdb')">
          <el-button type="warning" icon="Refresh" @click="syncdb">同步数据库</el-button>
        </el-form-item>
      </el-form>
    </div>
    <div class="table">
      <el-table :height="tableHeight" border :data="tableData" ref="tableref" v-loading="loadingPage"
                style="width: 100%">
        <el-table-column type="index" width="60" align="center" label="序号">
          <template #default="scope">
            <span v-text="getIndex(scope.$index)"></span>
          </template>
        </el-table-column>
        <el-table-column min-width="130" prop="verbose_name" label="名称"></el-table-column>
        <el-table-column min-width="130" prop="class_name" label="类名"></el-table-column>
        <el-table-column min-width="130" prop="db_table" label="表名"></el-table-column>
        <el-table-column min-width="150" prop="remark" label="备注" show-overflow-tooltip></el-table-column>
        <el-table-column min-width="150" prop="create_datetime" label="创建时间"></el-table-column>
        <el-table-column label="操作" fixed="right" width="250">
          <template #header>
            <div style="display: flex;justify-content: space-between;align-items: center;">
              <div>操作</div>
              <div @click="setFull">
                <el-tooltip content="全屏" placement="bottom">
                  <el-icon>
                    <full-screen/>
                  </el-icon>
                </el-tooltip>
              </div>
            </div>
          </template>
          <template #default="scope">
            <el-tooltip effect="dark" content="预览将要生成的CRUD前后台代码" placement="top"
                        v-if="hasPermission(this.$route.name,'PreCode')">
              <span class="table-operate-btn" @click="previewAutoCode(scope.row)">代码预览</span>
            </el-tooltip>

            <!--                        <span class="table-operate-btn" @click="handleEdit(scope.row,'edit')">表单设计</span>-->
            <el-tooltip effect="dark" content="生成并覆盖前后端代码的相关CRUD文件，并在数据库生成菜单/路由/按钮/"
                        placement="top" v-if="hasPermission(this.$route.name,'MountSync')">
              <span class="table-operate-btn" @click="generateMount(scope.row)">同步挂载</span>
            </el-tooltip>
            <span class="table-operate-btn" @click="handleEdit(scope.row,'edit')"
                  v-show="hasPermission(this.$route.name,'Update')">编辑</span>
            <span class="table-operate-btn" @click="handleEdit(scope.row,'delete')"
                  v-show="hasPermission(this.$route.name,'Delete')">删除</span>
          </template>
        </el-table-column>
      </el-table>
    </div>
    <Pagination v-bind:child-msg="pageparm" @callFather="callFather"></Pagination>
    <add-module-code-generate ref="addModuleFlag" @refreshData="getData"></add-module-code-generate>
    <add-module-preview-code ref="addModulePreviewFlag"></add-module-preview-code>
  </div>
</template>

<script>
import Pagination from "@/components/Pagination";
import {dateFormats, getTableHeight} from "@/utils/util";
import {
  autocodeAutocode,
  autocodeAutocodeDelete,
  autocodeAutocodeGeneratemount,
  autocodeAutocodePreviewcode,
  autocodeAutocodeSyncdb
} from '@/api/api'
import AddModuleCodeGenerate from "@/views/systemTools/components/addModuleCodeGenerate";
import AddModulePreviewCode from "@/views/systemTools/components/addModulePreviewCode";
import {useMutitabsStore} from "@/store/mutitabs";

export default {
  name: "lycodeGenerate",
  components: {AddModulePreviewCode, AddModuleCodeGenerate, Pagination},
  setup() {
    const mutitabsstore = useMutitabsStore()
    return {mutitabsstore}
  },
  data() {
    return {
      isFull: false,
      tableHeight: 500,
      loadingPage: false,
      formInline: {
        page: 1,
        limit: 10,
      },
      pageparm: {
        page: 1,
        limit: 10,
        total: 0
      },
      timers: [],
      tableData: [],
      showCodeGenerate: false,
    }
  },
  methods: {
    // 表格序列号
    getIndex($index) {
      // (当前页 - 1) * 当前显示数据条数 + 当前行数据的索引 + 1
      return (this.pageparm.page - 1) * this.pageparm.limit + $index + 1
    },
    setFull() {
      this.isFull = !this.isFull
      window.dispatchEvent(new Event('resize'))
    },
    addAdmin() {
      this.$refs.addModuleFlag.addModuleFn(null, '新增')
    },
    previewAutoCode(row) {
      autocodeAutocodePreviewcode({id: row.id}).then(res => {
        if (res.code == 2000) {
          // console.log(res.data.data)
          this.$refs.addModulePreviewFlag.addModuleFn(res.data.data, '代码预览')
        } else {
          this.$message.warning(res.msg)
        }
      })
    },
    generateMount(row) {
      let vm = this
      let tips = "是否确认同步挂载文件，该操作会生成并覆盖前后端代码的相关CRUD文件，并在数据库生成菜单/路由/按钮/，是否确认执行该操作?"
      vm.$confirm(tips, "警告", {
        confirmButtonText: "确定",
        cancelButtonText: "取消",
        type: "warning"
      }).then(function () {
        vm.loadingPage = true
        return autocodeAutocodeGeneratemount({id: row.id}).then(res => {
          if (res.code == 2000) {
            vm.$message.success(res.msg)
            vm.getData()
            vm.mutitabsstore.getMenu()
            vm.loadingPage = false
            location.reload();
          } else {
            vm.loadingPage = false
            vm.$alert(res.msg, "提示", {type: 'error'})
          }
        })
      })
    },
    syncdb() {
      let vm = this
      let tips = "该操作会同步Models修改到数据库，是否确认同步?"
      vm.$confirm(tips, "警告", {
        confirmButtonText: "确定",
        cancelButtonText: "取消",
        type: "warning"
      }).then(function () {
        vm.loadingPage = true
        return autocodeAutocodeSyncdb().then(res => {
          vm.loadingPage = false
          if (res.code == 2000) {
            // console.log(res.data)
            vm.$message.success(res.msg + ",如果无效果请重启后端IDE项目再试")
            vm.getData()
            vm.mutitabsstore.getMenu()
          } else {
            vm.$alert(res.msg, "提示", {type: 'error'})
          }
        })
      })
    },
    handleEdit(row, flag) {
      if (flag == 'edit') {
        this.$refs.addModuleFlag.addModuleFn(row, '编辑')
      } else if (flag == 'delete') {
        let vm = this
        vm.$prompt('要完全删除请输入"完全删除"，不填写则为逻辑删除！', "提示", {
          closeOnClickModal: false,
          type: 'warning'
        }).then(({value}) => {
          let pamars = {id: row.id}
          if (value === "完全删除") {
            pamars.real_delete = true
          }
          autocodeAutocodeDelete(pamars).then(res => {
            if (res.code == 2000) {
              vm.$message.success(res.msg)
              vm.getData()
              vm.mutitabsstore.getMenu()
              location.reload();
            } else {
              vm.$message.warning(res.msg)
            }
          })
        }).catch(() => {

        })
      } else if (flag == "reset") {
        this.formInline = {
          page: 1,
          limit: 10
        }
        this.pageparm = {
          page: 1,
          limit: 10,
          total: 0
        }
        this.getData()
        this.mutitabsstore.getMenu()
      }
    },
    /**
     * 从URL里下载文件
     */
    // 下载文件
    downloadFileURL(url) {
      var iframe = document.createElement("iframe")
      iframe.style.display = "none";
      iframe.src = url;
      document.body.appendChild(iframe);
    },
    exportDataBackend() {
      // var params = {
      //     page: 1,
      //     limit: 9999,
      // }
      // UsersUsersExportexecl(params).then(res => {
      //      if(res.code ==2000) {
      //          this.downloadFileURL(res.data.data)
      //          //this.$message.warning(res.data.data)
      //      }
      //  })
    },
    callFather(parm) {
      this.formInline.page = parm.page
      this.formInline.limit = parm.limit
      this.getData()
    },
    search() {
      this.formInline.page = 1
      this.formInline.limit = 10
      this.getData()
    },
    //获取列表
    async getData() {
      this.loadingPage = true
      autocodeAutocode(this.formInline).then(res => {
        this.loadingPage = false
        if (res.code == 2000) {
          this.tableData = res.data.data
          this.pageparm.page = res.data.page;
          this.pageparm.limit = res.data.limit;
          this.pageparm.total = res.data.total;
        }
      })
    },

    timeChange(val) {
      if (val) {
        this.formInline.beginAt = dateFormats(val[0], 'yyyy-MM-dd hh:mm:ss');
        this.formInline.endAt = dateFormats(val[1], 'yyyy-MM-dd hh:mm:ss');
      } else {
        this.formInline.beginAt = null
        this.formInline.endAt = null
      }
      this.search()
    },
    // 计算搜索栏的高度
    listenResize() {
      this.$nextTick(() => {
        this.getTheTableHeight()
      })
    },
    getTheTableHeight() {
      let tabSelectHeight = this.$refs.tableSelect ? this.$refs.tableSelect.offsetHeight : 0
      tabSelectHeight = this.isFull ? tabSelectHeight - 110 : tabSelectHeight
      this.tableHeight = getTableHeight(tabSelectHeight)
    }

  },
  created() {
    this.getData()
  },
  mounted() {
    // 监听页面宽度变化搜索框的高度
    window.addEventListener('resize', this.listenResize);
    this.$nextTick(() => {
      this.getTheTableHeight()
    })
  },

  unmounted() {
    // 页面销毁，去掉监听事件
    window.removeEventListener("resize", this.listenResize);
  },

}
</script>

<style lang="scss" scoped>

</style>