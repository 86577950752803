<!--
/**
 * 表单CRUD属性组件
 * version: 1.0
 * author: lybbn
 * program django-vue-lyadmin
 * email: 1042594286@qq.com
 * website: https://doc.lybbn.cn
 * date: 2023.04.22
 * remark: 如果要分发django-vue-lyadmin源码或其中组件等，需在本文件顶部保留此文件头信息！！！
 */
-->
<template>
    <el-form-item label="Model字段">
        <template #label>
            <div style="display: flex;align-items: center;">
            Model字段
                <el-tooltip effect="dark" content="后端根据此字段判断是否Model字段" placement="top-start">
                    <el-icon><QuestionFilled /></el-icon>
                </el-tooltip>
            </div>
        </template>
        <el-switch v-model="optionModel.isFormField" @change="handleChange"></el-switch>
    </el-form-item>
    <el-form-item label="字段名" v-if="optionModel.isFormField">
        <template #label>
            <div style="display: flex;align-items: center;">
                字段名
                <el-tooltip effect="dark" content="同字段标识" placement="top-start">
                    <el-icon><QuestionFilled /></el-icon>
                </el-tooltip>
            </div>
        </template>
        <el-input v-model.trim="optionModel.crudField.field_name" @change="handleChange" :disabled="true"></el-input>
    </el-form-item>
    <el-form-item label="显示名" v-if="optionModel.isFormField">
        <template #label>
            <div style="display: flex;align-items: center;">
                显示名
                <el-tooltip effect="dark" content="同标签文本" placement="top-start">
                    <el-icon><QuestionFilled /></el-icon>
                </el-tooltip>
            </div>
        </template>
        <el-input v-model="optionModel.crudField.verbose_name" @change="handleChange" :disabled="true"></el-input>
    </el-form-item>
    <el-form-item label="字段类型" v-if="optionModel.isFormField">
        <template #label>
            <div style="display: flex;align-items: center;">
            字段类型
                <el-tooltip effect="dark" content="后端Model字段类型" placement="top-start">
                    <el-icon><QuestionFilled /></el-icon>
                </el-tooltip>
            </div>
        </template>
        <el-select v-model="optionModel.crudField.field_type" placeholder="请选择" clearable filterable style="width: 100%" @change="handleChange">
            <el-option
                v-for="item in fieldTypeList"
                :key="item.value"
                :label="item.label"
                :value="item.value"
            >
            </el-option>
        </el-select>
    </el-form-item>
    <el-form-item label="外键项" v-if="optionModel.isFormField && (optionModel.crudField.field_type=='ForeignKey'||optionModel.crudField.field_type=='ManyToManyField'||optionModel.crudField.field_type=='OneToOneField') && selectedWidget.type=='select'">
        <template #label>
            <div style="display: flex;align-items: center;">
            外键项
                <el-tooltip effect="dark" content="该字段如果为外键，需要选择关联外键(仅select下拉选择框组件可用)" placement="top-start">
                    <el-icon><QuestionFilled /></el-icon>
                </el-tooltip>
            </div>
        </template>
        <el-button  plain round size="small" :type="optionModel.crudField.foreign_key.class_name==''?'info':'primary'" @click="setForeignKey(optionModel.crudField.foreign_key)">外键项</el-button>
    </el-form-item>
    <el-form-item label="最大长度" v-if="optionModel.isFormField && optionModel.crudField.field_type=='CharField'">
        <template #label>
            <div style="display: flex;align-items: center;">
            最大长度
                <el-tooltip effect="dark" content="后端model字段的max_length属性" placement="top-start">
                    <el-icon><QuestionFilled /></el-icon>
                </el-tooltip>
            </div>
        </template>
        <el-input v-model="optionModel.crudField.max_length" placeholder="最大长度max_length" @change="handleChange"></el-input>
    </el-form-item>
    <el-form-item label="默认值" v-if="optionModel.isFormField">
        <template #label>
            <div style="display: flex;align-items: center;">
            默认值
                <el-tooltip effect="dark" content="后端model字段的default属性" placement="top-start">
                    <el-icon><QuestionFilled /></el-icon>
                </el-tooltip>
            </div>
        </template>
        <el-input-number @change="handleChange" :controls="false" v-model="optionModel.crudField.default" placeholder="数值型" style="width: 100%;" v-if="optionModel.crudField.field_type == 'IntegerField' || optionModel.crudField.field_type == 'SmallIntegerField' || optionModel.crudField.field_type == 'PositiveIntegerField' || optionModel.crudField.field_type == 'PositiveSmallIntegerField' || optionModel.crudField.field_type == 'BigIntegerField' || optionModel.crudField.field_type == 'FloatField'" ></el-input-number>
        <el-switch  v-else-if="optionModel.crudField.field_type == 'BooleanField'" v-model="optionModel.crudField.default" placeholder="默认值" @change="handleChange"></el-switch>
        <el-input v-model="optionModel.crudField.default" placeholder="默认值default" @change="handleChange" v-else></el-input>
    </el-form-item>
    <el-form-item label="是否唯一" v-if="optionModel.isFormField">
        <template #label>
            <div style="display: flex;align-items: center;">
            是否唯一
                <el-tooltip effect="dark" content="后端unique属性" placement="top-start">
                    <el-icon><QuestionFilled /></el-icon>
                </el-tooltip>
            </div>
        </template>
        <el-switch v-model="optionModel.crudField.unique" @change="handleChange"></el-switch>
    </el-form-item>
    <el-form-item label="创建索引" v-if="optionModel.isFormField">
        <template #label>
            <div style="display: flex;align-items: center;">
            创建索引
                <el-tooltip effect="dark" content="后端db_index属性，用于是否创建索引" placement="top-start">
                    <el-icon><QuestionFilled /></el-icon>
                </el-tooltip>
            </div>
        </template>
        <el-switch v-model="optionModel.crudField.db_index" @change="handleChange"></el-switch>
    </el-form-item>
    <el-form-item label="允许空" v-if="optionModel.isFormField">
        <template #label>
            <div style="display: flex;align-items: center;">
            允许空
                <el-tooltip effect="dark" content="后端使用此字段用户设置null=True,blank=True" placement="top-start">
                    <el-icon><QuestionFilled /></el-icon>
                </el-tooltip>
            </div>
        </template>
        <el-switch v-model="optionModel.crudField.nullable" @change="handleChange"></el-switch>
    </el-form-item>
    <el-form-item label="可编辑" v-if="optionModel.isFormField">
        <template #label>
            <div style="display: flex;align-items: center;">
                可编辑
                <el-tooltip effect="dark" content="是否可编辑（编辑时是否显示）【同隐藏-取反】" placement="top-start">
                    <el-icon><QuestionFilled /></el-icon>
                </el-tooltip>
            </div>
        </template>
        <el-switch v-model="optionModel.crudField.can_edit" @change="handleChange" :disabled="true"></el-switch>
    </el-form-item>
    <el-form-item label="编辑禁用" v-if="optionModel.isFormField">
        <template #label>
            <div style="display: flex;align-items: center;">
                编辑禁用
                <el-tooltip effect="dark" content="编辑时禁用该字段（disabled属性），新增时不受此影响" placement="top-start">
                    <el-icon><QuestionFilled /></el-icon>
                </el-tooltip>
            </div>
        </template>
        <el-switch v-model="optionModel.crudField.edit_disabled" @change="handleChange"></el-switch>
    </el-form-item>
    <el-form-item label="可列表" v-if="optionModel.isFormField">
        <template #label>
            <div style="display: flex;align-items: center;">
                可列表
                <el-tooltip effect="dark" content="允许显示在列表中（el-table）" placement="top-start">
                    <el-icon><QuestionFilled /></el-icon>
                </el-tooltip>
            </div>
        </template>
        <el-switch v-model="optionModel.crudField.can_list" @change="handleChange"></el-switch>
    </el-form-item>
    <el-form-item label="列显示" v-if="optionModel.isFormField && optionModel.crudField.can_list">
        <template #label>
            <div style="display: flex;align-items: center;">
                列显示
                <el-tooltip effect="dark" content="el-table列自定义显示内容（写代码）,为空则正常显示该列信息" placement="top-start">
                    <el-icon><QuestionFilled /></el-icon>
                </el-tooltip>
            </div>
        </template>
        <el-button :type="!!optionModel.crudField.list_display_custom?'primary':'info'" icon="edit" plain round @click="editListDisplayCustom">编写代码</el-button>
    </el-form-item>
    <el-form-item label="列宽度" v-if="optionModel.isFormField && optionModel.crudField.can_list">
        <template #label>
            <div style="display: flex;align-items: center;">
                列宽度(px)
                <el-tooltip effect="dark" content="列表宽度（el-table列展示宽度）" placement="top-start">
                    <el-icon><QuestionFilled /></el-icon>
                </el-tooltip>
            </div>
        </template>
        <el-input-number :min="1" v-model="optionModel.crudField.width" style="width: 100%" @change="handleChange"></el-input-number>
    </el-form-item>
    <el-form-item label="可查询" v-if="optionModel.isFormField">
        <template #label>
            <div style="display: flex;align-items: center;">
                可查询
                <el-tooltip effect="dark" content="允许搜索（显示在搜索栏）" placement="top-start">
                    <el-icon><QuestionFilled /></el-icon>
                </el-tooltip>
            </div>
        </template>
        <el-switch v-model="optionModel.crudField.can_search" @change="handleChange"></el-switch>
    </el-form-item>
    <el-form-item label="查询方式" v-if="optionModel.isFormField && optionModel.crudField.can_search">
        <template #label>
            <div style="display: flex;align-items: center;">
                查询方式
                <el-tooltip effect="dark" content="后台filter搜索方式（模糊查询、精确查询等）" placement="top-start">
                    <el-icon><QuestionFilled /></el-icon>
                </el-tooltip>
            </div>
        </template>
        <el-select v-model="optionModel.crudField.search_type" clearable filterable @change="handleChange">
            <el-option label="=" value="=" />
            <el-option label="iexact" value="iexact" />
            <el-option label=">" value=">" />
            <el-option label=">=" value=">=" />
            <el-option label="<" value="<" />
            <el-option label="<=" value="<=" />
            <el-option label="in" value="in" />
            <el-option label="isnull" value="isnull" />
            <el-option label="icontains" value="icontains" />
            <el-option label="contains" value="contains" />
            <el-option label="startswith" value="startswith" />
            <el-option label="istartswith" value="istartswith" />
            <el-option label="endswith" value="endswith" />
            <el-option label="iendswith" value="iendswith" />
            <el-option label="range" value="range" />
            <el-option label="year" value="year" />
            <el-option label="year__lt" value="year__lt" />
            <el-option label="year__lt" value="year__lte" />
            <el-option label="year__gt" value="year__gt" />
            <el-option label="year__gt" value="year__gte" />
            <el-option label="month" value="month" />
            <el-option label="day" value="day" />
        </el-select>
    </el-form-item>
    <el-form-item label="可导出" v-if="optionModel.isFormField">
        <template #label>
            <div style="display: flex;align-items: center;">
                可导出
                <el-tooltip effect="dark" content="运行字段导出" placement="top-start">
                    <el-icon><QuestionFilled /></el-icon>
                </el-tooltip>
            </div>
        </template>
        <el-switch v-model="optionModel.crudField.can_export" @change="handleChange"></el-switch>
    </el-form-item>
    <el-drawer title="外键配置" v-model="foreignKeyVisible" :size="500" destroy-on-close @close="handleChange">
        <el-main style="padding:0 20px 20px 20px">
            <el-empty description="description" v-if="foreignKeyVisible=='' || foreignKeyVisible.length == 0" />
            <el-form v-else label-width="120px" :model="foreignKeySetting">
                <el-form-item label="外键类名：">
                    <el-select v-model="foreignKeySetting.class_name" clearable filterable placeholder="请选择">
                        <el-option
                            v-for="item in modelsData"
                            :key="item.class_name"
                            :label="item.table_name"
                            :value="item.class_name">
                             <span>{{ item.class_name+':'+item.table_name }}</span>
                        </el-option>
                    </el-select>
                </el-form-item>
                <el-form-item label="删除方法：">
                    <el-select v-model="foreignKeySetting.on_delete" clearable filterable placeholder="请选择">
                        <el-option label="CASCADE" value="CASCADE" />
                        <el-option label="PROTECT" value="PROTECT" />
                        <el-option label="SET_NULL" value="SET_NULL" />
                    </el-select>
                </el-form-item>
                <el-form-item label="主键字段（value）：">
                    <el-input v-model.trim="foreignKeySetting.value" placeholder="该外键的主键model字段"></el-input>
                </el-form-item>
                <el-form-item label="显示字段（label）：">
                    <el-input v-model.trim="foreignKeySetting.label" placeholder="该外键的前端新增/编辑时要显示的字段"></el-input>
                </el-form-item>
                <el-form-item label="API地址PATH：">
                    <template #label>
                            <span style="display: flex;align-items: center">
                                API地址PATH：
                                <el-tooltip effect="dark" content="用户新增编辑时选择外键时使用" placement="top-start">
                                    <el-icon><QuestionFilled /></el-icon>
                                </el-tooltip>
                            </span>
                        </template>
                    <el-select  v-model.trim="foreignKeySetting.api_path" filterable clearable  allow-create style="width: 100%;" placeholder="请选择或手动输入">
                        <el-option
                            v-for="item in apiList"
                            :key="item.value"
                            :label="item.label"
                            :value="item.value">
                        </el-option>
                    </el-select>
                </el-form-item>
            </el-form>
        </el-main>
    </el-drawer>
    <lyDialog v-model="showListDisplayCustomDialog" v-if="showListDisplayCustomDialog" title="列自定义显示" width="60%" top="20px" :before-close="handleListDisplayCustomDialogClose">
        <div>
            <el-alert title="<template #default='scope'>" type="info" :closable="false" />
            <lyCodeEditor v-model="listDisplayCustomCode" mode="javascript" height="600" :read-only="false"></lyCodeEditor>
            <el-alert title="</template>"  :closable="false" type="info"></el-alert>
        </div>
        <template v-slot:footer>
            <el-button @click="handleListDisplayCustomDialogClose" size="default">关闭</el-button>
            <el-button type="primary" @click="saveListDisplayCustom" size="default">确定</el-button>
        </template>
    </lyDialog>
</template>

<script setup>
    import {ref, reactive, onMounted, watch, defineAsyncComponent} from 'vue'
    import { useBuilderStore } from "@/store/lyFormBuilder";
    import {useDictStore} from "@/store/dict";
    import {platformsettingsSysconfigGetmodelsInfoList,getSystemLyapiList} from "@/api/api";
    import { ElMessage,ElMessageBox } from 'element-plus'
    import lyDialog from '@/components/dialog/dialog'
    import {deepClone} from "@/utils/util";
    const lyCodeEditor = defineAsyncComponent(() => import('@/components/lyform-builder/code-editor'));
    const props = defineProps({
        selectedWidget: Object,
        optionModel: Object,
    })
    const builderStore = useBuilderStore()
    function handleChange(newValue) {
         builderStore.saveCurrentHistoryStep()
    }
    const dictStore = useDictStore()
    let fieldTypeList = ref([])
    function getFieldType(){
        fieldTypeList.value = dictStore.data['model_field_type']
    }

    let modelsData = ref([])
    //获取models列表信息
    function getModlesListInfo(){
        platformsettingsSysconfigGetmodelsInfoList().then(res=>{
            if(res.code == 2000){
                modelsData.value = res.data
                modelsData.value.push({
                    class_name:'self',
                    db_table:'',
                    import_path:'',
                    tableFields:[],
                    table_name:'self'
                })
            }else{
                ElMessage.warning(res.msg)
            }
        })
    }
    let foreignKeyVisible = ref(false)
    let foreignKeySetting = ref({})
    let apiList = ref([])
    //设置外键项
    function setForeignKey(data){
        getModlesListInfo()
        getSystemLyapi()
        foreignKeySetting.value = data
        foreignKeyVisible.value = true
    }
    function getSystemLyapi(){
        getSystemLyapiList().then(res=>{
            var result = Object.keys(res.paths)
            var data = []
            for (const item of result) {
                const obj = {}
                if(item.indexOf("{id}") == -1 && item.indexOf("/multiple_delete/") == -1){
                    let newurl = item.replace("/api/","")
                    obj.label = newurl
                    obj.value = newurl
                    data.push(obj)
                }

            }
            apiList.value = data
        })
    }
    onMounted(()=>{
        props.optionModel.crudField.field_name = props.optionModel.name
        props.optionModel.crudField.verbose_name = props.optionModel.label
        props.optionModel.crudField.can_edit = !props.optionModel.hidden
        getFieldType()
    })
    watch(()=>props.optionModel.defaultValue,(newValue,oldValue)=>{
        props.optionModel.crudField.default = newValue
    })
    watch(()=>props.optionModel.name,(newValue,oldValue)=>{
        props.optionModel.crudField.field_name = newValue
    })
    watch(()=>props.optionModel.label,(newValue,oldValue)=>{
        props.optionModel.crudField.verbose_name = newValue
    })
    watch(()=>props.optionModel.hidden,(newValue,oldValue)=>{
        props.optionModel.crudField.can_edit = !newValue
    })
    watch(()=>props.optionModel.crudField.field_type,(newValue,oldValue)=>{
        if(newValue=='ForeignKey'||newValue=='ManyToManyField'||newValue=='OneToOneField'){
            if(props.selectedWidget.type !='select'){
                ElMessageBox.alert('外键只能在select下拉选择框组件可用！！！', '提示', {
                    confirmButtonText: '确定',
                    callback: (action) => {
                    },
                })
            }
        }
    })

    let showListDisplayCustomDialog = ref(false)
    let listDisplayCustomCode = ref('')
    function handleListDisplayCustomDialogClose(){
        showListDisplayCustomDialog.value = false
        listDisplayCustomCode.value = ''
    }
    function editListDisplayCustom(){
        showListDisplayCustomDialog.value = true
        listDisplayCustomCode.value = deepClone(props.optionModel.crudField.list_display_custom)
    }
    function saveListDisplayCustom() {
        props.optionModel.crudField.list_display_custom = deepClone(listDisplayCustomCode.value)
        handleChange()
        handleListDisplayCustomDialogClose()
    }

</script>

<style lang="scss" scoped>

</style>