<template>
	<div class="lyworkflow-wrap">
		<div class="lyworkflow-wrap-box lyworkflow-wrap-box-lyNotice" @click="show">
			<div class="title" style="background: #f06705;">
				<el-icon class="icon"><BellFilled /></el-icon>
				<span>{{ nodeConfig.nodeName }}</span>
                <el-popconfirm confirm-button-text="删除" cancel-button-text="取消" icon="InfoFilled" icon-color="#626AEF" title="确认删除吗?" @confirm="delNode()">
					<template #reference>
						<el-icon class="close" @click.stop=""><Close /></el-icon>
					</template>
				</el-popconfirm>
			</div>
			<div class="content">
				<span v-if="toText(nodeConfig)">{{ toText(nodeConfig) }}</span>
				<span v-else class="placeholder">请选择人员</span>
			</div>
		</div>
		<lyAddNodes v-model="nodeConfig.childNode" :isDesign="isDesign"></lyAddNodes>
		<el-drawer title="通知设置" v-model="drawer" destroy-on-close append-to-body size="40%" class="lyworkflow-drawer">
			<template #header>
				<div class="lyworkflow-wrap-drawer__title">
					<label @click="editTitle" v-if="!isEditTitle">{{form.nodeName}}<el-icon class="lyworkflow-wrap-drawer__title-edit"><Edit /></el-icon></label>
					<el-input v-if="isEditTitle" ref="nodeTitle" v-model="form.nodeName" clearable @blur="saveTitle" @keyup.enter="saveTitle"></el-input>
				</div>
			</template>
			<el-container>
				<el-main style="padding:0 20px 20px 20px">
					<el-form label-position="top">
                        <el-form-item label="通知人员类型">
							<el-select v-model="form.setType">
								<el-option :value="1" label="指定成员"></el-option>
								<el-option :value="2" label="主管"></el-option>
								<el-option :value="3" label="角色"></el-option>
								<el-option :value="4" label="发起人自选"></el-option>
								<el-option :value="5" label="发起人自己"></el-option>
								<el-option :value="7" label="连续多级主管"></el-option>
							</el-select>
						</el-form-item>

                        <el-form-item v-if="form.setType==1" label="选择通知成员">
							<el-button type="primary" icon="plus" round @click="selectHandle(1, form.nodeUserList)">选择人员</el-button>
							<div class="lyworkflow-tags-list">
								<el-tag v-for="(user, index) in form.nodeUserList" :key="user.id" closable @close="delUser(index)">{{user.name}}</el-tag>
							</div>
						</el-form-item>

						<el-form-item v-if="form.setType==2" label="指定主管">
							发起人的第  <el-input-number v-model="form.examineLevel" :min="1"/>  级主管
						</el-form-item>

						<el-form-item v-if="form.setType==3" label="选择角色">
							<el-button type="primary" icon="plus" round @click="selectHandle(2, form.nodeRoleList)">选择角色</el-button>
							<div class="lyworkflow-tags-list">
								<el-tag v-for="(role, index) in form.nodeRoleList" :key="role.id" type="info" closable @close="delRole(index)">{{role.name}}</el-tag>
							</div>
						</el-form-item>

						<el-form-item v-if="form.setType==4" label="发起人自选">
							<el-radio-group v-model="form.selectMode">
								<el-radio :label="1">自选一个人</el-radio>
								<el-radio :label="2">自选多个人</el-radio>
							</el-radio-group>
						</el-form-item>

                        <el-divider></el-divider>
						<el-form-item label="通知类型">
                            <el-checkbox-group v-model="form.noticeTypeList">
                                <el-checkbox :label="1">站内</el-checkbox>
                                <el-checkbox :label="2">邮件</el-checkbox>
                                <el-checkbox :label="3">短信</el-checkbox>
                                <el-checkbox :label="4">钉钉</el-checkbox>
                            </el-checkbox-group>
						</el-form-item>

                        <el-divider></el-divider>
                        <el-form-item label="通知内容">
                            <div style="display: flex;flex-direction: column;width: 100%;">
                                <div style="width: 100%;display: flex;margin-bottom: 10px;">
                                    <span style="color:var(--el-text-color-regular);width: 90px;text-align: right;">主题：</span>
                                    <el-input v-model="form.noticeSubject" placeholder="主题" />
                                </div>
                                <div style="width: 100%;display: flex;">
                                    <span style="color:var(--el-text-color-regular);width: 90px;text-align: right;">通知内容：</span>
                                    <el-input v-model="form.noticeContent" :autosize="{ minRows: 4, maxRows: 8 }" type="textarea" placeholder="通知内容" />
                                </div>
                            </div>
                        </el-form-item>
					</el-form>
				</el-main>
				<el-footer class="lyworkflow-drawer-footer">
					<el-button type="primary" @click="save">保存</el-button>
					<el-button @click="drawer=false">取消</el-button>
				</el-footer>
			</el-container>
		</el-drawer>
	</div>
</template>

<script setup>
	import { ref, onMounted, watch, computed , nextTick,inject} from 'vue'
    import lyAddNodes from "./lyAddNodes"

    const props = defineProps({
        modelValue: { type: Object, default: () => {}  },
        isDesign: { //是否设计模式
            type: Boolean,
            default: true
        }
    })

    const emits = defineEmits(['update:modelValue'])

    let nodeConfig = ref(props.modelValue)
    watch(()=>props.modelValue,()=>{
        nodeConfig.value = props.modelValue
    },{deep:true})
    // watch(()=>nodeConfig.value,()=>{
    //     emits("update:modelValue", nodeConfig.value)
    // },{deep:true})

    let form = ref({})
    let isEditTitle = ref(false)
    let drawer = ref(false)
    let nodeTitle = ref(null)
    const lySelectHandle = inject("lySelectHandle",null)

    function show(){
        form.value = {}
        form.value = JSON.parse(JSON.stringify(nodeConfig.value))
        drawer.value = true
    }

    function editTitle(){
        isEditTitle.value = true
        nextTick(()=>{
            nodeTitle.value.focus()
        })
    }

    function saveTitle(){
		isEditTitle.value = false
	}

    function selectHandle(type, data){
        lySelectHandle(type, data)
    }

    function save(){
        emits("update:modelValue", form.value)
		drawer.value = false
    }

    function delNode(){
        emits("update:modelValue", nodeConfig.value.childNode)
    }

    function delUser(index){
        form.value.nodeUserList.splice(index, 1)
    }

    function toText(nodeConfig){
        if(nodeConfig.setType == 1){
            if (nodeConfig.nodeUserList && nodeConfig.nodeUserList.length>0) {
                const users = nodeConfig.nodeUserList.map(item=>item.name).join("、")
                return users
            }else{
                return false
            }
        }else if (nodeConfig.setType == 2) {
            return nodeConfig.examineLevel == 1 ? '直接主管' : `发起人的第${nodeConfig.examineLevel}级主管`
        }else if (nodeConfig.setType == 3) {
            if (nodeConfig.nodeRoleList && nodeConfig.nodeRoleList.length>0) {
                const roles = nodeConfig.nodeRoleList.map(item=>item.name).join("、")
                return '角色-' + roles
            }else{
                return false
            }
        }else if (nodeConfig.setType == 4) {
            return "发起人自选"
        }else if (nodeConfig.setType == 5) {
            return "发起人自己"
        }else if (nodeConfig.setType == 7) {
            return "连续多级主管"
        }
    }
</script>

<style scoped>
</style>
