<template>
	<div class="lyworkflow-branch">
		<div class="lyworkflow-branch-box-wrap">
			<div class="lyworkflow-branch-box">
				<el-button class="add-branch" color="#67c23a" plain round @click="addTerm">添加条件</el-button>
				<div class="col-box" v-for="(item,index) in nodeConfig.conditionNodes" :key="index">
					<div class="condition-node">
						<div class="condition-node-box">
							<div class="lyworkflow-auto-judge" @click="show(index)">
								<div class="sort-left" v-if="index!=0" @click.stop="arrTransfer(index,-1)">
									<el-icon><Arrow-left /></el-icon>
								</div>
								<div class="title">
									<span class="node-title">{{ item.nodeName }}</span>
									<span class="priority-title">优先级{{item.priorityLevel}}</span>
									<el-popconfirm confirm-button-text="删除" cancel-button-text="取消" icon="InfoFilled" icon-color="#626AEF" title="确认删除吗?" @confirm="delTerm(index)">
										<template #reference>
											<el-icon class="close" @click.stop=""><Close /></el-icon>
										</template>
									</el-popconfirm>
								</div>
								<div class="content">
									<span v-if="toText(nodeConfig, index)">{{ toText(nodeConfig, index) }}</span>
									<span v-else class="placeholder">请设置条件</span>
								</div>
								<div class="sort-right" v-if="index!=nodeConfig.conditionNodes.length-1" @click.stop="arrTransfer(index)">
									<el-icon><Arrow-right /></el-icon>
								</div>
							</div>
							<lyAddNodes v-model="item.childNode" :isDesign="isDesign"></lyAddNodes>
						</div>
					</div>
					<slot v-if="item.childNode" :node="item"></slot>
					<div class="top-left-cover-line" v-if="index==0"></div>
					<div class="bottom-left-cover-line" v-if="index==0"></div>
					<div class="top-right-cover-line" v-if="index==nodeConfig.conditionNodes.length-1"></div>
					<div class="bottom-right-cover-line" v-if="index==nodeConfig.conditionNodes.length-1"></div>
				</div>
			</div>
			<lyAddNodes v-model="nodeConfig.childNode" :isDesign="isDesign"></lyAddNodes>
		</div>
		<el-drawer title="条件设置" v-model="drawer" destroy-on-close append-to-body size="40%" class="lyworkflow-drawer">
			<template #header>
				<div class="lyworkflow-wrap-drawer__title">
					<label @click="editTitle" v-if="!isEditTitle">{{form.nodeName}}<el-icon class="lyworkflow-wrap-drawer__title-edit"><Edit /></el-icon></label>
					<el-input v-if="isEditTitle" ref="nodeTitle" v-model="form.nodeName" clearable @blur="saveTitle" @keyup.enter="saveTitle"></el-input>
				</div>
			</template>
			<el-container>
				<el-main style="padding:0 20px 20px 20px">
					<el-form label-position="top">
						<el-form-item label="条件关系">
							<el-radio-group v-model="form.conditionMode">
								<el-radio :label="1">且</el-radio>
								<el-radio :label="2">或</el-radio>
							</el-radio-group>
						</el-form-item>
						<el-divider></el-divider>
						<el-form-item>
							<el-table :data="form.conditionList">
								<el-table-column prop="label" label="描述">
									<template #default="scope">
										<el-input v-model="scope.row.label" placeholder="描述"></el-input>
									</template>
								</el-table-column>
								<el-table-column prop="field" label="条件字段" width="130">
									<template #default="scope">
										<el-input v-model="scope.row.field" placeholder="条件字段"></el-input>
									</template>
								</el-table-column>
								<el-table-column prop="operator" label="运算符" width="130">
									<template #default="scope">
										<el-select v-model="scope.row.operator" placeholder="Select">
											<el-option label="等于" value="="></el-option>
											<el-option label="不等于" value="!="></el-option>
											<el-option label="大于" value=">"></el-option>
											<el-option label="大于等于" value=">="></el-option>
											<el-option label="小于" value="<"></el-option>
											<el-option label="小于等于" value="<="></el-option>
											<el-option label="包含" value="include"></el-option>
											<el-option label="不包含" value="notinclude"></el-option>
										</el-select>
									</template>
								</el-table-column>
								<el-table-column prop="value" label="值" width="100">
									<template #default="scope">
										<el-input v-model="scope.row.value" placeholder="值"></el-input>
									</template>
								</el-table-column>
								<el-table-column prop="value" label="移除" width="55">
									<template #default="scope">
										<el-link type="danger" :underline="false" @click="deleteConditionList(scope.$index)">移除</el-link>
									</template>
								</el-table-column>
							</el-table>
						</el-form-item>
						<p><el-button type="primary" icon="Plus" round @click="addConditionList">增加条件</el-button></p>
					</el-form>
				</el-main>
				<el-footer class="lyworkflow-drawer-footer">
					<el-button type="primary" @click="save">保存</el-button>
					<el-button @click="drawer=false">取消</el-button>
				</el-footer>
			</el-container>
		</el-drawer>
	</div>
</template>

<script setup>
    import { ref, onMounted, watch, computed , nextTick} from 'vue'
	import lyAddNodes from "./lyAddNodes"

    const props = defineProps({
        modelValue: { type: Object, default: () => {}  },
        isDesign: { //是否设计模式
            type: Boolean,
            default: true
        }
    })

    const emits = defineEmits(['update:modelValue'])

    let nodeConfig = ref(props.modelValue)
    watch(()=>props.modelValue,()=>{
        nodeConfig.value = props.modelValue
    },{deep:true})
    // watch(()=>nodeConfig.value,()=>{
    //     emits("update:modelValue", nodeConfig.value)
    // },{deep:true})

    let form = ref({})
    let isEditTitle = ref(false)
    let drawer = ref(false)
    let nodeTitle = ref(null)
    let sindex = ref(0)

    function show(index){
        sindex.value = index
        form.value = {}
        form.value = JSON.parse(JSON.stringify(nodeConfig.value.conditionNodes[index]))
        drawer.value = true
    }

    function editTitle(){
        isEditTitle.value = true
        nextTick(()=>{
            nodeTitle.value.focus()
        })
    }

    function saveTitle(){
		isEditTitle.value = false
	}

    function save(){
        nodeConfig.value.conditionNodes[sindex.value] = form.value
        emits("update:modelValue", nodeConfig.value)
		drawer.value = false
    }

    function addTerm(){
        let len = nodeConfig.value.conditionNodes.length + 1
        nodeConfig.value.conditionNodes.push({
            nodeName: "条件" + len,
            type: 3,
            priorityLevel: len,
            conditionMode: 1,
            conditionList: []
        })
    }

    function delTerm(index){
        nodeConfig.value.conditionNodes.splice(index, 1)
        if (nodeConfig.value.conditionNodes.length == 1) {
            if (nodeConfig.value.childNode) {
                if (nodeConfig.value.conditionNodes[0].childNode) {
                    reData(nodeConfig.value.conditionNodes[0].childNode, nodeConfig.value.childNode)
                }else{
                    nodeConfig.value.conditionNodes[0].childNode = nodeConfig.value.childNode
                }
            }
            emits("update:modelValue", nodeConfig.value.conditionNodes[0].childNode);
        }
    }

    function reData(data, addData) {
        if (!data.childNode) {
            data.childNode = addData
        } else {
            reData(data.childNode, addData)
        }
    }

    function arrTransfer(index, type = 1){
        nodeConfig.value.conditionNodes[index] = nodeConfig.value.conditionNodes.splice(index + type, 1, nodeConfig.value.conditionNodes[index])[0]
        nodeConfig.value.conditionNodes.map((item, index) => {
            item.priorityLevel = index + 1
        })
        emits("update:modelValue", nodeConfig.value)
    }

    function addConditionList(){
        form.value.conditionList.push({
            label: '',
            field: '',
            operator: '=',
            value: ''
        })
    }

    function deleteConditionList(index){
        form.value.conditionList.splice(index, 1)
    }

    function toText(nodeConfig, index){
        var { conditionList } = nodeConfig.conditionNodes[index]
        if (conditionList && conditionList.length == 1) {
            const text = conditionList.map(item => `${item.label}${item.operator}${item.value}`).join(" 和 ")
            return text
        }else if(conditionList && conditionList.length > 1){
            const conditionModeText = nodeConfig.conditionNodes[index].conditionMode==1?'且行':'或行'
            return conditionList.length + "个条件，" + conditionModeText
        }else{
            if(index == nodeConfig.conditionNodes.length - 1){
                return "其他条件进入此流程"
            }else{
                return false
            }
        }
    }

</script>

<style scoped>
</style>
