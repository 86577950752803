<template>
    <div :class="{'ly-is-full':isFull}">
        <div class="tableSelect" ref="tableSelect">
            <el-form :model="formInline">
                <el-form-item label="视频：">
                    <el-input v-model="formInline.video" maxlength="100" clearable placeholder="请输入视频" @change="search" style="width:180px"></el-input>
                </el-form-item>
                <el-form-item label="播放量：">
                    <ly-input-range v-model="amount_playDatas" @onchange="amount_playDatasChange"></ly-input-range>
                </el-form-item>
                <el-form-item label="收藏：">
                    <ly-input-range v-model="collectDatas" @onchange="collectDatasChange"></ly-input-range>
                </el-form-item>
                <el-form-item label="简介：">
                    <el-input v-model="formInline.content" maxlength="100" clearable placeholder="请输入简介" @change="search" style="width:180px"></el-input>
                </el-form-item>
                <el-form-item label="标题：">
                    <el-input v-model="formInline.title" maxlength="100" clearable placeholder="请输入标题" @change="search" style="width:180px"></el-input>
                </el-form-item>
                <el-form-item label="点赞：">
                    <el-input v-model="formInline.likes" maxlength="100" clearable placeholder="请输入点赞" @change="search" style="width:180px"></el-input>
                </el-form-item>
                <el-form-item label="商品：">
                    <el-input v-model="formInline.spu" maxlength="100" clearable placeholder="请输入商品" @change="search" style="width:180px"></el-input>
                </el-form-item>
                <el-form-item label="创建时间：" v-if="true">
                    <el-date-picker
                        style="width:350px"
                        v-model="timers"
                        type="datetimerange"
                        @change="timeChange"
                        range-separator="至"
                        start-placeholder="开始日期"
                        end-placeholder="结束日期">
                    </el-date-picker>
                </el-form-item>
                <el-form-item label="" v-show="hasPermission(this.$route.name,'Search')"><el-button  @click="search" type="primary" icon="Search">查询</el-button></el-form-item>
                <el-form-item label=""><el-button  @click="handleEdit('','reset')" icon="Refresh">重置</el-button></el-form-item>
                <el-form-item label="" v-show="hasPermission(this.$route.name,'Create')"><el-button type="primary" icon="Plus" @click="handelAdd" >新增</el-button></el-form-item>
                <el-form-item label="" v-if="hasPermission(this.$route.name,'Delete') && true"><el-button @click="handleDelete" type="danger" :disabled="multiple" icon="Delete" >删除</el-button></el-form-item>
                <el-form-item label="" v-if="hasPermission(this.$route.name,'Export') && false"><el-button type="primary" @click="exportDataBackend" :disabled="loadingPage" icon="Download">导出</el-button></el-form-item>
            </el-form>
        </div>
        <div class="table">
            <ly-table tableName="lyAutoCodeShortVideoTable" :height="tableHeight" :pageSize="10" :apiObj="apiObjList" :params="formInline" ref="tableref" :column="column" showSelectable showSequence @selection-change="handleSelectionChange">
                <template #video="scope">
                    <span>{{ !!scope.row.video?scope.row.video.split(',').length:0 }}个</span>
                </template>
                <template #likes="scope">
                    <div>
  {{!!scope.row.likes?scope.row.likes.length:0 }}个
</div>
                </template>
                <template #spu="scope">
                    <span>{{ scope.row.spu_lyautocode_name }}</span>
                </template>
                <el-table-column label="操作" fixed="right" width="180">
                    <template #header>
                        <div style="display: flex;justify-content: space-between;align-items: center;">
                            <div>操作</div>
                            <div @click="setFull">
                                <el-tooltip content="全屏" placement="bottom">
                                    <el-icon ><full-screen /></el-icon>
                                </el-tooltip>
                            </div>
                        </div>
                    </template>
                    <template #default="scope">
                        <span class="table-operate-btn" @click="handleEdit(scope.row,'detail')" v-show="hasPermission(this.$route.name,'Retrieve')">详情</span>
                        <span class="table-operate-btn" @click="handleEdit(scope.row,'edit')" v-show="hasPermission(this.$route.name,'Update')">编辑</span>
                        <span class="table-operate-btn" @click="handleEdit(scope.row,'delete')" v-show="hasPermission(this.$route.name,'Delete')">删除</span>
                    </template>
                </el-table-column>
            </ly-table>
        </div>
        <ly-dialog v-model="dialogVisible" :title="dialogTitle" :width="dialogWidth" :top="dialogTop" :fullscreen="fullscreen" :before-close="handleDialogClose">
            <el-form :model="formData" :rules="rules" ref="rulesForm" :disabled="formDisabled" :label-position="formLabelPosition" :label-width="formLabelWidth">
                <el-row :gutter="20">
                    <el-col :span="24?24:24">
                        <el-form-item label="视频" prop="video" v-if="true">
                            <ly-public-file-multiple-upload v-model="formData.video" :disabled="isFormItemEditDisabled(false)"
                                :multiple="true" :drag="true" :limit="10" tip="" :show-file-list="true"
                                :max-size="200" icon="plus" button-type="primary">
                                <el-icon class="el-icon--upload" v-if="true"><upload-filled /></el-icon>
                                <div class="el-upload__text" v-if="true">
                                拖拽文件到这 或 <em>点击上传</em>
                                </div>
                            </ly-public-file-multiple-upload>
                        </el-form-item>
                    </el-col>
                    <el-col :span="24?24:24">
                        <el-form-item label="播放量" prop="amount_play" v-if="true">
                            <el-input-number v-model="formData.amount_play" :disabled="isFormItemEditDisabled(false)"  :min="0" :max="999999" style="width: 100%;" />
                        </el-form-item>
                    </el-col>
                    <el-col :span="24?24:24">
                        <el-form-item label="收藏" prop="collect" v-if="true">
                            <el-input-number v-model="formData.collect" :disabled="isFormItemEditDisabled(false)"  :min="0" :max="999999" style="width: 100%;" />
                        </el-form-item>
                    </el-col>
                    <el-col :span="24?24:24">
                        <el-form-item label="简介" prop="content" v-if="true">
                            <el-input v-model="formData.content" :disabled="isFormItemEditDisabled(false)" :rows="3" type="textarea" style="width: 100%;" placeholder="请输入简介" />
                        </el-form-item>
                    </el-col>
                    <el-col :span="24?24:24">
                        <el-form-item label="标题" prop="title" v-if="true">
                            <el-input v-model="formData.title" :disabled="isFormItemEditDisabled(false)" style="width: 100%;" placeholder="请输入标题"/>
                        </el-form-item>
                    </el-col>
                    <el-col :span="24?24:24">
                        <el-form-item label="点赞" prop="likes" v-if="true">
                            <el-select v-model="formData.likes" :disabled="isFormItemEditDisabled(false)" multiple clearable filterable placeholder="请选择点赞" style="width: 100%;">
                                <el-option
                                    v-for="item in likesLyAudoCodeAPIList"
                                    :key="item.id"
                                    :label="item.nickname"
                                    :value="item.id" />
                            </el-select>
                        </el-form-item>
                    </el-col>
                    <el-col :span="24?24:24">
                        <el-form-item label="商品" prop="spu" v-if="true">
                            <el-select v-model="formData.spu" :disabled="isFormItemEditDisabled(false)" clearable filterable placeholder="请选择商品" style="width: 100%;">
                                <el-option
                                    v-for="item in spuLyAudoCodeAPIList"
                                    :key="item.id"
                                    :label="item.name"
                                    :value="item.id" />
                            </el-select>
                        </el-form-item>
                    </el-col>
                </el-row>
            </el-form>
            <template v-slot:footer>
                <el-button @click="handleDialogClose">取消</el-button>
                <el-button @click="handleDialogSubmit" type="primary" :loading="dialogLoadingSave" v-if="dialogTitle!='详情'">确定</el-button>
            </template>
        </ly-dialog>
    </div>
</template>

<script>

    import {ajaxGet,ajaxPost,ajaxDelete,ajaxPut,ajaxPatch,ajaxDownloadExcel} from '@/api/request'
    // 列表
    const lyAutoCodeList = params => ajaxGet({url: `autocode/ShortVideo/`,params})
    // 新增
    const lyAutoCodeAdd = params => ajaxPost({url: `autocode/ShortVideo/`,params})
    // 编辑
    const lyAutoCodeEdit = params => ajaxPut({url: `autocode/ShortVideo/`,params})
    // 删除
    const lyAutoCodeDelete = params => ajaxDelete({url: `autocode/ShortVideo/`,params})
    // 导出
    const lyAutoCodeExport = params => ajaxDownloadExcel({url: `autocode/ShortVideo/export/`,params})
    const likesLyAudoCodeAPI = params => ajaxGet({url: `users/users/`,params})
    const spuLyAudoCodeAPI = params => ajaxGet({url: `mall/goodsspu/`,params})
    
    import {useDictStore} from "@/store/dict";
    
    import {dateFormats,getTableHeight,deepClone} from "@/utils/util";
    import LyDialog from "@/components/dialog/dialog";
    export default {
        name: "lyAutoCodeShortVideo",
        components: {LyDialog},
        setup(){
            const dictStore = useDictStore()
            return { dictStore }
        },
        data(){
            return{
                isFull:false,
                tableHeight:500,
                loadingPage:false,
                apiObjList: lyAutoCodeList,
                // 选项框选中数组
                ids: [],
                // 选项框非单个禁用
                single: true,
                // 非多个禁用
                multiple: true,
                formInline:{},
                timers:[],
                tableData:[],
                column:[
                    {
                        label: "视频",
                        prop: "video",
                        minWidth:"100",
                        hide: !true
                    },
                    {
                        label: "播放量",
                        prop: "amount_play",
                        minWidth:"100",
                        hide: !true
                    },
                    {
                        label: "收藏",
                        prop: "collect",
                        minWidth:"100",
                        hide: !true
                    },
                    {
                        label: "简介",
                        prop: "content",
                        minWidth:"100",
                        hide: !true
                    },
                    {
                        label: "标题",
                        prop: "title",
                        minWidth:"100",
                        hide: !true
                    },
                    {
                        label: "点赞",
                        prop: "likes",
                        minWidth:"100",
                        hide: !true
                    },
                    {
                        label: "商品",
                        prop: "spu",
                        minWidth:"100",
                        hide: !true
                    },
                    {
                        label: "创建时间",
                        prop: "create_datetime",
                        minWidth:"180"
                    }
                ],
                //弹窗
                dialogVisible:false,
                dialogTitle:"",
                dialogLoadingSave:false,
                fullscreen:false,
                dialogWidth:"50%",
                dialogTop:"15vh",
                formDisabled:false,
                formLabelPosition:"right",
                formLabelWidth:"auto",
                formData:{"video": null, "amount_play": null, "collect": null, "content": null, "title": null, "likes": [], "spu": null},
                rules:{
                },
                likesLyAudoCodeAPIList:[],
                spuLyAudoCodeAPIList:[],
                amount_playDatas:[],
                collectDatas:[],
            }
        },
        methods:{
            setLoadingPage(bools){
                this.loadingPage = bools
                this.$refs.tableref.loadingPage(bools)
            },
            setFull(){
                this.isFull=!this.isFull
                window.dispatchEvent(new Event('resize'))
            },
            //多选项框被选中数据
            handleSelectionChange(selection) {
                this.ids = selection.map(item => item.id);
                this.single = selection.length !== 1;
                this.multiple = !selection.length;
            },
            /** 批量删除按钮操作 */
            handleDelete(row) {
                const ids = this.ids;
                let vm = this
                vm.$confirm('是否确认删除选中的数据项?', "警告", {
                    confirmButtonText: "确定",
                    cancelButtonText: "取消",
                    type: "warning"
                }).then(function() {
                    return lyAutoCodeDelete({id:ids}).then(res=>{
                        if(res.code == 2000) {
                            vm.$message.success(res.msg)
                            vm.getData()
                        } else {
                            vm.$message.warning(res.msg)
                        }
                    })
                })
            },
            convertStrNum(model,value){
                if(model === "" || model === null || model === undefined){
                    return value
                }
                else if(Object.prototype.toString.call(model)=== "[object String]"){
                    return value.toString()
                }
                else if(Object.prototype.toString.call(model) === "[object Number]"){
                    return value*1
                }else{
                    return value
                }
            },
            //判断单个组件编辑时是否禁用disabled参数为bool值
            isFormItemEditDisabled(disabled = false){
                if(disabled == "" || disabled == null || disabled == undefined){
                    return false
                }else if((disabled == true || disabled == 'True') && this.dialogTitle==='编辑'){
                    return true
                }
                return false
            },
            handelAdd() {
                this.dialogTitle = "添加"
                this.dialogVisible = true
                this.likesLyAudoCodeAPIFunction()
                this.spuLyAudoCodeAPIFunction()
            },
            handleDialogClose(){
                this.dialogTitle =""
                this.dialogVisible = false
                this.dialogLoadingSave = false
                this.formDisabled = false
                this.formData = {"video": null, "amount_play": null, "collect": null, "content": null, "title": null, "likes": [], "spu": null}
            },
            handleDialogSubmit(){
                this.$refs['rulesForm'].validate((obj)=>{
                    if(obj) {
                        this.dialogLoadingSave=true
                        let param = {
                            ...this.formData
                        }
                        if(this.dialogTitle=="编辑"){
                            lyAutoCodeEdit(param).then(res=>{
                                this.dialogLoadingSave=false
                                if(res.code ==2000) {
                                    this.$message.success(res.msg)
                                    this.handleDialogClose()
                                    this.getData()
                                } else {
                                    this.$message.warning(res.msg)
                                }
                            })
                        }else{
                            lyAutoCodeAdd(param).then(res=>{
                                this.dialogLoadingSave=false
                                if(res.code ==2000) {
                                    this.$message.success(res.msg)
                                    this.handleDialogClose()
                                    this.getData()
                                } else {
                                    this.$message.warning(res.msg)
                                }
                            })
                        }

                    }
                })
                this.dialogLoadingSave=false
            },
            handleEdit(row,flag) {
                if(flag=='edit') {
                    this.dialogTitle = "编辑"
                    this.dialogVisible = true
                    this.formData = deepClone(row)
                    this.likesLyAudoCodeAPIFunction()
                    this.spuLyAudoCodeAPIFunction()
                }
                else if(flag=='delete') {
                    let vm = this
                    vm.$confirm('您确定要删除选中的数据吗？','警告',{
                        closeOnClickModal:false,
                        type: "warning"
                    }).then(res=>{
                        lyAutoCodeDelete({id:row.id}).then(res=>{
                            if(res.code == 2000) {
                                vm.$message.success(res.msg)
                                vm.getData()
                            } else {
                                vm.$message.warning(res.msg)
                            }
                        })
                    }).catch(()=>{

                    })
                }else if(flag=='detail'){
                    this.dialogTitle = "详情"
                    this.dialogVisible = true
                    this.formDisabled = true
                    this.formData = deepClone(row)
                    this.likesLyAudoCodeAPIFunction()
                    this.spuLyAudoCodeAPIFunction()
                }else if(flag=="reset"){
                    this.formInline = {}
                    this.timers = []
                    this.amount_playDatas=[]
                    this.collectDatas=[]
                    this.search()
                }
            },
            /**
             * 从URL里下载文件
            */
            // 下载文件
            downloadFileURL(url) {
                var a =document.createElement("a")
                var event = new MouseEvent('click')
                a.href = url
                a.target = "_blank"
                a.dispatchEvent(event)
            },
            //excel文件流下载
            downloadExcelBlob(res) {
                let fileName = new Date().getTime() +".xlsx"
                let dispositionStr = res.headers["content-disposition"];
                if (dispositionStr == null || dispositionStr === "") {

                }else{
                    // 获取文件名
                    let dispositionArr = dispositionStr.split(";");
                    fileName = decodeURIComponent(dispositionArr[1]);
                    fileName = fileName.split("=")[1];
                }
                const blob = new Blob([res.data], {
                    type: "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet",
                });
                let href = window.URL.createObjectURL(blob); //下载链接
                let link = document.createElement("a")
                link.href = href
                link.download = fileName //下载后文件名
                document.body.appendChild(link);
                link.click(); //点击下载
                document.body.removeChild(link); //下载完成移除元素
                window.URL.revokeObjectURL(href);  //释放blob对象
                this.$message.success('导出成功')
            },
            //导出
            exportDataBackend() {
                let params = {}
                if(this.ids.length>0){
                    params = {
                        ids:this.ids,
                    }
                }
                this.setLoadingPage(true)
                lyAutoCodeExport(params).then(res => {
                    this.setLoadingPage(false)
                    this.downloadExcelBlob(res)
                })
            },
            search() {
                this.$refs.tableref.reload(this.formInline)
            },
            //获取列表
            async getData() {
                this.$refs.tableref.getData()
            },
            timeChange(val){
                if (val) {
                    this.formInline.beginAt=dateFormats(val[0],'yyyy-MM-dd hh:mm:ss');
                    this.formInline.endAt=dateFormats(val[1],'yyyy-MM-dd hh:mm:ss');
                } else {
                    this.formInline.beginAt = null
                    this.formInline.endAt = null
                }
                this.search()
            },
            amount_playDatasChange(val) {
                if (val) {
                    this.formInline.amount_play_min=val[0];
                    this.formInline.amount_play_max=val[1];
                } else {
                    this.formInline.amount_play_min = null
                    this.formInline.amount_play_max = null
                }
                this.search()
            },
            collectDatasChange(val) {
                if (val) {
                    this.formInline.collect_min=val[0];
                    this.formInline.collect_max=val[1];
                } else {
                    this.formInline.collect_min = null
                    this.formInline.collect_max = null
                }
                this.search()
            },
            // 计算搜索栏的高度
            listenResize() {
                this.$nextTick(() => {
                    this.getTheTableHeight()
                })
            },
            getTheTableHeight(){
                let tabSelectHeight = this.$refs.tableSelect?this.$refs.tableSelect.offsetHeight:0
                tabSelectHeight = this.isFull?tabSelectHeight - 110:tabSelectHeight
                this.tableHeight =  getTableHeight(tabSelectHeight,false) - 125
            },
            likesLyAudoCodeAPIFunction() {
                likesLyAudoCodeAPI({page:1,limit:999}).then(res => {
                    if(res.code ==2000) {
                        this.likesLyAudoCodeAPIList = res.data.data
                    }
                })
            },
            spuLyAudoCodeAPIFunction() {
                spuLyAudoCodeAPI({page:1,limit:999}).then(res => {
                    if(res.code ==2000) {
                        this.spuLyAudoCodeAPIList = res.data.data
                    }
                })
            },

        },
        mounted() {
            // 监听页面宽度变化搜索框的高度
            window.addEventListener('resize', this.listenResize);
            this.$nextTick(() => {
                this.getTheTableHeight()
            })
        },

        unmounted() {
            // 页面销毁，去掉监听事件
            window.removeEventListener("resize", this.listenResize);
        },

    }
</script>

<style lang="scss" scoped>

</style>
