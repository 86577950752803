<template>
  <div :class="{'ly-is-full':isFull}">
    <div class="tableSelect" ref="tableSelect">
      <el-form :inline="true" :model="formInline" label-position="left">
        <!--                <el-form-item label="订单编号：">-->
        <!--                    <el-input v-model.trim="formInline.order_no" maxlength="60"  clearable placeholder="订单编号" @change="search" style="width:200px"></el-input>-->
        <!--                </el-form-item>-->
        <el-form-item label="用户信息：">
          <el-input v-model.trim="formInline.search" maxlength="60" clearable placeholder="用户信息" @change="search"
                    style="width:150px"></el-input>
        </el-form-item>
        <el-form-item label="审核状态：">
          <el-select v-model="formInline.audit_status" placeholder="请选择" clearable @change="search"
                     style="width:100px">
            <el-option
                v-for="item in auditStatusList"
                :key="item.id"
                :label="item.name"
                :value="item.id">
            </el-option>
          </el-select>
        </el-form-item>
        <el-form-item label="时间：">
          <el-date-picker
              style="width:350px"
              v-model="timers"
              type="datetimerange"
              @change="timeChange"
              range-separator="至"
              start-placeholder="开始时间"
              end-placeholder="结束时间">
          </el-date-picker>
        </el-form-item>
        <el-form-item label="">
          <el-button @click="search" type="primary" icon="Search" v-show="hasPermission(this.$route.name,'Search')">
            查询
          </el-button>
        </el-form-item>
        <el-form-item label="">
          <el-button @click="handleEdit('','reset')" icon="Refresh">重置</el-button>
        </el-form-item>
      </el-form>
    </div>
    <el-table :height="tableHeight" border :data="tableData" ref="tableref" v-loading="loadingPage" style="width: 100%">
      <el-table-column type="index" width="60" align="center" label="序号">
        <template #default="scope">
          <span v-text="getIndex(scope.$index)"></span>
        </template>
      </el-table-column>
      <el-table-column width="150" prop="money" label="金额"></el-table-column>
      <!--            <el-table-column width="70" prop="avatar" label="用户头像">-->
      <!--                    <template #default="scope">-->
      <!--                        <img  :src="scope.row.userinfo.avatar ? scope.row.userinfo.avatar : defaultImg" style="width: 30px;height: 30px" :onerror="defaultImg">-->
      <!--                    </template>-->
      <!--            </el-table-column>-->
      <el-table-column width="130" prop="userinfo.nickname" label="用户昵称" show-overflow-tooltip></el-table-column>
      <el-table-column width="150" prop="userinfo.mobile" label="用户电话"></el-table-column>
      <el-table-column width="170" prop="create_datetime" label="时间"></el-table-column>
      <el-table-column width="150" prop="balance" label="账户余额" show-overflow-tooltip></el-table-column>
      <el-table-column width="120" prop="audit_status_name" label="审核状态"></el-table-column>
      <el-table-column width="120" prop="status_name" label="打款状态"></el-table-column>
      <el-table-column min-width="160" prop="audit_remarks" label="原因" show-overflow-tooltip></el-table-column>
      <el-table-column label="操作" fixed="right" width="180">
        <template #default="scope">
          <span class="table-operate-btn" @click="handleEdit(scope.row,'audit')"
                v-show="hasPermission(this.$route.name,'Audit')" v-if="scope.row.status==10">审核</span>
        </template>
      </el-table-column>
    </el-table>
    <Pagination v-bind:child-msg="pageparm" @callFather="callFather"></Pagination>
    <AuditModule ref="cashoutauditModuleFlag" @refreshData="getData" v-if="isDialogVisible"
                 @closed="isDialogVisible = false"></AuditModule>
  </div>
</template>
<script>
import Pagination from "@/components/Pagination";
import {dateFormats} from "@/utils/util";
import {lytiktokunionCashout} from '@/api/api'
import {lyMixins} from "@/mixins/mixins"
import AuditModule from "./components/auditModule";

export default {
  mixins: [lyMixins],
  components: {
    AuditModule,
    Pagination,
  },
  name: 'cashoutManage',
  data() {
    return {
      isFull: false,
      loadingPage: false,
      formInline: {
        page: 1,
        limit: 10,
      },
      orderstatics: {
        totalmoney: 0,
      },
      defaultImg: require('../../assets/img/avatar.jpg'),
      pageparm: {
        page: 1,
        limit: 10,
        total: 0
      },
      tableData: [],
      timers: [],
      auditStatusList: [
        {id: 10, name: "待审核"},
        {id: 20, name: "已通过"},
        {id: 30, name: "未通过"},
      ],
      isDialogVisible: false,
    }
  },
  methods: {
    setFull() {
      this.isFull = !this.isFull
    },
    // 表格序列号
    getIndex($index) {
      // (当前页 - 1) * 当前显示数据条数 + 当前行数据的索引 + 1
      return (this.pageparm.page - 1) * this.pageparm.limit + $index + 1
    },
    handleEdit(row, flag) {
      if (flag == 'audit') {
        this.isDialogVisible = true
        this.$nextTick(() => {
          this.$refs.cashoutauditModuleFlag.handleOpen(row)
        })
      } else if (flag == "reset") {
        this.formInline = {
          page: 1,
          limit: 10
        }
        this.pageparm = {
          page: 1,
          limit: 10,
          total: 0
        }
        this.timers = []
        this.search()
      }
    },

    callFather(parm) {
      this.formInline.page = parm.page
      this.formInline.limit = parm.limit
      this.getData()
    },
    search() {
      this.formInline.page = 1
      this.formInline.limit = 10
      this.getData()
    },
    //获取列表
    async getData() {
      this.loadingPage = true
      lytiktokunionCashout(this.formInline).then(res => {
        this.loadingPage = false
        if (res.code == 2000) {
          this.tableData = res.data.data
          this.pageparm.page = res.data.page;
          this.pageparm.limit = res.data.limit;
          this.pageparm.total = res.data.total;
        }
      })
    },
    timeChange(val) {
      if (val) {
        this.formInline.beginAt = dateFormats(val[0], 'yyyy-MM-dd hh:mm:ss');
        this.formInline.endAt = dateFormats(val[1], 'yyyy-MM-dd hh:mm:ss');
      } else {
        this.formInline.beginAt = null
        this.formInline.endAt = null
      }
      this.search()
    },
    /**
     * 从URL里下载文件
     */
    // 下载文件
    downloadFile(url) {
      var iframe = document.createElement("iframe")
      iframe.style.display = "none";
      iframe.src = url;
      document.body.appendChild(iframe);
    },
    exportData() {
      // var params = {
      //     type:3,
      //     page: 1,
      //     limit: 9999,
      // }
      // retrieveFinancestatisticsExport(params).then(res => {
      //      if(res.code ==2000) {
      //          this.downloadFile(res.data.data)
      //          //this.$message.warning(res.data.data)
      //      }
      //  })
    }
  },
  created() {
    this.getData()
  },
}
</script>
<style lang="scss">
.order-static {
  display: flex;
  font-size: 14px;
  background: var(--l-order-statics);
  padding: 10px;

  li {
    margin-right: 30px;

  }
}
</style>