<template>
  <div :class="{'ly-is-full':isFull}">
    <div class="tableSelect" ref="tableSelect">
      <el-form :model="formInline">
        <el-form-item label="系统模块：">
          <el-input v-model="formInline.model_name" maxlength="100" clearable placeholder="请输入系统模块"
                    @change="search" style="width:180px"></el-input>
        </el-form-item>
        <el-form-item label="操作地址：">
          <el-input v-model="formInline.ip_address" maxlength="100" clearable placeholder="请输入操作地址"
                    @change="search" style="width:180px"></el-input>
        </el-form-item>
        <el-form-item label="创建时间：" v-if="true">
          <el-date-picker
              style="width:350px"
              v-model="timers"
              type="datetimerange"
              @change="timeChange"
              range-separator="至"
              start-placeholder="开始日期"
              end-placeholder="结束日期">
          </el-date-picker>
        </el-form-item>
        <el-form-item label="" v-show="hasPermission(this.$route.name,'Search')">
          <el-button @click="search" type="primary" icon="Search">查询</el-button>
        </el-form-item>
        <el-form-item label="">
          <el-button @click="handleEdit('','reset')" icon="Refresh">重置</el-button>
        </el-form-item>
        <el-form-item label="" v-show="hasPermission(this.$route.name,'Create') && false">
          <el-button type="primary" icon="Plus" @click="handelAdd">新增</el-button>
        </el-form-item>
        <el-form-item label="" v-if="hasPermission(this.$route.name,'Delete') && false">
          <el-button @click="handleDelete" type="danger" :disabled="multiple" icon="Delete">删除</el-button>
        </el-form-item>
      </el-form>
    </div>
    <div class="table">
      <ly-table tableName="lyAutoCodeGeneralModelLogTable" :height="tableHeight" :pageSize="10" :apiObj="apiObjList"
                :params="formInline" ref="tableref" :column="column" showSelectable showSequence
                @selection-change="handleSelectionChange">
        <el-table-column label="操作" fixed="right" width="180">
          <template #header>
            <div style="display: flex;justify-content: space-between;align-items: center;">
              <div>操作</div>
              <div @click="setFull">
                <el-tooltip content="全屏" placement="bottom">
                  <el-icon>
                    <full-screen/>
                  </el-icon>
                </el-tooltip>
              </div>
            </div>
          </template>
          <template #default="scope">
            <span class="table-operate-btn" @click="handleEdit(scope.row,'detail')"
                  v-show="hasPermission(this.$route.name,'Retrieve')">详情</span>
          </template>
        </el-table-column>
      </ly-table>
    </div>
    <ly-dialog v-model="dialogVisible" :title="dialogTitle" :width="dialogWidth" :top="dialogTop"
               :fullscreen="fullscreen" :before-close="handleDialogClose">
      <el-form :model="formData" :rules="rules" ref="rulesForm" :disabled="formDisabled"
               :label-position="formLabelPosition" :label-width="formLabelWidth">
        <el-row :gutter="20">
          <el-col :span="24?24:24">
            <el-form-item label="系统模块" prop="model_name" v-if="true">
              <el-input v-model="formData.model_name" :disabled="isFormItemEditDisabled(false)" style="width: 100%;"
                        placeholder="请输入系统模块"/>
            </el-form-item>
          </el-col>
          <el-col :span="24?24:24">
            <el-form-item label="操作地址" prop="ip_address" v-if="true">
              <el-input v-model="formData.ip_address" :disabled="isFormItemEditDisabled(false)" style="width: 100%;"
                        placeholder="请输入操作地址"/>
            </el-form-item>
          </el-col>
        </el-row>
      </el-form>
      <template v-slot:footer>
        <el-button @click="handleDialogClose">取消</el-button>
      </template>

      <!--        子表编辑页面 start -->
      <div :class="{'ly-is-full':isFull2}">
        <div class="tableSelect" ref="tableSelect">
          <el-form :model="formInline2">
            <el-form-item label="手机品牌：">
              <el-input v-model="formInline2.brand_phone" maxlength="100" clearable placeholder="请输入手机品牌"
                        @change="search2" style="width:180px"></el-input>
            </el-form-item>
            <el-form-item label="手机型号：">
              <el-input v-model="formInline2.model_phone" maxlength="100" clearable placeholder="请输入手机型号"
                        @change="search2" style="width:180px"></el-input>
            </el-form-item>
            <el-form-item label="">
              <el-button @click="search2" type="primary" icon="Search">查询</el-button>
            </el-form-item>
            <el-form-item label="">
              <el-button @click="handleEdit2('','reset')" icon="Refresh">重置</el-button>
            </el-form-item>
            <el-form-item label="">
              <el-button type="primary" @click="exportDataBackend" :disabled="loadingPage" icon="Download">导出
              </el-button>
            </el-form-item>
          </el-form>
        </div>
        <div class="table">
          <ly-table tableName="lyAutoCodeGeneralModelLogDetailTable" :height="tableHeight2" :pageSize="10"
                    :apiObj="apiObjList2" :params="formInline2" ref="tableref" :column="column2" showSelectable
                    showSequence @selection-change="handleSelectionChange2">
            <template #general_model_log_id="scope">
              <span>{{ scope.row.general_model_log_id_lyautocode_name }}</span>
            </template>

          </ly-table>
        </div>
      </div>
      <!--        子表编辑页面 end -->

    </ly-dialog>
  </div>
</template>

<script>

import {ajaxDelete, ajaxDownloadExcel, ajaxGet, ajaxPost, ajaxPut} from '@/api/request'
import {useDictStore} from "@/store/dict";

import {dateFormats, deepClone, getTableHeight} from "@/utils/util";
import LyDialog from "@/components/dialog/dialog";
// 列表
const lyAutoCodeList = params => ajaxGet({url: `autocode/GeneralModelLog/`, params})
// 新增
const lyAutoCodeAdd = params => ajaxPost({url: `autocode/GeneralModelLog/`, params})
// 编辑
const lyAutoCodeEdit = params => ajaxPut({url: `autocode/GeneralModelLog/`, params})
// 删除
const lyAutoCodeDelete = params => ajaxDelete({url: `autocode/GeneralModelLog/`, params})
// 导出
const lyAutoCodeExport = params => ajaxDownloadExcel({url: `autocode/GeneralModelLog/export/`, params})
// 列表
const lyAutoCodeList2 = params => ajaxGet({url: `autocode/GeneralModelLogDetail/`, params})

export default {
  name: "lyAutoCodeGeneralModelLog",
  components: {LyDialog},
  setup() {
    const dictStore = useDictStore()
    return {dictStore}
  },
  data() {
    return {
      isFull: false,
      tableHeight: 500,
      loadingPage: false,
      apiObjList: lyAutoCodeList,
      // 选项框选中数组
      ids: [],
      // 选项框非单个禁用
      single: true,
      // 非多个禁用
      multiple: true,
      formInline: {},
      timers: [],
      tableData: [],
      column: [
        {
          label: "系统模块",
          prop: "model_name",
          minWidth: "100",
          hide: !true
        },
        {
          label: "操作地址",
          prop: "ip_address",
          minWidth: "100",
          hide: !true
        },
        {
          label: "创建时间",
          prop: "create_datetime",
          minWidth: "180"
        },
        {
          label: "操作人",
          prop: "creator",
          minWidth: "80"
        }
      ],
      //弹窗
      dialogVisible: false,
      dialogTitle: "",
      dialogLoadingSave: false,
      fullscreen: false,
      dialogWidth: "50%",
      dialogTop: "15vh",
      formDisabled: false,
      formLabelPosition: "right",
      formLabelWidth: "auto",
      formData: {"model_name": null, "ip_address": null},
      rules: {},

      // 子表数据
      isFull2: false,
      tableHeight2: 500,
      loadingPage2: false,
      apiObjList2: lyAutoCodeList2,
      // 选项框选中数组
      ids2: [],
      // 选项框非单个禁用
      single2: true,
      // 非多个禁用
      multiple2: true,
      formInline2: {},
      timers2: [],
      tableData2: [],
      column2: [
        {
          label: "表名称",
          prop: "table_type",
          minWidth: "100",
          hide: !true
        },
        {
          label: "手机品牌",
          prop: "brand_phone",
          minWidth: "100",
          hide: !true
        },
        {
          label: "手机型号",
          prop: "model_phone",
          minWidth: "100",
          hide: !true
        }
      ],
      //弹窗
      dialogVisible2: false,
      dialogTitle2: "",
      dialogLoadingSave2: false,
      fullscreen2: false,
      dialogWidth2: "50%",
      dialogTop2: "15vh",
      formDisabled2: false,
      formLabelPosition2: "right",
      formLabelWidth2: "auto",
      formData2: {"general_model_log_id": null, "table_type": null, "brand_phone": null, "model_phone": null},
      rules2: {},
    }
  },
  methods: {
    setLoadingPage(bools) {
      this.loadingPage = bools
      this.$refs.tableref.loadingPage(bools)
    },
    setFull() {
      this.isFull = !this.isFull
      window.dispatchEvent(new Event('resize'))
    },
    //多选项框被选中数据
    handleSelectionChange(selection) {
      this.ids = selection.map(item => item.id);
      this.single = selection.length !== 1;
      this.multiple = !selection.length;
    },
    /** 批量删除按钮操作 */
    handleDelete(row) {
      const ids = this.ids;
      let vm = this
      vm.$confirm('是否确认删除选中的数据项?', "警告", {
        confirmButtonText: "确定",
        cancelButtonText: "取消",
        type: "warning"
      }).then(function () {
        return lyAutoCodeDelete({id: ids}).then(res => {
          if (res.code == 2000) {
            vm.$message.success(res.msg)
            vm.getData()
          } else {
            vm.$message.warning(res.msg)
          }
        })
      })
    },
    convertStrNum(model, value) {
      if (model === "" || model === null || model === undefined) {
        return value
      } else if (Object.prototype.toString.call(model) === "[object String]") {
        return value.toString()
      } else if (Object.prototype.toString.call(model) === "[object Number]") {
        return value * 1
      } else {
        return value
      }
    },
    //判断单个组件编辑时是否禁用disabled参数为bool值
    isFormItemEditDisabled(disabled = false) {
      if (disabled == "" || disabled == null || disabled == undefined) {
        return false
      } else if ((disabled == true || disabled == 'True') && this.dialogTitle === '编辑') {
        return true
      }
      return false
    },
    handelAdd() {
      this.dialogTitle = "添加"
      this.dialogVisible = true
    },
    handleDialogClose() {
      this.dialogTitle = ""
      this.dialogVisible = false
      this.dialogLoadingSave = false
      this.formDisabled = false
      this.formData = {"model_name": null, "ip_address": null}
    },

    handleEdit(row, flag) {
      if (flag == 'edit') {
      } else if (flag == 'delete') {
      } else if (flag == 'detail') {
        this.dialogTitle = "详情"
        this.dialogVisible = true
        this.formDisabled = true
        this.formData = deepClone(row)
        console.log(row)
        this.general_model_log_id = row.id
        this.formInline2.general_model_log_id = row.id
        this.search2()
      } else if (flag == "reset") {
        this.formInline = {}
        this.timers = []
        this.search()
      }
    },
    /**
     * 从URL里下载文件
     */
    // 下载文件
    downloadFileURL(url) {
      var a = document.createElement("a")
      var event = new MouseEvent('click')
      a.href = url
      a.target = "_blank"
      a.dispatchEvent(event)
    },
    //excel文件流下载
    downloadExcelBlob(res) {
      let fileName = new Date().getTime() + ".xlsx"
      let dispositionStr = res.headers["content-disposition"];
      if (dispositionStr == null || dispositionStr === "") {

      } else {
        // 获取文件名
        let dispositionArr = dispositionStr.split(";");
        fileName = decodeURIComponent(dispositionArr[1]);
        fileName = fileName.split("=")[1];
      }
      const blob = new Blob([res.data], {
        type: "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet",
      });
      let href = window.URL.createObjectURL(blob); //下载链接
      let link = document.createElement("a")
      link.href = href
      link.download = fileName //下载后文件名
      document.body.appendChild(link);
      link.click(); //点击下载
      document.body.removeChild(link); //下载完成移除元素
      window.URL.revokeObjectURL(href);  //释放blob对象
      this.$message.success('导出成功')
    },

    //导出
    exportDataBackend() {
      let params = {}
      // if (this.ids.length > 0) {
      //   params = {
      //     ids: this.general_model_log_id,
      //   }
      // }
      params = {
        ids: this.general_model_log_id,
      }
      this.setLoadingPage(true)
      lyAutoCodeExport(params).then(res => {
        this.setLoadingPage(false)
        this.downloadExcelBlob(res)
      })
    },
    search() {
      this.$refs.tableref.reload(this.formInline)
    },
    //获取列表
    async getData() {
      this.$refs.tableref.getData()
    },
    timeChange(val) {
      if (val) {
        this.formInline.beginAt = dateFormats(val[0], 'yyyy-MM-dd hh:mm:ss');
        this.formInline.endAt = dateFormats(val[1], 'yyyy-MM-dd hh:mm:ss');
      } else {
        this.formInline.beginAt = null
        this.formInline.endAt = null
      }
      this.search()
    },
    // 计算搜索栏的高度
    listenResize() {
      this.$nextTick(() => {
        this.getTheTableHeight()
      })
    },
    getTheTableHeight() {
      let tabSelectHeight = this.$refs.tableSelect ? this.$refs.tableSelect.offsetHeight : 0
      tabSelectHeight = this.isFull ? tabSelectHeight - 110 : tabSelectHeight
      this.tableHeight = getTableHeight(tabSelectHeight, false) - 125
    },

    // 子表方法
    setLoadingPage2(bools) {
      this.loadingPage2 = bools
      this.$refs.tableref.loadingPage(bools)
    },
    setFull2() {
      this.isFull2 = !this.isFull2
      window.dispatchEvent(new Event('resize'))
    },
    //多选项框被选中数据
    handleSelectionChange2(selection) {
      this.ids2 = selection.map(item => item.id);
      this.single2 = selection.length !== 1;
      this.multiple2 = !selection.length;
    },

    convertStrNum2(model, value) {
      if (model === "" || model === null || model === undefined) {
        return value
      } else if (Object.prototype.toString.call(model) === "[object String]") {
        return value.toString()
      } else if (Object.prototype.toString.call(model) === "[object Number]") {
        return value * 1
      } else {
        return value
      }
    },
    //判断单个组件编辑时是否禁用disabled参数为bool值
    isFormItemEditDisabled2(disabled = false) {
      if (disabled == "" || disabled == null || disabled == undefined) {
        return false
      } else if ((disabled == true || disabled == 'True') && this.dialogTitle2 === '编辑') {
        return true
      }
      return false
    },
    handleDialogClose2() {
      this.dialogTitle2 = ""
      this.dialogVisible2 = false
      this.dialogLoadingSave2 = false
      this.formDisabled2 = false
      this.formData2 = {"general_model_log_id": null, "table_type": null, "brand_phone": null, "model_phone": null}
    },

    handleEdit2(row, flag) {
      if (flag == 'edit') {
      } else if (flag == 'delete') {
      } else if (flag == 'detail') {
      } else if (flag == "reset") {
        this.formInline2 = {
          'general_model_log_id': this.general_model_log_id
        }
        this.timers2 = []
        this.search2()
      }
    },

    search2() {
      console.log('search2')
      console.log(this.formInline2)
      this.$refs.tableref.reload(this.formInline2)
    },
    //获取列表
    async getData2() {
      this.$refs.tableref.getData(this.formInline2)
    },
    // 计算搜索栏的高度
    listenResize2() {
      this.$nextTick(() => {
        this.getTheTableHeight()
      })
    },
    getTheTableHeight2() {
      let tabSelectHeight = this.$refs.tableSelect ? this.$refs.tableSelect.offsetHeight : 0
      tabSelectHeight = this.isFull ? tabSelectHeight - 110 : tabSelectHeight
      this.tableHeight = getTableHeight(tabSelectHeight, false) - 125
    },
  },
  mounted() {
    // 监听页面宽度变化搜索框的高度
    window.addEventListener('resize', this.listenResize);
    this.$nextTick(() => {
      this.getTheTableHeight()
    })
  },

  unmounted() {
    // 页面销毁，去掉监听事件
    window.removeEventListener("resize", this.listenResize);
  },

}
</script>

<style lang="scss" scoped>

</style>
