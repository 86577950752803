const crudField={
  field_name: '',//字段名（英文名）
  verbose_name: '',//字段verbose_name
  field_type: 'CharField',//字段类型
  foreign_key:{
    class_name:'',
    on_delete:'',
    label:'',//显示
    value:'id',//主键
    api_path:'',//前端api的去除api前缀的地址
  },//外键
  max_length: '100',//长度
  default:null,//默认值
  db_index:false,//后端创建索引
  nullable:true,//后端使用此字段用户设置null=True,blank=True
  unique:false,//是否唯一
  can_edit:true,//是否可编辑（编辑时是否显示）
  edit_disabled:false,//编辑时禁用
  can_list:true,//允许显示在列表中（el-table）
  list_display_custom:'',//el-table列自定义显示
  can_search:false,//允许搜索（显示在搜索栏）
  search_type:'',//后端filter搜索方式
  can_export:false,//该字段是否可导出（导出功能使用）
  // form_type:'',//表单类型
  // dic_type:'',//使用字典
  width: 100,//el-table列宽度
}
export const lyContainers = [
  {
    type: 'grid',
    category: 'container',
    icon: 'Grid',
    cols: [],
    formItemFlag: false,//用于标志在el-form-item标签
    displayName:"栅格布局",
    options: {
      name: '',//字段名（可用于后端model的字段名）
      isFormField:false,//用于标志是否为form表单字段（后端自动生成表单字段使用）
      hidden: false,
      gutter: 12,
      colHeight: null,  //栅格列统一高度属性
      customClass: '',  //自定义css类名
    }
  },

  {
    type: 'table',
    category: 'container',
    icon: 'lyicon-table',
    rows: [],
    formItemFlag: false,
    displayName:"表格",
    options: {
      name: '',//字段名（可用于后端model的字段名）
      isFormField:false,//用于标志是否为form表单字段（后端自动生成表单字段使用）
      hidden: false,
      customClass: '',  //自定义css类名
    }
  },

  {
    type: 'tab',
    category: 'container',
    icon: 'lyicon-tab',
    displayType: 'border-card',
    tabs: [],
    formItemFlag: false,
    displayName:"标签页",
    options: {
      name: '',//字段名（可用于后端model的字段名）
      isFormField:false,//用于标志是否为form表单字段（后端自动生成表单字段使用）
      hidden: false,
      customClass: '',  //自定义css类名
      onTabClick: '',
    }
  },

  {
    type: 'grid-col',
    category: 'container',
    icon: '',
    internal: true, //内部组件
    widgetList: [],
    formItemFlag: false,
    displayName:"栅格列",
    options: {
      name: '',//字段名（可用于后端model的字段名）
      isFormField:false,//用于标志是否为form表单字段（后端自动生成表单字段使用）
      hidden: false,
      span: 12,
      offset: 0,
      push: 0,
      pull: 0,
      responsive: false,  //是否开启响应式布局
      md: 12,
      sm: 12,
      xs: 12,
      customClass: '',
    }
  },

  {
    type: 'table-cell',
    category: 'container',
    icon: '',
    displayName:"单元格",
    internal: true,
    widgetList: [],
    formItemFlag: false,
    merged: false,
    options: {
      name: '',//字段名（可用于后端model的字段名）
      isFormField:false,//用于标志是否为form表单字段（后端自动生成表单字段使用）
      cellWidth: '',
      cellHeight: '',
      colspan: 1,
      rowspan: 1,
      customClass: '', 
    }
  },

  {
    type: 'tab-pane',
    category: 'container',
    icon: '',
    internal: true,
    widgetList: [],
    formItemFlag: false,
    options: {
      name: '',//字段名（可用于后端model的字段名）
      isFormField:false,//用于标志是否为form表单字段（后端自动生成表单字段使用）
      label: '',//字段显示名（用于前端显示字段用）
      hidden: false,
      active: false,
      disabled: false,
      customClass: '',
    }
  },
  {
    type: 'card',
    category: 'container',
    icon: 'lyicon-card',
    displayName:"卡片",
    widgetList: [],
    formItemFlag: false,
    options: {
      name: '',//字段名（可用于后端model的字段名）
      isFormField:false,//用于标志是否为form表单字段（后端自动生成表单字段使用）
      label: 'card',
      hidden: false,
      showHeader:true,//是否显示头部
      folded: false,//默认展开
      showFold: true,//显示折叠按钮
      classicsMode:false,//经典模式
      cardWidth: '100%',
      shadow: 'never',
      customClass: '',
    }
  }


]

export const lyBasicFields = [
  {
    type: 'input',
    icon: 'lyicon-text-field',
    formItemFlag: true,
    displayName:"单行输入",
    options: {
      name: '',//字段名（可用于后端model的字段名）
      isFormField:true,//用于标志是否为form表单字段（后端自动生成表单字段使用）
      crudField:crudField,//后端crud需要的字段信息
      label: '',
      labelAlign: '',
      type: 'text',
      defaultValue: '',
      placeholder: '',
      columnWidth: '200px',
      size: '',
      labelWidth: null,
      labelHidden: false,
      readonly: false,
      disabled: false,
      hidden: false,
      clearable: true,
      showPassword: false,
      required: false,
      requiredHint: '',
      validation: '',
      validationHint: '',
      customClass: '',  //自定义css类名
      minLength: null,
      maxLength: null,
      showWordLimit: false,
      prefixIcon: '',
      suffixIcon: '',
      prependSlot:'',//插槽
      appendSlot:'',
      onInput: '',
      onChange: '',
      onFocus: '',
      onBlur: '',
    },
  },

  {
    type: 'textarea',
    icon: 'lyicon-textarea-field',
    formItemFlag: true,
    displayName:"多行输入",
    options: {
      name: '',//字段名（可用于后端model的字段名）
      isFormField:true,//用于标志是否为form表单字段（后端自动生成表单字段使用）
      crudField:crudField,//后端crud需要的字段信息
      label: '',
      labelAlign: '',
      rows: 3,
      defaultValue: '',
      placeholder: '',
      columnWidth: '200px',
      size: '',
      labelWidth: null,
      labelHidden: false,
      readonly: false,
      disabled: false,
      hidden: false,
      required: false,
      requiredHint: '',
      validation: '',
      validationHint: '',
      customClass: '',  //自定义css类名
      minLength: null,
      maxLength: null,
      showWordLimit: false,
      onInput: '',
      onChange: '',
      onFocus: '',
      onBlur: '',
    },
  },

  {
    type: 'number',
    icon: 'lyicon-number-field',
    formItemFlag: true,
    displayName:"计数器",
    options: {
      name: '',//字段名（可用于后端model的字段名）
      isFormField:true,//用于标志是否为form表单字段（后端自动生成表单字段使用）
      crudField:crudField,//后端crud需要的字段信息
      label: '',
      labelAlign: '',
      defaultValue: 0,
      placeholder: '',
      columnWidth: '200px',
      size: '',
      labelWidth: null,
      labelHidden: false,
      disabled: false,
      hidden: false,
      required: false,
      requiredHint: '',
      validation: '',
      validationHint: '',
      customClass: '',  //自定义css类名
      min: -100000000000,
      max: 100000000000,
      precision: 0,
      step: 1,
      controlsPosition: 'right',
      onChange: '',
      onFocus: '',
      onBlur: '',
    },
  },

  {
    type: 'radio',
    icon: 'lyicon-radio-field',
    formItemFlag: true,
    displayName: "单选框组",
    options: {
      name: '',//字段名（可用于后端model的字段名）
      isFormField:true,//用于标志是否为form表单字段（后端自动生成表单字段使用）
      crudField:crudField,//后端crud需要的字段信息
      label: '',
      labelAlign: '',
      defaultValue: null,
      columnWidth: '200px',
      size: '',
      displayStyle: 'inline',
      buttonStyle: false,
      border: false,
      labelWidth: null,
      labelHidden: false,
      disabled: false,
      hidden: false,
      optionItems: [
        {label: '选项 1', value: 1},
        {label: '选项 2', value: 2},
        {label: '选项 3', value: 3},
      ],
      required: false,
      requiredHint: '',
      validation: '',
      validationHint: '',
      customClass: '',  //自定义css类名
      onChange: '',
    },
  },

  {
    type: 'checkbox',
    icon: 'lyicon-checkbox-field',
    formItemFlag: true,
    displayName: "多选框组",
    options: {
      name: '',//字段名（可用于后端model的字段名）
      isFormField:true,//用于标志是否为form表单字段（后端自动生成表单字段使用）
      crudField:crudField,//后端crud需要的字段信息
      label: '',
      labelAlign: '',
      defaultValue: [],
      columnWidth: '200px',
      size: '',
      displayStyle: 'inline',
      buttonStyle: false,
      border: false,
      labelWidth: null,
      labelHidden: false,
      disabled: false,
      hidden: false,
      optionItems: [
        {label: '选项 1', value: 1},
        {label: '选项 2', value: 2},
        {label: '选项 3', value: 3},
      ],
      required: false,
      requiredHint: '',
      validation: '',
      validationHint: '',
      customClass: '',  //自定义css类名
      onChange: '',
    },
  },

  {
    type: 'select',
    icon: 'lyicon-select-field',
    formItemFlag: true,
    displayName: "下拉选择框",
    options: {
      name: '',//字段名（可用于后端model的字段名）
      isFormField:true,//用于标志是否为form表单字段（后端自动生成表单字段使用）
      crudField:crudField,//后端crud需要的字段信息
      label: '',
      labelAlign: '',
      defaultValue: '',
      placeholder: '',
      columnWidth: '200px',
      size: '',
      labelWidth: null,
      labelHidden: false,
      disabled: false,
      hidden: false,
      clearable: true,
      filterable: false,
      allowCreate: false,
      remote: false,
      automaticDropdown: false,  //自动下拉
      multiple: false,
      multipleLimit: 0,
      useForeignData:false,//使用外键数据
      optionItems: [
        {label: '选项 1', value: 1},
        {label: '选项 2', value: 2},
        {label: '选项 3', value: 3},
      ],
      required: false,
      requiredHint: '',
      validation: '',
      validationHint: '',
      customClass: '',  //自定义css类名
      onRemoteMethod: '',
      onRemoveTag: '',
      onChange: '',
      onFocus: '',
      onBlur: '',
    },
  },
  {
    type: 'cascader',
    icon: 'lyicon-cascader-field',
    formItemFlag: true,
    displayName: "级联选择",
    options: {
      name: '',//字段名（可用于后端model的字段名）
      isFormField:true,//用于标志是否为form表单字段（后端自动生成表单字段使用）
      crudField:crudField,//后端crud需要的字段信息
      label: '',
      labelAlign: '',
      defaultValue: '',
      placeholder: '',
      size: '',
      labelWidth: null,
      labelHidden: false,
      columnWidth: '200px',
      disabled: false,
      hidden: false,
      clearable: true,
      filterable: false,
      multiple: false,
      checkStrictly: false,  //可选择任意一级选项，默认不开启
      showAllLevels: true,  //显示完整路径
      optionItems: [
        {label: '选项 1', value: 1, children: [{label: '子选项 1', value: 11}]},
        {label: '选项 2', value: 2},
        {label: '选项 3', value: 3},
      ],
      required: false,
      requiredHint: '',
      customRule: '',
      customRuleHint: '',
      customClass: '',  //自定义css类名
      onChange: '',
      onFocus: '',
      onBlur: '',
    },
  },
  {
    type: 'time',
    icon: 'Timer',
    formItemFlag: true,
    displayName: "时间",
    options: {
      name: '',//字段名（可用于后端model的字段名）
      isFormField:true,//用于标志是否为form表单字段（后端自动生成表单字段使用）
      crudField:crudField,//后端crud需要的字段信息
      label: '',
      labelAlign: '',
      defaultValue: null,
      placeholder: '',
      columnWidth: '200px',
      size: '',
      autoFullWidth: true,
      labelWidth: null,
      labelHidden: false,
      readonly: false,
      disabled: false,
      hidden: false,
      clearable: true,
      editable: false,
      format: 'HH:mm:ss',  //时间格式
      required: false,
      requiredHint: '',
      validation: '',
      validationHint: '',
      customClass: '',  //自定义css类名
      onChange: '',
      onFocus: '',
      onBlur: '',
    },
  },

  {
    type: 'time-range',
    icon: 'lyicon-time-range-field',
    formItemFlag: true,
    displayName: "时间范围",
    options: {
      name: '',//字段名（可用于后端model的字段名）
      isFormField:true,//用于标志是否为form表单字段（后端自动生成表单字段使用）
      crudField:crudField,//后端crud需要的字段信息
      label: '',
      labelAlign: '',
      defaultValue: null,
      startPlaceholder: '',
      endPlaceholder: '',
      columnWidth: '200px',
      size: '',
      autoFullWidth: true,
      labelWidth: null,
      labelHidden: false,
      readonly: false,
      disabled: false,
      hidden: false,
      clearable: true,
      editable: false,
      format: 'HH:mm:ss',  //时间格式
      required: false,
      requiredHint: '',
      validation: '',
      validationHint: '',
      customClass: '',  //自定义css类名
      onChange: '',
      onFocus: '',
      onBlur: '',
    },
  },

  {
    type: 'date',
    icon: 'lyicon-date-field',
    formItemFlag: true,
    displayName: "日期",
    options: {
      name: '',//字段名（可用于后端model的字段名）
      isFormField:true,//用于标志是否为form表单字段（后端自动生成表单字段使用）
      crudField:crudField,//后端crud需要的字段信息
      label: '',
      labelAlign: '',
      type: 'date',
      defaultValue: null,
      placeholder: '',
      columnWidth: '200px',
      size: '',
      autoFullWidth: true,
      labelWidth: null,
      labelHidden: false,
      readonly: false,
      disabled: false,
      hidden: false,
      clearable: true,
      editable: false,
      format: 'YYYY-MM-DD',  //日期显示格式
      valueFormat: 'YYYY-MM-DD',  //日期对象格式
      required: false,
      requiredHint: '',
      validation: '',
      validationHint: '',
      customClass: '',  //自定义css类名
      onChange: '',
      onFocus: '',
      onBlur: '',
    },
  },

  {
    type: 'date-range',
    icon: 'lyicon-date-range-field',
    formItemFlag: true,
    displayName: "日期范围",
    options: {
      name: '',//字段名（可用于后端model的字段名）
      isFormField:true,//用于标志是否为form表单字段（后端自动生成表单字段使用）
      crudField:crudField,//后端crud需要的字段信息
      label: '',
      labelAlign: '',
      type: 'daterange',
      defaultValue: null,
      startPlaceholder: '',
      endPlaceholder: '',
      columnWidth: '200px',
      size: '',
      autoFullWidth: true,
      labelWidth: null,
      labelHidden: false,
      readonly: false,
      disabled: false,
      hidden: false,
      clearable: true,
      editable: false,
      format: 'YYYY-MM-DD',  //日期显示格式
      valueFormat: 'YYYY-MM-DD',  //日期对象格式
      required: false,
      requiredHint: '',
      validation: '',
      validationHint: '',
      customClass: '',  //自定义css类名
      onChange: '',
      onFocus: '',
      onBlur: '',
    },
  },

  {
    type: 'switch',
    icon: 'open',
    formItemFlag: true,
    displayName: "开关",
    options: {
      name: '',//字段名（可用于后端model的字段名）
      isFormField:true,//用于标志是否为form表单字段（后端自动生成表单字段使用）
      crudField:crudField,//后端crud需要的字段信息
      label: '',
      labelAlign: '',
      defaultValue: null,
      columnWidth: '200px',
      size: '',
      labelWidth: null,
      labelHidden: false,
      disabled: false,
      hidden: false,
      customClass: '',  //自定义css类名
      inlinePrompt:false,
      switchWidth: 40,
      activeText: '',
      inactiveText: '',
      activeColor: null,
      inactiveColor: null,
      onChange: '',
    },
  },

  {
    type: 'rate',
    icon: 'Star',
    formItemFlag: true,
    displayName: "评分",
    options: {
      name: '',//字段名（可用于后端model的字段名）
      isFormField:true,//用于标志是否为form表单字段（后端自动生成表单字段使用）
      crudField:crudField,//后端crud需要的字段信息
      label: '',
      labelAlign: '',
      defaultValue: null,
      columnWidth: '200px',
      labelWidth: null,
      labelHidden: false,
      disabled: false,
      hidden: false,
      required: false,
      requiredHint: '',
      validation: '',
      validationHint: '',
      customClass: '',  //自定义css类名
      max: 5,
      lowThreshold: 2,
      highThreshold: 4,
      allowHalf: false,
      showText: false,
      showScore: false,
      onChange: '',
    },
  },

  {
    type: 'color',
    icon: 'lyicon-color-field',
    formItemFlag: true,
    displayName: "取色器",
    options: {
      name: '',//字段名（可用于后端model的字段名）
      isFormField:true,//用于标志是否为form表单字段（后端自动生成表单字段使用）
      crudField:crudField,//后端crud需要的字段信息
      label: '',
      labelAlign: '',
      defaultValue: null,
      columnWidth: '200px',
      size: '',
      labelWidth: null,
      labelHidden: false,
      disabled: false,
      hidden: false,
      required: false,
      requiredHint: '',
      validation: '',
      validationHint: '',
      customClass: '',  //自定义css类名
      onChange: '',
    },
  },

  {
    type: 'slider',
    icon: 'lyicon-slider-field',
    formItemFlag: true,
    displayName: "滑块",
    options: {
      name: '',//字段名（可用于后端model的字段名）
      isFormField:true,//用于标志是否为form表单字段（后端自动生成表单字段使用）
      crudField:crudField,//后端crud需要的字段信息
      label: '',
      labelAlign: '',
      columnWidth: '200px',
      showStops: true,
      size: '',
      labelWidth: null,
      labelHidden: false,
      disabled: false,
      hidden: false,
      required: false,
      requiredHint: '',
      validation: '',
      validationHint: '',
      customClass: '',  //自定义css类名
      min: 0,
      max: 100,
      step: 10,
      range: false,
      //vertical: false,
      height: null,
      onChange: '',
    },
  },
  {
    type: 'statistic',
    icon: 'Medal',
    formItemFlag: false,
    displayName: "统计",
    options: {
      name: '',//字段名（可用于后端model的字段名）
      isFormField:false,//用于标志是否为form表单字段（后端自动生成表单字段使用）
      columnWidth: '200px',
      hidden: false,
      value: 98500,//数字内容
      title:'Daily active users',//数字标题
      decimalSeparator:'.',//设置小数点符号
      groupSeparator:',',//设置千分位标识符
      precision:0,
      prefix:'',//设置数字的前缀
      suffix:'',
      customClass: '',  //自定义css类名
    },
  },
  {
    type: 'static-text',
    icon: 'lyicon-static-text',
    formItemFlag: false,
    displayName: "静态文字",
    options: {
      name: '',//字段名（可用于后端model的字段名）
      isFormField:false,//用于标志是否为form表单字段（后端自动生成表单字段使用）
      columnWidth: '200px',
      hidden: false,
      textContent: '文字内容',
      type:'',
      tag:'div',
      truncated:false,//显示省略号
      labelPosition: 'left',//left、center、right
      fontSize:'14px',//字体大小
      customClass: '',  //自定义css类名
    },
  },

  {
    type: 'html-text',
    icon: 'lyicon-html-text',
    formItemFlag: false,
    displayName: "HTML",
    options: {
      name: '',//字段名（可用于后端model的字段名）
      isFormField:false,//用于标志是否为form表单字段（后端自动生成表单字段使用）
      columnWidth: '200px',
      hidden: false,
      htmlContent: '<b>html text</b>',
      customClass: '',  //自定义css类名
    },
  },

  {
    type: 'button',
    icon: 'lyicon-button',
    formItemFlag: true,
    displayName: "按钮",
    options: {
      name: '',//字段名（可用于后端model的字段名）
      isFormField:false,//用于标志是否为form表单字段（后端自动生成表单字段使用）
      label: '',
      labelWidth: null,
      labelHidden: true,
      buttonText:'button',
      columnWidth: '200px',
      size: '',
      displayStyle: 'block',
      disabled: false,
      hidden: false,
      type: '',
      link:false,
      loading:false,
      plain: false,
      round: false,
      circle: false,
      icon: null,
      color:null,
      customClass: '',  //自定义css类名
      onClick: '',
    },
  },

  {
    type: 'divider',
    icon: 'minus',
    formItemFlag: false,
    displayName: "分割线",
    options: {
      name: '',//字段名（可用于后端model的字段名）
      isFormField:false,//用于标志是否为form表单字段（后端自动生成表单字段使用）
      label: '',
      columnWidth: '200px',
      direction: 'horizontal',
      contentPosition: 'center',
      borderStyle:'solid',
      hidden: false,
      customClass: '',  //自定义css类名
    },
  },
  {
    type: 'space-around',
    icon: 'lyicon-space-around',
    formItemFlag: false,
    displayName: "上下间距",
    options: {
      name: '',//字段名（可用于后端model的字段名）
      isFormField:false,//用于标志是否为form表单字段（后端自动生成表单字段使用）
      label: '',
      height: 20,
      hidden: false,
      customClass: '',  //自定义css类名
    },
  },
  {
    type: 'alert',
    icon: 'lyicon-alert',
    formItemFlag: false,
    displayName: "提示",
    options: {
      name: '',//字段名（可用于后端model的字段名）
      isFormField:false,//用于标志是否为form表单字段（后端自动生成表单字段使用）
      title: '提示信息...',
      type: 'info',
      description: '',
      closable: true,
      closeText: '',
      center: true,
      showIcon: false,
      effect: 'light',
      hidden: false,
      customClass: '',
    },
  },

]

export const lyAdvancedFields = [
  {
    type: 'picture-upload',
    icon: 'Picture',
    formItemFlag: true,
    displayName: "多图上传",
    options: {
      name: '',//字段名（可用于后端model的字段名）
      isFormField:true,//用于标志是否为form表单字段（后端自动生成表单字段使用）
      crudField:crudField,//后端crud需要的字段信息
      label: '',
      labelAlign: '',
      labelWidth: null,
      labelHidden: false,
      columnWidth: '200px',
      disabled: false,
      hidden: false,
      required: false,
      requiredHint: '',
      customRule: '',
      customRuleHint: '',
      uploadURL: '',
      uploadTip: '',
      multipleSelect: false,
      uploadWidth: 148,//宽度
      uploadHight: 148,//高度
      showFileList: true,
      limit: 3,
      fileMaxSize: 5, //MB
      fileTypes: ['jpg', 'jpeg', 'png'],
      draggable:true,
      customClass: '',  //自定义css类名
      onSuccess: '',
    },

  },
  {
    type: 'picture-single',
    icon: 'Picture',
    formItemFlag: true,
    displayName: "单图上传",
    options: {
      name: '',//字段名（可用于后端model的字段名）
      isFormField:true,//用于标志是否为form表单字段（后端自动生成表单字段使用）
      crudField:crudField,//后端crud需要的字段信息
      label: '',
      labelAlign: '',
      labelWidth: null,
      labelHidden: false,
      columnWidth: '200px',
      disabled: false,
      hidden: false,
      required: false,
      requiredHint: '',
      customRule: '',
      customRuleHint: '',
      uploadURL: '',
      uploadTitle: '',
      uploadIcon:'plus',//提示图标
      uploadWidth: 148,//宽度
      uploadHight: 148,//高度
      showFileList: true,
      fileMaxSize: 5, //MB
      fileTypes: ['jpg', 'jpeg', 'png'],
      round :false,//是否圆形
      cropper:false,//是否开启裁剪
      compress:1,//剪裁压缩率 范围0.1-1
      aspectRatio:NaN,//固定剪裁比例 比如1/1, 4/3, 16/9
      customClass: '',  //自定义css类名
      onSuccess: '',
    },

  },
  {
    type: 'file-upload',
    icon: 'document',
    formItemFlag: true,
    displayName: "文件上传",
    options: {
      name: '',//字段名（可用于后端model的字段名）
      isFormField:true,//用于标志是否为form表单字段（后端自动生成表单字段使用）
      crudField:crudField,//后端crud需要的字段信息
      label: '',
      labelAlign: '',
      labelWidth: null,
      labelHidden: false,
      columnWidth: '200px',
      disabled: false,
      hidden: false,
      required: false,
      requiredHint: '',
      customRule: '',
      customRuleHint: '',
      buttonType:"primary",
      uploadURL: '',
      uploadTip: '',
      uploadTitle:'点击上传',
      uploadIcon:'',
      multipleSelect: false,
      showFileList: true,
      limit: 1,
      fileMaxSize: 10, //MB
      fileTypes: ['.doc', '.docx', '.xls', '.xlsx'],
      draggable:false,
      customClass: '',  //自定义css类名
      onSuccess: '',
    },
  },

  {
    type: 'rich-text',
    icon: 'lyicon-rich-text-field',
    formItemFlag: true,
    displayName: "富文本框",
    options: {
      name: '',//字段名（可用于后端model的字段名）
      isFormField:true,//用于标志是否为form表单字段（后端自动生成表单字段使用）
      crudField:crudField,//后端crud需要的字段信息
      label: '',
      labelAlign: '',
      defaultValue: '',
      placeholder: '',
      labelWidth: null,
      labelHidden: false,
      columnWidth: 100,//%百分比
      contentHeight: 200,
      disabled: false,
      hidden: false,
      required: false,
      requiredHint: '',
      customClass: '',  //自定义css类名
      minLength: null,
      maxLength: null,
      onChange:'',
    },
  },

]

export const lyCustomFields = [

]

export function lyAddWidgetConfigSchema(flag,schema) {
  if(flag=='lyContainers'){
    lyContainers.push(schema)
  }else if(flag == "lyBasicFields"){
    lyBasicFields.push(schema)
  }else if(flag=="lyAdvancedFields"){
    lyAdvancedFields.push(fieldSchema)
  }else if(flag == "lyCustomFields"){
    lyCustomFields.push(widgetSchema)
  }
}
