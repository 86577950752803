<template>
    <div class="lyworkflow-wrap">
		<div class="lyworkflow-wrap-box lyworkflow-wrap-box-lyInitiator start-node" @click="show">
			<div class="title" style="background: #163d47;">
				<el-icon class="icon"><user-filled /></el-icon>
				<span>{{ nodeConfig.nodeName }}</span>
			</div>
			<div class="content">
				<span>{{ toText(nodeConfig) }}</span>
			</div>
		</div>
		<lyAddNodes v-model="nodeConfig.childNode" :isDesign="isDesign"></lyAddNodes>
		<el-drawer title="发起人" v-model="drawer" destroy-on-close append-to-body size="40%" class="lyworkflow-drawer">
			<template #header>
				<div class="lyworkflow-wrap-drawer__title">
					<label @click="editTitle" v-if="!isEditTitle">{{form.nodeName}}<el-icon class="lyworkflow-wrap-drawer__title-edit"><Edit /></el-icon></label>
					<el-input v-if="isEditTitle" ref="nodeTitle" v-model="form.nodeName" clearable @blur="saveTitle" @keyup.enter="saveTitle"></el-input>
				</div>
			</template>
			<el-container>
				<el-main style="padding:0 20px 20px 20px">
					<el-form label-position="top">
						<el-form-item label="谁可以发起此审批">
							<el-button type="primary" icon="plus" round @click="selectHandle(2, form.nodeRoleList)">选择角色</el-button>
							<div class="lyworkflow-tags-list">
								<el-tag v-for="(role, index) in form.nodeRoleList" :key="role.id" type="info" closable @close="delRole(index)">{{role.name}}</el-tag>
							</div>
						</el-form-item>
						<el-alert v-if="form.nodeRoleList.length==0" title="不指定则默认所有人都可发起此审批" type="info" :closable="false"/>
					</el-form>
				</el-main>
				<el-footer class="lyworkflow-drawer-footer">
					<el-button type="primary" @click="save">保存</el-button>
					<el-button @click="drawer=false">取消</el-button>
				</el-footer>
			</el-container>
		</el-drawer>
	</div>
</template>

<script setup>
    import { ref, onMounted, watch, computed , nextTick,inject} from 'vue'
    import lyAddNodes from "./lyAddNodes"

    const props = defineProps({
        modelValue: { type: Object, default: () => {}  },
        isDesign: { //是否设计模式
            type: Boolean,
            default: true
        }
    })

    const emits = defineEmits(['update:modelValue'])

    let nodeConfig = ref(props.modelValue)
    watch(()=>props.modelValue,()=>{
        nodeConfig.value = props.modelValue
    },{deep:true})
    // watch(()=>nodeConfig.value,()=>{
    //     emits("update:modelValue", nodeConfig.value)
    // },{deep:true})

    let form = ref({})
    let isEditTitle = ref(false)
    let drawer = ref(false)
    let nodeTitle = ref(null)
    const lySelectHandle = inject("lySelectHandle",null)

    function show(){
        if(props.isDesign){
            form.value = {}
            form.value = JSON.parse(JSON.stringify(nodeConfig.value))
            isEditTitle.value = false
            drawer.value = true
        }
    }

    function editTitle(){
        isEditTitle.value = true
        nextTick(()=>{
            nodeTitle.value.focus()
        })
    }

    function saveTitle(){
		isEditTitle.value = false
	}

    function selectHandle(type, data){
        lySelectHandle(type, data)
    }

    function delRole(index){
        form.value.nodeRoleList.splice(index, 1)
    }

    function save(){
        nodeConfig.value = form.value
        emits("update:modelValue", form.value)
		drawer.value = false
    }

    function toText(nodeConfig){
        if(nodeConfig.nodeRoleList && nodeConfig.nodeRoleList.length > 0){
            return nodeConfig.nodeRoleList.map(item=>item.name).join("、")
        }else{
            return "所有人"
        }
    }

</script>

<style scoped>
    
</style>