<template>
	<div class="lyworkflow-wrap">
		<div class="lyworkflow-wrap-box lyworkflow-wrap-box-lySend" @click="show">
			<div class="title" style="background: #419efa;">
				<el-icon class="icon"><Promotion /></el-icon>
				<span>{{ nodeConfig.nodeName }}</span>
                <el-popconfirm confirm-button-text="删除" cancel-button-text="取消" icon="InfoFilled" icon-color="#626AEF" title="确认删除吗?" @confirm="delNode()">
					<template #reference>
						<el-icon class="close" @click.stop=""><Close /></el-icon>
					</template>
				</el-popconfirm>
			</div>
			<div class="content">
				<span v-if="toText(nodeConfig)">{{ toText(nodeConfig) }}</span>
				<span v-else class="placeholder">请选择人员</span>
			</div>
		</div>
		<lyAddNodes v-model="nodeConfig.childNode" :isDesign="isDesign"></lyAddNodes>
		<el-drawer title="抄送人设置" v-model="drawer" destroy-on-close append-to-body size="40%" class="lyworkflow-drawer">
			<template #header>
				<div class="lyworkflow-wrap-drawer__title">
					<label @click="editTitle" v-if="!isEditTitle">{{form.nodeName}}<el-icon class="lyworkflow-wrap-drawer__title-edit"><Edit /></el-icon></label>
					<el-input v-if="isEditTitle" ref="nodeTitle" v-model="form.nodeName" clearable @blur="saveTitle" @keyup.enter="saveTitle"></el-input>
				</div>
			</template>
			<el-container>
				<el-main style="padding:0 20px 20px 20px">
					<el-form label-position="top">
						<el-form-item label="选择要抄送的人员">
							<el-button type="primary" icon="Plus" round @click="selectHandle(1, form.nodeUserList)">选择人员</el-button>
							<div class="lyworkflow-tags-list">
								<el-tag v-for="(user, index) in form.nodeUserList" :key="user.id" closable @close="delUser(index)">{{user.name}}</el-tag>
							</div>
						</el-form-item>
						<el-form-item label="">
							<el-checkbox v-model="form.userSelectFlag" label="允许发起人自选抄送人"></el-checkbox>
						</el-form-item>
					</el-form>
				</el-main>
				<el-footer class="lyworkflow-drawer-footer">
					<el-button type="primary" @click="save">保存</el-button>
					<el-button @click="drawer=false">取消</el-button>
				</el-footer>
			</el-container>
		</el-drawer>
	</div>
</template>

<script setup>
	import { ref, onMounted, watch, computed , nextTick,inject} from 'vue'
    import lyAddNodes from "./lyAddNodes"

    const props = defineProps({
        modelValue: { type: Object, default: () => {}  },
        isDesign: { //是否设计模式
            type: Boolean,
            default: true
        }
    })

    const emits = defineEmits(['update:modelValue'])

    let nodeConfig = ref(props.modelValue)
    watch(()=>props.modelValue,()=>{
        nodeConfig.value = props.modelValue
    },{deep:true})
    // watch(()=>nodeConfig.value,()=>{
    //     emits("update:modelValue", nodeConfig.value)
    // },{deep:true})

    let form = ref({})
    let isEditTitle = ref(false)
    let drawer = ref(false)
    let nodeTitle = ref(null)
    const lySelectHandle = inject("lySelectHandle",null)

    function show(){
        form.value = {}
        form.value = JSON.parse(JSON.stringify(nodeConfig.value))
        drawer.value = true
    }

    function editTitle(){
        isEditTitle.value = true
        nextTick(()=>{
            nodeTitle.value.focus()
        })
    }

    function saveTitle(){
		isEditTitle.value = false
	}

    function selectHandle(type, data){
        lySelectHandle(type, data)
    }

    function save(){
        emits("update:modelValue", form.value)
		drawer.value = false
    }

    function delNode(){
        emits("update:modelValue", nodeConfig.value.childNode)
    }

    function delUser(index){
        form.value.nodeUserList.splice(index, 1)
    }

    function toText(nodeConfig){
        if (nodeConfig.nodeUserList && nodeConfig.nodeUserList.length>0) {
            const users = nodeConfig.nodeUserList.map(item=>item.name).join("、")
            return users
        }else{
            if(nodeConfig.userSelectFlag){
                return "发起人自选"
            }else{
                return false
            }

        }
    }
</script>

<style scoped>
</style>
