<!--
/**
 * author: lybbn
 * program django-vue-lyadmin
 * email: 1042594286@qq.com
 * website: http://www.lybbn.cn
 * date: 2022.11.17
 * remark: 如果要分发django-vue-lyadmin源码或其中组件等，需在本文件顶部保留此文件头信息！！！
 */
-->
<template>
    <div class="lywp-main">
        <el-tabs v-model="lyTabValue" class="lywp-first-tabs" tab-position="left" @tab-change="handleTabsChange">
            <el-scrollbar class="lywp-scrow" :style="{height: scrollerHeight}">
                <el-tab-pane name="lyfirst">
                    <template #label>
                        <span class="lycustom-tabs-label"><el-icon size="28"><SetUp /></el-icon><span>组件库</span></span>
                    </template>
                    <el-collapse class="lywp-collapse" v-model="lyCollapseActiveList">
                        <el-collapse-item name="lywpc01" title="容器">
                            <draggable tag="ul" :list="containers" item-key="key" :group="{name: 'lyDragGroup', pull: 'clone', put: false}" :clone="handleContainerClone" ghost-class="ghost" :sort="false" :move="handleContainerMove" @end="handleContainerEnd">
                                <template #item="{ element: lycn }">
                                      <li class="lycontainer-widget-item" :title="lycn.displayName" @dblclick="handleContainerByDbClick(lycn)">
                                            <span class="lyflex-center lymarginleft2">
                                                <svg-icon :icon-class="lycn.icon?lycn.icon:'Menu'" style="color:#409eff;font-size: 16px;"></svg-icon>
                                                {{lycn.displayName}}
                                            </span>
                                      </li>
                                </template>
                            </draggable>
                        </el-collapse-item>
                        <el-collapse-item name="lywpc02" title="基础组件">
                            <draggable tag="ul" :list="basicFields" item-key="key" :group="{name: 'lyDragGroup', pull: 'clone', put: false}" :clone="handleFieldClone" ghost-class="ghost" :sort="false" :move="handleFieldMove" @end="handleFieldEnd">
                                <template #item="{ element: lycn }">
                                      <li class="lycontainer-widget-item" :title="lycn.displayName" @dblclick="handleFieldByDbClick(lycn)">
                                            <span class="lyflex-center lymarginleft2">
                                                <svg-icon :icon-class="lycn.icon?lycn.icon:'Menu'" style="color:#409eff;font-size: 16px;"></svg-icon>
                                                {{lycn.displayName}}
                                            </span>
                                      </li>
                                </template>
                            </draggable>
                        </el-collapse-item>
                        <el-collapse-item name="lywpc03" title="高级组件">
                            <draggable tag="ul" :list="advancedFields" item-key="key" :group="{name: 'lyDragGroup', pull: 'clone', put: false}" :clone="handleFieldClone" ghost-class="ghost" :sort="false" :move="handleFieldMove" @end="handleFieldEnd">
                                <template #item="{ element: lycn }">
                                      <li class="lycontainer-widget-item" :title="lycn.displayName" @dblclick="handleFieldByDbClick(lycn)">
                                            <span class="lyflex-center lymarginleft2">
                                                <svg-icon :icon-class="lycn.icon?lycn.icon:'Menu'" style="color:#409eff;font-size: 16px;"></svg-icon>
                                                {{lycn.displayName}}
                                            </span>
                                      </li>
                                </template>
                            </draggable>
                        </el-collapse-item>
                        <el-collapse-item name="lywpc04" title="自定义组件">
                            <draggable tag="ul" :list="customFields" item-key="key" :group="{name: 'lyDragGroup', pull: 'clone', put: false}" :clone="handleFieldClone" ghost-class="ghost" :sort="false" :move="handleFieldMove" @end="handleFieldEnd">
                                <template #item="{ element: lycn }">
                                      <li class="lycontainer-widget-item" :title="lycn.displayName" @dblclick="handleFieldByDbClick(lycn)">
                                            <span class="lyflex-center lymarginleft2">
                                                <svg-icon :icon-class="lycn.icon?lycn.icon:'Menu'" style="color:#409eff;font-size: 16px;"></svg-icon>
                                                {{lycn.displayName}}
                                            </span>
                                      </li>
                                </template>
                            </draggable>
                        </el-collapse-item>
                    </el-collapse>
                </el-tab-pane>
<!--                <el-tab-pane name="lytwo" v-if="builderConfig.showFormTemplates">-->
<!--                    <template #label>-->
<!--                        <span class="lycustom-tabs-label"><el-icon size="28"><CopyDocument /></el-icon><span>模板库</span></span>-->
<!--                    </template>-->
<!--                </el-tab-pane>-->
                <el-tab-pane name="lythree">
                    <template #label>
                        <span class="lycustom-tabs-label"><svg-icon icon-class="lyicon-node-tree" style="font-size: 28px;"></svg-icon><span>结构树</span></span>
                    </template>
                    <el-tree ref="nodeTree" :data="nodeTreeData" node-key="id" default-expand-all highlight-current  @node-click="onNodeTreeClick" class="struct-tree"></el-tree>
                </el-tab-pane>
            </el-scrollbar>
        </el-tabs>
    </div>
</template>

<script setup>
    import { ref, onMounted ,reactive,nextTick,onBeforeUnmount,watch,inject } from 'vue'
    import {lyContainers, lyBasicFields, lyAdvancedFields, lyCustomFields} from "./lyWidgetConfig"
    import {randomId} from '@/utils/util'
    import { useBuilderStore } from "@/store/lyFormBuilder";
    import {ElMessage} from 'element-plus'

    const props = defineProps({
        isFull:{
            type:Boolean,
            default:false
        },
        builder: Object,
    })
    const builderStore = useBuilderStore()
    const builderConfig = builderStore.builderConfig
    let lyCollapseActiveList = ref([])
    let lyTabValue = ref("lyfirst")

    let containers = ref([])
    let basicFields = ref([])
    let advancedFields = ref([])
    let customFields = ref([])

    function handleTabsChange(){
        if(lyTabValue.value === "lythree"){
            showNodeTree()
        }
    }


    function lyLoadWidgetConfig() {
        containers = lyContainers.map(item => {
            return {
                key: randomId(),
                ...item
            }
        }).filter(item => {
            return !item.internal
        })

        basicFields = lyBasicFields.map(item => {
            return {
                key: randomId(),
                ...item
            }
        })

        advancedFields = lyAdvancedFields.map(item => {
            return {
                key: randomId(),
                ...item
            }
        })

        customFields = lyCustomFields.map(item => {
            return {
                key: randomId(),
                ...item
            }
        })
    }

    function handleContainerClone(e) {
        return builderStore.cloneNewContainerWidget(e)
    }
    function handleContainerMove(e){
        return builderStore.handleWidgetMove(e)
    }
    function handleContainerEnd(e){

    }
    function handleContainerByDbClick(e){
        builderStore.addContainerByDbClick(e)
    }
    function handleFieldByDbClick(e){
        builderStore.addFieldByDbClick(e)
    }
    function handleFieldClone(e) {
        return builderStore.cloneNewFieldWidget(e)
    }
    function  handleFieldMove(e) {
        return builderStore.handleFieldMove(e)
    }
    function handleFieldEnd(e){
        
    }

    function buildTreeNodeOfWidget(widget, treeNode) {
        let curNode = {
            id: widget.id,
            label: widget.options.label || widget.type,
        }
        treeNode.push(curNode)

        if (widget.category === undefined) {
            return
        }

        curNode.children = []
        if (widget.type === 'grid') {
            widget.cols.map(col => {
                let colNode = {
                    id: col.id,
                    label: col.options.name || widget.type,
                    children: []
                }
                curNode.children.push(colNode)
                col.widgetList.map(wChild => {
                    buildTreeNodeOfWidget(wChild, colNode.children)
                })
            })
        } else if (widget.type === 'table') {
            //TODO: 需要考虑合并单元格！！
            widget.rows.map(row => {
                let rowNode = {
                    id: row.id,
                    label: 'table-row',
                    selectable: false,
                    children: [],
                }
                curNode.children.push(rowNode)

                row.cols.map(cell => {
                    if (!!cell.merged) {  //跳过合并单元格！！
                        return
                    }

                    let rowChildren = rowNode.children
                    let cellNode = {
                        id: cell.id,
                        label: 'table-cell',
                        children: []
                    }
                    rowChildren.push(cellNode)

                    cell.widgetList.map(wChild => {
                        buildTreeNodeOfWidget(wChild, cellNode.children)
                    })
                })
            })
        } else if (widget.type === 'tab') {
            widget.tabs.map(tab => {
                let tabNode = {
                    id: tab.id,
                    label: tab.options.name || widget.type,
                    selectable: false,
                    children: []
                }
                curNode.children.push(tabNode)
                tab.widgetList.map(wChild => {
                    buildTreeNodeOfWidget(wChild, tabNode.children)
                })
            })
        } else if (widget.type === 'sub-form') {
            widget.widgetList.map(wChild => {
                buildTreeNodeOfWidget(wChild, curNode.children)
            })
        } else if (widget.category === 'container') {  //自定义容器
            widget.widgetList.map(wChild => {
                buildTreeNodeOfWidget(wChild, curNode.children)
            })
        }
    }

    let nodeTreeData = ref([])

    function refreshNodeTree() {
        nodeTreeData.value.length = 0
        builderStore.widgetList.forEach(item => {
            buildTreeNodeOfWidget(item, nodeTreeData.value)
        })
    }

    let nodeTree = ref(null)

    function showNodeTree() {
        refreshNodeTree()
        if (!!builderStore.selectedId) {  //同步当前选中组件到节点树！！！
            nextTick(()=>{
                nodeTree.value.setCurrentKey(builderStore.selectedId)
            })
        }
    }

    function traverseAllWidgets(widgetList, handler) {
        if (!widgetList) {
            return
        }

        widgetList.map(w => {
            handler(w)

            if (w.type === 'grid') {
                w.cols.map(col => {
                    handler(col)
                    traverseAllWidgets(col.widgetList, handler)
                })
            } else if (w.type === 'table') {
                w.rows.map(row => {
                    row.cols.map(cell => {
                        handler(cell)
                        traverseAllWidgets(cell.widgetList, handler)
                    })
                })
            } else if (w.type === 'tab') {
                w.tabs.map(tab => {
                    traverseAllWidgets(tab.widgetList, handler)
                })
            } else if (w.type === 'sub-form') {
                traverseAllWidgets(w.widgetList, handler)
            } else if (w.category === 'container') {  //自定义容器
                traverseAllWidgets(w.widgetList, handler)
            }
        })
    }

    function findWidgetById(wId) {
        let foundW = null
        traverseAllWidgets(builderStore.widgetList, (w) => {
            if (w.id === wId) {
                foundW = w
            }
        })
        return foundW
    }

    function onNodeTreeClick(nodeData, node, nodeEl) {
        if ((nodeData.selectable !== undefined) && !nodeData.selectable) {
            ElMessage.info('当前组件节点不可选择')
        } else {
            const selectedId = nodeData.id
            const foundW = findWidgetById(selectedId)
            if (!!foundW) {
                builderStore.setSelected(foundW)
            }
        }
    }

    let scrollerHeight = ref(0)

    function lyHandleResize(){
        nextTick(() => {
            if(props.isFull){
                scrollerHeight.value = window.innerHeight - 49 + 'px'
            }else{
                scrollerHeight.value = window.innerHeight - 162 + 'px'
            }

        })
    }
    watch(() => props.isFull, (n) => {
        lyHandleResize()
    })
    onMounted(()=>{
        lyLoadWidgetConfig()
        lyHandleResize()
        window.addEventListener('resize',lyHandleResize)
        lyCollapseActiveList.value=['lywpc01','lywpc02','lywpc03','lywpc04']
    })
    onBeforeUnmount(() => {
        window.removeEventListener('resize',lyHandleResize)
    })

</script>

<style lang="scss" scoped>
    ::v-deep(.struct-tree){
        .el-tree > .el-tree-node:after {
            border-top: none;
        }
        .el-tree-node {
            position: relative;
            padding-left: 12px;
        }

        .el-tree-node__content {
            padding-left: 0 !important;
        }

        .el-tree-node__expand-icon.is-leaf{
            display: none;
        }

        .el-tree-node__children {
            padding-left: 12px;
            overflow: visible !important;
        }

        .el-tree-node :last-child:before {
            height: 38px;
        }

        .el-tree > .el-tree-node:before {
            border-left: none;
        }

        .el-tree > .el-tree-node:after {
            border-top: none;
        }

        .el-tree-node:before {
            content: "";
            left: -4px;
            position: absolute;
            right: auto;
            border-width: 1px;
        }

        .el-tree-node:after {
            content: "";
            left: -4px;
            position: absolute;
            right: auto;
            border-width: 1px;
        }

        .el-tree-node:before {
            border-left: 1px dashed #4386c6;
            bottom: 0px;
            height: 100%;
            top: -10px;
            width: 1px;
        }

        .el-tree-node:after {
            border-top: 1px dashed #4386c6;
            height: 20px;
            top: 12px;
            width: 16px;
        }

        .el-tree-node.is-current > .el-tree-node__content {
            background: var(--el-color-primary-light-9) !important;
        }

        .el-tree-node__expand-icon {
            margin-left: -3px;
            padding: 6px 6px 6px 0px;
            font-size: 16px;
        }
    }
    .lywp-scrow{
        background:var(--l-changetab-bg);
        ::v-deep(.el-scrollbar__wrap) {
            overflow-x: hidden;
        }
    }
    .lywp-first-tabs{
        background:var(--l-changetab-bg);
        ::v-deep(.el-tabs__header) {
            margin-bottom: 0;
            height: v-bind(scrollerHeight);
        }
        ::v-deep(.el-tabs__header){
            width: 71px;
        }
        ::v-deep(.el-tabs__nav){
            margin-top: 15px;
        }
        ::v-deep(.el-tabs__active-bar){
            background-color:unset;
        }
        ::v-deep(.el-tabs__item){
            height: 80px;
        }
        ::v-deep(.el-tabs__nav-wrap.is-left::after){
            width: 1px;
        }
        ::v-deep(.el-collapse-item__header) {
            font-weight: bold;
        }
        .lycustom-tabs-label{
            display: flex;
            flex-direction: column;
            align-items: center;
        }
        .lywp-collapse{
            border-top-width: 0;
            ::v-deep(.el-collapse-item__content){
                padding-bottom:2px;
                ul{
                    margin-block-end: 0.25em;
                    .lycontainer-widget-item{
                        display: inline-block;
                        height: 28px;
                        line-height: 28px;
                        width: 100px;
                        margin: 2px 6px 1px 1px;
                        cursor: move;
                        white-space: nowrap;
                        text-overflow: ellipsis;
                        overflow: hidden;
                        background: var(--el-bg-color-page);
                        border: 1px var(--el-color-info-light-3) solid;
                        border-radius: 5px;
                        .el-icon{
                            margin-right: 3px;
                        }
                    }
                    .lycontainer-widget-item:hover{
                        background: var(--el-color-primary-light-9);
                        outline: 1px solid var(--el-color-primary);
                    }
                }

            }
        }
    }
    .lyflex-center{
        display: flex;
        align-items: center;
    }
    .lymarginleft2{
        margin-left: 2px;
    }

    .lywp-main{

    }

</style>