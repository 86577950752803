<template>
    <div :class="isFull?'lydatapanel is-full':'lydatapanel'">
        <img src="@/assets/img/panel/bg-big-header.png" class="bg-header">
        <div class="lybg">
            <div class="data-title">
                <div class="title-left">
                    <div class="title-left-title"><span>机构排名</span></div>
                    <div class="title-data">
                        <div class="table-header">
                            <span>排名</span>
                            <span>机构名称</span>
                            <span><em>成交订单</em> </span>
                        </div>
                        <el-carousel class="swiper-txt" height="calc(100vh - 4vh - 20px)" direction="vertical" :autoplay="true" :interval="interval">
                            <el-carousel-item v-for="(item,i) in organList" :key="i">
                                <div class="swiper-item" v-for="(v,index) in item" :key="index">
                                    <span><em>{{i*16+index+1}}</em></span>
                                    <span style="min-width: 0"><em class="ellipsis">{{v.organ}}</em> </span>
                                    <span>{{v.buynums}}</span>
                                </div>

                            </el-carousel-item>
                        </el-carousel>
                    </div>
                </div>
                <div class="title-center">
                    <div class="center-data">
                        <div class="center-data-activity">
                            <el-select v-model="formInline.activity" filterable clearable placeholder="请选择"  @change="getData" popper-class="lydropdown-select">
                                <el-option
                                        v-for="item in activityList"
                                        :key="item.id"
                                        :label="item.name"
                                        :value="item.id">
                                </el-option>
                            </el-select>
                        </div>
                        <div class="center-data-left"><div class="center-data-left-title" >总订单量</div></div>
                        <div class="center-data-right">
                            <span v-for="(item,index) in totalpersonarr" :key="index">{{item}}</span>
                        </div>
                    </div>
                    <div class="center-box">
                        <div style="width: 100%">
                            <span class="center-box-nums">{{totalperson}}</span>
<!--                            <span class="center-box-person">人</span>-->
                        </div>
                    </div>
                </div>
                <div class="title-left">
                    <div class="title-left-title"><span>销售排名</span></div>
                    <div class="title-data">
                         <div class="table-header">
                             <span>排名</span>
                            <span>销售人员</span>
                            <span>所属机构</span>
                            <span><em>个人签单</em> </span>
                        </div>
                        <el-carousel class="swiper-txt" height="calc(100vh - 4vh - 20px)" direction="vertical" :autoplay="true" :interval="interval">
                            <el-carousel-item v-for="(item,i) in salerList" :key="i">
                                <div class="swiper-item" v-for="(v,index) in item" :key="index">
                                    <span><em>{{i*16+index+1}}</em></span>
                                    <span style="min-width: 0"><em class="ellipsis">{{v.saler}}</em></span>
                                    <span style="min-width: 0"><em class="ellipsis">{{v.organ}}</em> </span>
                                    <span>{{v.buynums}}</span>
                                </div>
                            </el-carousel-item>
                        </el-carousel>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
    // import {dataActivitybigdatapanel,dataActivity} from '@/api/api'
    import screenfull from 'screenfull'
    import bus from "@/utils/bus";
    export default {
        name: "lyDataPanel",
        data(){
            return{
                isFull:false,
                interval:6000,
                numLengh:7,
                organList1:[
                    {organ:"测试机构1",buynums:125},
                    {organ:"测试机构2",buynums:95},
                    {organ:"测试机构3",buynums:92},
                    {organ:"测试机构4",buynums:92},
                    {organ:"测试机构5",buynums:92},
                    {organ:"测试机构6",buynums:92},
                    {organ:"测试机构7",buynums:92},
                    {organ:"测试机构8",buynums:92},
                    {organ:"测试机构9",buynums:92},
                    {organ:"测试机构10",buynums:92},
                    {organ:"测试机构11",buynums:92},
                    {organ:"测试机构12",buynums:92},
                    {organ:"测试机构13",buynums:92},
                    {organ:"测试机构14",buynums:92},
                    {organ:"测试机构15",buynums:92},
                    {organ:"测试机构14",buynums:92},
                    {organ:"测试机构15",buynums:92},
                    {organ:"测试机构14",buynums:92},
                    {organ:"测试机构15",buynums:92},
                    {organ:"测试机构14",buynums:92},
                    {organ:"测试机构15",buynums:92},
                ],
                salerList1:[
                    {saler:'lyadmin',organ:"测试机构1",buynums:126},
                    {saler:'lyadmin',organ:"测试机构1",buynums:123},
                    {saler:'lyadmin',organ:"测试机构1",buynums:123},
                    {saler:'lyadmin',organ:"测试机构1",buynums:123},
                    {saler:'lyadmin',organ:"测试机构1",buynums:123},
                    {saler:'lyadmin',organ:"测试机构1",buynums:123},
                    {saler:'lyadmin',organ:"测试机构1",buynums:123},
                    {saler:'lyadmin',organ:"测试机构1",buynums:123},
                    {saler:'lyadmin',organ:"测试机构1",buynums:123},
                    {saler:'lyadmin',organ:"测试机构1",buynums:123},
                    {saler:'lyadmin',organ:"测试机构1",buynums:123},
                    {saler:'lyadmin',organ:"测试机构1",buynums:123},
                    {saler:'lyadmin',organ:"测试机构1",buynums:123},
                    {saler:'lyadmin',organ:"测试机构1",buynums:123},
                    {saler:'lyadmin',organ:"测试机构1",buynums:123},
                    {saler:'lyadmin',organ:"测试机构1",buynums:123},
                    {saler:'lyadmin',organ:"测试机构1",buynums:123},
                    {saler:'lyadmin',organ:"测试机构1",buynums:123},
                    {saler:'lyadmin',organ:"测试机构1",buynums:123},
                    {saler:'lyadmin',organ:"测试机构1",buynums:123},
                    {saler:'lyadmin',organ:"测试机构1",buynums:123},
                    {saler:'lyadmin',organ:"测试机构1",buynums:123},
                    {saler:'lyadmin',organ:"测试机构1",buynums:123},
                ],
                organList:[],
                salerList:[],
                formInline:{
                    page: 1,
                    limit: 999,
                    activity:"",
                },
                totalperson:5866,
                totalpersonarr:[],
                activityList:[
                    {id:1,name:"测试机构1"}
                ],
            }
        },
        mounted() {
            screenfull.on("change", this.toggleFullScreen);
        },
        unmounted() {
            screenfull.off("change", this.toggleFullScreen)
        },
        created() {
            // 网络请求去掉下面注释
            // this.getData()
            // this.getActivityList()
            this.organList=this.getList(this.organList1);
            this.salerList=this.getList(this.salerList1);
            this.totalpersonarr = this.updateLength(this.totalperson)
        },
        methods:{
            setFull(){
                this.isFull=!this.isFull
                window.dispatchEvent(new Event('resize'))
            },
            setupFull() {
                if (!screenfull.isEnabled) {
                    this.$message({
                        message: '您的浏览器不支持全屏',
                        type: 'warning'
                    })
                    return false
                }
                screenfull.toggle()
            },
            updateLength(nums){
                var newnums = nums.toString()
                var newarr = newnums.split("")
                if (newarr.length<this.numLengh){
                    var sz = this.numLengh - newarr.length
                    for(var i=0;i<sz;i++){
                        newarr.unshift('0')
                    }
                    return newarr
                }else{
                    return newarr
                }
            },
            toggleFullScreen(){
                this.isFull=!this.isFull
                bus.emit('fullScreen', this.isFull)
            },
            getList(data) {
                let num = 16
                var Arr = new Array(Math.ceil(data.length/num));
                for(var i = 0; i<Arr.length;i++){
                    Arr[i] = new Array();
                    for(var j = 0; j<num; j++){
                        Arr[i][j] = '';
                    }
                }
                for(var i = 0; i<data.length;i++){
                    Arr[parseInt(i/num)][i%num] = data[i];
                }
                let list = []
                Arr.forEach(item=>{
                    let list1=[]
                    list1=item.filter(v=>v.organ)
                    list.push(list1)
                })
                return list
            },
            tableRowClassName({ row, rowIndex }) {
                if (rowIndex % 2 == 0) {
                    return "lysuccess-row";
                } else {
                    return "lywarning-row";
                }
            },
            tableHeaderStyle({row, column, rowIndex, columnIndex}){
                if(columnIndex==2){
                    return { background: '#07346e' ,color: '#4B81EC'}
                }else{
                    return { background: '#07346e' ,color: '#4B81EC'}
                }

            },
            //获取列表
            getData() {
                let that = this
                // dataActivitybigdatapanel(that.formInline).then((res) => {
                //      if(res.code ==2000) {
                //          that.totalperson =res.data.data.totalperson
                //          let isvirtual = res.data.data.isvirtual
                //          if(isvirtual){
                //              that.organList = that.organList1
                //              that.salerList = that.salerList1
                //          }else{
                //             that.organList = res.data.data.organ_list
                //             that.salerList = res.data.data.saler_list
                //          }
                //          that.organList=that.getList(that.organList);
                //          that.salerList=that.getList(that.salerList);
                //          this.totalpersonarr = this.updateLength(that.totalperson)
                //      }
                //  })
            },
            //获取活动列表
            async getActivityList(){
                // dataActivity({page:1,limit:999}).then((res) => {
                //      if(res.code ==2000) {
                //          this.activityList = [
                //              {id:"",name:"全部"},
                //          ]
                //          let templist = res.data.data
                //          let that = this
                //          templist.forEach((item)=>{
                //              that.activityList.push({
                //                  id:item.id,
                //                  name:item.name
                //              })
                //          })
                //      }
                //  })
            },
        },
    }
</script>
<style lang="scss">
    .lydropdown-select{
        //下拉框颜色
        background:#01265a !important;
        border: 1px solid #00b9ca;
        .el-select-dropdown__item{
            color: #ffffff;
        }
        .el-select-dropdown__item.selected{
            color: #4B81EC;
        }
        .el-select-dropdown__item.hover{
            background-color:#1442A7;
        }
        .el-popper__arrow{
            border-bottom-color:#00b9ca !important;
        }
        .el-popper__arrow::after{
            border-bottom-color:#01265a !important;
        }
    }
</style>
<style lang="scss" scoped>
    .center-data-activity{
        ::v-deep(.el-input__inner){
            height: 60px;
            border: none;
            background: transparent;
            color: #FFFFFF;
            font-weight: 400;
            font-size: 20px;
        }
        ::v-deep(.el-input__wrapper){
            background:#01265a !important;
            box-shadow: none !important;
        }
        ::v-deep(.el-input__inner::placeholder){
            color: #FFFFFF;
        }

    }

    .table-header{
        display: flex;
        align-items: center;
        justify-content: center;
        height: 4vh;
        color: #4B81EC;
        font-size: 15px;
        background: linear-gradient(90deg, #01265A, #07346E, #07346E, #012659);
        span{
            flex: 1;
            display: flex;
            align-items: center;
            justify-content: center;
            &:last-child{
                em{
                    border: 1px solid #2F6099;
                    height: 2.8vh;
                    width: 60%;
                    font-style: normal;
                    background: linear-gradient(0deg , #0060FF #2F6099 0%,  100%);
                    box-shadow: 0px 0px 30px 0px rgba(0,96,255,0.5000) inset;
                    border-radius: 5px;
                    color: #ffffff;
                    display: flex;
                    align-items: center;
                    justify-content: center;
                }
            }
        }
    }
    .swiper-txt{
        width: 100%;
        height: calc(100% - 4.5vh - 20px);
        .swiper-item{
            display: flex;
            align-items: center;
            justify-content: center;
            height: 4vh;
            &:nth-child(even) {
                background: linear-gradient(90deg, #01265A, #07346E, #07346E, #012659);
            }
            span{
                flex:1;
                display: flex;
                align-items: center;
                justify-content: center;
                em{
                    font-style: normal;
                    background: #1442A7;
                    border-radius: 5px;
                    color: #ffffff;
                    display: flex;
                    align-items: center;
                    justify-content: center;
                    height: 2.8vh;
                    width: 70%;
                }
            }
        }
    }
    .el-table__empty-block{
        background: #01265a !important;
    }
    .el-table{
        color: white;
        font-size: 14px;
        background: #01265a !important;
    }
    .el-table::before {
      height: 0px;
    }

    .lytd-bg{
        min-width: 90px;
        background: #1442A7;
        height: 30px;
        border-radius: 3px;
        color: white;
        display: flex;
        align-items: center;
        text-align: center;
    }
    .lydatapanel{
        /*width: 100%;*/
        min-width: 1200px;
        height: calc(100vh - 113px);
        overflow-y: hidden;
        position: relative;
        background: #04073a;
        .bg-header{
            width: 100%;
            height: 7vh;
            display: block;
            margin: 0;
            outline: none;
        }
        .lybg{
            background: url(../../assets/img/panel/bg-big-footer.png) no-repeat 50% 50%;
            background-size: 100% 100%;
            overflow: hidden;
            background-size: 100% 100%;
            width: 100%;
            height: calc(100% - 7vh);
            color: white;
            .data-title{
                display: flex;
                padding: 0 1vw;
                justify-content: space-between;
                .title-left{
                    /*background: url('../../assets/img/panel/databg.png') no-repeat 50% 80px;*/
                    background: url('../../assets/img/panel/bg-footer.png') no-repeat 50% 5vh;
                    width: 27%;
                    height:calc(100vh - 200px - 2vh);
                    /*margin-left: 30px;*/
                    margin-top: 1vh;
                    background-size: 100% calc(100% - 5vh);
                    overflow: hidden;
                    .title-left-title{
                        color: white;
                        font-size: 20px;
                        font-weight: 500;
                        text-align: center;
                        line-height: 5vh;
                        background: url('../../assets/img/panel/bg-header.png') no-repeat 50% 50%;
                        background-size: 100% 100%;

                    }
                    .title-data{
                        padding: 15px 5px 5px 5px;
                        box-sizing: border-box;
                        height: calc(100% - 4vh);
                    }
                }
                .title-center{
                    width: 40%;
                    display: flex;
                    flex-direction: column;
                    .center-data{
                        display: flex;
                        margin-top: 2vh;
                        margin-bottom: 5vh;
                        justify-content: center;
                        .center-data-activity{
                            width: 25%;
                            /*font-size: 23px;*/
                            /*font-weight: 400;*/
                            color: #FFFFFF;
                            /*height: 60px;*/
                            border: 1px solid #00b9ca;
                        }
                        .center-data-left{
                            margin-left: 5px;
                            width: 30%;
                            font-size: 23px;
                            font-weight: 400;
                            color: #FFFFFF;
                            height: 60px;
                            border: 1px solid #00b9ca;
                            text-align: center;
                            align-items: center;
                            display: flex;
                            .center-data-left-title{
                                flex: 1;
                                text-align: center;
                                display: flex;
                                justify-content: center;
                                align-items: center;
                                background: url('../../assets/img/panel/titlebg.png') no-repeat 50% 50%;
                                background-size: 100%;
                                line-height: 30px;
                            }
                        }
                        .center-data-right{
                            width: 40%;
                            font-size: 35px;
                            font-weight: 400;
                            margin-left: 5px;
                            height: 60px;
                            line-height: 40px;
                            border: 1px solid #00b9ca;
                            align-items: center;
                            display: flex;
                            text-align: center;
                            color: #6F9AF9;
                            justify-content: center;
                            column-gap: 3px;
                            padding: 0 10px;
                            span{
                                flex: 1;
                                display: flex;
                                align-items: center;
                                justify-content: center;
                                background: rgba(0, 255, 255, .2);
                                height: 48px;
                            }
                        }
                    }
                    .center-box{
                        background: url('../../assets/img/panel/box.png') no-repeat center;
                        width: 100%;
                        height: 100%;
                        background-size: 100% 100%;
                        display: flex;
                        align-items: center;
                        text-align: center;
                        .center-box-nums{
                            min-width: 190px;
                            font-size: 100px;
                            font-weight: 400;
                            color: #00FFD4;
                            line-height: 10px;
                            background: linear-gradient(0deg, #2CF4D1 0%, #22D0E5 100%);
                            -webkit-background-clip: text;
                            -webkit-text-fill-color: transparent;
                        }
                        .center-box-person{
                            font-size: 22px;
                            font-weight: 400;
                            color: #FFFFFF;
                            line-height: 91px;
                        }

                    }
                }
                .title-right{
                    background: url('../../assets/img/panel/databg.png') no-repeat;
                    width: 24%;
                    height:calc(100vh - 200px - 1vh);
                    margin-top: 1vh;
                    margin-right: 30px;
                    background-size: 100% 100%;
                    overflow: hidden;
                    .title-right-title{
                        color: white;
                        font-size: 20px;
                        font-weight: 500;
                        text-align: center;
                        line-height: 35px;
                    }
                    .title-data{
                        margin: 15px 5px 5px 5px;
                    }
                }
            }
        }

    }
    @media screen and (max-width:1600px) {
        .swiper-txt .swiper-item {
            font-size: 12px;
        }
        .swiper-txt .swiper-item span {
            &:nth-child(2) em {
                width: 96%;
            }
        }
        .table-header{
            font-size: 12px;
        }
        .table-header span:last-child em{
            width: 90%;
        }
    }
    .is-full{
        position: fixed !important;
        left: 0;
        top: 0;
        width: 100vw;
        height: 100vh;
        z-index: 99;
    }
    .el-icon-full-screen{
        font-size: 30px;
        color: #ffffff;
        position: absolute;
        z-index: 9;
        right: 20px;
        top: 20px;
    }
</style>