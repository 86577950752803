<template>
    <form-item-wrapper-ly :field="field" :rules="rules" :design-state="designState"
             :parent-widget="parentWidget" :parent-list="parentList" :index-of-parent-list="indexOfParentList">
        <el-button ref="lyfieldEditor" :type="field.options.type" :size="widgetSize"
               :plain="field.options.plain" :round="field.options.round"
               :circle="field.options.circle" :icon="field.options.icon"
               :disabled="field.options.disabled"
               :link="field.options.link"
               :loading="field.options.loading"
               :color="field.options.color"
               @click="handleButtonWidgetClick">
            {{field.options.buttonText}}</el-button>
    </form-item-wrapper-ly>
</template>

<script setup>
    import {ref, onMounted ,reactive,computed,watch,inject, getCurrentInstance} from 'vue'
    import { useBuilderStore } from "@/store/lyFormBuilder";
    import useCommon from "@/components/lyform-builder/lyform-design/useCommon";

    const {proxy}  = getCurrentInstance(); 

    const props = defineProps({
        field: Object,
        parentWidget: Object,
        parentList: Array,
        indexOfParentList: Number,
        designState: {
            type: Boolean,
            default: false
        },
        rules: Array,
    })
    const builderStore = useBuilderStore()
    const fieldModel = ref(null)
    let rules = ref([])
    let refList = inject("refList",null)

    let {createFieldRules} = useCommon()

    const widgetSize = computed(() => {
        return props.field.options.size || ''
    })

    function handleButtonWidgetClick() {
        if (!!props.designState) { //设计状态不触发点击事件
            return
        }

        if (!!props.field.options.onClick) {
            let customFn = new Function(props.field.options.onClick)
            customFn.call(proxy)
        }
    }

    function getWidgetRef(widgetName) {
        if((refList != null) && (refList.value != null)){
            return refList.value[widgetName]
        }
        return null
    }

    function getValue(){
        return fieldModel.value
    }

    function setValue(newValue){
        fieldModel.value = newValue
        // updateFormModel(newValue)
        // handleFieldValidation(getPropName(props))
    }

    function clearFieldRules() {
        if (!props.field.formItemFlag) {
            return
        }
        rules.value.splice(0, rules.value.length)
    }

    function setHidden(flag) {
        props.field.options.hidden = flag
        if (!!flag) { 
            clearFieldRules() //既然隐藏了，就没必要做form表单验证
        } else {
            createFieldRules(props.field,rules.value) //不隐藏就把表单校验加回来
        }
    }

    function initRefList() {
        if ((refList != null) && (refList.value != null) && !!props.field.options.name) {
            refList.value[props.field.options.name] = proxy
        }
    }

    function initFieldModel() {
        if (!props.field.formItemFlag) {
            return
        }
        fieldModel.value = props.field.options.defaultValue
    }
    
    watch(()=>props.field.options.defaultValue,(n,o)=>{
        fieldModel.value = n
    })
    onMounted(()=>{
        initFieldModel()
        initRefList()
        createFieldRules(props.field,rules.value)
    })

    defineExpose({
        getWidgetRef,
        getValue,
        setValue,
        setHidden
    })


</script>

<style lang="scss" scoped>
    .radio-group-block {
        display: block !important;
    }
</style>