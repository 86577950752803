<template>
  <div :class="{'ly-is-full':isFull}">
    <div class="tableSelect" ref="tableSelect">
      <el-form :model="formInline">
        <el-form-item label="产品分类：">
          <el-input v-model="formInline.classification" maxlength="100" clearable placeholder="请输入产品分类"
                    @change="search" style="width:180px"></el-input>
        </el-form-item>
        <el-form-item label="产品名称：">
          <el-input v-model="formInline.product_name" maxlength="100" clearable placeholder="请输入产品名称"
                    @change="search" style="width:180px"></el-input>
        </el-form-item>
        <el-form-item label="批发价：">
          <ly-input-range v-model="trade_priceDatas" @onchange="trade_priceDatasChange"></ly-input-range>
        </el-form-item>
        <el-form-item label="会员价：">
          <ly-input-range v-model="vip_priceDatas" @onchange="vip_priceDatasChange"></ly-input-range>
        </el-form-item>
        <el-form-item label="超级会员价：">
          <ly-input-range v-model="super_vip_priceDatas" @onchange="super_vip_priceDatasChange"></ly-input-range>
        </el-form-item>
        <el-form-item label="对普通会员展示：">
          <el-select v-model="formInline.show_vip" clearable filterable placeholder="请选择对普通会员展示"
                     style="width:180px;" @change="search">
            <el-option
                v-for="item in dictStore.data['button_whether_bool']"
                :key="item.id"
                :label="item.label"
                :value="item.value"/>
          </el-select>
        </el-form-item>
        <el-form-item label="关联型号表：">
          <el-input v-model="formInline.model_list" maxlength="100" clearable placeholder="请输入关联型号表"
                    @change="search" style="width:180px"></el-input>
        </el-form-item>
        <el-form-item label="关联通用表：">
          <el-input v-model="formInline.general_list" maxlength="100" clearable placeholder="请输入关联通用表"
                    @change="search" style="width:180px"></el-input>
        </el-form-item>
        <el-form-item label="服务：">
          <el-input v-model="formInline.serve_product" maxlength="100" clearable placeholder="请输入服务"
                    @change="search" style="width:180px"></el-input>
        </el-form-item>
        <el-form-item label="特点：">
          <el-input v-model="formInline.characteristic_product" maxlength="100" clearable placeholder="请输入特点"
                    @change="search" style="width:180px"></el-input>
        </el-form-item>
        <el-form-item label="发布时间：">
          <el-date-picker style="width:350px" v-model="issue_timeTimers" type="datetimerange"
                          @change="issue_timeTimeChange" range-separator="至" start-placeholder="开始时间"
                          end-placeholder="结束时间"></el-date-picker>
        </el-form-item>
        <el-form-item label="创建时间：" v-if="false">
          <el-date-picker
              style="width:350px"
              v-model="timers"
              type="datetimerange"
              @change="timeChange"
              range-separator="至"
              start-placeholder="开始日期"
              end-placeholder="结束日期">
          </el-date-picker>
        </el-form-item>
        <el-form-item label="" v-show="hasPermission(this.$route.name,'Search')">
          <el-button @click="search" type="primary" icon="Search">查询</el-button>
        </el-form-item>
        <el-form-item label="">
          <el-button @click="handleEdit('','reset')" icon="Refresh">重置</el-button>
        </el-form-item>
        <el-form-item label="" v-show="hasPermission(this.$route.name,'Create')">
          <el-button type="primary" icon="Plus" @click="handelAdd">新增</el-button>
        </el-form-item>
        <el-form-item label="" v-if="hasPermission(this.$route.name,'Delete') && false">
          <el-button @click="handleDelete" type="danger" :disabled="multiple" icon="Delete">删除</el-button>
        </el-form-item>
        <el-form-item label="" v-if="hasPermission(this.$route.name,'Export') && false">
          <el-button type="primary" @click="exportDataBackend" :disabled="loadingPage" icon="Download">导出</el-button>
        </el-form-item>
      </el-form>
    </div>
    <div class="table">
      <ly-table tableName="lyAutoCodeProductManagementListTable" :height="tableHeight" :pageSize="10"
                :apiObj="apiObjList" :params="formInline" ref="tableref" :column="column" showSelectable showSequence
                @selection-change="handleSelectionChange">
        <template #classification="scope">
          <span>{{ scope.row.classification_lyautocode_name }}</span>
        </template>
        <template #img_url="scope">
          <el-image preview-teleported :src="scope.row.img_url" style="width: 30px;height:30px"
                    :preview-src-list="[scope.row.img_url]" v-if="scope.row.img_url"></el-image>
        </template>
        <template #show_vip="scope">
                    <span v-for="(item,index) in dictStore.data['button_whether_bool']">
                        <el-tag v-if="scope.row.show_vip && !!JSON.parse(item.value)" type="success">{{
                            item.label
                          }}</el-tag>
                        <el-tag v-else-if="!scope.row.show_vip && !(!!JSON.parse(item.value))"
                                type="danger">{{ item.label }}</el-tag>
                    </span>
        </template>
        <template #model_list="scope">
          <span>{{ scope.row.model_list_lyautocode_name }}</span>
        </template>
        <template #general_list="scope">
          <span>{{ scope.row.general_list_lyautocode_name }}</span>
        </template>
        <template #serve_product="scope">
                    <span v-for="(item,index) in scope.row.serve_product__list"
                          class="el-select-tags-wrapper has-prefix"
                          style="margin-left: 11px;">
    <span v-if="item" class="el-tag is-closable el-tag--info el-tag--default el-tag--light"
          :style="{backgroundColor:item.colour}">
        <span class="el-tag__content">
            <span class="el-select__tags-text" style="max-width: 283px;">
                {{ item.name }}
            </span>
        </span>
    </span>
</span>
        </template>
        <template #characteristic_product="scope">
                    <span v-for="(item,index) in scope.row.characteristic_product__list"
                          class="el-select-tags-wrapper has-prefix"
                          style="margin-left: 11px;">
    <span v-if="item" class="el-tag is-closable el-tag--info el-tag--default el-tag--light"
          :style="{backgroundColor:item.colour}">
        <span class="el-tag__content">
            <span class="el-select__tags-text" style="max-width: 283px;">
                {{ item.name }}
            </span>
        </span>
    </span>
</span>
        </template>
        <template #spu_id="scope">
          <span>{{ scope.row.spu_id_lyautocode_name }}</span>
        </template>
        <el-table-column label="操作" fixed="right" width="180">
          <template #header>
            <div style="display: flex;justify-content: space-between;align-items: center;">
              <div>操作</div>
              <div @click="setFull">
                <el-tooltip content="全屏" placement="bottom">
                  <el-icon>
                    <full-screen/>
                  </el-icon>
                </el-tooltip>
              </div>
            </div>
          </template>
          <template #default="scope">
            <span class="table-operate-btn" @click="handleEdit(scope.row,'detail')"
                  v-show="hasPermission(this.$route.name,'Retrieve')">详情</span>
            <span class="table-operate-btn" @click="handleEdit(scope.row,'edit')"
                  v-show="hasPermission(this.$route.name,'Update')">编辑</span>
            <span class="table-operate-btn" @click="handleEdit(scope.row,'delete')"
                  v-show="hasPermission(this.$route.name,'Delete')">删除</span>
          </template>
        </el-table-column>
      </ly-table>
    </div>
    <ly-dialog v-model="dialogVisible" :title="dialogTitle" :width="dialogWidth" :top="dialogTop"
               :fullscreen="fullscreen" :before-close="handleDialogClose">
      <el-form :model="formData" :rules="rules" ref="rulesForm" :disabled="formDisabled"
               :label-position="formLabelPosition" :label-width="formLabelWidth">
        <el-row :gutter="20">
          <el-col :span="12?12:24">
            <el-form-item label="产品分类" prop="classification" v-if="true">
              <el-select v-model="formData.classification" :disabled="isFormItemEditDisabled(false)" clearable
                         filterable placeholder="请选择产品分类" style="width: 100%;">
                <el-option
                    v-for="item in classificationLyAudoCodeAPIList"
                    :key="item.id"
                    :label="item.name"
                    :value="item.id"/>
              </el-select>
            </el-form-item>
          </el-col>
          <el-col :span="12?12:24">
            <el-form-item label="产品名称" prop="product_name" v-if="true">
              <el-input v-model="formData.product_name" :disabled="isFormItemEditDisabled(false)" style="width: 100%;"
                        placeholder="请输入产品名称"/>
            </el-form-item>
          </el-col>
          <el-col :span="24?24:24">
            <el-form-item label="图片" prop="img_url" v-if="true">
              <ly-public-pictrue-single-upload v-model="formData.img_url" :disabled="isFormItemEditDisabled(false)"
                                               :round="false" :cropper="false"
                                               title="" :show-file-list="true" :width="148" :height="148"
                                               icon="plus" :compress="1" :aspect-ratio="NaN" :max-size="10">
              </ly-public-pictrue-single-upload>
            </el-form-item>
          </el-col>
          <el-col :span="6?6:24">
            <el-form-item label="批发价" prop="trade_price" v-if="true">
              <el-input v-model="formData.trade_price" :disabled="isFormItemEditDisabled(false)" style="width: 100%;"
                        placeholder="请输入批发价"/>
            </el-form-item>
          </el-col>
          <el-col :span="6?6:24">
            <el-form-item label="会员价" prop="vip_price" v-if="true">
              <el-input v-model="formData.vip_price" :disabled="isFormItemEditDisabled(false)" style="width: 100%;"
                        placeholder="请输入会员价"/>
            </el-form-item>
          </el-col>
          <el-col :span="6?6:24">
            <el-form-item label="超级会员价" prop="super_vip_price" v-if="true">
              <el-input v-model="formData.super_vip_price" :disabled="isFormItemEditDisabled(false)"
                        style="width: 100%;" placeholder="请输入超级会员价"/>
            </el-form-item>
          </el-col>
          <el-col :span="6?6:24">
            <el-form-item label="对普通会员展示" prop="show_vip" v-if="true">
              <el-switch v-model="formData.show_vip" :disabled="isFormItemEditDisabled(false)" active-color="#13ce66"
                         inactive-color="#ff4949"></el-switch>
            </el-form-item>
          </el-col>
          <el-col :span="12?12:24">
            <el-form-item label="关联型号表" prop="model_list" v-if="true">
              <el-select v-model="formData.model_list" :disabled="isFormItemEditDisabled(false)" clearable filterable
                         placeholder="请选择关联型号表" style="width: 100%;">
                <el-option
                    v-for="item in model_listLyAudoCodeAPIList"
                    :key="item.id"
                    :label="item.name"
                    :value="item.id"/>
              </el-select>
            </el-form-item>
          </el-col>
          <el-col :span="12?12:24">
            <el-form-item label="关联通用表" prop="general_list" v-if="true">
              <el-select v-model="formData.general_list" :disabled="isFormItemEditDisabled(false)" clearable filterable
                         placeholder="请选择关联通用表" style="width: 100%;">
                <el-option
                    v-for="item in general_listLyAudoCodeAPIList"
                    :key="item.id"
                    :label="item.name"
                    :value="item.id"/>
              </el-select>
            </el-form-item>
          </el-col>
          <el-col :span="12?12:24">
            <el-form-item label="服务" prop="serve_product" v-if="true">
              <el-select v-model="formData.serve_product" :disabled="isFormItemEditDisabled(false)" multiple clearable
                         filterable placeholder="请选择服务" style="width: 100%;">
                <el-option
                    v-for="item in serve_productLyAudoCodeAPIList"
                    :key="item.id"
                    :label="item.name"
                    :value="item.id"/>
              </el-select>
            </el-form-item>
          </el-col>
          <el-col :span="12?12:24">
            <el-form-item label="特点" prop="characteristic_product" v-if="true">
              <el-select v-model="formData.characteristic_product" :disabled="isFormItemEditDisabled(false)" multiple
                         clearable filterable placeholder="请选择特点" style="width: 100%;">
                <el-option
                    v-for="item in characteristic_productLyAudoCodeAPIList"
                    :key="item.id"
                    :label="item.name"
                    :value="item.id"/>
              </el-select>
            </el-form-item>
          </el-col>
          <el-col :span="12?12:24">
            <el-form-item label="发布时间" prop="issue_time" v-if="true">
              <el-date-picker type="datetime" v-model="formData.issue_time" :disabled="isFormItemEditDisabled(false)"
                              clearable format="YYYY-MM-DD HH:mm:ss" value-format="YYYY-MM-DD HH:mm:ss"
                              placeholder="请选择发布时间">
              </el-date-picker>
            </el-form-item>
          </el-col>
          <el-col :span="24?24:24">
            <el-form-item label="关联商品" prop="spu_id" v-if="true">
              <el-select v-model="formData.spu_id" :disabled="isFormItemEditDisabled(false)" clearable filterable
                         placeholder="请选择关联商品" style="width: 100%;">
                <el-option
                    v-for="item in spu_idLyAudoCodeAPIList"
                    :key="item.id"
                    :label="item.name"
                    :value="item.id"/>
              </el-select>
            </el-form-item>
          </el-col>
        </el-row>
      </el-form>
      <template v-slot:footer>

        <!--      备注表数据开始-->
        <div :class="{'ly-is-full':isFull2}">
          <div class="tableSelect" ref="tableSelect">
            <el-form :model="formInline2">
              <!--              <el-form-item label="创建时间：" v-if="false">-->
              <!--                <el-date-picker-->
              <!--                    style="width:350px"-->
              <!--                    v-model="timers2"-->
              <!--                    type="datetimerange"-->
              <!--                    @change="timeChange2"-->
              <!--                    range-separator="至"-->
              <!--                    start-placeholder="开始日期"-->
              <!--                    end-placeholder="结束日期">-->
              <!--                </el-date-picker>-->
              <!--              </el-form-item>-->
              <!--              <el-form-item label="" v-show="hasPermission(this.$route.name,'Search')">-->
              <!--                <el-button @click="search2" type="primary" icon="Search">查询</el-button>-->
              <!--              </el-form-item>-->
              <!--              <el-form-item label="">-->
              <!--                <el-button @click="handleEdit2('','reset')" icon="Refresh">重置</el-button>-->
              <!--              </el-form-item>-->
              <el-form-item label="" v-show="hasPermission(this.$route.name,'Create')">
                <el-button type="primary" icon="Plus" @click="handelAdd2">新增</el-button>
              </el-form-item>
              <el-form-item label="" v-if="hasPermission(this.$route.name,'Delete') && false">
                <el-button @click="handleDelete2" type="danger" :disabled="multiple2" icon="Delete">删除</el-button>
              </el-form-item>
              <el-form-item label="" v-if="hasPermission(this.$route.name,'Export') && false">
                <el-button type="primary" @click="exportDataBackend2" :disabled="loadingPage" icon="Download">导出
                </el-button>
              </el-form-item>
            </el-form>
          </div>
          <div class="table">
            <ly-table tableName="lyAutoCodeProductManagementRemarkTable" :height="tableHeight2" :pageSize="10"
                      :data="tableData2"
                      :apiObj="apiObjList2" :params="formInline2" ref="tableref" :column="column2" showSelectable
                      showSequence @selection-change="handleSelectionChange2">
              <template #product="scope">
                <span>{{ scope.row.product_lyautocode_name }}</span>
              </template>
              <template #remark_file="scope">
                <el-image v-for="(item,index) in scope.row.remark_file.split(',')" v-bind:key="index" preview-teleported
                          :src="item" style="width: 30px;height:30px;margin-right: 5px;" :preview-src-list="[item]"
                          v-if="scope.row.remark_file"></el-image>
              </template>
              <template #status="scope">
                    <span v-for="(item,index) in dictStore.data['button_whether_bool']">
                        <el-tag v-if="scope.row.status && !!JSON.parse(item.value)" type="success">{{
                            item.label
                          }}</el-tag>
                        <el-tag v-else-if="!scope.row.status && !(!!JSON.parse(item.value))" type="danger">{{
                            item.label
                          }}</el-tag>
                    </span>
              </template>
              <el-table-column label="操作" fixed="right" width="180">
                <template #header>
                  <div style="display: flex;justify-content: space-between;align-items: center;">
                    <div>操作</div>
                    <div @click="setFull2">
                      <el-tooltip content="全屏" placement="bottom">
                        <el-icon>
                          <full-screen/>
                        </el-icon>
                      </el-tooltip>
                    </div>
                  </div>
                </template>
                <template #default="scope">
                  <span class="table-operate-btn" @click="handleEdit2(scope.row,'detail')"
                        v-show="hasPermission(this.$route.name,'Retrieve')">详情</span>
                  <span class="table-operate-btn" @click="handleEdit2(scope.row,'edit')"
                        v-show="hasPermission(this.$route.name,'Update')">编辑</span>
                  <span class="table-operate-btn" @click="handleEdit2(scope.row,'delete')"
                        v-show="hasPermission(this.$route.name,'Delete')">删除</span>
                </template>
              </el-table-column>
            </ly-table>
          </div>
          <ly-dialog v-model="dialogVisible2" :title="dialogTitle2" :width="dialogWidth2" :top="dialogTop2"
                     :fullscreen="fullscreen2" :before-close="handleDialogClose2">
            <el-form :model="formData2" :rules="rules2" ref="rulesForm" :disabled="formDisabled2"
                     :label-position="formLabelPosition2" :label-width="formLabelWidth2">
              <el-row :gutter="20">
                <el-col :span="24?24:24">
                  <el-form-item label="ID" prop="id" v-if="false">
                  </el-form-item>
                </el-col>

                <el-col :span="24?24:24">
                  <el-form-item label="备注内容" prop="remark_tips" v-if="true">
                    <el-input v-model="formData2.remark_tips" :disabled="isFormItemEditDisabled2(false)"
                              style="width: 100%;" placeholder="请输入备注内容"/>
                  </el-form-item>
                </el-col>
                <el-col :span="24?24:24">
                  <el-form-item label="备注图片/文件" prop="remark_file" v-if="true">
                    <ly-public-file-multiple-upload v-model="formData.remark_file"
                                                    :disabled="isFormItemEditDisabled(false)"
                                                    :multiple="true" :drag="true" :limit="10" tip=""
                                                    :show-file-list="true"
                                                    :max-size="200" icon="plus" button-type="primary">
                      <el-icon class="el-icon--upload" v-if="true">
                        <upload-filled/>
                      </el-icon>
                      <div class="el-upload__text" v-if="true">
                        拖拽文件到这 或 <em>点击上传</em>
                      </div>
                    </ly-public-file-multiple-upload>
                  </el-form-item>
                </el-col>
                <el-col :span="24?24:24">
                  <el-form-item label="展示" prop="status" v-if="true">
                    <el-switch v-model="formData2.status" :disabled="isFormItemEditDisabled2(false)"
                               active-color="#13ce66" inactive-color="#ff4949"></el-switch>
                  </el-form-item>
                </el-col>
              </el-row>
            </el-form>
            <template v-slot:footer>
              <el-button @click="handleDialogClose2">取消</el-button>
              <el-button @click="handleDialogSubmit2" type="primary" :loading="dialogLoadingSave2"
                         v-if="dialogTitle2!='详情'">确定
              </el-button>
            </template>
          </ly-dialog>

        </div>
        <!--      备注表数据结束-->
        <el-button @click="handleDialogClose">取消</el-button>
        <el-button @click="handleDialogSubmit" type="primary" :loading="dialogLoadingSave" v-if="dialogTitle!='详情'">
          确定
        </el-button>
      </template>
    </ly-dialog>
  </div>
</template>

<script>

import {ajaxDelete, ajaxDownloadExcel, ajaxGet, ajaxPost, ajaxPut} from '@/api/request'
import {useDictStore} from "@/store/dict";

import {dateFormats, deepClone, getTableHeight} from "@/utils/util";
import LyDialog from "@/components/dialog/dialog";
// 列表
const lyAutoCodeList = params => ajaxGet({url: `autocode/ProductManagementList/`, params})
// 新增
const lyAutoCodeAdd = params => ajaxPost({url: `autocode/ProductManagementList/`, params})
// 编辑
const lyAutoCodeEdit = params => ajaxPut({url: `autocode/ProductManagementList/`, params})
// 删除
const lyAutoCodeDelete = params => ajaxDelete({url: `autocode/ProductManagementList/`, params})
// 导出
const lyAutoCodeExport = params => ajaxDownloadExcel({url: `autocode/ProductManagementList/export/`, params})
const classificationLyAudoCodeAPI = params => ajaxGet({url: `autocode/Classification/`, params})
const model_listLyAudoCodeAPI = params => ajaxGet({url: `autocode/ModelListType/`, params})
const general_listLyAudoCodeAPI = params => ajaxGet({url: `autocode/GeneralList/`, params})
const serve_productLyAudoCodeAPI = params => ajaxGet({url: `autocode/ProductService/`, params})
const characteristic_productLyAudoCodeAPI = params => ajaxGet({url: `autocode/ProductFeature/`, params})
const spu_idLyAudoCodeAPI = params => ajaxGet({url: `mall/goodsspu/`, params})

// 备注数据表的列表
// 列表
const lyAutoCodeList2 = params => ajaxGet({url: `autocode/ProductManagementRemark/`, params})
// 新增
const lyAutoCodeAdd2 = params => ajaxPost({url: `autocode/ProductManagementRemark/`, params})
// 编辑
const lyAutoCodeEdit2 = params => ajaxPut({url: `autocode/ProductManagementRemark/`, params})
// 删除
const lyAutoCodeDelete2 = params => ajaxDelete({url: `autocode/ProductManagementRemark/`, params})
// 导出
const lyAutoCodeExport2 = params => ajaxDownloadExcel({url: `autocode/ProductManagementRemark/export/`, params})
const productLyAudoCodeAPI2 = params => ajaxGet({url: `autocode/ProductManagementList/`, params})

export default {
  name: "lyAutoCodeProductManagementList",
  components: {LyDialog},
  setup() {
    const dictStore = useDictStore()
    return {dictStore}
  },
  data() {
    return {
      isFull: false,
      tableHeight: 500,
      loadingPage: false,
      apiObjList: lyAutoCodeList,
      // 选项框选中数组
      ids: [],
      // 选项框非单个禁用
      single: true,
      // 非多个禁用
      multiple: true,
      formInline: {},
      timers: [],
      tableData: [],
      column: [
        {
          label: "产品分类",
          prop: "classification",
          minWidth: "100",
          hide: !true
        },
        {
          label: "产品名称",
          prop: "product_name",
          minWidth: "100",
          hide: !true
        },
        {
          label: "图片",
          prop: "img_url",
          minWidth: "100",
          hide: !true
        },
        {
          label: "批发价",
          prop: "trade_price",
          minWidth: "100",
          hide: !true
        },
        {
          label: "会员价",
          prop: "vip_price",
          minWidth: "100",
          hide: !true
        },
        {
          label: "超级会员价",
          prop: "super_vip_price",
          minWidth: "100",
          hide: !true
        },
        {
          label: "对普通会员展示",
          prop: "show_vip",
          minWidth: "100",
          hide: !true
        },
        {
          label: "关联型号表",
          prop: "model_list",
          minWidth: "100",
          hide: !true
        },
        {
          label: "关联通用表",
          prop: "general_list",
          minWidth: "100",
          hide: !true
        },
        {
          label: "服务",
          prop: "serve_product",
          minWidth: "100",
          hide: !true
        },
        {
          label: "特点",
          prop: "characteristic_product",
          minWidth: "100",
          hide: !true
        },
        {
          label: "发布时间",
          prop: "issue_time",
          minWidth: "100",
          hide: !true
        },
        {
          label: "关联商品",
          prop: "spu_id",
          minWidth: "100",
          hide: !true
        },
        {
          label: "创建时间",
          prop: "create_datetime",
          minWidth: "180"
        }
      ],
      //弹窗
      dialogVisible: false,
      dialogTitle: "",
      dialogLoadingSave: false,
      fullscreen: false,
      dialogWidth: "50%",
      dialogTop: "15vh",
      formDisabled: false,
      formLabelPosition: "right",
      formLabelWidth: "auto",
      formData: {
        "classification": null,
        "product_name": null,
        "img_url": null,
        "trade_price": null,
        "vip_price": null,
        "super_vip_price": null,
        "show_vip": true,
        "model_list": null,
        "general_list": null,
        "serve_product": [],
        "characteristic_product": [],
        "issue_time": null,
        "spu_id": null
      },
      rules: {
        product_name: [{required: true, message: "产品名称不能为空", trigger: 'blur'}],
      },
      classificationLyAudoCodeAPIList: [],
      model_listLyAudoCodeAPIList: [],
      general_listLyAudoCodeAPIList: [],
      serve_productLyAudoCodeAPIList: [],
      characteristic_productLyAudoCodeAPIList: [],
      spu_idLyAudoCodeAPIList: [],
      issue_timeTimers: [],
      trade_priceDatas: [],
      vip_priceDatas: [],
      super_vip_priceDatas: [],
    // 备注表数据
      isFull2: false,
      tableHeight2: 500,
      loadingPage2: false,
      // 打开以下标签会自动刷新页面数据
      // apiObjList2: lyAutoCodeList2,
      // 选项框选中数组
      ids2: [],
      // 选项框非单个禁用
      single2: true,
      // 非多个禁用
      multiple2: true,
      formInline2: {
        page: 1,
        limit: 10,
      },
      pageparm2: {
        page: 1,
        limit: 10,
        total: 0
      },
      timers2: [],
      tableData2: [],
      product_management_list_id_id: '',
      column2: [
        {
          label: "ID",
          prop: "id",
          minWidth: "100",
          hide: !true
        },
        // {
        //   label: "产品分类",
        //   prop: "product_management_list_id",
        //   minWidth: "100",
        //   hide: true
        // },
        {
          label: "备注内容",
          prop: "remark_tips",
          minWidth: "100",
          hide: !true
        },
        {
          label: "备注图片/文件",
          prop: "remark_file",
          minWidth: "100",
          hide: !true
        },
        {
          label: "展示",
          prop: "status",
          minWidth: "100",
          hide: !true
        },
        {
          label: "创建时间",
          prop: "create_datetime",
          minWidth: "180"
        }
      ],
      //弹窗
      dialogVisible2: false,
      dialogTitle2: "",
      dialogLoadingSave2: false,
      fullscreen2: false,
      dialogWidth2: "50%",
      dialogTop2: "15vh",
      formDisabled2: false,
      formLabelPosition2: "right",
      formLabelWidth2: "auto",
      formData2: {
        "id": null,
        // "product_management_list_id": null,
        "remark_tips": null, "remark_file": null, "status": true
      },
      rules2: {
        id: [{required: true, message: "ID不能为空", trigger: 'blur'}],
        // product_management_list_id: [{required: true, message: "产品分类不能为空", trigger: 'change'}],
      },
      productLyAudoCodeAPI2List2: [],
    }
  },
  methods: {
    setLoadingPage(bools) {
      this.loadingPage = bools
      this.$refs.tableref.loadingPage(bools)
    },
    setFull() {
      this.isFull = !this.isFull
      window.dispatchEvent(new Event('resize'))
    },
    //多选项框被选中数据
    handleSelectionChange(selection) {
      this.ids = selection.map(item => item.id);
      this.single = selection.length !== 1;
      this.multiple = !selection.length;
    },
    /** 批量删除按钮操作 */
    handleDelete(row) {
      const ids = this.ids;
      let vm = this
      vm.$confirm('是否确认删除选中的数据项?', "警告", {
        confirmButtonText: "确定",
        cancelButtonText: "取消",
        type: "warning"
      }).then(function () {
        return lyAutoCodeDelete({id: ids}).then(res => {
          if (res.code == 2000) {
            vm.$message.success(res.msg)
            vm.getData()
            vm.getData2(ids)
          } else {
            vm.$message.warning(res.msg)
          }
        })
      })
    },
    convertStrNum(model, value) {
      if (model === "" || model === null || model === undefined) {
        return value
      } else if (Object.prototype.toString.call(model) === "[object String]") {
        return value.toString()
      } else if (Object.prototype.toString.call(model) === "[object Number]") {
        return value * 1
      } else {
        return value
      }
    },
    //判断单个组件编辑时是否禁用disabled参数为bool值
    isFormItemEditDisabled(disabled = false) {
      if (disabled == "" || disabled == null || disabled == undefined) {
        return false
      } else if ((disabled == true || disabled == 'True') && this.dialogTitle === '编辑') {
        return true
      }
      return false
    },
    handelAdd() {
      this.dialogTitle = "添加"
      this.dialogVisible = true
      this.classificationLyAudoCodeAPIFunction()
      this.model_listLyAudoCodeAPIFunction()
      this.general_listLyAudoCodeAPIFunction()
      this.serve_productLyAudoCodeAPIFunction()
      this.characteristic_productLyAudoCodeAPIFunction()
      this.spu_idLyAudoCodeAPIFunction()
    },
    handleDialogClose() {
      this.dialogTitle = ""
      this.dialogVisible = false
      this.dialogLoadingSave = false
      this.formDisabled = false
      this.formData = {
        "classification": null,
        "product_name": null,
        "img_url": null,
        "trade_price": null,
        "vip_price": null,
        "super_vip_price": null,
        "show_vip": true,
        "model_list": null,
        "general_list": null,
        "serve_product": [],
        "characteristic_product": [],
        "issue_time": null,
        "spu_id": null
      }
    },
    handleDialogSubmit() {
      this.$refs['rulesForm'].validate((obj) => {
        if (obj) {
          this.dialogLoadingSave = true
          let param = {
            ...this.formData
          }
          if (this.dialogTitle == "编辑") {
            lyAutoCodeEdit(param).then(res => {
              this.dialogLoadingSave = false
              if (res.code == 2000) {
                this.$message.success(res.msg)
                this.handleDialogClose()
                this.getData()
              } else {
                this.$message.warning(res.msg)
              }
            })
          } else {
            lyAutoCodeAdd(param).then(res => {
              this.dialogLoadingSave = false
              if (res.code == 2000) {
                this.$message.success(res.msg)
                this.handleDialogClose()
                this.getData()
              } else {
                this.$message.warning(res.msg)
              }
            })
          }

        }
      })
      this.dialogLoadingSave = false
    },
    handleEdit(row, flag) {
      if (flag == 'edit') {
        this.dialogTitle = "编辑"
        this.dialogVisible = true
        this.formData = deepClone(row)
        this.classificationLyAudoCodeAPIFunction()
        this.model_listLyAudoCodeAPIFunction()
        this.general_listLyAudoCodeAPIFunction()
        this.serve_productLyAudoCodeAPIFunction()
        this.characteristic_productLyAudoCodeAPIFunction()
        this.spu_idLyAudoCodeAPIFunction()
        this.getData2(row.id)
      } else if (flag == 'delete') {
        let vm = this
        vm.$confirm('您确定要删除选中的数据吗？', '警告', {
          closeOnClickModal: false,
          type: "warning"
        }).then(res => {
          lyAutoCodeDelete({id: row.id}).then(res => {
            if (res.code == 2000) {
              vm.$message.success(res.msg)
              vm.getData()
            } else {
              vm.$message.warning(res.msg)
            }
          })
        }).catch(() => {

        })
      } else if (flag == 'detail') {
        this.dialogTitle = "详情"
        this.dialogVisible = true
        this.formDisabled = true
        this.formData = deepClone(row)
        this.classificationLyAudoCodeAPIFunction()
        this.model_listLyAudoCodeAPIFunction()
        this.general_listLyAudoCodeAPIFunction()
        this.serve_productLyAudoCodeAPIFunction()
        this.characteristic_productLyAudoCodeAPIFunction()
        this.spu_idLyAudoCodeAPIFunction()
        // 获取子表数据
        this.getData2(row.id)
      } else if (flag == "reset") {
        this.formInline = {}
        this.timers = []
        this.issue_timeTimers = []
        this.trade_priceDatas = []
        this.vip_priceDatas = []
        this.super_vip_priceDatas = []
        this.search()
      }
    },
    /**
     * 从URL里下载文件
     */
    // 下载文件
    downloadFileURL(url) {
      var a = document.createElement("a")
      var event = new MouseEvent('click')
      a.href = url
      a.target = "_blank"
      a.dispatchEvent(event)
    },
    //excel文件流下载
    downloadExcelBlob(res) {
      let fileName = new Date().getTime() + ".xlsx"
      let dispositionStr = res.headers["content-disposition"];
      if (dispositionStr == null || dispositionStr === "") {

      } else {
        // 获取文件名
        let dispositionArr = dispositionStr.split(";");
        fileName = decodeURIComponent(dispositionArr[1]);
        fileName = fileName.split("=")[1];
      }
      const blob = new Blob([res.data], {
        type: "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet",
      });
      let href = window.URL.createObjectURL(blob); //下载链接
      let link = document.createElement("a")
      link.href = href
      link.download = fileName //下载后文件名
      document.body.appendChild(link);
      link.click(); //点击下载
      document.body.removeChild(link); //下载完成移除元素
      window.URL.revokeObjectURL(href);  //释放blob对象
      this.$message.success('导出成功')
    },
    //导出
    exportDataBackend() {
      let params = {}
      if (this.ids.length > 0) {
        params = {
          ids: this.ids,
        }
      }
      this.setLoadingPage(true)
      lyAutoCodeExport(params).then(res => {
        this.setLoadingPage(false)
        this.downloadExcelBlob(res)
      })
    },
    search() {
      this.$refs.tableref.reload(this.formInline)
    },
    //获取列表
    async getData() {
      this.$refs.tableref.getData()
    },
    timeChange(val) {
      if (val) {
        this.formInline.beginAt = dateFormats(val[0], 'yyyy-MM-dd hh:mm:ss');
        this.formInline.endAt = dateFormats(val[1], 'yyyy-MM-dd hh:mm:ss');
      } else {
        this.formInline.beginAt = null
        this.formInline.endAt = null
      }
      this.search()
    },
    issue_timeTimeChange(val) {
      if (val) {
        this.formInline.issue_time_beginAt = dateFormats(val[0], 'yyyy-MM-dd hh:mm:ss');
        this.formInline.issue_time_endAt = dateFormats(val[1], 'yyyy-MM-dd hh:mm:ss');
      } else {
        this.formInline.issue_time_beginAt = null
        this.formInline.issue_time_endAt = null
      }
      this.search()
    },
    trade_priceDatasChange(val) {
      if (val) {
        this.formInline.trade_price_min = val[0];
        this.formInline.trade_price_max = val[1];
      } else {
        this.formInline.trade_price_min = null
        this.formInline.trade_price_max = null
      }
      this.search()
    },
    vip_priceDatasChange(val) {
      if (val) {
        this.formInline.vip_price_min = val[0];
        this.formInline.vip_price_max = val[1];
      } else {
        this.formInline.vip_price_min = null
        this.formInline.vip_price_max = null
      }
      this.search()
    },
    super_vip_priceDatasChange(val) {
      if (val) {
        this.formInline.super_vip_price_min = val[0];
        this.formInline.super_vip_price_max = val[1];
      } else {
        this.formInline.super_vip_price_min = null
        this.formInline.super_vip_price_max = null
      }
      this.search()
    },
    // 计算搜索栏的高度
    listenResize() {
      this.$nextTick(() => {
        this.getTheTableHeight()
      })
    },
    getTheTableHeight() {
      let tabSelectHeight = this.$refs.tableSelect ? this.$refs.tableSelect.offsetHeight : 0
      tabSelectHeight = this.isFull ? tabSelectHeight - 110 : tabSelectHeight
      this.tableHeight = getTableHeight(tabSelectHeight, false) - 125
    },
    classificationLyAudoCodeAPIFunction() {
      classificationLyAudoCodeAPI({page: 1, limit: 999}).then(res => {
        if (res.code == 2000) {
          this.classificationLyAudoCodeAPIList = res.data.data
        }
      })
    },
    model_listLyAudoCodeAPIFunction() {
      model_listLyAudoCodeAPI({page: 1, limit: 999}).then(res => {
        if (res.code == 2000) {
          this.model_listLyAudoCodeAPIList = res.data.data
        }
      })
    },
    general_listLyAudoCodeAPIFunction() {
      general_listLyAudoCodeAPI({page: 1, limit: 999}).then(res => {
        if (res.code == 2000) {
          this.general_listLyAudoCodeAPIList = res.data.data
        }
      })
    },
    serve_productLyAudoCodeAPIFunction() {
      serve_productLyAudoCodeAPI({page: 1, limit: 999}).then(res => {
        if (res.code == 2000) {
          this.serve_productLyAudoCodeAPIList = res.data.data
        }
      })
    },
    characteristic_productLyAudoCodeAPIFunction() {
      characteristic_productLyAudoCodeAPI({page: 1, limit: 999}).then(res => {
        if (res.code == 2000) {
          this.characteristic_productLyAudoCodeAPIList = res.data.data
        }
      })
    },
    spu_idLyAudoCodeAPIFunction() {
      spu_idLyAudoCodeAPI({page: 1, limit: 999}).then(res => {
        if (res.code == 2000) {
          this.spu_idLyAudoCodeAPIList = res.data.data
        }
      })
    },


    // 备注表方法
    setLoadingPage2(bools) {
      this.loadingPage2 = bools
      this.$refs.tableref.loadingPage(bools)
    },
    setFull2() {
      this.isFull2 = !this.isFull2
      window.dispatchEvent(new Event('resize'))
    },
    //多选项框被选中数据
    handleSelectionChange2(selection) {
      this.ids2 = selection.map(item => item.id);
      this.single2 = selection.length !== 1;
      this.multiple2 = !selection.length;
    },
    /** 批量删除按钮操作 */
    handleDelete2(row) {
      const ids2 = this.ids2;
      let vm = this
      vm.$confirm('是否确认删除选中的数据项?', "警告", {
        confirmButtonText: "确定",
        cancelButtonText: "取消",
        type: "warning"
      }).then(function () {
        return lyAutoCodeDelete2({id: ids2}).then(res => {
          if (res.code == 2000) {
            vm.$message.success(res.msg)
            vm.getData2(this.product_management_list_id_id)
          } else {
            vm.$message.warning(res.msg)
          }
        })
      })
    },
    convertStrNum2(model, value) {
      if (model === "" || model === null || model === undefined) {
        return value
      } else if (Object.prototype.toString.call(model) === "[object String]") {
        return value.toString()
      } else if (Object.prototype.toString.call(model) === "[object Number]") {
        return value * 1
      } else {
        return value
      }
    },
    //判断单个组件编辑时是否禁用disabled参数为bool值
    isFormItemEditDisabled2(disabled = false) {
      if (disabled == "" || disabled == null || disabled == undefined) {
        return false
      } else if ((disabled == true || disabled == 'True') && this.dialogTitle2 === '编辑') {
        return true
      }
      return false
    },
    handelAdd2() {
      this.dialogTitle2 = "添加"
      this.dialogVisible2 = true
      this.productLyAudoCodeAPI2Function2()
    },
    handleDialogClose2() {
      this.dialogTitle2 = ""
      this.dialogVisible2 = false
      this.dialogLoadingSave2 = false
      this.formDisabled2 = false
      this.formData2 = {
        "id": null,
        // "product_management_list_id": null,
        "remark_tips": null, "remark_file": null, "status": true
      }
    },
    handleDialogSubmit2() {
      this.$refs['rulesForm'].validate((obj) => {
        if (obj) {
          this.dialogLoadingSave2 = true
          let param = {
            ...this.formData2
          }
          param.product_management_list_id = this.product_management_list_id_id
          console.log(param)
          if (this.dialogTitle2 == "编辑") {
            lyAutoCodeEdit2(param).then(res => {
              this.dialogLoadingSave2 = false
              if (res.code == 2000) {
                this.$message.success(res.msg)
                this.handleDialogClose2()
                this.getData2(this.product_management_list_id_id)
              } else {
                this.$message.warning(res.msg)
              }
            })
          } else {
            lyAutoCodeAdd2(param).then(res => {
              this.dialogLoadingSave2 = false
              if (res.code == 2000) {
                this.$message.success(res.msg)
                this.handleDialogClose2()
                this.getData2(this.product_management_list_id_id)
              } else {
                this.$message.warning(res.msg)
              }
            })
          }

        }
      })
      this.dialogLoadingSave2 = false
    },
    handleEdit2(row, flag) {
      if (flag == 'edit') {
        this.dialogTitle2 = "编辑"
        this.dialogVisible2 = true
        this.formData2 = deepClone(row)
        this.productLyAudoCodeAPI2Function2()
      } else if (flag == 'delete') {
        let vm = this
        vm.$confirm('您确定要删除选中的数据吗？', '警告', {
          closeOnClickModal: false,
          type: "warning"
        }).then(res => {
          lyAutoCodeDelete2({id: row.id}).then(res => {
            if (res.code == 2000) {
              vm.$message.success(res.msg)
              vm.getData2(row.id)
            } else {
              vm.$message.warning(res.msg)
            }
          })
        }).catch(() => {

        })
      } else if (flag == 'detail') {
        this.dialogTitle2 = "详情"
        this.dialogVisible2 = true
        this.formDisabled2 = true
        this.formData2 = deepClone(row)
        this.productLyAudoCodeAPI2Function2()
      } else if (flag == "reset") {
        // this.formInline2 = {}
        // this.search2()
        this.formInline2 = {
          page: 1,
          limit: 10
        }
        this.pageparm2 = {
          page: 1,
          limit: 10,
          total: 0
        }
        this.timers2 = []
        this.getData2(this.product_management_list_id_id)
      }
    },
    /**
     * 从URL里下载文件
     */
    // 下载文件
    downloadFileURL2(url) {
      var a = document.createElement("a")
      var event = new MouseEvent('click')
      a.href = url
      a.target = "_blank"
      a.dispatchEvent(event)
    },
    //excel文件流下载
    downloadExcelBlob2(res) {
      let fileName = new Date().getTime() + ".xlsx"
      let dispositionStr = res.headers["content-disposition"];
      if (dispositionStr == null || dispositionStr === "") {

      } else {
        // 获取文件名
        let dispositionArr = dispositionStr.split(";");
        fileName = decodeURIComponent(dispositionArr[1]);
        fileName = fileName.split("=")[1];
      }
      const blob = new Blob([res.data], {
        type: "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet",
      });
      let href = window.URL.createObjectURL(blob); //下载链接
      let link = document.createElement("a")
      link.href = href
      link.download = fileName //下载后文件名
      document.body.appendChild(link);
      link.click(); //点击下载
      document.body.removeChild(link); //下载完成移除元素
      window.URL.revokeObjectURL(href);  //释放blob对象
      this.$message.success('导出成功')
    },
    //导出
    exportDataBackend2() {
      let params = {}
      if (this.ids2.length > 0) {
        params = {
          ids: this.ids2,
        }
      }
      this.setLoadingPage2(true)
      lyAutoCodeExport2(params).then(res => {
        this.setLoadingPage2(false)
        this.downloadExcelBlob2(res)
      })
    },

    callFather2(parm) {
      this.formInline2.page = parm.page
      this.formInline2.limit = parm.limit
      this.getData2()
    },
    search2() {
      // this.$refs.tableref.reload(this.formInline2)
      this.formInline2.page = 1
      this.formInline2.limit = 10
      this.getData2(this.product_management_list_id_id)
    },
    //获取列表
    async getData2(product_management_id) {
      this.loadingPage2 = true
      // 先将 this.formInline 的值复制出来
      const formInlineChild = {...this.formInline2};
      // 添加一个新的 product_management_list_id_id 键和值
      if (product_management_id) {
        formInlineChild.product_management_list_id_id = product_management_id; // rproduct_management_id 是你要添加的新值
      } else {
        formInlineChild.product_management_list_id_id = this.product_management_list_id_id;
      }
      formInlineChild.page = 1; // 目前永远取第一页数据
      lyAutoCodeList2(formInlineChild).then(res => {
        this.loadingPage2 = false
        if (res.code == 2000) {
          this.tableData2 = res.data.data
          this.product_management_list_id_id = product_management_id
          // this.pageparm.page = res.data.page;
          // this.pageparm.limit = res.data.limit;
          // this.pageparm.total = res.data.total;
        }
      })
    },
    timeChange2(val) {
      if (val) {
        this.formInline2.beginAt = dateFormats(val[0], 'yyyy-MM-dd hh:mm:ss');
        this.formInline2.endAt = dateFormats(val[1], 'yyyy-MM-dd hh:mm:ss');
      } else {
        this.formInline2.beginAt = null
        this.formInline2.endAt = null
      }
      this.search2()
    },
    // 计算搜索栏的高度
    listenResize2() {
      this.$nextTick(() => {
        this.getTheTableHeight2()
      })
    },
    getTheTableHeight2() {
      let tabSelectHeight = this.$refs.tableSelect ? this.$refs.tableSelect.offsetHeight : 0
      tabSelectHeight = this.isFull2 ? tabSelectHeight - 110 : tabSelectHeight
      this.tableHeight2 = getTableHeight(tabSelectHeight, false) - 125
    },
    productLyAudoCodeAPI2Function2() {
      productLyAudoCodeAPI2({page: 1, limit: 999}).then(res => {
        if (res.code == 2000) {
          this.productLyAudoCodeAPI2List2 = res.data.data
        }
      })
    },
  },
  mounted() {
    // 监听页面宽度变化搜索框的高度
    window.addEventListener('resize', this.listenResize);
    this.$nextTick(() => {
      this.getTheTableHeight()
    })
  },

  unmounted() {
    // 页面销毁，去掉监听事件
    window.removeEventListener("resize", this.listenResize);
  },


  mounted2() {
    // 监听页面宽度变化搜索框的高度
    window.addEventListener('resize', this.listenResize2);
    this.$nextTick(() => {
      this.getTheTableHeight2()
    })
  },

  unmounted2() {
    // 页面销毁，去掉监听事件
    window.removeEventListener("resize", this.listenResize2);
  },
}
</script>

<style lang="scss" scoped>

</style>
