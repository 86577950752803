<template>
  <div>
    <ly-dialog v-model="dialogVisible" :title="loadingTitle" width="560px" :before-close="handleClose">
      <el-form :inline="false" :model="formData" :rules="rules" ref="rulesForm" label-position="right"
               label-width="auto">
        <el-form-item label="用户头像：">
          <img :src="formData.avatar ? formData.avatar : defaultImg" style="width: 60px;height:60px"
               :onerror="defaultImg">

          <!--                <el-upload-->
          <!--                        class="avatar-uploader"-->
          <!--                        action=""-->
          <!--                        :show-file-list="false"-->
          <!--                        :http-request="imgUploadRequest"-->
          <!--                        :on-success="imgUploadSuccess"-->
          <!--                        :before-upload="imgBeforeUpload">-->
          <!--                    <img v-if="formData.img" :src="formData.img" class="avatar">-->
          <!--                    <i v-else class="el-icon-plus avatar-uploader-icon"></i>-->
          <!--                </el-upload>-->
        </el-form-item>
        <el-form-item label="用户名：" prop="username">
          {{ formData.username }}
        </el-form-item>
        <el-form-item label="用户昵称：" prop="nickname">
          {{ formData.nickname }}
        </el-form-item>
        <el-form-item label="手机号：" prop="mobile">
          {{ formData.mobile }}
        </el-form-item>
        <el-form-item label="创建时间：" prop="mobile">
          {{ formData.create_datetime }}
        </el-form-item>
        <el-form-item label="更新时间：" prop="mobile">
          {{ formData.update_datetime }}
        </el-form-item>
        <el-form-item label="状态：" prop="is_active">
          <el-switch
              v-model="formData.is_active"
              active-color="#13ce66"
              inactive-color="#ff4949" disabled>
          </el-switch>
        </el-form-item>
        <el-form-item label="会员等级：" prop="vip_grade">
          <el-select v-model="formData.vip_grade" clearable filterable placeholder="{{formData.vip_grade}}"
                     style="width:180px;"
                     active-color="#13ce66"
                     inactive-color="#ff4949">
            <el-option
                v-for="item in [
                    {'id': 0,'label': '普通用户','value': 0},
                    {'id': 1,'label': '普通会员','value': 1},
                    {'id': 2,'label': '超级会员','value': 2}
                    ]"
                :key="item.id"
                :label="item.label"
                :value="item.value"/>
          </el-select>
        </el-form-item>


        <el-form-item label="商店身份：" prop="store_identity">
          <el-select v-model="formData.store_identity" clearable filterable placeholder="{{formData.vip_grade}}"
                     style="width:180px;"
                     active-color="#13ce66"
                     inactive-color="#ff4949">
            <el-option
                v-for="item in [
                    {'id': 0,'label': '电商店主','value': '电商店主'},
                    {'id': 1,'label': '线下门店','value': '线下门店'},
                    ]"
                :key="item.id"
                :label="item.label"
                :value="item.value"/>
          </el-select>
        </el-form-item>
        <el-form-item label="店铺名称：" prop="store_name">
          <el-input v-model="formData.store_name"></el-input>
        </el-form-item>
        <el-form-item label="店铺地址：" prop="store_address">
          <el-input v-model="formData.store_address"></el-input>
        </el-form-item>
        <el-form-item label="备注：" prop="descr_admin">
          <el-input v-model="formData.descr_admin"></el-input>
        </el-form-item>
        <el-form-item label="拒绝会员原因：" prop="descr_admin">
          <el-input v-model="formData.vip_reject_descr"></el-input>
        </el-form-item>
      </el-form>
      <template #footer>
        <el-button @click="submitData(false)" :loading="loadingSave">拒绝</el-button>
        <el-button type="primary" @click="submitData(true)" :loading="loadingSave">通过</el-button>
      </template>
    </ly-dialog>
  </div>
</template>

<script>
import {platformsettingsUploadPlatformImg, UsersUsersAdd, UsersUsersPass, UsersUsersReject} from "@/api/api";
import LyDialog from "../../../components/dialog/dialog";

export default {
  name: "editUserVIP",
  components: {LyDialog},
  emits: ['refreshData'],
  data() {
    return {
      dialogVisible: false,
      loadingSave: false,
      loadingTitle: '',
      defaultImg: 'this.src="' + require('../../../assets/img/avatar.jpg') + '"',
      formData: {
        username: '',
        nickname: '',
        mobile: '',
        is_active: true,
        avatar: ''
      },
      rules: {
        username: [
          {required: true, message: '请输入用户名', trigger: 'blur'}
        ],
        // nickname: [
        //     {required: true, message: '请输入昵称',trigger: 'blur'}
        // ],
        password: [
          {required: true, message: '请输入密码', trigger: 'blur'}
        ],
        mobile: [
          {required: true, message: '请输入手机号', trigger: 'blur'}
        ],
        is_active: [
          {required: true, message: '请选择是否启用', trigger: 'blur'}
        ]
      },
      rolelist: [],
      options: [],
    }
  },
  methods: {
    handleClose() {
      this.dialogVisible = false
      this.loadingSave = false
      this.$emit('refreshData')
    },
    editUserVIPFn(item, flag) {
      this.loadingTitle = flag
      this.dialogVisible = true
      if (item) {
        delete this.rules.password
        this.formData = item
      } else {
        this.rules.password = [
          {required: true, message: '请输入密码', trigger: 'blur'}
        ]
        this.formData = {
          name: '',
          nickname: '',
          username: '',
          mobile: '',
          is_active: true,
          avatar: ''
        }
      }
    },
    submitData(flag) {
      this.$refs['rulesForm'].validate(obj => {
        if (obj) {
          this.loadingSave = true
          let param = {
            ...this.formData
          }
          // param.role = param.role?param.role.split(" "):[]
          if (this.formData.id && flag) {
            UsersUsersPass(param).then(res => {
              this.loadingSave = false
              if (res.code == 2000) {
                this.$message.success(res.msg)
                this.handleClose()
                this.$emit('refreshData')
              } else {
                this.$message.warning(res.msg)
              }
            })
          } else if (this.formData.id && !flag){
            UsersUsersReject(param).then(res => {
              this.loadingSave = false
              if (res.code == 2000) {
                this.$message.success(res.msg)
                this.handleClose()
                this.$emit('refreshData')
              } else {
                this.$message.warning(res.msg)
              }
            })
          }else {
            UsersUsersAdd(param).then(res => {
              this.loadingSave = false
              if (res.code == 2000) {
                this.$message.success(res.msg)
                this.handleClose()
                this.$emit('refreshData')
              } else {
                this.$message.warning(res.msg)
              }
            })
          }

        }
      })
    },
    imgBeforeUpload(file) {
      const isJPG = file.type === 'image/jpeg' || file.type === 'image/png';
      if (!isJPG) {
        this.$message.error('图片只能是 JPG/PNG 格式!');
        return false
      }
      return isJPG;
    },
    async imgUploadRequest(param) {
      var vm = this
      let obj = await platformsettingsUploadPlatformImg(param)
      if (obj.code == 2000) {
        let res = ''
        if (obj.data.data[0].indexOf("://") >= 0) {
          res = obj.data.data[0]

        } else {
          res = url.split('/api')[0] + obj.data.data[0]
        }
        vm.formData.avatar = res
      } else {
        vm.$message.warning(res.msg)
      }
    },
    imgUploadSuccess() {
      this.$refs.uploadDefaultImage.clearFiles()
    }
  }
}
</script>
<style scoped>
.avatar-uploader .el-upload {
  border: 1px dashed #d9d9d9;
  border-radius: 6px;
  cursor: pointer;
  position: relative;
  overflow: hidden;
}

.avatar-uploader .el-upload:hover {
  border-color: #409EFF;
}

.avatar-uploader-icon {
  font-size: 28px;
  color: #8c939d;
  width: 128px;
  height: 128px;
  line-height: 128px;
  text-align: center;
}

.avatar {
  width: 128px;
  height: 128px;
  display: block;
}
</style>

