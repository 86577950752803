<template>
  <div :class="{'ly-is-full':isFull}">
    <div class="tableSelect" ref="tableSelect">
      <el-form :model="formInline">
        <el-form-item label="标签分类：">
          <el-input v-model="formInline.classification" maxlength="100" clearable placeholder="请输入标签分类"
                    @change="search" style="width:180px"></el-input>
        </el-form-item>
        <el-form-item label="标签名：">
          <el-input v-model="formInline.name" maxlength="100" clearable placeholder="请输入标签名" @change="search"
                    style="width:180px"></el-input>
        </el-form-item>
        <el-form-item label="创建时间：" v-if="true">
          <el-date-picker
              style="width:350px"
              v-model="timers"
              type="datetimerange"
              @change="timeChange"
              range-separator="至"
              start-placeholder="开始日期"
              end-placeholder="结束日期">
          </el-date-picker>
        </el-form-item>
        <el-form-item label="" v-show="hasPermission(this.$route.name,'Search')">
          <el-button @click="search" type="primary" icon="Search">查询</el-button>
        </el-form-item>
        <el-form-item label="">
          <el-button @click="handleEdit('','reset')" icon="Refresh">重置</el-button>
        </el-form-item>
        <el-form-item label="" v-show="hasPermission(this.$route.name,'Create')">
          <el-button type="primary" icon="Plus" @click="handelAdd">新增</el-button>
        </el-form-item>
        <el-form-item label="" v-if="hasPermission(this.$route.name,'Delete') && true">
          <el-button @click="handleDelete" type="danger" :disabled="multiple" icon="Delete">删除</el-button>
        </el-form-item>
        <el-form-item label="" v-if="hasPermission(this.$route.name,'Export') && false">
          <el-button type="primary" @click="exportDataBackend" :disabled="loadingPage" icon="Download">导出</el-button>
        </el-form-item>
      </el-form>
    </div>
    <div class="table">
      <!--            <ly-table tableName="lyAutoCodeCustomLabelTable" :height="tableHeight" :pageSize="10" :apiObj="apiObjList" :params="formInline" ref="tableref" :column="column" showSelectable showSequence @selection-change="handleSelectionChange">-->
      <el-table
          :data="treeData"
          style="width: 100%"
          row-key="id"
          :default-expand-all="true"
          :tree-props="{children: 'children', hasChildren: 'hasChildren'}"
      >
        <template #classification="scope">
          <span>{{ scope.row.classification_lyautocode_name }}</span>
        </template>

  <!-- 标签名列 -->
  <el-table-column label="标签名" prop="name"></el-table-column>

  <!-- 创建时间列 -->
  <el-table-column label="创建时间" prop="create_datetime"></el-table-column>

  <!-- 其他您想要显示的列，例如： -->
  <el-table-column label="修改者" prop="modifier_name"></el-table-column>
  <el-table-column label="部门归属" prop="dept_belong_id"></el-table-column>
        <el-table-column label="操作" fixed="right" width="180">
          <template #header>
            <div style="display: flex;justify-content: space-between;align-items: center;">
              <div>操作</div>
              <div @click="setFull">
                <el-tooltip content="全屏" placement="bottom">
                  <el-icon>
                    <full-screen/>
                  </el-icon>
                </el-tooltip>
              </div>
            </div>
          </template>
          <template #default="scope">
            <span class="table-operate-btn" @click="handleEdit(scope.row,'detail')"
                  v-show="hasPermission(this.$route.name,'Retrieve')">详情</span>
            <span class="table-operate-btn" @click="handleEdit(scope.row,'edit')"
                  v-show="hasPermission(this.$route.name,'Update')">编辑</span>
            <span class="table-operate-btn" @click="handleEdit(scope.row,'delete')"
                  v-show="hasPermission(this.$route.name,'Delete')">删除</span>
          </template>
        </el-table-column>
        <!--            </ly-table>-->
      </el-table>
    </div>
    <ly-dialog v-model="dialogVisible" :title="dialogTitle" :width="dialogWidth" :top="dialogTop"
               :fullscreen="fullscreen" :before-close="handleDialogClose">
      <el-form :model="formData" :rules="rules" ref="rulesForm" :disabled="formDisabled"
               :label-position="formLabelPosition" :label-width="formLabelWidth">
        <el-row :gutter="20">
          <el-col :span="24?24:24">
            <el-form-item label="标签分类" prop="classification" v-if="true">
              <el-select v-model="formData.classification" :disabled="isFormItemEditDisabled(false)" clearable
                         filterable placeholder="请选择标签分类" style="width: 100%;">
                <el-option
                    v-for="item in classificationLyAudoCodeAPIList"
                    :key="item.id"
                    :label="item.name"
                    :value="item.id"/>
              </el-select>
            </el-form-item>
          </el-col>
          <el-col :span="24?24:24">
            <el-form-item label="标签名" prop="name" v-if="true">
              <el-input v-model="formData.name" :disabled="isFormItemEditDisabled(false)" style="width: 100%;"
                        placeholder="请输入标签名"/>
            </el-form-item>
          </el-col>
        </el-row>
      </el-form>
      <template v-slot:footer>
        <el-button @click="handleDialogClose">取消</el-button>
        <el-button @click="handleDialogSubmit" type="primary" :loading="dialogLoadingSave" v-if="dialogTitle!='详情'">
          确定
        </el-button>
      </template>
    </ly-dialog>
  </div>
</template>

<script>

import {ajaxDelete, ajaxDownloadExcel, ajaxGet, ajaxPost, ajaxPut} from '@/api/request'
import {useDictStore} from "@/store/dict";

import {dateFormats, deepClone, getTableHeight} from "@/utils/util";
import LyDialog from "@/components/dialog/dialog";
// 列表
const lyAutoCodeList = params => ajaxGet({url: `autocode/CustomLabel/`, params})
// 新增
const lyAutoCodeAdd = params => ajaxPost({url: `autocode/CustomLabel/`, params})
// 编辑
const lyAutoCodeEdit = params => ajaxPut({url: `autocode/CustomLabel/`, params})
// 删除
const lyAutoCodeDelete = params => ajaxDelete({url: `autocode/CustomLabel/`, params})
// 导出
const lyAutoCodeExport = params => ajaxDownloadExcel({url: `autocode/CustomLabel/export/`, params})
const classificationLyAudoCodeAPI = params => ajaxGet({url: `autocode/CustomLabel/`, params})

export default {
  name: "lyAutoCodeCustomLabel",
  components: {LyDialog},
  setup() {
    const dictStore = useDictStore()
    return {dictStore}
  },
  data() {
    return {
      isFull: false,
      tableHeight: 500,
      loadingPage: false,
      apiObjList: lyAutoCodeList,
      // 选项框选中数组
      ids: [],
      // 选项框非单个禁用
      single: true,
      // 非多个禁用
      multiple: true,
      formInline: {},
      timers: [],
      tableData: [],
      column: [
        {
          label: "标签分类",
          prop: "classification",
          minWidth: "100",
          hide: !true
        },
        {
          label: "标签名",
          prop: "name",
          minWidth: "100",
          hide: !true
        },
        {
          label: "创建时间",
          prop: "create_datetime",
          minWidth: "180"
        }
      ],
      //弹窗
      dialogVisible: false,
      dialogTitle: "",
      dialogLoadingSave: false,
      fullscreen: false,
      dialogWidth: "50%",
      dialogTop: "15vh",
      formDisabled: false,
      formLabelPosition: "right",
      formLabelWidth: "auto",
      formData: {"classification": null, "name": null},
      rules: {
        name: [{required: true, message: "标签名不能为空", trigger: 'blur'}],
      },
      classificationLyAudoCodeAPIList: [],
       treeData: [], // 初始化 treeData
    }
  },
  methods: {
    setLoadingPage(bools) {
      this.loadingPage = bools
      this.$refs.tableref.loadingPage(bools)
    },
    setFull() {
      this.isFull = !this.isFull
      window.dispatchEvent(new Event('resize'))
    },
    //多选项框被选中数据
    handleSelectionChange(selection) {
      this.ids = selection.map(item => item.id);
      this.single = selection.length !== 1;
      this.multiple = !selection.length;
    },
    /** 批量删除按钮操作 */
    handleDelete(row) {
      const ids = this.ids;
      let vm = this
      vm.$confirm('是否确认删除选中的数据项?', "警告", {
        confirmButtonText: "确定",
        cancelButtonText: "取消",
        type: "warning"
      }).then(function () {
        return lyAutoCodeDelete({id: ids}).then(res => {
          if (res.code == 2000) {
            vm.$message.success(res.msg)
            vm.getData()
          } else {
            vm.$message.warning(res.msg)
          }
        })
      })
    },
    convertStrNum(model, value) {
      if (model === "" || model === null || model === undefined) {
        return value
      } else if (Object.prototype.toString.call(model) === "[object String]") {
        return value.toString()
      } else if (Object.prototype.toString.call(model) === "[object Number]") {
        return value * 1
      } else {
        return value
      }
    },
    //判断单个组件编辑时是否禁用disabled参数为bool值
    isFormItemEditDisabled(disabled = false) {
      if (disabled == "" || disabled == null || disabled == undefined) {
        return false
      } else if ((disabled == true || disabled == 'True') && this.dialogTitle === '编辑') {
        return true
      }
      return false
    },
    handelAdd() {
      this.dialogTitle = "添加"
      this.dialogVisible = true
      this.classificationLyAudoCodeAPIFunction()
    },
    handleDialogClose() {
      this.dialogTitle = ""
      this.dialogVisible = false
      this.dialogLoadingSave = false
      this.formDisabled = false
      this.formData = {"classification": null, "name": null}
    },
    handleDialogSubmit() {
      this.$refs['rulesForm'].validate((obj) => {
        if (obj) {
          this.dialogLoadingSave = true
          let param = {
            ...this.formData
          }
          if (this.dialogTitle == "编辑") {
            lyAutoCodeEdit(param).then(res => {
              this.dialogLoadingSave = false
              if (res.code == 2000) {
                this.$message.success(res.msg)
                this.handleDialogClose()
                this.getData()
              } else {
                this.$message.warning(res.msg)
              }
            })
          } else {
            lyAutoCodeAdd(param).then(res => {
              this.dialogLoadingSave = false
              if (res.code == 2000) {
                this.$message.success(res.msg)
                this.handleDialogClose()
                this.getData()
              } else {
                this.$message.warning(res.msg)
              }
            })
          }

        }
      })
      this.dialogLoadingSave = false
    },
    handleEdit(row, flag) {
      if (flag == 'edit') {
        this.dialogTitle = "编辑"
        this.dialogVisible = true
        this.formData = deepClone(row)
        this.classificationLyAudoCodeAPIFunction()
      } else if (flag == 'delete') {
        let vm = this
        vm.$confirm('您确定要删除选中的数据吗？', '警告', {
          closeOnClickModal: false,
          type: "warning"
        }).then(res => {
          lyAutoCodeDelete({id: row.id}).then(res => {
            if (res.code == 2000) {
              vm.$message.success(res.msg)
              vm.getData()
            } else {
              vm.$message.warning(res.msg)
            }
          })
        }).catch(() => {

        })
      } else if (flag == 'detail') {
        this.dialogTitle = "详情"
        this.dialogVisible = true
        this.formDisabled = true
        this.formData = deepClone(row)
        this.classificationLyAudoCodeAPIFunction()
      } else if (flag == "reset") {
        this.formInline = {}
        this.timers = []
        this.search()
      }
    },
    /**
     * 从URL里下载文件
     */
    // 下载文件
    downloadFileURL(url) {
      var a = document.createElement("a")
      var event = new MouseEvent('click')
      a.href = url
      a.target = "_blank"
      a.dispatchEvent(event)
    },
    //excel文件流下载
    downloadExcelBlob(res) {
      let fileName = new Date().getTime() + ".xlsx"
      let dispositionStr = res.headers["content-disposition"];
      if (dispositionStr == null || dispositionStr === "") {

      } else {
        // 获取文件名
        let dispositionArr = dispositionStr.split(";");
        fileName = decodeURIComponent(dispositionArr[1]);
        fileName = fileName.split("=")[1];
      }
      const blob = new Blob([res.data], {
        type: "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet",
      });
      let href = window.URL.createObjectURL(blob); //下载链接
      let link = document.createElement("a")
      link.href = href
      link.download = fileName //下载后文件名
      document.body.appendChild(link);
      link.click(); //点击下载
      document.body.removeChild(link); //下载完成移除元素
      window.URL.revokeObjectURL(href);  //释放blob对象
      this.$message.success('导出成功')
    },
    //导出
    exportDataBackend() {
      let params = {}
      if (this.ids.length > 0) {
        params = {
          ids: this.ids,
        }
      }
      this.setLoadingPage(true)
      lyAutoCodeExport(params).then(res => {
        this.setLoadingPage(false)
        this.downloadExcelBlob(res)
      })
    },
    search() {
      // this.$refs.tableref.reload(this.formInline)
      // 直接调用 getData 来获取数据，不再使用 this.$refs.tableref.reload
  this.getData();
    },
    //获取列表
    async getData() {
      // this.$refs.tableref.getData()
      // 假设lyAutoCodeListForTags是获取用户标签数据的API调用
      const response = await lyAutoCodeList(this.formInline);
      if (response.code === 2000) {
        this.tableData = response.data.data;  // 原始数据（可能用于其他目的）
        this.treeData = this.buildTree(response.data.data);  // 转换为树形数据
      }
    },
    buildTree(items) {
      // 实现树形数据转换的逻辑（类似于之前的buildTree函数）
      // 创建一个映射，用于快速查找标签对象
      const map = new Map(items.map(item => [item.id, {...item, children: []}]));
      // 构建树
      items.forEach(item => {
        if (item.classification) {
          // 如果存在classification（父标签ID），则将其添加到父标签的children数组中
          map.get(item.classification).children.push(map.get(item.id));
        }
      });
      // 根节点是没有父节点的节点
      const tree = [];
      items.forEach(item => {
        if (!item.classification) {
          tree.push(map.get(item.id));
        }
      });
      return tree;
    },
    timeChange(val) {
      if (val) {
        this.formInline.beginAt = dateFormats(val[0], 'yyyy-MM-dd hh:mm:ss');
        this.formInline.endAt = dateFormats(val[1], 'yyyy-MM-dd hh:mm:ss');
      } else {
        this.formInline.beginAt = null
        this.formInline.endAt = null
      }
      this.search()
    },
    // 计算搜索栏的高度
    listenResize() {
      this.$nextTick(() => {
        this.getTheTableHeight()
      })
    },
    getTheTableHeight() {
      let tabSelectHeight = this.$refs.tableSelect ? this.$refs.tableSelect.offsetHeight : 0
      tabSelectHeight = this.isFull ? tabSelectHeight - 110 : tabSelectHeight
      this.tableHeight = getTableHeight(tabSelectHeight, false) - 125
    },
    classificationLyAudoCodeAPIFunction() {
      classificationLyAudoCodeAPI({page: 1, limit: 999}).then(res => {
        if (res.code == 2000) {
          this.classificationLyAudoCodeAPIList = res.data.data
        }
      })
    },

  },
  mounted() {
    // 监听页面宽度变化搜索框的高度
    window.addEventListener('resize', this.listenResize);
    this.$nextTick(() => {
      this.getTheTableHeight()
    })
  },

  unmounted() {
    // 页面销毁，去掉监听事件
    window.removeEventListener("resize", this.listenResize);
  },

}
</script>

<style lang="scss" scoped>

</style>
